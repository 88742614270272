@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.tasks-modal-header {
  padding-right: 4.0rem;
  p {
    margin-bottom: 1.0rem;
  }
  button {
    margin-top: -0.6rem;
  }
}
.tasks-modal-add-new {
  .tasks-modal-form-sections {
    height: auto;
    &:first-child {
      margin-bottom: $gap-16;
    }
    .tasks-modal-file-upload {
      .ant-upload-select-picture::after {
        content: "Only JPEG, GIF, PNG or PDF";
        font-size: $font-size-xxm;
        margin: $gap-10;
      }
    }
  }
}

.custom-task-table-task {
  .app-table {
    max-height: 50.0rem;
    overflow-y: auto;
  }
}

.patient-list-modal {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    .filter-wrapper {
      display: flex;
    }
    .exclude-filter {
      margin-left: $gap-10;
      margin-top: $gap-05;
      align-self: center;
      width: 15.6rem;
    }
  }
  .patent-list-search {
    margin-bottom: $gap-10;
  }
  .app-table {
    max-height: 50.0rem;
    overflow: auto;
  }
}

.tasks-modal-form-sections-wrap {
  @include flexbox();
  .tasks-modal-form-sections {
    @include flex(1);
    overflow: auto;
    height: 38vh;
    margin-right: $gap-12;
    & + .tasks-modal-form-sections {
      margin-bottom: $gap-16;
    }
  }
  .ant-upload-list.ant-upload-list-picture {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20.8rem);
    position: relative;
    left: -19.8rem;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    max-width: 21.6rem;
    min-width: 21.6rem;
    margin-left: $gap-12;
  }
}
.comment-card-container-wrap {
  height: 120px;
  overflow: auto;
  .ant-comment-inner {
    padding: 0px 0 $gap-16;
  }
}

.cmt-mr-b-0 {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}

.text-area-100 {
  width: calc(100% - 3.0rem);
}
