@use '../../css/vars' as vars;
@import "../css/mixin.scss";

.app {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    min-height: 100vh;
    background: #f7f9fb;
}

.right-content {
    @include flexbox();
    @include flex-direction(column);
    flex: 80;
    -webkit-flex: 80;
    padding-left: 64px;
    position: relative;
    transition: padding 0.2s ease-in;
    &.open-menu{
        padding-left: 25.6rem;
    }
}