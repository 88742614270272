@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.diagnostic-icd-component {
  .custom-select {
    .ant-row {
      .ant-select-selector {
        width: 25.0rem;
      }
    }
  }
  .app-table {
    margin: 0;
    border: 0;
    .delete-button {
      cursor: pointer;
    }
  }
  .icd-lookup-select {
    width: 30.0rem;
  }
  .title {
    @include align-items(center);
  }
}
.option {
  @include flexbox();
  @include flex-direction(column);
}

.delet-btn-icon{
  background: url('../../img/popup-delete-icon.png');
  width: 13px;
  height: 13px;
  display: inline-block;
  background-size: cover;
  position: relative;
  top: 3px;
}

