@import "../../../../../css/vars.scss";
@import "../../../../../css/mixin.scss";

$header-height: 38px;

.new-template-container {
  @include flexbox();
  @include flex-direction(column);
  height: calc(100vh - 3.2rem);
  max-height: calc(100vh - 3.2rem);

  .template-header {
    @include flexbox();
    @include align-items(center);
    padding-bottom: $gap-08;
    width: 100%;
    height: $header-height;
    max-height: $header-height;

    .page-title {
      margin-bottom: 0px;
      line-height: 1;

      &.back-arrow {
        position: relative;
        padding-left: 3.2rem;
        cursor: pointer;
        &:before {
          content: "";
          height: 16px;
          background: url("../../../../../img/backarrow.png");
          position: absolute;
          left: 0;
          width: 16px;
          top: 3px;
        }
      }
    }

    .editor-header-options {
      @include flex(1);
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);

      .label-container {
        margin-right: $gap-12;
        font-size: $font-size-xsm;
        label {
          color: $color-blue-light;
          margin-right: $gap-04;
        }
      }

      .settings-button {
        font-size: $font-size-ml;
      }
    }
  }

  .template-body {
    @include flex(1);
    @include flexbox();
    height: calc(100vh - #{$header-height + 32px});
    max-height: calc(100vh - #{$header-height + 32px});

    .merge-fields-container {
      margin-right: $gap-12;
      height: 100%;
      max-height: 100%;
      background: $app-background;
      border: $gap-01 solid $button-border;
      border-radius: $gap-04;

      .ant-input-search {
        width: 25.0rem !important;
      }

      .json-tree-container {
        overflow-y: auto;
        height: calc(100% - 5.2rem);
        width: 26.6rem;
        margin-top: $gap-08;

        .ant-tree {
          padding: $gap-04 $gap-08 $gap-04 0;
          background: none;
          font-size: $font-size-xsm;

          .tree-leaf-node {
            padding: 0 $gap-08;
            border: 1px solid $border-color;
            border-radius: $border-radius-base;
            margin: 0 -$gap-04;
            transition: background-color 0.2s linear;
            &:hover {
              background-color: $editor-highlight-light;
            }
          }
        }
      }
    }

    .editor-container {
      @include flex(1);
      height: 100%;
      max-height: 100%;
      position: relative;
      overflow: auto;

      .fr-box {
        @include flexbox();
        @include flex-direction(column);

        .fr-wrapper {
          @include flex(1);
          overflow: auto;
          border-radius: 0;

          .fr-element {
            height: 100%;
            max-height: 100%;
            ul,
            li {
              list-style: unset;
            }
            p {
              line-height: 1.5;
            }

            .drop-tag {
              padding: $gap-01 $gap-04 $gap-02;
              border: $gap-01 solid $border-color;
              border-radius: $border-radius-base;
              margin-top: -2px;
              line-height: 1.5;
              transition: background-color 0.2s linear;
              background-color: $editor-highlight-light;
            }
          }
        }

        .fr-toolbar {
          border-radius: 0;
          .fr-btn-grp {
            margin: 0 $gap-12;
          }
          .fr-more-toolbar {
            z-index: 3;
            max-height: 3.8rem;
            background-color: $editor-highlight-light;
          }
          &.fr-toolbar-open .fr-newline {
            padding-top: 3.8rem;
          }

          button {
            height: auto;
            border: 0.1rem solid $editor-highlight-light;

            &.fr-open {
              margin-top: 0;
              margin-bottom: 0.8rem;
              background-color: $editor-highlight-light !important;
            }
            &.fr-options {
              height: 3.0rem;
              border: 0;
              background-color: transparent;
            }
            &.fr-active {
              border: $gap-01 solid $color-green;
              background-color: $color-green-opc;
              svg > path {
                fill: $color-green !important;
              }
            }

            svg {
              margin: $gap-02;
            }
          }

          .fr-popup {
            &.fr-above {
              border-radius: $border-radius-base;
              .fr-buttons {
                padding: 0 $gap-08;
              }
            }
          }
        }

        &#header-container {
          min-height: 20%;

          .fr-wrapper {
            border: {
              top: $gap-01 solid $border-color;
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
              top-left-radius: $border-radius-base;
              top-right-radius: $border-radius-base;
            }
          }

          .fr-toolbar {
            z-index: 6 !important;
            border: {
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
              bottom: $gap-01 solid $border-color;
            }
            .fr-popup.fr-above {
              top: 50px !important;
            }
            .fr-dropdown-menu {
              z-index: 10;
              bottom: unset !important;
              top: 40px;
            }
          }
        }

        &#body-container {
          min-height: 60%;

          .fr-wrapper {
            border: 0 {
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
            }
          }

          .fr-toolbar {
            z-index: 7 !important;
            border: 0 {
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
            }
          }
        }

        &#footer-container {
          min-height: 20%;

          .fr-wrapper {
            border: {
              top: $gap-01 solid $border-color;
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
            }
          }

          .fr-toolbar {
            z-index: 8 !important;
            border: {
              left: $gap-01 solid $border-color;
              right: $gap-01 solid $border-color;
              bottom: $gap-01 solid $border-color;
              bottom-left-radius: $border-radius-base;
              bottom-right-radius: $border-radius-base;
            }
          }
        }
      }

      .extra-options-container {
        display: none;
        position: absolute;
        left: 50%;
        background-color: $color-white;
        padding: 8px 12px;
        .custom-select-box {
          width: 200px;
          .ant-form-item {
            margin-bottom: 0;
            margin-right: 8px;
          }
        }
        &.visible {
          @include flexbox();
        }
        &:focus-within {
          @include flexbox();
        }
      }
      .header-options-container {
        z-index: 5;
        -ms-transform: translateX(-50%) translateY(-1px);
        transform: translateX(-50%) translateY(-1px);
        border: 1px solid $border-color {
          top: 0;
          border-bottom-left-radius: $border-radius-base;
          border-bottom-right-radius: $border-radius-base;
        }
        form + button {
          min-width: 100px;
        }
      }
      .footer-options-container {
        z-index: 8;
        -ms-transform: translateX(-50%) translateY(-44px);
        transform: translateX(-50%) translateY(-44px);
        border: 1px solid $border-color {
          bottom: 0;
          border-top-left-radius: $border-radius-base;
          border-top-right-radius: $border-radius-base;
        }
        form + button {
          min-width: 100px;
        }
      }
    }
  }
}

.fr-popup {
  z-index: 10 !important;
}

.temp-search-wrapper {
  background: #ebf3ff;
  padding: $gap-04 $gap-08;
  .ant-input-search{
    height: 2.8rem;
  }
}

@media (max-width: $tablet-medium) {
  .new-template-container {
    .template-body{
      display: block;
      .merge-fields-container {
        margin-right: 0;
        margin-bottom: $gap-08;
        .json-tree-container{
          width: 100%;
        }
      }
    }
  }
}
