@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.note-section-form {
  color: $color-blue-light;

  .ant-form-item-control {
    .ant-form-item-control-input {
      height: auto;
      .ant-form-item-control-input-content {
        height: auto;
      }
    }
  }
}
.description-feilds {
  .note {
    color: $color-blue-light;
    max-width: 25.0rem;
    margin-top: $gap-05;
    min-height: $gap-10;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: $gap-15;
  }
}

.notes-widget {
  position: relative;
  .notes-edit-icon {
    background: url("../../img/pencil-fill2.png");
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    background-repeat: no-repeat;
    margin-bottom: 0px;
  }
}

.insurance-summary {
  padding: $gap-12 $gap-16;   
}

.notes-container {
  max-height: 28.5rem;
  overflow-y: auto;
  padding: $gap-02;
  .notes-inner-container {
    display: flex;
    align-items: center;
    padding: $gap-02 $gap-10;
    border: $gap-01 solid #d5d5d5;
    margin-bottom: $gap-05;
  }
}
