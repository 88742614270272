@import "../../css/vars.scss";

.app-content {
    margin: $gap-08 2.4rem 2.4rem;
    /* -------table css ---------*/
    .app-table {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                a {
                  color: $color-blue-light;
                }
              }
            }
          }
        }
      }
    }
  
}
