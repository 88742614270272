@import "../../../../../css/vars.scss";
@import "../../../../../css/mixin.scss";

.green-label {
  color: $color-green;
}

.blue-label {
  color: $color-blue-text;
}

