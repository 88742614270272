@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.custom-checkbox-select {
  .ant-row {
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 2.8rem;
        border-radius: $gap-04;
        border: $gap-01 solid rgba(165, 188, 223, 0.7);
      }
    }
    .ant-select-single {
        .ant-select-selector {
          .ant-select-selection-item {
            line-height: 2.7rem;
            color: $color-blue-light;
          }
          .ant-select-selection-placeholder {
            line-height: 2.8rem;
            color: $content-color;
            font-size: $font-size-s;
            font-weight: $font-regular;
            opacity: 1;
          }
          .ant-select-selection-search {
            top: -1px;
            input {
              line-height: 2.7rem;
              color: $color-blue-light;
              font-weight: 500;
            }
          }
        }
    }
    .ant-form-item-label > label {
      line-height: $font-size-m;
      font-size: $font-size-xsm;
      color: $color-blue-light;
    }
    #area1 {
      font-size: $font-size-xsm;
    }
  }
}
.checkbox-menu {
  width: auto;
  padding: $gap-08;
  @include flexbox();
  background-color: $color-white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  .custom-menu-items {
    @include flexbox();
    @include flex-direction(column);
    flex: 1;
    .item-left {
      .ant-checkbox-wrapper{
        @include flexbox();
        width: auto;
        font-weight: $font-regular;
        font-size: $font-size-s;
        line-height: 2.2rem;
        cursor: pointer;
        span{
          white-space: nowrap;
          line-height: 12px;
        }
        .ant-checkbox{
          position: relative;
          right: auto;
        }
      }
    }
    .item {
      @include flexbox();
      padding-left: 2.3rem;
      &.item-left {
        padding-left: 0;
      }
        .ant-checkbox-wrapper{
          @include flexbox();
          width: auto;
          font-weight: $font-regular;
          font-size: $font-size-s;
          line-height: 22px;
          margin-left: 0;
          cursor: pointer;
          span{
            white-space: nowrap;
            padding-right: 0;
          }
          .ant-checkbox{
            position: relative;
            right: auto;
          }
        }
      }
      
      .sm-checkbox-selected-active-outline {
        .ant-checkbox {
          outline: 3px solid $color-green;
          outline-offset: 1px;
        }
      }

      .sm-checkbox {
        .ant-checkbox-wrapper {
          @include align-items(center);
          padding-right: 5px;
          padding-bottom: 4px;
        }
      }
  } 
}

.label-without-checkbox {
  color: $content-color;
  font-size: $font-size-xsm;
  font-weight: 400;
  padding-right: $gap-08;
}