@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";

.collection-tab {
  .heading {
    height: 5.0rem;
  }
  .claim-container {
    @include flexbox();
    .side-tabs {
      padding: 1.6rem;
      @include flexbox();
      @include flex-direction(column);
      background: $color-white;
      border: $gap-01 solid $button-border;
      border-top-left-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
      box-shadow: $box-shadow-base;
      min-width: 24.2rem;
      button{
        margin-bottom: $gap-08;
      }
    }
    .top-tabs {
      flex-grow: 1;
      .complete-form-wrap{
        padding: 0 1.6rem 1.6rem;
      }
      .new-encounter{
        padding: $gap-08 1.6rem $gap-04;
      }
    }
  }
}
.claim-insurance-wrap{
  .insurance-side-bar{
    border-left: none;
    margin-left: 0;
  }
}

.change-responsibility-modal {
 .current-payer {
   color: $color-blue-light;
   font-size: $font-size-xsm;
 } 
}

@media (max-width: $tablet-medium) {
.collection-tab{
  .claim-container{
    display: block;
    .side-tabs{
      margin-bottom: $gap-08;
    }
  }
}
}