@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";

.new-doctor {
    .heading {
        height: 5rem;
    }
    &.claim-container {
        @include flexbox();
        .side-tabs {
            padding: 1.6rem;
            @include flexbox();
            @include flex-direction(column);
            background: $color-white;
            border: 1px solid $button-border;
            border-top-left-radius: $border-radius-base;
            border-bottom-left-radius: $border-radius-base;
            box-shadow: $box-shadow-base;
            min-width: 28.4rem;
            min-height: 88vh;
            margin-right: 1.6rem;
            button {
                margin-bottom: $gap-08;
            }
        }
        .new-doctor-tabs {
            width: calc(100% - 28.4rem);
        }
        .top-tabs {
            flex-grow: 1;
            .complete-form-wrap {
                padding: 0 1.6rem 1.6rem;
            }
            .new-encounter {
                padding: $gap-08 1.6rem $gap-04;
            }
        }
    }
}
.claim-insurance-wrap {
    .insurance-side-bar {
        border-left: none;
        margin-left: 0;
    }
}

.change-responsibility-modal {
    .current-payer {
        color: $color-blue-light;
        font-size: $font-size-xsm;
    }
}

span.hide-line {
    display: inline-block;
    position: absolute;
    height: calc(50% - 9px);
    width: 0px;
    background: #f6f9ff;
    bottom: 9px;
    left: -56px;
}
.new-doctor-tabs {
    .h-tabs {
        margin-left: 0;
        margin-top: 0;
    }
    .top-exam {
        .motility-container {
            height: calc(100% - 46px);
            form {
                &.ant-form-middle {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        form {
            &.ant-form-middle {
                .form-grid-component {
                    height: 100%;
                }
            }
            .form-grid-component {
                @include flexbox;
                @include flex-direction(column);
                grid-row-gap: 0;
            }
        }
        .mag-motility {
            width: calc(50% - 1.6rem);
            margin-right: $gap-12;
            .motility-shadow-container {
                height: 100%;
            }
        }
        .refact-area {
            width: 100%;
        }
        .other-vision-section {
            width: 100%;
        }
        .stereo-vision-exam-section {
            width: 100%;
        }
    }
}
.new-doctor-tabs .top-exam .medication-body form.ant-form-middle {
    width: 100%;
}
.patient-details {
    &.active {
        .new-main-dr-wrap {
            .new-doctor-aside {
                display: none;
                & + div {
                    width: 100%;
                }
            }
        }
    }
}
.new-main-dr-wrap {
    .select-box-section .procedures .right-tab .temp-search-wrapper .ant-input-search {
        margin-bottom: 0;
    }
    @include flexbox();
    margin-top: $gap-08;
    .h-tabs {
        margin: 0;
    }
    .new-dr-tabs {
        .h-tabs {
            & + div {
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%; // Before calc(100vh - 23.5rem);
                &.fix-height-tabs {
                    overflow-y: hidden;
                }
            }
            & + .fix-height-tabs {
                height: auto;
            }
        }
    }
    .new-doctor-aside {
        padding: 1.6rem;
        display: flex;
        -moz-flex-direction: column;
        flex-direction: column;
        background: #fff;
        border: 1px solid #d2ddef;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        min-width: 30rem;
        max-width: 30rem;
        min-height: 66vh;
        margin-right: 1.6rem;
        height: 100%;
        & + div {
            width: calc(100% - 31.6rem);
        }
        & + .new-dr-tabs {
            width: calc(100% - 30.2rem);
        }
        & + .select-box-section {
            .pannel.back-pannel {
                margin-bottom: 0;
            }
        }
        .ant-collapse-item {
            background: #eaf1fe;
            .ant-collapse-header {
                .add-list-menu {
                    @include flexbox;
                    @include justify-content(space-between);
                    @include align-items(center);
                    padding-right: 2rem;
                    color: $color-blue-text;
                }
            }
            .ant-collapse-content {
                .add-list-menu {
                    padding-right: 0;
                    @include justify-content(flex-start);
                    color: $content-color;
                    span {
                        margin-bottom: 4px;
                        margin-right: 4px;
                        white-space: normal;
                    }
                }
            }
        }
        .ant-collapse {
            & > .ant-collapse-item {
                &.ant-collapse-item-active {
                    & > .ant-collapse-header {
                        .ant-collapse-arrow {
                            transform: rotate(0deg);
                            -webkit-transform: rotate(0deg);
                        }
                    }
                }
                & > .ant-collapse-header {
                    .ant-collapse-arrow {
                        left: auto;
                        background: url("../../../../img/arrow-icons.png");
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        right: 1rem;
                        top: 50%;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        margin-top: -10px;
                        transition: transform 0.24s;
                        -webkit-transition: transform 0.24s;
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
        .ant-collapse-content > .ant-collapse-content-box {
            padding: $gap-10;
        }
        .add-list-menu {
            .ant-collapse-item {
                .ant-collapse-header {
                    font-size: $font-size-xsm;
                    color: $color-blue-text;
                    font-weight: $font-bold;
                    padding-left: 10px;
                }
            }
            .medication-list {
                &.custom-table-scroll {
                    padding: 0;
                    .pannel-wrapper-medication-list {
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
    .refact-area {
        margin-top: 0;
    }
    .select-box-section .procedures .side-tab {
        min-height: calc(100vh - 27.4rem);
        &.drag-section {
            .pannel {
                height: 100%;
                margin-bottom: 0;
                button {
                    &.min-wt-86 {
                        position: absolute;
                        bottom: 12px;
                    }
                }
            }
        }
        .pannel.back-pannel {
            height: 100%;
            position: relative;
            padding-bottom: 4.4rem;
            margin-bottom: 0;
            button {
                position: absolute;
                bottom: 1.6rem;
            }
        }
        & + .right-tab {
            min-height: calc(100vh - 28.6rem);
            overflow-y: scroll;
            .side-tab {
                min-height: calc(100vh - 35.4rem);
            }
        }
    }
    .modifier-wrap .label {
        margin-left: 2rem;
        @include flexbox();
        @include align-items(center);
    }
    .doctor-side-tabs {
        & > div {
            width: 100%;
        }
        .new-doctor-aside-wrap {
            width: auto;
        }
    }
    .tb-dlt-icon {
        top: $gap-03;
    }
    .billing-modal {
        .content-filter-area .manage-billing-filter-header + .modifier-wrap .modi-fire-ui-manage {
            overflow: hidden;
        }
    }
    .content-filter-area {
        &.filter-wrap {
            @include flex-direction(column);
            @include align-items(flex-start);
            margin-bottom: $gap-08;
            .modifier-wrap {
                form {
                    overflow: inherit;
                }
            }
        }
        .manage-billing-filter-header {
            @include flexbox;
            margin-bottom: $gap-08;
            .code-value {
                margin-right: $gap-12;
            }
            .billing-radio {
                @include flexbox;
                @include align-items(center);
            }
            & + .modifier-wrap {
                .modifier-input-wrap {
                    width: calc(100% - 2rem);
                    @include align-items(flex-start);
                    .mr-lt-12 {
                        margin-left: 0.8rem;
                    }
                    .billing-diagnosis {
                        margin-right: $gap-08;
                        height: 2.8rem;
                    }
                    button {
                        margin-left: 0;
                    }
                }
                .modi-fire-ui-manage {
                    width: 100%;
                    overflow: auto;
                    margin-right: 0.8rem;
                    .modi-fire-form-wrap {
                        .ant-col-18 {
                            overflow: auto;
                            max-width: 100%;
                            flex: 0 0 100%;
                            .billing-overflow {
                                overflow: hidden;
                                overflow-x: auto;
                            }
                        }
                        .billing-diagnosis {
                            &:last-child {
                                margin-right: 0;
                            }
                            .ant-space.ant-space-vertical {
                                width: 100%;
                                .ant-select {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                }
                .label {
                    margin-left: 0;
                }
                form {
                    & + .label {
                        margin-left: $gap-08;
                    }
                }
                .modifier-input-wrap form {
                    max-width: 100%;
                }
            }
        }
    }
}

.new-dr-all-wrap {
    @include flex-direction(column);
    .patient-details {
        width: 100%;
    }
    .summary-btn {
        margin-bottom: 0.8rem;
        display: inline-block;
        max-width: 11rem;
    }
}
.new-main-dr-wrap .new-doctor-aside-wrap + div {
    width: calc(100% - 31.6rem);
    &.select-box-section {
        width: calc(100% - 31.6rem);
    }
}
.add-list-menu {
    position: relative;
    .problem {
        position: relative;
        display: inline-block;
        &::before {
            content: "";
            position: absolute;
            background: url("../../../../img/alert-fill.png");
            width: 18px;
            height: 17px;
            left: -28px;
            top: 2px;
        }
    }
    .allergy {
        position: relative;
        display: inline-block;
        &::before {
            content: "";
            position: absolute;
            background: url("../../../../img/virus-icon.png");
            width: 18px;
            height: 17px;
            left: -28px;
            top: 2px;
        }
    }
    .medication {
        position: relative;
        display: inline-block;
        &::before {
            content: "";
            position: absolute;
            background: url("../../../../img/medication-icon.png");
            width: 16px;
            height: 16px;
            left: -28px;
            top: 2px;
        }
    }
    .care {
        position: relative;
        display: inline-block;
        &::before {
            content: "";
            position: absolute;
            background: url("../../../../img/team-icon.png");
            width: 18px;
            height: 17px;
            left: -28px;
            top: 2px;
        }
    }
}

.top-exam-wrap-section {
    @include flexbox();
    @include flex-wrap(wrap);
    & > form,
    & > .exam-left-wrap,
    & > .mag-motility {
        @include flex(1);
        min-width: 28.8rem;
        max-width: 28.8rem;
        margin-bottom: $gap-08;
    }
}
.manage-expend-collapse-btn {
    border-top: 1px solid $button-border;
    border: 1px solid $button-border;
    background: $color-white;
    position: absolute;
    left: -1px;
    width: calc(100% + -6px);
    padding: 0 8px;
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 37px;
    border-radius: 4px;
    margin-right: 8px;
    .wrap-expend-collapse {
        @media (max-width: 1440px) {
            position: relative !important;
            top: 5px !important;
        }
    }
    .json-tree-container {
        &.order-set-tree-wrap {
            max-height: 34vh;
        }
    }
    .right-tab {
        .json-tree-container {
            &.order-set-tree-wrap {
                .ap-accordion-tree {
                    .right-tree {
                        .ant-collapse {
                            .ant-collapse-item {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .merge-fields-container {
        .temp-search-wrapper {
            .ant-input-search {
                width: 100% !important;
            }
        }
    }
}

.middle-btns .clipbox {
    width: 9.2rem;
    margin-top: $gap-03;
    margin-bottom: $gap-03;
    color: $color-green;
    border: $gap-01 solid $color-green;
    text-transform: capitalize;
    margin-right: $gap-08;
    .back-arrow {
        padding-left: 2.2rem;
        padding-top: $gap-01;
    }
}
.imaging-header {
    background: $color-light-grayies;
    border: $gap-01 solid $button-border;
    color: $color-blue-text;
    @include flexbox;
    @include align-items(center);
    height: 3.4rem;
    padding-left: $gap-12;
    border-radius: $gap-04 $gap-04 0 0;
    @include justify-content(space-between);
    padding-right: $gap-12;
    p {
        margin: 0;
    }
    & + div {
        border: 1px solid $button-border;
        border-top: none;
        padding: $gap-08 $gap-12;
    }
}
.image-switch {
    width: 5.4rem;
    border: $gap-01 solid $button-border;
    border-radius: $gap-12;
    overflow: hidden;
    margin-left: $gap-12;
    height: 2.4rem;
    span {
        @include flex(1);
        width: 50%;
        display: inline-block;
        text-align: center;
        height: 2.4rem;
        position: relative;
        &.active-btn {
            &.active {
                background: $color-green;
                color: $color-white;

                &::before {
                    content: "";
                    background: url("../../../../img/file-list-line.png");
                    position: absolute;
                    width: 2rem;
                    height: 1.6rem;
                    background-size: contain;
                    background-repeat: no-repeat;
                    left: 0.5rem;
                    top: 0.3rem;
                }
            }
            &::before {
                content: "";
                background: url("../../../../img/file-list-line-w.png");
                position: absolute;
                width: 1.3rem;
                height: 1.6rem;
                background-size: contain;
                background-repeat: no-repeat;
                left: 0.7rem;
                top: 0.4rem;
                border-radius: 2px;
            }
        }
        &.in-active-btn {
            &::before {
                content: "";
                background: url("../../../../img/layout-line-2.png");
                position: absolute;
                width: 2rem;
                height: 1.8rem;
                background-size: contain;
                background-repeat: no-repeat;
                left: 0.3rem;
                top: 0.2rem;
            }
            &.active {
                background: $color-green;
                color: $color-white;
                &::before {
                    content: "";
                    background: url("../../../../img/layout-line-2-w.png");
                    position: absolute;
                    width: 1.3rem;
                    height: 1.4rem;
                    background-size: contain;
                    background-repeat: no-repeat;
                    left: 0.5rem;
                    top: 0.4rem;
                }
            }
        }
    }
}
.add-circle {
    color: $content-color;
    font-size: $font-size-m;
    font-weight: $font-bold;
    margin-bottom: $gap-06;
    margin-top: $gap-04;
    span {
        display: inline-block;
        background: url("../../../../img/Sprite.png");
        background-size: 751px;
        background-position: -242px -418px;
        width: 20px;
        height: 20px;
        top: 5px;
        position: relative;
    }
}
.tb-dlt-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url("../../../../img/delete-bin.png");
    position: relative;
    top: 7px;
    cursor: pointer;
}
.custom-table-view {
    overflow-y: auto;
    .custom-table {
        width: 100%;
        border: 1px solid $button-border;
        tr {
            td {
                padding: 0 6px;
            }
        }
        .table-all-select-wrap {
            .ant-input-search {
                height: 2.8rem;
            }
            @include flexbox;
            .table-selec-wrap {
                @include flexbox;
                margin-right: $gap-08;
                & > span {
                    padding: 0 $gap-06;
                    width: 1.8rem;
                    height: 1.8rem;
                    color: #748aac;
                    border-radius: 50%;
                    font-weight: bold;
                    display: inline-flex;
                    justify-content: center;
                    -webkit-justify-content: center;
                    align-items: center;
                    margin: $gap-05;
                }
                .custom-select {
                    .ant-row {
                        width: 6rem;
                    }
                }
                .ant-row.ant-form-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.encounter-form-ui {
    .form-grid-component {
        .form-grid-column {
            display: block;
            .custom-select-box {
                .ant-row.ant-form-item{
                    margin-bottom: 0.4rem;
                }
            }
        }
    }
}
.gap-mng-td {
    .ant-row.ant-form-item {
        margin: $gap-04 0;
    }
}
.flex.width-100 {
    width: 8.8rem;
    min-width: 8.8rem;
    & > div {
        margin-right: $gap-06;
        & + div {
            margin-right: 0;
        }
    }
    .custom-checkbox {
        .ant-row .ant-form-item-label > label {
            padding-top: 0;
        }
    }
}
.dx-label {
    min-width: 25%;
    font-size: $font-size-xsm;
    color: $color-blue-light;
}
.dx-filter {
    @include flexbox;
    @include align-items(center);
    .dx-filter-content {
        @include flexbox;
        width: calc(33.333% - 4px - 8.3%);
        margin-right: $gap-06;
        .custom-select {
            width: 100%;
        }
    }
}
.diagnosis-wrap {
    margin: 0;
    .table-selec-wrap {
        @include flexbox;
        & > span {
            padding: 0 $gap-06;
            width: 1.8rem;
            height: 1.8rem;
            color: #fff;
            border-radius: 50%;
            font-weight: bold;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: $gap-05;
            background: #32c788;
        }
        .custom-select {
            width: 100%;
        }
        .ant-row.ant-form-item {
            margin-bottom: 0;
        }
    }
}
.align-search-box {
    .ant-input-search {
        width: 100% !important;
        margin: $gap-02 0 $gap-02 0;
    }
}
.doctor-side-tabs {
    .addition-header {
        border-bottom: none;
    }
}
.checkbox-wraps {
    @include flexbox;
    .sm-checkbox {
        .ant-row.ant-form-item {
            margin-bottom: 0;
        }
        .ant-checkbox-wrapper {
            @include flexbox;
            @include align-items(center);
        }
    }
}
.image-layout-wrap {
    @include flexbox;
    @include justify-content(space-between);
    .side-image-wrap {
        width: 15rem;
    }
    .right-side-menu {
        width: 15rem;
    }
    .mid-section {
        width: calc(100% - 30rem);
    }
}
.section-checkbox-wrapper {
    &.only-group-check {
        .custom-checkbox {
            .ant-col-10 {
                .ant-checkbox-group {
                    @include flexbox;
                    @include justify-content(flex-end);
                    .ant-checkbox-group-item {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.left-layout-img-wrap {
    @include flexbox;
    @include flex-wrap(wrap);
    .img-layout {
        position: relative;
        .sm-checkbox {
            position: absolute;
            top: -$gap-02;
            left: $gap-06;
        }
        .dlt-icon {
            display: none;
        }
        &:hover {
            .dlt-icon {
                display: block;
                background: url("../../../../img/delete-bin.png");
                width: 12px;
                height: 12px;
                background-size: cover;
                position: absolute;
                right: 6px;
                top: 6px;
                cursor: pointer;
            }
        }
    }
}
.layout-area {
    @include flexbox;
    .images-viewer-modal {
        padding: $gap-08;
        border: $gap-01 solid $button-border;
        border-radius: 4px;
        .ant-row {
            .ant-col-xs-8,
            .ant-col-xs-16 {
                max-width: 100%;
                flex: 0 0 100%;
            }
            .ant-col-xs-8 {
                margin-bottom: $gap-08;
            }
        }
    }
    .left-layout-img {
        border: $gap-01 solid $button-border;
        width: 16rem;
        margin-right: $gap-12;
        border-radius: $gap-04;
        padding: $gap-08;
        @include flexbox;
        @include flex-direction(column);
        & + div {
            width: calc(100% - 32rem);
        }
        .img-layout {
            width: 6.6rem;
            min-width: 6.6rem;
            height: 6.4rem;
            border: $gap-01 solid $button-border;
            margin: 0 $gap-02 $gap-04;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .right-od-os {
        border: $gap-01 solid $button-border;
        width: 16rem;
        margin-left: $gap-12;
        border-radius: $gap-04;
        padding: $gap-08;
        .right-od-os-wrap {
            .ant-input-search {
                width: 100% !important;
                height: 3rem;
            }
        }
    }
    .drop-imaging-wrap {
        .addition-header.images-viewer-modal-header {
            border-bottom: none;
        }
    }
}
.select-box-section {
    .procedure-right-section {
        .side-tab {
            width: 100%;
            margin-right: 0;
        }
    }
}
.procedure-right {
    position: relative;
    padding-left: 4.9rem;
    &.ap-tree {
        padding-top: $gap-05;
        .arrow {
            left: $gap-07;
        }
    }
    .arrow {
        background: #ffffff;
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        top: $gap-07;
        left: -4.2rem;
        border: $gap-01 solid #000;
        cursor: pointer;
        z-index: 9;
    }
    & > div {
        &:last-child {
            .sub-l-child-group {
                span.hide-line {
                    display: inline-block;
                    position: absolute;
                    height: calc(50% - 9px);
                    width: 0px;
                    background: #f6f9ff;
                    bottom: $gap-09;
                    left: -5.7rem;
                }
            }
        }
    }
}
.procedure-right .down:before {
    content: "-";
    top: -15px;
    left: 3px;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    position: absolute;
}
.ap-parent {
    &.diagnosis-group-arrow {
        &.right-tree {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.hhh {
    font-size: $font-size-s;
    font-weight: bold;
    color: $color-blue-light;
    .sub-l-child-group {
        font-size: $font-size-s;
        color: $color-blue-light;
        font-weight: $font-regular;
    }
    .sm-checkbox {
        position: absolute;
        width: 13px;
        height: 13px;
        left: -25px;
        top: -6px;
    }
    .positive-relative {
        p {
            margin: $gap-03 0 $gap-04 0;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            white-space: normal;
            display: block;
            padding-right: 0;
            min-height: 2.2rem;
        }
    }
    .in-between-space {
        display: none;
    }
}
.with-checkbox {
    .pannel {
        margin-left: 2rem;
        .pannel-heading {
            position: relative;
            .sm-checkbox {
                height: 1.6rem;
                position: absolute;
                left: -2.8rem;
                top: 0;
            }
        }
    }
}

.manage-checkbox-ui {
    .ant-checkbox-group {
        @include flexbox;
        @include justify-content(flex-end);
        label {
            width: 5rem !important;
            margin-right: 0px;
            font-weight: normal;
        }
    }
}
.positive-relative {
    position: relative;
    display: inline-block;
}
.billing-only-head {
    @include flexbox;
    margin-bottom: $gap-08;
    .orange-flag-area {
        background: $orange-panel;
        border: $gap-01 solid $orange-panel-border;
        border-radius: 4px;
        @include flexbox;
        padding: $gap-06 $gap-12;
        margin-right: $gap-12;
        width: calc(100% - 20.8rem);
        p {
            margin-bottom: 0;
            color: $color-blue-light;
        }
    }
    .billing-footer {
        margin-top: 0;
    }
}

.diagnosis {
    .ant-tree-treenode {
        position: relative;
        .ant-tree-title {
            position: relative;
            display: block;
        }
        .close-icon {
            background: #ffffff;
            width: 14px;
            height: 14px;
            position: absolute;
            top: 4px;
            right: -22px;
            border-radius: 50%;
            border: solid 1px #d2ddef;
            font-weight: 400;
            font-style: normal;
            line-height: 12px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            font-size: 11px;
        }
    }
}

.procedures {
    & > .side-tab {
        .merge-fields-container {
            .json-tree-container {
                .ant-tree-treenode {
                    padding-right: 2rem;
                    position: relative;
                    .ant-tree-title {
                        position: relative;
                        display: block;
                        .tree-parent-node {
                            padding-right: 2.2rem;
                        }
                        .tree-leaf-node {
                            padding-right: 2.2rem;
                        }
                    }
                    .dlt-list {
                        width: 16px;
                        height: 16px;
                        background: url("../../../../img/close-circle.png");
                        display: inline-block;
                        right: 0;
                        top: 3px;
                        position: absolute;
                    }
                }
            }
        }
    }
    .remove-tree-switchers {
        .ant-tree-switcher {
            display: none;
        }
    }
}
.new-dr-tabs {
    width: 100%;
    .top-exam {
        & > .refact-area.app-table {
            width: calc(100% - 1.4rem);
        }
        .other-vision-section,
        .stereo-vision-exam-section {
            width: calc(100% - 1.4rem);
        }
    }
    .width-288 {
        min-width: 28.8rem;
        max-width: 28.8rem;
    }
    .crossponding-area {
        margin-bottom: 0;
        @include flexbox;
        @include justify-content(flex-end);
    }
    .patient-history {
        .tab-container {
            padding-right: 0;
        }
    }
}

.imaging-table-selectbox {
    & > div {
        width: 100%;
    }
}
.imaging-diagnosis {
    @include flexbox;
    @include align-items(center);
    .label {
        color: $color-blue-light;
        font-size: $font-size-xsm;
        margin-right: $gap-06;
    }
}
.imaging-diagnosis-dx {
    padding-top: 0;
    padding-bottom: $gap-04;
    @include flexbox;
    @include align-items(center);
    .billing-overflow {
        overflow-y: hidden;
        overflow-x: auto;
        .billing-diagnosis {
            margin-right: 0.4rem;
        }
    }
}
.od-os-wrap-main {
    .od-os-wrap {
        .custom-input {
            & + .custom-input {
                margin-right: 0;
            }
        }
    }
}
.drop-imaging-wrap {
    width: 100%;
    .imaging-history-list {
        .table-container {
            overflow: hidden;
        }
    }
    .addition-header {
        .ant-row {
            width: 100%;
        }
    }
    .imaging-view-filter-section .folder-container .panzoom-container {
        max-width: 100%;
        max-height: 100%;
        .image-bottom-control-collapsable {
            display: none;
        }
    }
}
.sub-filter-header {
    margin: 0 -$gap-12 $gap-08;
    padding: 0 $gap-12;
    border-bottom: $gap-01 solid #d2ddef;
}

.header-filter {
    .right-od-os {
        .right-od-os-wrap {
            @include flexbox;
            .ant-input-affix-wrapper {
                height: 2.8rem;
                margin: 0;
            }
            .diagnosis-wrap {
                margin: 0;
            }
            .custom-input,
            .diagnosis-wrap,
            .ant-input-affix-wrapper.ant-input-search {
                width: 160px !important;
                margin-right: $gap-12;
            }
        }
    }
}

.mng-vision-gap {
    #form {
        .form-grid-component {
            display: block;
            height: calc(100% - 4.5rem);
        }
    }
    .mag-motility {
        .motility-shadow-container {
            margin-right: $gap-12;
        }
    }
}

.block-ui-display {
    .pannel {
        box-shadow: none;
        border: 1px solid #d2ddef;
    }
    .pannel-body {
        padding: $gap-06 1.4rem 0 1.4rem !important;
        p {
            color: $color-blue-light;
            font-size: $font-size-xsm;
        }
        textarea {
            border: none !important;
            width: 100%;
            height: auto;
            min-height: auto;
            resize: none;
            z-index: 0;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    .ant-input-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: transparent;
        cursor: default;
        opacity: 1;
        overflow: hidden;
    }
}
.right-tree-view {
    position: relative;
    .positive-relative {
        b {
            color: #32c788;
            margin: $gap-02 0 $gap-04 -$gap-08;
            display: inline-block;
        }
    }
    &:before {
        content: "";
        width: 1px;
        height: calc(100% - 25px);
        background: #000;
        position: absolute;
        top: 15px;
        left: 14px;
    }
    & > div > div > div {
        position: relative;
        &::before {
            content: "";
            width: 8px;
            height: 1px;
            background: #000;
            position: absolute;
            top: 9px;
            left: -34px;
        }
    }
    .sub-l-child-group {
        position: relative;
        &::before {
            content: "";
            width: 32px;
            height: 1px;
            position: absolute;
            bottom: auto;
            left: -56px;
            top: calc(50% - 0px);
        }
        &:after {
            content: "";
            width: 1px;
            height: calc(100% - 19px);
            background: #000;
            position: absolute;
            top: 9px;
            left: -24px;
        }
        & > div {
            position: relative;
            &::before {
                content: "";
                width: 8px;
                height: 1px;
                background: #000;
                position: absolute;
                bottom: 10px;
                left: -23px;
            }
        }
    }
    .arrow-close {
        background: #ffffff;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0px;
        right: 22px;
        border-radius: 50%;
        border: 1.8px solid #0000007d;
        font-weight: bold;
        font-style: normal;
        line-height: 8px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        color: #00000070;
        padding-left: 1px;
        font-family: sans-serif;
        padding-top: 1px;
    }
}
.right-tree {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: $gap-02 1.6rem 0px 2.6rem;
        color: $color-blue-text;
        font-size: $font-size-m;
        text-transform: capitalize;
        font-weight: normal;
        .ant-collapse-arrow {
            left: $gap-08;
            padding-top: 2px;
        }
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
        background: #edf1f9fa;
        border-top: $gap-01 solid rgba(165, 188, 223, 0.7);
    }
}

.tree-panel-box {
    margin: $gap-08;
    .ant-input-disabled {
        color: $color-blue-light;
        background-color: transparent;
        cursor: default;
        opacity: 1;
        overflow: hidden;
    }
}

.json-tree-container {
    margin-bottom: $gap-08;
    .right-tree {
        margin-bottom: 0px;
        border-bottom: $gap-01 solid #cccccc;
        &:empty {
            border: none;
        }
        &:last-child {
            border-bottom: none;
        }
        .ant-collapse {
            background-color: #eaf1fe;
            border: none;
            border-radius: 0px;
            .ap-parent {
                margin-bottom: 0;
            }
        }
    }
    &.remove-tree-switchers {
        .ant-tree-treenode {
            position: relative;
            border: $gap-01 solid #cccccc;
            padding-left: 3rem;
            margin-bottom: $gap-03;
            border-radius: $gap-02;
            padding-right: 2.4rem;
            color: $color-blue-light;
            font-size: $font-size-xsm;
            font-weight: bold;
            padding-bottom: 0;
            min-height: 2.4rem;
            @include align-items(center);
            width: 100%;
            .ant-tree-node-content-wrapper {
                @include flexbox();
                position: static;
            }
            &:after {
                content: "";
                width: 26px;
                height: 100%;
                background: #ccc;
                position: absolute;
                top: 0;
                left: 0px;
                background-image: url("../../../../img/grid-fill4.png");
                background-repeat: no-repeat;
                background-position: 2px 0px;
                background-size: 84%;
            }
            .ant-tree-title {
                line-height: 1.6rem;
                padding-top: $gap-05;
                padding-bottom: $gap-02;
            }
        }
        .ant-tree .ant-tree-node-content-wrapper:hover {
            background: transparent;
        }
    }

    .ap-accordion-tree {
        .procedure-right {
            padding-left: $gap-10;
            padding-right: $gap-12;
        }
        .right-tree-view {
            .hhh {
                .positive-relative {
                    @include flexbox();
                }
            }
            &:before {
                width: 0px;
            }
            .arrow {
                display: none;
            }
            .sub-l-child-group > div::before {
                height: 0;
            }
            .sub-l-child-group {
                & > div {
                    &:after {
                        content: "";
                        width: 15px;
                        height: 2px;
                        background: #cccccc;
                        position: absolute;
                        bottom: 10px;
                        left: -16px;
                    }
                }
            }
            & > div > div > div {
                border: 1px solid #cccccc;
                padding-left: 3.8rem;
                margin-bottom: $gap-03;
                border-radius: $gap-02;
                position: relative;
                &.sub-l-child-group {
                    padding-left: $gap-08;
                    &::before {
                        display: none;
                    }
                    &:after {
                        content: "";
                        width: 2px;
                        height: calc(100% - 8px);
                        background: #ccc;
                        position: absolute;
                        top: -3px;
                        left: -9px;
                    }
                    border: none;
                    & > div {
                        border: $gap-01 solid #cccccc;
                        padding-left: 3.8rem;
                        margin-bottom: $gap-03;
                        border-radius: $gap-02;
                        position: relative;
                        &::before {
                            content: "";
                            width: 26px;
                            height: 100%;
                            background: #ccc;
                            position: absolute;
                            top: 0;
                            left: 0px;
                            background-image: url("../../../../../src/img/grid-fill4.png");
                            background-repeat: no-repeat;
                            background-position: 2px 0px;
                            background-size: 84%;
                        }
                    }
                }
                &::before {
                    content: "";
                    width: 26px;
                    height: 100%;
                    background: #ccc;
                    position: absolute;
                    top: 0;
                    left: 0px;
                    background-image: url("../../../../../src/img/grid-fill4.png");
                    background-repeat: no-repeat;
                    background-position: 2px 0px;
                    background-size: 84%;
                }
            }
        }
    }
}
.ap-accordion-tree {
    border: 1px solid rgba(165, 188, 223, 0.7);
    .right-tree .ant-collapse > .ant-collapse-item > .ant-collapse-content {
        background: $color-white;
    }
}
.tree-modals {
    .ap-parent > div > div > div {
        padding-left: 4.8rem;
    }
    .ap-parent > div > div > div.sub-l-child-group > div {
        padding-left: 4.8rem;
    }
    .ap-parent > div > div > div.sub-l-child-group {
        padding-left: 0 !important;
    }
}

i.arrow-close.extra-close {
    display: none;
}
@media (max-width: $lg-tablet) {
    .new-main-dr-wrap {
        .new-doctor-aside {
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
        }
        .new-doctor-aside-wrap {
            & + div {
                &.select-box-section {
                    width: 100%;
                    margin-top: $gap-12;
                }
            }
        }
    }
}
@media (min-width: $medium-desktop) {
    .top-exam-wrap-section > form,
    .top-exam-wrap-section > .exam-left-wrap,
    .top-exam-wrap-section > .mag-motility {
        min-width: 30rem;
        max-width: 30rem;
    }
    .intake-specific-area {
        max-width: 90rem;
    }
}
@media (max-width: $medium-desktop) {
    .new-main-dr-wrap .modifier-wrap .modifier-input-wrap form {
        max-width: 9.6rem;
    }
}
@media (min-width: $medium-desktop) {
    .custom-input .ant-row .ant-form-item-label > label {
        max-width: 26.8rem;
    }
}
@media (max-width: $lg-tablet) {
    .middle-btns {
        .clipbox {
            margin-top: 0rem;
            margin-bottom: $gap-12;
        }
    }

    .new-main-dr-wrap {
        .new-dr-tabs {
            .h-tabs {
                & + div {
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: $min-tablet) {
    .new-main-dr-wrap {
        .new-doctor-aside-wrap {
            & + div {
                width: 100%;
            }
        }
    }
    .new-dr-tabs {
        .width-288 {
            min-width: 100%;
            max-width: 100%;
        }
    }
    .top-exam-wrap-section {
        & > form,
        & > .exam-left-wrap,
        & > .mag-motility {
            min-width: 100%;
            max-width: 100%;
        }
    }
}

@media (max-width: $tablet-medium) {
    .intake-specific-area {
        max-width: 90rem;
    }
    .collection-tab {
        .claim-container {
            display: block;
            .side-tabs {
                margin-bottom: $gap-08;
            }
        }
    }
    .middle-btns {
        .clipbox {
            .back-arrow {
                padding-left: 2.8rem;
                padding-top: 0rem;
            }
        }
    }
    .new-main-dr-wrap {
        .select-box-section {
            .procedures {
                .side-tab {
                    min-height: 100%;
                    margin-bottom: 12px;
                    & + .right-tab {
                        min-height: 100%;
                        min-width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .billing-only-head {
        display: block;
        .orange-flag-area {
            width: 100%;
            margin-bottom: 8px;
        }
    }
    .new-main-dr-wrap {
        .content-filter-area {
            .manage-billing-filter-header {
                display: block;
                .billing-radio {
                    margin-left: 0;
                }
            }
        }
    }
}
