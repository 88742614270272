@import "../../css/vars.scss";

.tooltip-container > .ant-tooltip-content {
  .ant-tooltip-inner {
    color: $color-darkgray;

    div:first-child {
      color: $color-lightgray-2;
      border-bottom: $gap-01 solid $color-lightgray;
      padding-bottom: $gap-01;
      margin-bottom: $gap-02;
      font-size: $font-size-m;
    }
    h4 {
      margin-bottom: $gap-05;
    }
    p {
      font-size: $font-size-xsm;
    }
  }
}
