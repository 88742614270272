@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";

.referral-panel {
  .referral-buttons {
    @include flexbox;
    @include justify-content(flex-end);
    padding-bottom: 1.5rem;
  }
  .referral-list {
    width: 35.0rem;
    @include flexbox;
    color: $color-blue-light;
    font-size: $font-size-s;
    .name {
      font-weight: $font-bold;
    }
    .detail-wrapper {
      flex: 1;
    }
    .actions {
      width: 3.0rem;
      @include flexbox;
      @include justify-content(flex-end);
      @include align-items(center);
      div {
        padding-right: $gap-10;
      }
    }
  }
  .referrals {
    max-height: 8.0rem;
    overflow: auto;
  }
}

.width-350{
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $content-color!important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $content-color!important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $content-color!important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $content-color!important;
  }
  .custom-input .ant-row .ant-form-item-control .ant-input{
    padding: 4px 6px;
  }
  .ant-select-multiple .ant-select-selection-placeholder{
    left: 6px;
  }
}