@import '../../css/vars.scss';
@import '../../css/mixin.scss';

.app-open-downloadbox {
  background: #eaf2ff;
  border-right: $gap-01 solid $button-border;
  background-color: transparent;
  position: relative;

  .ant-drawer-content-wrapper {
    padding-left: 60px;
    .ant-drawer-content {
      overflow: visible;
      .ant-drawer-body {
        padding-top: $gap-12;
        position: relative;
      }
    }
  }

  // Print overlay
  .print-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $gap-08;
  }

  // Download header
  .download-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap-08;

    .title {
      margin-bottom: 0;
    }
  }

  // Document filter
  .document-filter {
    margin-bottom: $gap-12;
    
    .ant-radio-group {
      margin-left: $gap-04;
    }
  }

  // Download statistics
  .download-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: $gap-12;
    padding: $gap-08 $gap-04;
    background-color: #f9f9f9;
    border-radius: 8px;

    .stat-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $gap-04;
      
      .stat-badge {
        margin-bottom: $gap-04;
        
        .ant-badge-count {
          font-size: 16px;
          font-weight: 500;
          height: 36px;
          width: 36px;
          min-width: 36px;
          border-radius: 50%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }

      .ant-typography {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  // Section divider
  .section-divider {
    margin: $gap-08 0;
  }

  // Task sections
  .task-section {
    margin-bottom: $gap-16;

    .section-header {
      display: flex;
      align-items: center;
      margin-bottom: $gap-08;
      
      .section-icon {
        margin-right: $gap-04;
        font-size: 16px;
      }
    }
  }

  // Task cards
  .tasks-container {
    .task-card {
      &.task-card-in-progress {
        background-color: #fffaf5;
      }

      &.task-card-completed {
        background-color: #fffaf5;
      }

      &.task-card-downloaded {
        background-color: #f4fff4;
      }

      &.task-card-error {
        background-color: #ffe9e9;
      }

      margin-bottom: 1rem;
      width: 100%;

      > .ant-card-head {
        padding: 0px 10px;
      }
      > .ant-card-head-title {
        padding: 2px;
      }
      > .ant-card-body {
        padding: 14px;
      }

      .card-content {
        .card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $gap-08;

          .document-type-tag {
            font-size: 13px;
            font-weight: 500;
            padding: $gap-04 $gap-08;
            margin-right: 0;
            margin-bottom: 0;
            border-radius: 4px;
          }

          .status-indicator {
            display: flex;
            align-items: center;
            gap: $gap-04;
          }
        }

        .card-details {
          padding: $gap-04 0;
          
          .detail-item {
            display: flex;
            align-items: center;
            margin-bottom: $gap-04;
            
            .detail-icon {
              margin-right: $gap-08;
              color: rgba(0, 0, 0, 0.45);
              font-size: 14px;
            }
            
            &.print-all-item {
              background-color: rgba(82, 196, 26, 0.1);
              padding: $gap-04 $gap-08;
              border-radius: 4px;
              border-left: 3px solid #52c41a;
              
              .detail-icon {
                color: #52c41a;
              }
            }
          }
        }
        
        .card-actions {
          margin-top: $gap-08;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          
          .action-buttons {
            display: flex;
            gap: $gap-08;
            align-items: center;
          }
          
          .download-action-button {
            padding: 0 $gap-08;
            height: 32px;
            border-radius: 4px;
            font-weight: 500;
          }
          
          .print-button {
            color: #1890ff;
            padding: 0 $gap-04;
            height: 32px;
            min-width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            
            &:hover {
              background-color: rgba(24, 144, 255, 0.1);
              color: #1890ff;
            }
            
            &.ant-btn-loading {
              opacity: 0.8;
            }
          }
        }

        .card-text {
          font-size: 14px;
          margin-bottom: 0.5rem;
        }

        .status-container {
          font-size: 14px;
          display: flex;
          align-items: center;

          span {
            margin-right: 0.5rem;
          }
        }
      }
    }

    // Empty state
    .empty-state {
      margin-top: $gap-12;
    }
  }

  .close-back {
    position: absolute;
    top: 2rem;
    right: -$gap-10;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background: #fff;
    color: $color-blue-light;
    border: 1px solid rgba(0, 0, 0, 0.12);
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;
    background-image: url('../../img//arrow-icons.png');
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      color: $color-white;
      background: $color-blue;
      border: 0px solid $color-blue;
      background-image: url('../../img/white-arr.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
    }
  }
  .ant-drawer-body:hover {
    .close-back {
      @include flexbox;
    }
  }
  &.ant-drawer-open {
    z-index: 999 !important;
  }
}