
@import '../../css/vars.scss';

.comment-action {
    padding-left: $gap-08;
    cursor: 'auto';
  }
  
  [class*='-col-rtl'] .comment-action {
    padding-right: $gap-08;
    padding-left: 0;
  }