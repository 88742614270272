@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.schedular-table-filters {
  height: 100%;
  overflow: hidden;
  @include flexbox();
  @include flex-direction(column);
  .input-wrap {
    &.filter-select-box{
      .custom-select-box{
        .ant-select-selector{
          height: 2.8rem;
        }
      }
    }
  }
  .app-table {
    max-height: 100%;
    overflow: auto;
  }
  .table-filters {
    .filter-right {
      display: flex;
      .schedular-customize-columns {
        padding: 0 $gap-08 0 1.6rem;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        & + div {
          & > .app-table {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
        .input-wrap {
          display: inline-block;
          .ant-row.ant-form-item {
            margin-bottom: 0;
          }
        }
        .collapse-window {
          position: relative;
          width: 24px;
          height: 24px;
          cursor: pointer;
          &:before {
            width: 24px;
            height: 24px;
            background-position: -326px 88px;
            position: absolute;
          }
        }
      }
    }
  }
  table {
    tbody {
      td {
        .action-button {
          min-height: 1.8rem;
          line-height: 1;
          font-size: $font-size-xs;
          padding: 0 $gap-05;
        }
        &.status-cell-container {
          span {
            padding: $gap-01 $gap-05 $gap-02;
            border-radius: $border-radius-base;
            &.check-in {
              background-color: $green-panel-background;
              border: $gap-01 solid $color-green;
              button {
                background-color: $green-panel-background;
              }
            }
            &.pending {
              background-color: $blue-panel-background;
              border: $gap-01 solid $color-blue;
              color: $color-blue-light;
              button {
                background-color: $blue-panel-background;
              }
            }
            &.canceled {
              background-color: $red-panel-background;
              border: $gap-01 solid $red-panel-border;
              button {
                background-color: $red-panel-background;
              }
            }
            &.pending-checkout {
              background-color: $yellow-panel-background;
              border: $gap-01 solid $yellow-panel-border;
              button {
                background-color: $yellow-panel-background;
              }
            }
            &.check-out {
              background-color: $dark-blue-panel-background;
              border: $gap-01 solid $color-blue;
              color: $color-white;
            }
            &.reschedule {
              background-color: $green-panel-background;
              border: $gap-01 solid $color-green;
              button {
                background-color: $green-panel-background;
              }
            }
            &.billedandpendingcheckout  {
              background-color: $light-brown-background;
              border: 1px solid brown;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobile){
  .schedular-table-filters{
    .table-filters{
      .flex{
        display: block;
        margin-left: 0.8rem;
      }
      .filter-components{
        .filter-ant-search{
          .ant-input-search{
            width: calc(100% - 8px);
            margin-bottom: 8px;
          }
          &+ div{
            width: calc(100% - 8px);
          }
        }
      }
    }
  }
}