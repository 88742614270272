@import "./vars";
@import "./root";
@import "./mixin";

.header-search {
  @include flexbox();
  @include justify-content(space-between);
  margin-bottom: 2.4rem;
  &.person-modal-header {
    margin-bottom: $gap-12;
  }
}
.ant-modal-content {
  .ant-modal-header {
    padding: $gap-08 2.4rem;
    .ant-modal-title{
      color: $color-blue-light;
      font-size: $font-size-m;
      font-weight: $font-medium;
    }
  }
  .ant-modal-close {
    .ant-modal-close-x {
      display: block;
      width: 2.6rem;
      height: 2.3rem;
      font-size: $font-size-l;
      font-style: normal;
      line-height: 2.6rem;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
    }
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0px 2.2rem $gap-12;
  }
  .ant-modal-body {
    padding: $gap-12 2.4rem $gap-12 2.4rem;
    .modal-content-weapper {
      padding: $gap-12 1.6rem;
      .modal-from-feilds {
        margin-bottom: 0;
      }
    }
  }
}
.ant-search {
  .ant-input-search {
    width: 21.6rem;
    border: 1px solid $border-gray;
    height: 3.6rem;
    border-radius: $gap-04;
    outline: none;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover {
    border-color: $border-gray;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: $border-gray;
  }
  .ant-input-suffix {
    svg {
      font-size: $font-size-xl;
      color: #748aac;
      top: $gap-04;
      position: relative;
    }
  }
}

.modal-content-weapper {
  padding: 2.4rem 1.6rem;
}

.addition-header {
  @include flexbox();
  @include justify-content(space-between);
  width: calc(100% + 4.8rem);
  border-bottom: 1px solid $button-border;
  margin-left: -2.4rem;
  padding-left: 2.4rem;
  margin-bottom: $gap-12;
  &.add-header-btn {
    .flex {
      width: 100%;
      margin-right: 3.4rem;
      button {
        position: relative;
        top: -$gap-06;
      }
    }
  }
  &.mng-addition-header {
    padding-right: 2.4rem;
    margin-top: -$gap-12;
    h2 {
      margin-bottom: $gap-08;
    }
  }
  .lookup {
    p {
      font-size: $font-size-m;
    }
  }
  p {
    font-size: $font-size-m;
    color: $color-blue-light;
    font-weight: $font-medium;
    padding-bottom: $gap-02;
    margin-bottom: $gap-08;
  }
  & + h4.back-arrow {
    &:before {
      top: $gap-02;
    }
  }
}

.custom-toggler {
  @include flexbox();
  background: $app-background;
  @include align-items(center);
  border-radius: $gap-04;
  font-size: $font-size-xsm;
  border: 1px solid $color-green;
  cursor: pointer;
  .toggler {
    padding: $gap-10 1.6rem;
    p {
      margin: 0;
      font-size: $font-size-m;
    }
    &.first {
      &.active {
        background: $color-green;
        border-top-left-radius: $gap-04;
        border-bottom-left-radius: $gap-04;
        p {
          color: $color-white;
          font-weight: $font-medium;
        }
      }
    }

    &.second {
      &.active {
        background: $color-green;
        border-top-right-radius: $gap-04;
        border-bottom-right-radius: $gap-04;
        p {
          color: $color-white;
          font-weight: $font-medium;
        }
      }
    }
  }
}

.lookup {
  @include flexbox();
  @include align-items(center);
  p {
    color: $color-blue-light;
    font-size: $font-size-l;
    font-weight: $font-medium;
  }
  &:before {
    width: 2.2rem;
    height: 2.2rem;
    top: $gap-01;
    position: absolute;
  }
}

.appointment-modal {
  .ant-modal-content {
    .ant-modal-close {
      display: block;
    }
    .ant-modal-body {
      padding: $gap-12 2.4rem $gap-12 2.4rem;
    }
    .ant-modal-footer {
      padding-bottom: $gap-12;
    }
  }
  .ant-modal-title {
    color: $color-blue-light;
    font-weight: $font-bold;
    font-size: $font-size-xl;
  }
  .ant-modal-header {
    padding: $gap-12 2.4rem 0 2.4rem;
    border: none;
  }
  .ant-modal-footer {
    padding: 0 1.6rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 2.6rem;
    color: $content-color;
    font-size: $font-size-s;
    font-weight: $font-regular;
    opacity: 1;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 2.8rem;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 2.8rem;
    border: $gap-01 solid rgba(165, 188, 223, 0.7);
  }
  .ant-picker-input {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $content-color;
      font-size: $font-size-s;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $content-color;
      font-size: $font-size-s;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $content-color;
      font-size: $font-size-s;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $content-color;
      font-size: $font-size-s;
    }
    .ant-picker-suffix {
      svg {
        color: $content-color;
      }
    }
    input {
      color: $color-blue-light;
    }
  }
  .ant-form-item-label > label {
    color: $content-color;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
  }
  .ant-form-item-label > label::after {
    display: none;
  }
}

/*--------- Appointment modal css ------------*/

.dialogue-modal {
  border: 1px solid $color-blue;
  color: $color-blue-light;
  border-radius: $gap-04;
  padding-left: 1rem;
  height: 2.8rem;
  @include flexbox();
  @include align-items(center);
  margin-bottom: $gap-08;
  position: relative;
  p {
    width: 100%;
    color: $content-color;
    font-weight: normal;
    margin-bottom: 0;
    padding-top: 0.5px;
  }
  .close-dialogue {
    display: block;
    position: static;
    &:before {
      width: 20px;
      height: 20px;
      background-position: -87px -51px;
      position: absolute;
      right: $gap-04;
      top: 0.5rem;
      background-size: 600px;
      cursor: pointer;
    }
    &:after {
      content: "";
      position: absolute;
      background: rgba(165, 188, 223, 0.7);
      width: 1px;
      height: calc(100% + 2px);
      top: -1px;
      right: 28px;
    }
  }
}

.appointment-content-wrap {
  @include flexbox();
  max-height: 100%;
  .appointment-rt-wrapper {
    width: 53rem;
    min-width: 53rem;
    margin-right: $gap-08;
    position: relative;
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    .appointment-user-wrap {
      border: 1px solid $button-border;
      border-radius: $gap-04;
      margin-bottom: $gap-08;
      padding: $gap-12 $gap-08 $gap-06 1.6rem;
      .user-top {
        @include flexbox();
        position: relative;
        .user-boxes {
          &.posi-relative {
            position: static;
            padding-right: 12px;
            @include flex-wrap(wrap);
            @include flexbox();
            .edit-icon1 {
              position: absolute;
              right: 12px;
            }
          }
        }
        .user-img {
          width: 8rem;
          min-width: 8rem;
          height: 8rem;
          border-radius: 50%;
          overflow: hidden;
          background: #bfbec5;
          margin-right: 2.2rem;
          border: 1px solid #bfbec5;
          img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .appointment-insurance-wrap {
      border: $gap-01 solid $button-border;
      border-radius: $gap-04;
    }
  }
  .appointment-lt-wrapper {
    height: 74vh;
    overflow-y: auto;
    overflow-x: hidden;
    .appointment-scheduler-wrap {
      width: 52.5rem;
      min-width: 52.5rem;
      border: $gap-01 solid $button-border;
      border-radius: $gap-04;
      margin-bottom: $gap-10;
      .ant-form-item-label,
      label {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.user-boxes {
  @include flexbox();
  margin-left: 1.6rem;
  .box {
    min-width: 7rem;
    height: 7.4rem;
    margin-right: $gap-04;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    padding: 4px 6px;
    margin-bottom: $gap-04;
    p {
      color: $content-color;
      margin-bottom: $gap-03;
      font-size: $font-size-xsm;
    }
    .title {
      color: $color-blue;
      font-size: $font-size-xl;
      font-weight: $font-bold;
    }
    &.first {
      background: rgba(46, 121, 248, 0.1);
    }
    &.second {
      background: rgba(50, 199, 136, 0.1);
    }
    &.third {
      background: rgba(255, 193, 7, 0.1);
    }
    &.fourth {
      background: rgba(255, 81, 7, 0.1);
    }
  }
}

.pending {
  color: $color-orange;
}
.user-detail-list {
  max-width: 16rem;
  .title {
    color: #0c4da2;
    font-size: $font-size-l;
    font-weight: $font-bold;
    margin-bottom: $gap-04;
  }
  .date {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    margin-bottom: $gap-04;
  }
  .name {
    color: $content-color;
    font-size: $font-size-xsm;
    margin-bottom: $gap-04;
    font-weight: $font-bold;
  }
  .value {
    color: $color-blue-light;
    font-size: $font-size-xsm;
  }
  p {
    margin-bottom: $gap-04;
    .add-icon {
      margin: -$gap-03;
      position: relative;
      float: right;
    }
  }
  .pending {
    color: $color-orange;
    font-size: $font-size-xsm;
    white-space: nowrap;
    max-width: 7.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;
    top: $gap-03;
    margin-right: $gap-08;
  }
}

.user-btm {
  &.flex {
    @include flexbox();
  }
  margin-top: $gap-08;
  .select-feilds-wrap {
    .ant-form-item-label {
      label {
        min-width: 10.2rem;
      }
    }
  }
  .user-name {
    .feilds {
      @include flexbox();
      .name {
        color: $content-color;
        font-size: $font-size-xsm;
        margin-bottom: $gap-04;
        min-width: 10.2rem;
        font-weight: $font-bold;
      }
      .value {
        color: $color-blue-light;
        font-size: $font-size-xsm;
        margin-bottom: $gap-04;
      }
    }
  }
  .user-detail {
    margin-left: 4.2rem;
    .feilds {
      @include flexbox();
      .name {
        color: $content-color;
        font-size: $font-size-xsm;
        margin-bottom: $gap-04;
        width: 11rem;
        font-weight: $font-bold;
      }
      .value {
        color: $color-blue-light;
        font-size: $font-size-xsm;
        margin-bottom: $gap-04;
      }
    }
  }
}

.card-header-title {
  height: 2.8rem;
  border-radius: $gap-04;
  background-color: #f2f7ff;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  .patient-view-insurance {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    color: #0c4da2;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
  }
}
.insurance-info-wrap {
  padding: $gap-08 1.6rem $gap-02 1.6rem;
  .left-area {
    .feilds-wrap {
      margin-bottom: $gap-04;
      @include flexbox();
      .feilds {
        @include flexbox();
        width: 50%;
        @include align-items(center);
        &.select-fields-wrap-100 {
          width: 100%;
          min-width: 50%;
          &.manage-select-inherit {
            .custom-select-box {
              .ant-col-16 {
                max-width: inherit;
                flex: inherit;
                -webkit-flex: inherit;
                .ant-col-20 {
                  .ant-select-single .ant-select-selector .ant-select-selection-item {
                    max-width: 100%;
                    div {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
          .custom-select-box {
            .ant-col-12 {
              max-width: initial;
              flex: inherit;
              -webkit-flex: inherit;
            }
          }
        }
        &.select-fields-sub-child-100 {
          & > div {
            width: 100%;
          }
        }
        .custom-select,
        .custom-autocomplete {
          .ant-row {
            .ant-col-10,
            .ant-col-8 {
              min-width: 10.2rem;
              max-width: 10.2rem;
            }
            .ant-col-8 {
              & + .ant-col-16 {
                max-width: calc(100% - 11.2rem);
              }
            }
          }
        }
        .label,
        label {
          color: #748aac;
          font-size: $font-size-xsm;
          min-width: 10.2rem;
          font-weight: $font-bold;
        }
        .value {
          color: #2a4a79;
          font-size: $font-size-xsm;
          padding-right: 1.2rem;
          &.pd-rt-zero{
            padding-right: 0;
          }
          &.fix-no-value {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.schedular-feild-area {
  padding: $gap-08 1.6rem;

  .schedular-feild-wraper {
    @include flexbox();
    .ant-form-item {
      margin-bottom: $gap-08;
    }
  }
  .textarea-wrappers {
    .ant-form-item {
      margin-bottom: $gap-08;
    }
    .ant-input {
      width: 39.9rem;
    }
  }
  .ant-form-item-label {
    width: 9.2rem;
    text-align: left;
    white-space: normal;
  }
  .left-feilds {
    margin-right: 1.8rem;
    flex: 1;
  }
  .right-feilds {
    flex: 1;
  }
  .feild-wrap {
    .ant-form-item-control {
      input {
        border: 1px solid rgba(165, 188, 223, 0.7);
      }
    }
    .ant-picker {
      border: 1px solid rgba(165, 188, 223, 0.7);
      input {
        border: none;
      }
    }
  }
}

.select-feilds-wrap {
  .ant-form-item {
    margin-bottom: 0px;
    .ant-select {
      max-width: 100%;
      min-width: 100%;
    }
  }
  .ant-form-item-label {
    min-width: 10.2rem;
    text-align: left;
    label {
      color: $content-color;
      font-size: $font-size-xsm;
      margin-bottom: 0px;
      min-width: 10.2rem;
    }
    &.ant-form-item-label-left {
      label {
        &.ant-form-item-no-colon {
          color: $content-color;
        }
      }
    }
  }
}

.scan-text {
  font-weight: $font-medium;
  font-size: $font-size-xsm;
  position: absolute;
  right: 10px;
  cursor: pointer;
  &.edit-scan {
    top: -1.6rem;
    &:before {
      content: "";
      position: absolute;
      background: url("../img/pencil-fill.png");
      width: 16px;
      height: 14px;
      top: 2px;
      left: -30px;
      background-repeat: no-repeat;
    }
  }
}
.edit-icon1 {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: url("../img/pencil-fill.png");
    width: 16px;
    height: 14px;
    top: 2px;
    background-repeat: no-repeat;
    right: -19px;
    cursor: pointer;
  }
}

/*----------- Authorization modal ------------------*/

.authorization-modal-inn {
  @include flexbox();
  .left-feilds {
    width: 33.3333%;
    margin-right: 2rem;
  }
  .mid-feilds {
    width: 33.3333%;
    margin-right: 2.4rem;
  }
  .right-feilds {
    width: 33.3333%;
  }
  .ant-form-item-label > label::after {
    display: none;
  }
  .input-style {
    .ant-form-item-label {
      max-width: 11rem;
      min-width: 11rem;
      text-align: left;
      white-space: normal;
    }
    .ant-form-item-control {
      max-width: 18.1rem;
    }
  }
  .select-style {
    .ant-form-item-label {
      max-width: 11rem;
      min-width: 11rem;
      text-align: left;
      white-space: normal;
    }
    .ant-form-item-control {
      max-width: 18.1rem;
    }
  }
}

button {
  &.ant-btn,
  &.ant-btn:hover {
    border: solid 1px #d2ddef;
    border-radius: $gap-08;
    font-size: $font-size-m;
    font-weight: $font-bold;
  }
  &.ant-modal-close {
    top: $gap-08;
    right: $gap-06;
    color: rgba(0, 0, 0, 0.75);
    &:hover,
    &:focus {
      color: $color-white;
      background: $color-green;
      transition: all 0.2s ease-in;
    }
  }
}
@media (min-width: $lg-desktop) {
  .appointment-modal {
    width: 70% !important;
  }
  .appointment-content-wrap {
    .appointment-rt-wrapper {
      width: 50%;
    }
    .appointment-lt-wrapper {
      .appointment-scheduler-wrap {
        width: 100%;
      }
    }
  }
}
@media (max-width: $min-desktop) {
  .schedular-patient-modal {
    width: 60rem !important;
  }
}

@media (max-width: $lg-tablet) {
  .appointment-content-wrap {
    @include flex-wrap(wrap);
    .appointment-rt-wrapper {
      width: 100%;
      margin-right: 0;
    }
    .appointment-lt-wrapper {
      width: 100%;
      margin-top: $gap-08;
    }
  }
}
@media (max-width: $min-tablet) {
  .appointment-content-wrap {
    .appointment-lt-wrapper {
      .appointment-scheduler-wrap {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}
@media (max-width: $tablet-medium) {
  .schedular-feild-area {
    .schedular-feild-wraper {
      display: block;
    }
    .left-feilds {
      margin-right: 0;
    }
  }
  .appointment-content-wrap {
    .appointment-rt-wrapper {
      min-width: 100%;
      .appointment-user-wrap {
        .user-top {
          display: block;
          .user-boxes {
            margin-left: 0;
            margin-top: 8px;
          }
        }
        .user-btm {
          display: block;
          .user-detail {
            margin-left: 0;
          }
        }
      }
      .appointment-scheduler-wrap {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}

@media (max-width: $mobile) {
  .appointment-modal {
    .edit-icon1 {
      &:before {
        top: -20px;
        right: 0;
      }
    }
    .social-icons {
      position: relative;
      top: -34px;
      right: 10px;
      &.open-social {
        &.mobile-social {
          ul {
            right: -14px;
            background: #ddd;
            padding: 6px 6px 2px 0px;
            z-index: 9;
          }
        }
      }
    }
  }
  .appointment-insurance-wrap {
    .insurance-info-wrap {
      .left-area {
        .feilds-wrap {
          display: block;
          .feilds {
            width: 100%;
            .ant-row {
              display: block;
            }
            .custom-select {
              .ant-col-10,
              .ant-col-12 {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
