@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.documents-wrap {
  .green-select-box {
    &.no-mar-bt {
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-row {
        .ant-select-single:not(.ant-select-customize-input) {
          .ant-select-selector {
            height: 2.8rem;
          }
        }
        .ant-select-single {
          .ant-select-selector {
            .ant-select-selection-placeholder {
              line-height: 2.8rem;
              font-weight: $font-bold;
              font-size: $font-size-xsm;
            }
          }
        }
      }
    }
  }
  .top-button {
    margin-top: 0px;
    @include flexbox();
    @include justify-content(flex-end);
    .ant-form-item {
      margin-bottom: $gap-12;
    }
    .button-wraper {
      margin-left: $gap-10;
    }
  }
}

/* -------------- document tab css ------------------*/

.documents-list-wrap {
  position: relative;
  width: 25.3rem;
  min-width: 25.3rem;
  margin-left: -2.4rem;
  border: solid 1px $button-border;
  border-top-right-radius: $gap-01;
  border-bottom-right-radius: $gap-04;
  margin-right: 2.4rem;
  .list-header {
    background: $color-light-gray;
    height: 3.8rem;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding: $gap-08;
    .ant-search {
      .ant-input-suffix {
        svg {
          font-size: $font-size-l;
          color: $content-color;
          top: 0px;
          position: relative;
        }
      }
      .ant-input-search {
        width: 100% !important;
        height: 2.8rem;
        .ant-input-affix-wrapper {
          height: 2.6rem;
          border: none;
        }
        .ant-input-group-addon {
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            display: block;
            border-left: 1px solid rgba(165, 188, 223, 0.7);
            transition: all 0.3s;
            content: "";
            z-index: 9;
          }
          .ant-input-search-button {
            height: 2.4rem;
            border: none;
          }
        }
        .ant-input {
          height: auto;
          border: none;
        }
      }
    }
  }
  .ant-search {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .category-header {
    height: 3.2rem;
    @include flexbox();
    @include justify-content(space-between);
    padding: $gap-08;
    p {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      font-weight: $font-medium;
      &.green-text {
        color: $color-green;
      }
    }
    .title {
      font-weight: $font-bold;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
  }
  .category-list {
    height: 54vh;
    overflow-y: auto;
    ul {
      margin-bottom: 0;
      li {
        height: 4rem;
        box-shadow: inset 0 -1px 0 0 #d2ddef;
        @include flexbox();
        @include align-items(center);
        color: $color-blue-light;
        font-size: $font-size-m;
        padding-left: 1.6rem;
        cursor: pointer;
        &:last-child {
          box-shadow: none;
        }
        p {
          margin-bottom: 0;
          padding-left: 1.6rem;
        }
        &.active {
          background: $color-blue;
          color: $color-white;
          .list-icon {
            width: 18px;
            height: 20px;
            display: inline-block;
            background: url(../../../../img/file-text-white.png);
            background-repeat: no-repeat;
          }
        }
        .list-icon {
          width: 18px;
          height: 20px;
          display: inline-block;
          background: url(../../../../img/file-text-blue.png);
          background-repeat: no-repeat;
        }
      }
    }
  }
  .btm-tags {
    height: 4rem;
    color: $color-blue-light;
    @include flexbox();
    @include align-items(center);
    background: $app-background;
    font-size: $font-size-xsm;
    padding-left: 1.6rem;
  }
}
.green-select-box {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: 1px solid rgba(165, 188, 223, 0.7);
    border-radius: $gap-04;
  }
  .ant-select-selection-placeholder {
    color: $color-white;
    opacity: 1;
  }
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    width: auto;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $color-green;
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
}
.documents-wrap-in {
  @include flexbox();
  margin-top: $gap-08;
}
.documents-table-wrap {
  width: calc(100% - 215px);
}
.document-list-table {
  .app-table {
    border: 1px solid $button-border;
    border-radius: $gap-04;
    margin: 0;
    table {
      box-shadow: none;
      thead {
        tr {
          th {
            &:first-child {
              border-top-left-radius: $gap-04;
            }
            &:last-child {
              border-top-right-radius: $gap-04;
            }
            &.thPadding {
              padding-left: 4.8rem;
            }
            &.thBacbground {
              background-color: $color-light-gray;
              border-bottom: $gap-01 solid $button-border;
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            .tdBgColor {
              background: $app-background;
            }
          }
        }
        td {
          .actions-container {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            height: 1.8rem;

            .action-button {
              min-height: 1.8rem;
              line-height: 1;
              font-size: $font-size-xs;
              padding: 0 $gap-05;
            }
          }
        }
      }
    }
  }
}

.care-team-upload-icon{
  svg{
    color: $color-green;
    font-size: $font-size-l;
    margin-top: 8px
  }
}
.ant-dropdown{
  .ant-dropdown-menu{
    li{
      padding: 8px 10px;
      border-bottom: 1px solid #ddd;
      &:last-child{
        border-bottom: none;
      }
    }
  }
}
.care-team-letter-icon {
  .anticon {
    svg {
      display: none;
    }
    &.anticon-profile {
      background: url("../../../../img/download-document.png");
      width: 16px;
      height: 18px;
      background-size: cover;
    }
  }
}

@media (max-width: $tablet-medium) {
  .documents-wrap-in {
    display: block;
    .documents-list-wrap {
      width: 100%;
      margin-left: 0;
    }
    .documents-table-wrap {
      width: 100%;
      margin-top: $gap-08;
    }
  }
}

@media (max-width: $mobile) {
  .documents-wrap {
    .top-button {
      display: block;
      &.green-select-box .ant-form-item .ant-select,
      .green-select-box .ant-form-item .ant-cascader-picker {
        width: 100%;
        margin-bottom: 8px;
      }
      .button-wraper {
        width: 100%;
        margin-left: 0;
        button {
          width: 100%;
        }
      }
    }
  }
}
