@import "../../../../../css/mixin.scss";
@import "../../../../../css/vars.scss";

.user-rt-forms {
  border-radius: $gap-08;
  width: calc(100% + 48px);
  margin-left: -2.4rem;
  margin-top: -2.4rem;
  overflow: hidden;
  background-color: $color-white;
  .selet-all {
    cursor: pointer;
  }
  .selet-none {
    cursor: pointer;
  }
}

.user-form-header {
  background: $app-background;
  height: 4.2rem;
  @include align-items(center);
  @include flexbox();
  @include justify-content(space-between);
  padding-right: $gap-16;
  box-shadow: inset 0 -1px 0 0 #d2ddef;
  .user-form-header-wrap {
    @include flexbox();
    .right-option-header {
      width: 25.4rem;
      color: $content-color;
      font-size: $font-size-xsm;
      @include justify-content(center);
      @include flexbox();
      line-height: 3.7rem;
      & + .sm-checkbox {
        margin-top: $gap-03;
        margin-left: 3.8rem;
        .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
          border: 2px solid $content-color;
        }
        .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
          border: 2px solid $color-green;
        }
        .ant-checkbox-inner:after {
          width: 6px;
          height: 9px;
        }
        .ant-checkbox + span {
          font-size: $font-size-m;
        }
      }
    }
    .selet-all {
      color: $color-green;
      font-weight: $font-medium;
      font-size: $font-size-m;
      margin-left: 4rem;
      margin-right: 4.8rem;
    }
    .selet-none {
      color: $color-green;
      font-weight: $font-medium;
      font-size: $font-size-m;
    }
  }
}

.user-right-wrap {
  .ant-tabs-left-bar,
  .ant-tabs-nav {
    width: 25.4rem;
  }
  .ant-checkbox-inner {
    border-radius: 0;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    box-shadow: inset 0 -1px 0 0 #d2ddef;
    margin: 0;
    height: 4rem;
    font-size: $font-size-m;
    text-align: left;
    padding-left: 5.6rem;
    line-height: 2.6rem;
  }
  .ant-tabs {
    .ant-tabs-left-bar {
      .ant-tabs-tab {
        &.ant-tabs-tab-active {
          background: $color-blue;
          color: $color-white;
          font-weight: $font-regular;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .ant-tabs .ant-tabs-content-left {
    .custom-checkbox {
      .ant-checkbox-wrapper {
        span {
          font-size: $font-size-m;
          text-transform: capitalize;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    padding-left: 2.6rem;
    width: auto;
    line-height: 2.6rem;
    .ant-checkbox {
      position: absolute;
      right: auto;
      top: -1px;
      left: 0;
      & + span {
        display: block;
      }
    }
  }
  .ant-tabs-nav-wrap {
    .appointments-icon {
      &.sprite-img-next-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: 557px 124px;
          position: absolute;
          left: -38px;
          background-size: 700px;
        }
      }
    }
    .task-management-icon {
      &.sprite-img-next-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: 634px 248px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .report-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -724px -152px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .technician-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -505px -239px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .dashboard-icon-user {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -285px 440px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .scheduler-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -20px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .provider-module-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -502px -65px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .manual-intervention-queue-icon {
      &.sprite-img-next-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: 638px 362px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .patients-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -64px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .management-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -108px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .chart-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -151px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .billing-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -196px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .claims-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -240px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .utilization-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -284px;
          position: absolute;
          left: -38px;
        }
      }
    }

    .remittance-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -328px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .collections-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -373px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .documents-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -416px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .contractsicon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -371px -283px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .analytic-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -681px -283px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .setting-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -681px -372px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .ant-tabs-tab-active {
      .appointments-icon {
        &.sprite-img-next-before {
          &:before {
            background-position: 606px 124px;
          }
        }
      }
      .task-management-icon {
        &.sprite-img-next-before {
          &:before {
            background-position: 688px 248px;
          }
        }
      }
      .report-icon {
        &.sprite-img-before {
          &:before {
            background-position: -724px -108px
          }
        }
      }
      .technician-icon {
        &.sprite-img-before {
          &:before {
            background-position: -549px -239px;
          }
        }
      }
      .dashboard-icon-user {
        &.sprite-img-before {
          &:before {
            background-position: -64px 440px;
          }
        }
      }
      .scheduler-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -20px;
          }
        }
      }
      .provider-module-icon {
        &.sprite-img-before {
          &:before {
            background-position: -546px -65px;
          }
        }
      }
      .patients-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -64px;
          }
        }
      }
      .management-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -108px;
          }
        }
      }
      .chart-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -151px;
          }
        }
      }
      .manual-intervention-queue-icon {
        &.sprite-img-next-before {
          &:before {
            background-position: 691px 362px;
          }
        }
      }
      .billing-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -196px;
          }
        }
      }
      .claims-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -240px;
          }
        }
      }
      .utilization-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -284px;
          }
        }
      }

      .remittance-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -328px;
          }
        }
      }
      .collections-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -373px;
          }
        }
      }
      .documents-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -416px;
          }
        }
      }
      .contractsicon {
        &.sprite-img-before {
          &:before {
            background-position: -371px -327px;
          }
        }
      }
      .analytic-icon {
        &.sprite-img-before {
          &:before {
            background-position: -681px -328px;
          }
        }
      }
      .setting-icon {
        &.sprite-img-before {
          &:before {
            background-position: -681px -416px;
          }
        }
      }
      .sprite-img-before {
        &:after {
          display: none;
        }
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 0px !important;
  }
}

.main-form-container {
  .user-rt-forms {
    form {
      width: 100%;
      max-width: 100%;
    }
  }
}

.hide {
  display: none;
}

.v-tab .ant-tabs-nav .ant-tabs-tab-active .sprite-img-before:after,
.v-tab .ant-tabs-nav-list .ant-tabs-tab-active .sprite-img-before:after {
  display: none;
}

.v-tab {
  .ant-tabs-left {
    .ant-tabs-nav {
      .ant-tabs-tab {
        font-weight: $font-medium;
        &.ant-tabs-tab-active {
          color: $color-white;
          font-weight: $font-regular;
        }
      }
    }
  }
}

@media (max-width: $tablet-medium) {
  .user-form-header .user-form-header-wrap .right-option-header {
    @include justify-content(flex-start);
    line-height: 2rem;
    padding-left: $gap-02;
  }
  .user-form-header .user-form-header-wrap {
    display: block;
  }
  .user-form-header {
    display: block;
    height: auto;
    padding: $gap-08 $gap-16;
  }
  .user-form-header .user-form-header-wrap .right-option-header + .sm-checkbox {
    margin-left: $gap-16;
  }
  .user-right-wrap {
    .ant-tabs-left-bar {
      width: 100%;
    }
    .ant-tabs-nav {
      width: 100%;
    }
  }
}
