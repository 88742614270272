@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.dashboard-filter-header-section {
  padding: 0px 0px 8px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  @include flex-wrap(wrap);
  h3 {
    margin-bottom: 0;
  }
  .filters-wrap {
    @include flexbox();
    @include align-items(center);
    gap: 3px;
  }
  .filters {
    @include flexbox();
    @include align-items(center);
    margin-right: 8px;
    .lt-arrow {
      background: url("../../../../img/arrow-right.png");
      width: 14px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
      border: none;
      height: 28px;
      padding: 0;
      min-height: 28px;
    }
    .rt-arrow {
      background: url("../../../../img/arrow-right.png");
      width: 14px;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 8px;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      border: none;
      height: 28px;
      padding: 0;
      min-height: 28px;
    }
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
    .mng-select-gaps {
      margin-left: 20px;
      .custom-select {
        .ant-col {
          &.ant-col-8 {
            max-width: 60px;
          }
        }
      }
      &.mng-select-text {
        .custom-select {
          .ant-col {
            &.ant-col-8 {
              max-width: 50px;
            }
          }
        }
      }
    }
    .task-btn {
      margin-right: 10px !important
    }
  }
}

@media (max-width: $lg-tablet) {
  .dashboard-filter-header-section {
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include align-items(flex-start);
    h3 {
      margin-bottom: 8px;
    }
  }
}
