@import '../../../../../css/vars.scss';
@import "../../../../../css/mixin.scss";
.auditLogs {
  height: 150px;
  margin-top: 10px;
  overflow: auto;
  box-shadow: none;
  padding: $gap-10 $gap-12 $gap-04;
  background: rgba(210, 221, 239, 0.2);
  border-radius: $gap-04;
  border: 1px solid rgba(0, 0, 0, 0.04);
  .ant-comment{
    background-color: transparent;
  }
  .ant-comment-inner {
    padding: 0px 0 $gap-16;
    align-items: center;
    justify-content: start;
    align-content: center;
  }
  .author {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    h4 {
        margin: 0;
    }
    p {
        margin: 0;
        padding-left: 5px;
    }
    span {
        font-weight: 500;
        color: #2a4a79;
        padding-left: 4px;
    }
  }
  .content {
    max-width: 50%;
    margin: 10px 0 0 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
    span {
        font-weight: 500;
    }
  }
  .ant-comment-content-author-time{
    color: #00000073;
    font-size: 11px;
  }
  .no-data-text {
    @include flexbox();
    @include justify-content(space-around);
    @include align-items(center);
    height: 100%;
  }
}
.comment-log-section {
    margin-top: 10px;
}