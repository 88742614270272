@import "../../css/mixin.scss";
@import "../../css/vars.scss";

/*------------------ coded by developer --------------*/
.margin-left-20 {
  margin-left: 2rem;
}

.flex-1 {
  @include flex(1);
}

.justify-content-sp-ev {
  @include justify-content(space-evenly);
}

html {
  scroll-behavior: smooth;
}

.spinner {
  width: 100%;
  margin: 20px;
}

/*------------------End of coded by developer --------------*/

.user-form-header {
  background: $app-background;
  height: 4.9rem;
  @include align-items(center);
  @include flexbox();
  @include justify-content(space-between);
  padding-right: $gap-16;
  box-shadow: inset 0 -1px 0 0 #d2ddef;
  .user-form-header-wrap {
    @include flexbox();
    .right-option-header {
      width: 25.4rem;
      color: $content-color;
      font-size: $font-size-xsm;
      @include justify-content(center);
      @include flexbox();
    }
    .selet-all {
      color: $color-green;
      font-weight: $font-medium;
      font-size: $font-size-m;
      margin-left: 4rem;
      margin-right: 4.8rem;
    }
    .selet-none {
      color: $color-green;
      font-weight: $font-medium;
      font-size: $font-size-m;
    }
  }
}

.user-right-wrap {
  .ant-tabs-left-bar,
  .ant-tabs-nav {
    width: 25.4rem;
  }
  .ant-checkbox-inner {
    border-radius: 0;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    box-shadow: inset 0 -1px 0 0 #d2ddef;
    margin: 0;
    height: 4rem;
    color: $color-blue;
    font-size: $font-size-m;
    text-align: left;
    padding-left: 5.6rem;
    line-height: 2.6rem;
  }
  .ant-tabs {
    .ant-tabs-left-bar {
      .ant-tabs-tab {
        &.ant-tabs-tab-active {
          background: $color-blue;
          color: $color-white;
          font-weight: $font-regular;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .ant-tabs .ant-tabs-left-content {
    padding: $gap-14 4rem;
  }
  .ant-checkbox-wrapper {
    padding-left: 2.6rem;
    width: auto;
    line-height: 2.6rem;
    .ant-checkbox {
      position: absolute;
      right: auto;
      top: -1px;
      left: 0;
      & + span {
        display: block;
      }
    }
  }
  .ant-tabs-nav-wrap {
    // .ant-tabs-tab {
    .appointments-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -20px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .patients-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -64px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .management-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -108px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .chart-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -151px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .billing-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -196px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .claims-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -240px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .utilization-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -284px;
          position: absolute;
          left: -38px;
        }
      }
    }

    .remittance-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -328px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .collections-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -373px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .documents-icon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -416px -416px;
          position: absolute;
          left: -38px;
        }
      }
    }
    .contractsicon {
      &.sprite-img-before {
        &:before {
          width: 24px;
          height: 24px;
          background-position: -371px -283px;
          position: absolute;
          left: -38px;
        }
      }
    }
    &.ant-tabs-tab-active {
      .appointments-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -20px;
          }
        }
      }
      .patients-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -64px;
          }
        }
      }
      .management-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -108px;
          }
        }
      }
      .chart-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -151px;
          }
        }
      }
      .billing-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -196px;
          }
        }
      }
      .claims-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -240px;
          }
        }
      }
      .utilization-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -284px;
          }
        }
      }

      .remittance-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -328px;
          }
        }
      }
      .collections-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -373px;
          }
        }
      }
      .documents-icon {
        &.sprite-img-before {
          &:before {
            background-position: -460px -416px;
          }
        }
      }
      .contractsicon {
        &.sprite-img-before {
          &:before {
            background-position: -371px -327px;
          }
        }
      }
      .sprite-img-before {
        &:after {
          display: none;
        }
      }
    }
    //   }
    // }
  }
  .ant-tabs-ink-bar {
    height: 0px !important;
  }
}

.user-rt-forms {
  border-radius: $gap-08;
  width: calc(100% + 48px);
  margin-left: -2.4rem;
  margin-top: -2.4rem;
  overflow: hidden;
  background-color: $color-white;
}

.main-form-container {
  .user-rt-forms {
    form {
      width: 100%;
      max-width: 100%;
    }
  }
}

.hide {
  display: none;
}
.user-basic-form {
  .custom-autocomplete .ant-select-clear {
    display: block !important ;
  }
}
@media (max-width: $tablet-medium) {
  .user-right-wrap {
    .ant-tabs-left-bar {
      width: 100%;
    }
    .ant-tabs-nav {
      width: 100%;
    }
  }
}
