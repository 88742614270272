@import '../../../../css/mixin.scss';
@import '../../../../css/vars.scss';

.suspense-back-arrow {
  &:before {
    top: 3px !important;
  }
  &.manage-header-field {
    & + .heading-area {
      .custom-select-box {
        position: relative;
        z-index: 9;
      }
      & + .patient-filter {
        .table-filters {
          margin-top: -3.6rem;
          margin-left: 164px;
        }
      }
    }
  }
}
.filter-btm-section{
  @include flexbox;
  @include justify-content(space-between);
}
.total-values{
  color: $color-blue-light;
  font-size: $font-size-xsm;
  display: block;
  text-align: right;
  .key{
    font-weight: $font-regular;
    font-size: $font-size-xsm;
    color: $color-blue-light;
    .value{      
      font-weight: $font-medium;
    }
  }
}
.remittance-search-filter-wrap-all{
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(flex-start);
  .remittance-search-filter-wrap{
    .search-checkbox-wrap{
      @include flexbox;
      @include align-items(center);
      @include flex-wrap(wrap);
      .mr-top-4{
        margin-top: 0px;
      }
    }
  }
  width: 100%;
  position: relative;
  .align-content-and-button{
    button{
      margin-left: 1rem;
    }
  }
  .search-checkbox-wrap{
    .mr-bt-8 {
      &.mng-custom-search{
        margin-bottom: 0;
      }
    }
  }
}
.filter-text-wrap{
  margin-top: 0.8rem;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

.mng-custom-search{
  .ant-input-affix-wrapper{
    height: 2.8rem;
  }
  .ant-input-group-addon{
    .ant-input-search-button{
      height: 2.8rem;
    }
  }
}

.work-list-table-alignment{
  .app-table{
    table{
      tbody{
        td{
          text-align: right;
          &:first-child{
            text-align: left;
          }
        }
      }
    }
  }
}

.align-content-and-button{
  @include flexbox();
  @include align-items(center);
  .key{
    font-weight: $font-regular;
    font-size: $font-size-xsm;
    color: $color-blue-light;
    .value{      
      font-weight: $font-medium;
    }
  }
}
.worklist-tab-wrap{
  @include flexbox;
  @include justify-content(space-between);
  .mng-custom-search{
    margin-left: 0;
  }
}

.filter-and-btn-wrap{
  @include flexbox;
  @include flex-wrap(wrap);
  &.outbox-filter{
    @include justify-content(flex-end);
  }
}
.user-auto-suggest-filter{
  .ant-col-1{
    display: block;
    max-width: 0px;
  }
}