@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.notes-modal {
  .ant-modal-body {
    padding-bottom: 2.0rem;
  }
  .custom-task-table-task {
    .ant-affix {
      display: none;
    }
    .app-table {
      max-height: 35.0rem;
      overflow-y: auto;
    }
  }
  .modal-content-area {
    .add-notes-content {
      @include flex(1);
      padding: $gap-15 $gap-15 $gap-08;
      .group-btns {
          @include flexbox();
          @include justify-content(flex-end);
      }
    }

    .notes-wrapper {
      @include flexbox();
      @include justify-content(space-between);
      .notes-search {
        width: 30.0rem !important;
        margin-bottom: $gap-10;
      }
      .ant-input-wrapper{
        .ant-input{
          height: 28px;
        }
      }
    }
  }

  .panel-area {
    @include flexbox();
    &.toggle-side-pannel {
      .left {
        width: 100%;
        margin-right: 0;
      }
      .right {
        width: 0;
        margin-left: 0;
        flex: none;
        overflow: hidden;
        transition: all 0.8s ease;
      }
    }
    
    .left,
    .right {
      position: relative;
    }
    .left {
      @include flex(1);
    }
    .right {
      transition: all 0.3s ease-in-out;
      margin-left: $gap-12;
      @include flex(2);
    }
  }
}

@media (max-width: $mobile){
  .notes-modal {
    .modal-content-area {
      .notes-wrapper{
        display: block;
        .notes-search{
          width: 100% !important;
        }
      }
    }
    &.m-notes-modal{
      .addition-header{
        padding-right: 30px;
      }
    }
  }
}