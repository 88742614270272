@import "../../css/vars";
@import "../../css/root";
@import "../../css/mixin";

.ant-checkbox-wrapper {
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
      border: $gap-02 solid $content-color;
      background: transparent;
      border-radius: 2px;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $color-green;
      border: $gap-02 solid $color-green;
      &:after{
        top: calc(50% - 0px);
        left: 1px;
      }
    }
    &:after{
      border: none; 
    }
  }
}

.ant-form-item-control-input-content {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-green;
  }
}
