@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.synonyms-container {
  .tab-container {
    position: relative;
    .table-filters {
      margin-bottom: $gap-08;
    }
    .table-data {
      .cell-pending {
        color: $color-blue-light;
      }
      .cell-approved {
        color: $color-green;
      }
      .cell-declined {
        color: $color-red;
      }
      .actions-container {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);
        height: 1.8rem;

        .action-button {
          min-height: 1.8rem;
          line-height: 1;
          font-size: $font-size-xs;
          padding: 0 $gap-05;
          margin-right: $gap-08;
        }
      }
    }
  }
}

.pannel {
  .pannel-body {
    .inner-header {
      width: calc(100% + 32px);
      background: $color-light-grayies;
      margin-left: -16px;
      padding: $gap-05 $gap-16;
      color: $color-blue-light;
      font-size: $font-size-xsm;
      margin-bottom: $gap-08;
      p {
        margin: 0;
        color: $color-blue-text;
      }
    }
    .footer-btn-section-upload-btn {
      width: calc(100% + 32px);
      margin-left: -16px;
      padding: $gap-05 $gap-16 $gap-02;
      border-top: $gap-01 solid $button-border;
      @include justify-content(flex-start);
      @include flexbox;
    }
  }
}

.input-group-wrapper {
  .ant-form-item-label {
    display: flex;
    flex: 1;
    label {
      font-size: $font-size-xsm;
    }
  }
  .input-group-only {
    flex: 2;
  }
}

.create-invoice,
.search-invoice {
  .panel {
    .panel-body {
      .footer-btn-section {
        justify-content: flex-start;
      }
      .font-size-xsm {
        font-size: $font-size-xsm;
      }
    }
  }
  .content-color {
    color: $color-blue-light;
    font-size: $font-size-xsm;
  }
  .inline-flex {
    display: inline-flex;
    padding: 2.0rem 3.0rem;
    border: $gap-01 solid $border-color;
    border-radius: $gap-05;
  }
}

.custom-synonyms-btn-wrapper {
  button.btn.sm-btn {
    max-height: 13.0rem;
  }
}

.row-icdSearch-hide {
  position: relative;
  color:red;
  cursor: pointer;
}

.tick {
  background: url("../../../img/right-tick.png");
  display: inline-block;
  width: 16px;
  height: 11px;
  background-size: cover;
  position: relative;
  top: -1px;
  cursor: pointer;
}
