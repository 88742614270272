@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.bills-wrap {
  .top-section {
    @include justify-content(flex-end);
  }
}
.contract-btns {
  &.table-action-btn {
    &.form-btns {
      margin-top: 0;
      position: relative;
      span {
        margin-left: 1.6rem;
      }
    }
  }
  .copy-icon {
    background: url("../../../../img/file-copy-.png");
    width: 13px;
    height: 15px;
    display: inline-block;
    background-size: cover;
    position: relative;
    top: 3px;
    margin-right: 6px;
  }
  .proof-icon {
    background: url("../../../../img/Sprite.png");
    background-size: 599px;
    background-position: -471px -17px;
    width: 20px;
    height: 18px;
    display: inline-block;
    position: absolute;
    top: 2px;
    margin-right: 6px;
  }
}

.form-wrap-patient-claims {
  form { 
    width: 100% !important;
    max-width: 100% !important;
  }
}

.total-count-wrapper{
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-end);
}

.table-scroll-wrapper{
  .app-table{
    max-height: 800px;
    overflow-y: auto;
  }
}
.patient-statement-list-table{
  .app-table{
    max-height: 80.0rem;
    overflow-y: auto;
  }
}