@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.user-role-container {
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-base;
    background-color: $color-white;
    padding-bottom: $gap-16;
    height: 81vh;
    margin-top: $gap-06;
    & > div{
        height: 100%;
        .user-rt-forms{
            height: 100%;
            .user-right-wrap{
                .ant-tabs-content-holder{
                    height: 71vh;
                    overflow: auto;
                }
            }
        }
    }
    .user-role-header {
        box-shadow: inset 0 -1px 0 0 #d2ddef;
        background: $app-background;
        height: 4.2rem;
        padding: $gap-08 $gap-16;
        @include flexbox;
        @include justify-content(flex-end);
    }
    .user-right-wrap {
        .ant-tabs-nav {
            width: 18rem;
            height: calc(100vh - 165px);
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-tab-active {
                .appointments-icon {
                    &.sprite-img-before:before {
                        background-position: -460px -20px;
                    }
                }
            }
            .appointments-icon {
                &.sprite-img-before:before {
                    width: 24px;
                    height: 24px;
                    background-position: -416px -20px;
                    position: absolute;
                    left: -38px;
                }
            }
            .patients-icon {
                .sprite-img-before {
                    &:before {
                        width: 24px;
                        height: 24px;
                        background-position: -416px -64px;
                        position: absolute;
                        left: -38px;
                    }
                }
            }
        }

        .v-tab {
            .ant-tabs-nav-list {
                .ant-tabs-tab-active {
                    .sprite-img-before {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            .ant-tabs-nav {
                .ant-tabs-tab-active {
                    .sprite-img-before {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: $color-white;
            }
        }
    }
}
.user-role-location-table {
    .custom-select {
        .ant-select-selector {
            height: 2.8rem;
            .ant-select-selection-overflow{
                .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest{
                    height: 2.8rem;
                }
            }
        }
        .ant-select.ant-select-multiple.ant-select .ant-select-selection-item {
            margin-top: 0.1rem;
            height: 2.2rem;
        }
    }
    .table-filters{
        @include flex-wrap(wrap);
        .mr-lt-16{
            margin-left: 0.8rem;
        }
        .filter-ant-search{
            margin-right: 0.8rem;
        }
    }
    .dropdown-filters-roles-wrapper {
        .ant-select {
            &.ant-tree-select {
                .ant-select-selector {
                    height: 2.8rem;
                    .ant-select-selection-item {
                        line-height: 2.2rem;
                    }
                    .ant-select-selection-placeholder {
                        color: #2a4a79;
                        opacity: 0.7;
                        font-weight: 400;
                        font-size: 1.3rem;
                        line-height: 2.8rem;
                    }
                }
            }
        }
    }
}
.add-child-role-modal {
    .hide-label {
        .ant-form-item-label {
            display: none;
        }
    }
}
.user-right-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: $color-white;
}
.user-screen {
    & + .user-role-wrapper {
        @include flexbox;
        @include justify-content(space-between);
        & + .user-role-container {
            .user-rt-forms {
                width: 100%;
                margin-left: 0;
                margin-top: 0;
            }
            .user-right-wrap .ant-tabs-nav{
                width: 25.4rem;
            }
        }
    }
}

.user-appointment-wrap {
    margin: $gap-16 $gap-16 0 0;
    .app-table {
        table {
            thead {
                tr {
                    th {
                        padding: $gap-06 $gap-04 $gap-06 $gap-06;
                        text-align: center;
                        vertical-align: top;
                        color: $color-blue-text;
                        font-weight: $font-medium;
                        background: #e9eff5;
                        box-shadow: inset 0 -1px 0 0 #d2ddef;
                        font-size: $font-size-s;
                        &:first-child {
                            background: $color-green;
                            color: $color-white;
                            box-shadow: inset 0 -1px 0 0 #d2ddef;
                            padding-left: $gap-16;
                            .table-header {
                                @include flexbox;
                            }
                        }
                        .table-header {
                            display: block;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: $font-size-m;
                        &:first-child {
                            background: $color-green;
                            color: $color-white;
                            box-shadow: inset 0 -1px 0 0 #d2ddef;
                        }
                    }
                }
            }
        }
    }
    .ant-checkbox-inner {
        border-radius: $gap-01;
    }
}
.search-user-role-wrap{
    @include flexbox;
    @include justify-content(space-between);
    margin-bottom: 8px;
}

@media (max-width: $tablet-medium) {
    .user-role-container {
        .user-right-wrap {
            .ant-tabs-nav {
                width: 100%;
                height: 100%;
            }
        }
    }
}
