@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.preview-container {
  @include flexbox;
  @include justify-content(space-between);
  height: 84vh;
  .left-section {
    max-width: 360px;
  }
}
.preview-image-tools {
  position: absolute;
  right: 0;
  bottom: $gap-10;
  z-index: 9999;
  width: 4.0rem;
  margin-right: 20px;

  span {
    background: $color-green;
    border-radius: 5.0rem;
    height: 4.0rem;
    margin-top: $gap-10;
    margin-bottom: $gap-10;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;
    color: $color-white;
    font-size: $font-size-l;
  }
}
.main-form-container .left-section form {
  max-width: 100%;
}
.upload-document-preview {
  position: relative;
  background-color: $document-preview-background;
  border: $gap-01 solid $document-preview-background;
  border-radius: $border-radius-base;
  width: calc(100% - 38.4rem);
  .doc-document {
    height: 100%;
    overflow-y: scroll;
  }
  .pdf-wrapper {
    .pdf-document {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      .react-pdf__Document {
        width: 100%;
        height: 100%;
        .ReactVirtualized__Grid__innerScrollContainer{
          margin: auto;
        }
        .react-pdf__Page{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
        }
      }
      width: 100%;
      overflow: hidden;
      .pdf-page {
        margin-bottom: $gap-10;
        -webkit-box-shadow: $box-shadow-base;
        box-shadow: $box-shadow-base;
      }
    }
  }
}
.view-pdf-wrapper{
  .react-pdf__Document {
    width: 100%;
    height: 100%;
    .ReactVirtualized__Grid__innerScrollContainer{
      margin: auto;
    }
    .react-pdf__Page{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }
}
.upload-design {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  padding: $gap-05;
}

.file-not-found-preview {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  height: inherit;
  font-size: $font-size-xl;
}

.image-preview-container {
  height: 84vh;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  max-width: 100%;
  .pan-zoom-image {
    img {
      width: 100%;
    }
  }
}
@media (max-width: $min-desktop) {
  .image-preview-container{
    height: 62vh;
  }
}

@media (max-width: $lg-tablet) {
  .preview-container {
    @include flex-wrap(wrap);
    .left-section {
      max-width: 100%;
      width: 100%;
    }
    .upload-document-preview {
      width: 100%;
      margin-top: 0;
      .pdf-wrapper {
        .pdf-document{
          height: 36.0rem;
        }
      }
    }
  }
}
