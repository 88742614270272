@import "../../../../../css/vars.scss";

.cancel-appointment-modal {
  .ant-modal-title {
    color: $color-blue-light;
    font-weight: $font-bold;
    font-size: $font-size-xl;
  }
  .ant-modal-body {
    color: $color-blue-light;
    .ant-form {
        margin-top: 20px;
    }
  }
}
