@import '../../../../css/vars.scss';

.create-new-batch {
  .lookup {
    p {
      margin-bottom: 8px;
    }
  }
  .ant-modal-content {
    .ant-modal-footer {
      padding-bottom: 16px;
      padding-top: 0;
    }
    .ant-modal-body {
      padding: $gap-12 2.4rem $gap-08 2.4rem;
    }
  }
}
