@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.doctor-screen {
  .filter-header {
    @include flexbox();
    @include justify-content(space-between);
    margin-bottom: $gap-08;
    .filter-left {
      @include flexbox();
      @include align-items(bottom);
      .collapse-icon {
        @extend .input-wrap;
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: $border-radius-base;
        border: 1px solid $border-gray;
        background-color: $color-white;
        cursor: pointer;
        &:before {
          content: "";
          width: 36px;
          height: 36px;
          background-position: -323px 137px;
          position: absolute;
        }
      }
      .input-wrap {
        margin-right: $gap-08;
      }
      .ant-search {
        @extend .input-wrap;
      }
      .doctor-action-btn {
          margin-right: $gap-08;
        .btn {
          margin-top: 0;
          &.mr-rt-8 {
            margin-right: $gap-12;
          }
        }
      }
    }
    .ant-picker {
      .ant-picker-input {
        > input {
          line-height: 2.6rem;
        }
      }
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .patient-suggestive-search {
      .ant-select {
        &.ant-select-auto-complete {
          width: 15.0rem;
          margin-left: 1.1rem;
        }
      }
    }
  }

  /* Filter scss ends here.... */

  .doctor-wrapper {
    @include flexbox();
    &.bg-user-profile {
      .user-profile-section {
        background: $gray-panel;
        border: 1px solid $gray-panel-border;
      }
    }
  }
}

.doctor-screen-wrap {
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-base;
  border: $gap-01 solid $button-border;
  overflow: hidden;
  .medication-wrap {
    .ocular-area {
      .pannel {
        box-shadow: none;
        margin-bottom: 0;
        border-top: $gap-01 solid $button-border;
        border-radius: 0;
        .pannel-body {
          border-bottom: none;
        }
      }
      .medication-list{
        height: 27.1rem;
        ul{
          & > div{
            border: $gap-01 solid #a5bcdf;
            background: #f7f9fb9c;
            padding: $gap-04 $gap-04 0;
            border-radius: $gap-03;
            margin-bottom: $gap-04;
            li{
              border-bottom: $gap-01 solid #e2e9f3;
              &:last-child{
                margin-bottom: 0;
                border-bottom: none;
              }
              &:first-child{
                padding-top: $gap-02;
              }
            }
          }
        }
      }
    }
  }
  .assesment-area {
    .pannel {
      box-shadow: none;
      margin-bottom: 0;
      border-top: $gap-01 solid $button-border;
      border-radius: 0;
      border-bottom: none;
      border-left: none;
      border-right: none;
      .pannel-body {
        border-bottom: none;
      }
    }
  }
  .other-wrap {
    .exam-heading {
      .ant-collapse-header {
        font-size: $font-size-xsm;
        padding: $gap-05;
        color: $color-blue-text;
        font-weight: 700;
      }
    }
    .panel {
      &.follow-up {
        box-shadow: none;
        margin-bottom: 0;
        border-top: $gap-01 solid $button-border;
        border-radius: 0;
        .space {
          .ant-form-item-control-input {
            .ant-input {
              padding: $gap-04 $gap-05;
            }
          }
          & + .ant-col-5 {
            .ant-input {
              padding: $gap-04 $gap-05;
            }
            & + .ant-col-5 {
              .ant-input {
                padding: $gap-04 $gap-05;
              }
              & + .ant-col-5 {
                .ant-input {
                  padding: $gap-04 $gap-05;
                }
              }
            }
          }
        }
      }
    }
  }
  .allergies-list-para {
    color: $color-blue-light;
    font-size: $font-size-s;
  }
  .search-area{
    .procedure-modal-area{
      &.procedure-section-form .single-line .w-146{
        width: auto;
        & + div{
          overflow-y: hidden;
        }
      }
    }
  }
}

.filter-right {
  button {
    margin-left: $gap-08;
  }
}

.encounter-filter {
  padding: 0 $gap-08 0 1.6rem;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  &.manage-add-gap {
    padding-left: 0;
  }
  & + div {
    & > .app-table {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .custom-checkbox-select {
    .ant-col {
      label {
        display: none;
      }
    }
  }
  .input-wrap {
    display: inline-block;
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }
  .collapse-window {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:before {
      width: 24px;
      height: 24px;
      background-position: -326px 88px;
      position: absolute;
      right: 6px;
    }
  }
}

.dr-prescription-wrapper {
  @include flexbox();
  position: relative;
  .ocular-problem-wrapper {
    position: relative;
  }
  .encounter-left-panel-wrapper {
    @include flexbox();
    @include flex-direction(column);
    height: 86.1rem;
    .allergies-panel{
      padding-top: 0;
      padding-bottom: 0;
      .list {
        padding-top: $gap-05;
      padding-bottom: $gap-05;
      }
    }
    .draggable-panel-container {
      &.collapsable-open {
        .procedure-pannel {
          min-height: 10.0rem;
          border-radius: 0;
        }
      }
      .info-accordian {
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        .ant-collapse-item {
          @include flexbox();
          @include flex-direction(column);
          height: 100%;
          .ant-collapse-content {
            .ant-collapse-content-box {
              & > .pannel {
                height: 100%;
                & > .pannel-body {
                  height: 100%;
                  padding-top: 0;
                  padding-bottom: 0;
                  .table-container {
                    .app-table {
                      border: 0;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
            & > .resize-custom {
              top: auto !important;
              bottom: 0;
            }
          }
        }
      }
    }
  }
  .medication-wrap {
    width: 26%;
    border-right: 1px solid $button-border;
    .pannel {
      box-shadow: none;
      margin-bottom: 0;
      &.imaging-pannel {
        border-radius: 0;
        .table-in-scroll-header{
          border-radius: 0;
        }
        .pannel-body {
          .medication-list {
            ul {
              margin-bottom: 0;
              li {
                display: block;
              }
            }
          }
        }
      }
      &.procedure-pannel {
        .pannel-heading {
          box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
        }
        .pannel-body {
          padding: 0;
        }
      }
      .pannel-heading {
        padding-left: $gap-08;
        padding-right: $gap-08;
        position: relative;
        .view {
          color: $color-green;
          cursor: pointer;
          &.copy {
            padding-right: 1.6rem;
            font-weight: $font-regular;
          }
        }
        .add {
          position: absolute;
          top: 4px;
          right: 5px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          &:before {
            content: "";
            background-position: -242px -418px;
            width: 20px;
            height: 20px;
            position: absolute;
          }
        }
      }
      .pannel-body {
        padding: $gap-08;
        border-bottom: 1px solid $button-border;
        border-radius: $gap-04 $gap-04 0 0;
        .medication-list {
          ul {
            li {
              &.only-right {
                .first-list {
                  @include justify-content(flex-end);
                  .right-section {
                    width: auto;
                  }
                  p {
                    &:first-child,
                    &:last-child {
                      margin: 0;
                      width: auto;
                    }
                  }
                }
              }
            }
          }
          .first-list {
            @include flexbox();
            @include justify-content(space-between);
            width: 100%;
            .right-section {
              @include flexbox();
              width: 14.6rem;
              @include justify-content(space-between);
              p {
                &:last-child {
                  width: 9.1rem;
                  text-align: left;
                }
              }
            }
          }
          .second-list {
            @extend .first-list;
            margin-top: $gap-02;
            .right-section {
              p {
                &:first-child {
                  width: 3.2rem;
                  text-align: center;
                }
              }
            }
          }
          .ocular-problem-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: $gap-05;
            text-align: start;
            flex-grow: 1;
            cursor: default;
          }
          .ocular-problem-code {
            min-width: 6.0rem;
            max-width: 6.0rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;
          }
        }
      }
    }
    .info-accordian {
      box-shadow: $box-shadow-base;
      border-radius: $border-radius-base;

      .ant-collapse-extra {
        position: relative;
        right: 2.5rem;
        color: $color-green;
      }
      .ant-collapse-content-box {
        padding: 0px;
      }
      .copy {
        .copy-text {
          padding-right: $gap-07;
          font-weight: $font-regular;
        }
      }
    }
    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border-bottom: none;
        box-shadow: inset 0 1px 0 0 $button-border;
        & > .ant-collapse-header {
          background: $color-light-grayies;
          padding: 2.5px 8px;
          color: $color-blue-text;
          font-size: $font-size-xsm;
          font-weight: $font-bold;
          border-bottom: 1px solid $button-border;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      & > .ant-collapse-item {
        &.ant-collapse-item-active {
          & > .ant-collapse-header {
            .ant-collapse-arrow {
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
            }
          }
        }
        & > .ant-collapse-header {
          .ant-collapse-arrow {
            background: url("../../img/arrow-icons.png");
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            right: 10px;
            top: 50%;
            left: auto;
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            margin-top: -10px;
            transition: transform 0.24s;
            -webkit-transition: transform 0.24s;
            svg {
              display: none;
            }
          }
        }
      }
    }
    .ant-collapse-header {
      .ant-collapse-extra {
        width: 35px;
        .anticon-plus {
          background: url("../../img/Sprite.png");
          background-size: 748px;
          background-position: -242px -418px;
          width: 20px;
          height: 20px;
          position: absolute;
          right: -3px;
          svg {
            display: none;
          }
        }
        .anticon-edit {
          font-size: 16px;
        }
      }
    }
    .ant-collapse-content {
      border-top: none;
    }
  }

  .complaint-wrap {
    width: 38%;
    .input-fields-section {
      .info-row {
        position: relative;
        .custom-input .ant-row .ant-form-item-label > label {
          font-size: $font-size-xsm;
        }
        .info {
          position: absolute;
          left: 70%;
          top: 9px;
          &:before {
            display: none;
            top: 2px;
          }
          &.info-label {
            left: 87%;
          }
        }
      }
    }
    .second-colunm {
      .info-row {
        position: relative;
        .custom-input .ant-row .ant-form-item-label > label {
          font-size: $font-size-xsm;
        }
        .info {
          position: absolute;
          left: 91%;
          top: 9px;
          &:before {
            display: none;
          }
        }
      }
      .ant-radio-wrapper {
        margin-right: 2px;
        span {
          font-size: $font-size-xsm;
        }
      }
    }
  }
  .other-wrap {
    width: 36%;
    position: relative;
    background: $app-background;
    .pannel {
      &.follow-up {
        .pannel-body {
          padding: $gap-08 0px $gap-04 $gap-08;
        }
      }
    }
    .first-colunm {
      @include flexbox;
      .info-row {
        position: relative;
        &:first-child {
          min-width: 13.4rem;
          margin-right: $gap-12;
          .custom-input {
            label {
              color: $content-color;
              font-weight: $font-regular;
            }
          }
          .info {
            position: absolute;
            right: 0;
          }
        }
        .custom-input {
          input {
            padding: $gap-06;
          }
        }
        .info {
          position: absolute;
          top: 8px;
          right: 13px;
          color: $content-color;
          &:before {
            display: none;
          }
        }
      }
    }
    .btm-follow-up {
      width: calc(100% + 8px);
      margin-left: -$gap-08;
      padding: 0 $gap-08 $gap-08;
      @include flexbox;
      .left-area {
        @include flex(1);
        position: relative;
        &:before {
          content: "";
          width: 1px;
          height: calc(100% + 0.8px);
          background: $button-border;
          position: absolute;
          right: 0;
          top: 0;
        }
        padding: $gap-08 $gap-08 0 0;
        p {
          margin-bottom: 0;
          color: $color-blue-light;
          font-size: $font-size-xsm;
          line-height: $font-size-l;
        }
      }
      .right-area {
        padding: $gap-08 0 0 $gap-08;
        p {
          margin-bottom: 0;
          color: $color-blue-light;
          font-size: $font-size-xsm;
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
}
.assesment-area {
  .pannel {
    border-radius: 0;
    border: $gap-01 solid $button-border;
    .pannel-body {
      min-height: 25.0rem;
      position: relative;
      .small-btn {
        position: absolute;
        top: -3.0rem;
        right: 0.8rem;
      }
    }
  }
  .assessment-pannel {
    position: relative;
    min-height: 2.8rem;
    .assessment-tab {
      position: absolute;
      right: 8px;
      top: 5px;
      .tab1 {
        background: url("../../img/layout-row-fill.png");
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 16px;
        cursor: pointer;
      }
      .tab2 {
        background: url("../../img/grid-line.png");
        width: 18px;
        height: 18px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}
.copy {
  .copy-text {
    padding-right: $gap-07;
    font-weight: $font-regular;
    &.green-copy-icon {
      background: url("../../img/file-copy-green.png");
      width: 13px;
      height: 15px;
      display: inline-block;
      background-size: cover;
      position: relative;
      top: 3px;
      margin-right: 6px;
    }
  }
}

.procedure-table-row-text-container {
  margin: -7px;
  padding: $gap-07;
  .cpt-description, .cpt-name {
    padding-right: 8px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-icon-procedure {
    padding: 4px;
    font-weight: $font-regular;
    position: absolute;
    bottom: 4px;
    right: 0;
  }
}

.hr-divider {
  border-radius: 0.4rem;
  border-bottom: solid 1px $button-border;
  margin-bottom: $gap-04;
}

.complaint-wrap {
  border-right: 1px solid $button-border;
  .search-area {
    .ant-form-item-control-input-content {
      height: 2.8rem;
    }
    .sm-checkbox {
      .ant-form-item-control-input-content {
        height: auto;
      }
    }
    .ant-search {
      .ant-input-search {
        width: 100% !important;
        height: 2.8rem;
        padding: 0;
        border-radius: 0;
        border: none;
        border-bottom: $gap-01 solid $button-border;
        .ant-input {
          height: 2.5rem;
          padding-left: 8px;
        }
        .ant-input-suffix {
          display: none;
        }
      }
      textarea {
        height: 6.5rem;
        min-height: 6.5rem;
        resize: none;
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
      .custom-input .ant-row .ant-form-item-control .ant-input {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $button-border;
      }
    }
  }
}

.modal-area {
  padding: $gap-03 $gap-08;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  &.active {
    .pannel {
      height: auto;
    }
  }
  .pannel {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in;
    .pannel-heading {
      padding-left: $gap-08;
      position: relative;
      .close-icon {
        position: absolute;
        right: $gap-04;
      }
    }
    .pannel-body {
      padding-left: $gap-08;
      box-sizing: border-box;
      padding-right: $gap-08;
      &.medication-body {
        max-height: 70.8rem;
        overflow-y: auto;        
      }
      &.refract-area {
        padding-right: $gap-08;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }
}

.other-wrap {
  .top-header {
    @include flexbox();
    border-bottom: $gap-01 solid $button-border;
    .box {
      background: $color-light-grayies;
      width: 33.3333%;
      @include flex(auto);
      min-height: 64px;
      color: $color-blue-light;
      font-size: $font-size-xsm;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      &.history {
        display: block;
        padding: $gap-04 $gap-08 0;
        box-shadow: inset 0 0px 0 0 $button-border, inset 0 0px 0 0 $button-border, inset -1px 0 0 0 $button-border,
          inset 1px 0 0 0 $button-border;
        p {
          margin-bottom: $gap-04;
          line-height: 1.6rem;
          position: relative;
          span {
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }
    }
  }
  .methometic-area {
    .left-pannel {
      &.left-one {
        box-shadow: none;
      }
    }
  }
}

.active-circle {
  background: $color-green;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.red-circle {
  background: $red-panel-border;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.close-icon {
  background: url("../../img/close.png");
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.inner-pannel-third {
  p {
    @include flexbox;
    width: 60px;
    @include justify-content(space-between);
  }
}
.methometic-area {
  position: relative;
  @include flexbox();
  overflow-y: hidden;
  border-bottom: 0.1rem solid $button-border;
  @media (min-width: $lg-desktop){
    min-height: 149px;
  }
  .digit-one {
    min-width: 36px;
    p {
      margin-bottom: 7px;
    }
  }
  .child-hover {
    position: absolute;
    border-radius: $border-radius-base;
    background-color: $color-white;
    box-sizing: border-box;
  }
}
.right-pannel {
  padding: $gap-04 $gap-08 0;
  color: $color-blue-light;
  font-size: $font-size-xsm;
  p {
    margin-bottom: $gap-04;
  }
  .crossponding-area {
    margin-bottom: $gap-04;
    .text-one {
      margin-right: 3.0rem;
    }
    .left-arrow {
      margin-right: 2.6rem;
    }
    .vertical-arrow {
      margin-right: 2.8rem;
      margin-left: 2.8rem;
    }
  }
}
.methometic-pannel {
  @include flex(1);
  box-sizing: border-box;
  .pressure-vision-widget {
    min-height: 50%;
  }
}
.mid-border {
  &.left-pannel {
    box-shadow: inset 0 0px 0 0 $button-border, inset 0 0px 0 0 $button-border, inset -1px 0 0 0 $button-border,
      inset 1px 0 0 0 $button-border;
  }
}
.group-select-input {
  @include flexbox;
  .sm-select {
    width: 44%;
    @include flexbox;
    @include align-items(center);
  }
  .sm-select > div {
    width: 100%;
  }
  .group-input-wrap {
    width: 100%;
  }
  .left-arrow {
    margin: -$gap-07 $gap-08 0 $gap-08;
    min-width: 12px;
  }
}
.left-arrow {
  margin-right: 16px;
  background: url("../../img/cross-arrow-left.png");
  width: 8px;
  height: 12px;
  background-position: center;
  display: inline-block;
}
.crossponding-area {
  @include flexbox();
  @include align-items(center);
  margin-bottom: 12px;
  .text-one {
    margin-right: 8px;
    &.reduction-wrap {
      margin-right: 0;
    }
  }
  .left-arrow {
    margin-right: 16px;
    background: url("../../img/cross-arrow-left.png");
    width: 8px;
    height: 12px;
    background-position: center;
    margin-top: -8px;
  }
  .vertical-arrow {
    // margin-right: 14px;
    margin-left: 8px;
    background: url("../../img/right-arrow.png");
    width: 33px;
    height: 20px;
    background-position: -2px 12px;
    margin-top: -10px;
    background-repeat: no-repeat;
  }
  .digit-one {
    min-width: 60px;
    & > {
      p {
        margin-bottom: 6px;
      }
    }
    p {
      margin-bottom: 6px;
    }
  }
}
.btm-area {
  @include flexbox();
  margin-top: $gap-10;
  .btn-left-area {
    @include flex(1);
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    position: relative;
    .info-icon {
      position: absolute;
      left: 3.7rem;
      & + .ant-dropdown-trigger {
        .down-arrow {
          margin-left: 3.0rem;
        }
      }
    }
    .oneof {
      @include flexbox();
      line-height: 1.5rem;
      .ant-form {
        all: unset
      }
    }
  }
  .btn-right-area {
    @include flex(1);
    @include flexbox();
    @include justify-content(flex-end);
    position: relative;
    .add-icon {
      background: url("../../img/add.png");
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
}
.left-pannel {
  padding: $gap-04 $gap-08 0;
  color: $color-blue-light;
  font-size: $font-size-xsm;
  box-shadow: none;
  p {
    margin-bottom: $gap-06;
    line-height: 1.6rem;
  }
}
.wrap-inner-section {
  padding: $gap-08 $gap-08 0px $gap-08;
  .group-select-input {
    @include align-items(center);
  }
  .crossponding-area {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    .text-one {
      p {
        margin-bottom: $gap-10;
      }
    }
  }
}
.hip-child-hover {
  position: absolute;
  width: 60%;
  top: 9.0rem;
  background-color: $color-white;
  border-radius: $border-radius-base;
  z-index: 999;
}

.hover-inn-wrapper-area {
  max-width: 22.2rem;
  &.manage-form-area-2 {
    max-width: 25.0rem;
    min-width: 19.5rem;
  }
  .wrap-inner-section {
    padding-bottom: $gap-05;
  }
}

.info-icon {
  background: url("../../img/information-line.png");
  width: 14px;
  height: 14px;
}
.down-arrow {
  background: url("../../img/cross-arrow-left.png");
  width: 8px;
  height: 12px;
  background-position: center;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transition: all 0.2s ease-in;
  min-width: 20px;
  cursor: pointer;
}
.see-more-data {
  position: relative;
  left: 50%;
  margin-left: -4px;
}
.ant-dropdown-open {
  .down-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}
.right-arrow {
  background: url("../../img/cross-arrow-left.png");
  width: 8px;
  height: 12px;
  background-position: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.close-circle {
  width: 21px;
  height: 21px;
  background: url("../../img/cross-circle.png");
  display: inline-block;
  background-size: cover;
  cursor: pointer;
}

.select-box-section {
  .ant-collapse-content {
    border-top: none;
  }
  .info-accordian {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(165, 188, 223, 0.7);
    &:last-child{
      border-bottom: none;
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    &:last-child {
      border-radius: 0;
    }
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
  }
  .ant-collapse {
    border-radius: 0;
  }

  .header-pannel {
    height: 2.8rem;
    @include flexbox();
    background: $app-background;
    border-bottom: $gap-01 solid $button-border;
    border-top: 1px solid $button-border;
    & + .content-body {
      .text-box,
      .select-box {
        padding-top: $gap-04;
      }
    }
    .head {
      @include flex(2);
      color: $content-color;
      padding-left: 0px;
      @include flexbox();
      @include align-items(center);
      font-size: $font-size-xsm;
      position: relative;
      &.box-one {
        @include flex(1);
        min-width: 8.4rem;
      }
      &.box-two {
        border-right: $gap-01 solid $button-border;
        border-left: $gap-01 solid $button-border;
        .od {
          margin-left: $gap-08;
        }
      }
      &.box-three {
        @include flex-direction(row-reverse);
        .close-circle {
          margin-right: $gap-08;
        }
        .os {
          margin-right: $gap-08;
        }
      }
      .prescription-checkbox {
        position: absolute;
        width: 0;
        left: 40px;
        top: 4px;
        .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
          width: 14px;
          height: 14px;
          border-radius: 0;
        }
        .ant-checkbox-inner {
          &:after {
            top: 5px;
            left: 2px;
            display: table;
            width: 5px;
            height: 8px;
          }
        }
      }
      .circle {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 8px;
      }
      .close-circle {
        margin-left: 8px;
      }
      .copy-icon {
        position: absolute;
        right: -3px;
        background: $app-background;
        width: 6px;
        height: 26px;
        &:before {
          content: "";
          width: 15px;
          height: 17px;
          background: url("../../img/file-copy-.png");
          display: inline-block;
          background-size: cover;
          cursor: pointer;
          position: absolute;
          top: 2px;
          left: -4px;
        }
      }
    }
  }
  .content-body {
    background: $color-white;
    @include flexbox();
    @include align-items(center);
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      max-width: 100%;
      min-width: 100%;
    }
    .ant-select-selection-item {
      width: 9.0rem;
    }
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
    .text-box {
      @include flexbox();
      @include flex(1);
      min-width: 8.4rem;
      color: $content-color;
      font-size: $font-size-xsm;
      @include align-items(center);
      height: auto;
      padding-left: $gap-04;
      line-height: $font-size-m;
      padding-bottom: 0;
      word-break: break-all;
    }
    .select-box {
      @include flex(2);
      padding: 0 $gap-04 0;
      position: relative;
      &.one {
        border-right: $gap-01 solid $button-border;
        border-left: $gap-01 solid $button-border;
        .ant-select {
          width: calc(100% - 10px);
        }
      }
      &.two {
        .ant-select {
          width: calc(100% - 10px);
        }
        & > .ant-select {
          margin-left: $gap-12;
        }
        .right-arrow {
          position: absolute;
          right: auto;
          left: 3px;
          top: 11px;
          cursor: pointer;
          transform: rotate(00deg);
          -webkit-transform: rotate(0deg);
        }
      }
      .right-arrow {
        position: absolute;
        right: 4px;
        top: 11px;
        cursor: pointer;
      }
    }
  }
}

.button-list {
  @include flexbox();
  @include flex-wrap(wrap);
  padding: $gap-06 0 0 $gap-08;
  border-top: 1px solid $button-border;
  .medication-btn {
    margin-right: $gap-08;
    margin-bottom: $gap-06;
  }
  .btn-success {
    background: $color-green;
    border: $gap-01 solid $color-green !important;
    color: $color-white !important;
    &:hover {
      color: $color-white !important;
      background: $color-green;
      border: 1px solid $color-green !important;
    }
  }
}
.check-icon {
  width: 21px;
  height: 21px;
  background: url("../../img/checkbox-circle.png");
  display: inline-block;
  background-size: cover;
  cursor: pointer;
}
.ros-table-area {
  @include flexbox;

  .label-area {
    min-width: 5.2rem;
    margin-right: 2.0rem;
    margin-top: 2.0rem;
    p {
      font-size: $font-size-m;
      color: $color-blue-light;
      margin-bottom: $gap-05;
      line-height: 2.0rem;
    }
  }
  .od-os-label {
    @include flexbox;
    @include justify-content(space-between);
    span {
      font-size: $font-size-xsm;
      color: $color-blue-light;
      font-weight: $font-medium;
      &.check-icon{
        position: relative;
        top: -3px;
      }
    }
  }
  .table-area {
    margin-bottom: $gap-12;
    .table-row {
      @include flexbox;
      .table-col {
        border: $gap-01 solid $button-border;
        border-bottom: none;
        min-width: 5.4rem;
        border-right: none;
        &.last {
          border-right: $gap-01 solid $button-border;
        }
      }
      &.last-row {
        .table-col {
          border-bottom: $gap-01 solid $button-border;
        }
      }
    }
  }
  .ant-row {
    &.ant-form-item {
      margin-bottom: 0;
    }
  }
  .ant-row .ant-form-item-control .ant-form-item-control-input {
    height: 2.4rem;
    line-height: 2.4rem;
  }
  .custom-input .ant-row .ant-form-item-control .ant-input {
    border: none;
    box-shadow: none;
    height: 2.4rem;
    &:focus {
      outline: none;
      border: none;
      height: 2.4rem;
      box-shadow: none;
    }
  }
}
.medication-heading {
  p {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    font-weight: $font-medium;
    margin-bottom: $gap-08;
    &.gree-heading {
      color: $color-green;
      cursor: pointer;
    }
  }
}
.pharmacy-address {
  p {
    margin-bottom: $gap-04;
    color: $color-blue-light;
    font-size: $font-size-xsm;
  }
}

.contact-details {
  p {
    margin-right: 2.4rem;
    color: $color-blue-light;
    .key {
      color: $content-color;
    }
  }
}
.form-group-btns {
  button {
    margin-right: 1.6rem;
  }
}

.btn-medication-group {
  @include flexbox();
  @include flex-wrap(wrap);
  .medication-btn {
    margin-right: $gap-08;
    margin-bottom: $gap-08;
  }
}
.top-header {
  .hpi {
    &.hover-bg {
      background: rgba(50, 199, 136, 0.2);
    }
  }
  .history {
    &.hover-bg-1 {
      background: rgba(50, 199, 136, 0.2);
    }
  }
  .ros {
    &.hover-bg-2 {
      background: rgba(50, 199, 136, 0.2);
    }
  }
  .hpi,
  .history,
  .ros {
    &:hover {
      background: rgba(50, 199, 136, 0.2);
    }
    &.ant-dropdown-open {
      background: rgba(50, 199, 136, 0.2);
    }
  }
}

.hover-section-1 {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.16);
  border: $gap-01 solid $button-border;
  background-color: $color-white;
  overflow: hidden;
  border-radius: $border-radius-base;
  z-index: 999;
}

.ant-dropdown {
  .ant-dropdown-menu {
    padding: 0;
  }
}

.history-pannel-header {
  width: calc(100% + 1.6rem);
  margin-left: -$gap-08;
  padding-left: $gap-08;
  .expand-icon {
    position: absolute;
    right: $gap-04;
    top: $gap-04;
  }
}
.radio-group-wrap {
  position: relative;
  @include flexbox;
  width: 90%;
  @include justify-content(space-between);
  .radio-wrap {
    max-width: 18.0rem;
    width: 18.0rem;
    .ant-radio-wrapper {
      @include flexbox;
      .ant-radio {
        & + span {
          white-space: normal;
          padding-left: $gap-08;
        }
      }
    }
  }
}
.expand-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../../img/expand.png");
  background-repeat: no-repeat;
  cursor: pointer;
}
.expend-icon-right {
  @include flexbox;
  @include justify-content(flex-end);
  margin-bottom: 6px;
}

.ros-header {
  height: 2.8rem;
  @include flexbox;
  @include justify-content(space-between);
  padding: $gap-06 $gap-08;
  background: $color-light-grayies;
  p {
    margin: 0;
    &.system {
      color: $color-blue-text;
      font-weight: $font-bold;
      font-size: $font-size-m;
    }
    span {
      font-size: $font-size-xsm;
      color: $color-green;
      font-weight: $font-medium;
      margin-left: $gap-04;
      cursor: pointer;
    }
  }
}
.switch-wrap {
  margin-bottom: $gap-06;
  @include flexbox;
  @include justify-content(space-between);
  font-size: $font-size-xsm;
  color: $content-color;
  @include align-items(center);
}
.list {
  .switch-wrap {
    label {
      padding-right: $gap-08;
      max-width: 11.0rem;
    }
  }
}
.ros-content {
  padding: 0 $gap-08;
  @include flexbox;
  .left-area {
    @include flex(1);
    border-right: 1px solid $button-border;
    padding-right: $gap-10;
  }
  .right-area {
    @include flex(1);
    padding-left: $gap-10;
  }
  .title {
    color: $color-blue-light;
    font-size: $font-size-m;
    font-weight: $font-bold;
    margin-bottom: $gap-08;
  }
}

.hover-section-content {
  padding: $gap-08;
  .ant-select {
    &.ant-select-multiple {
      .ant-select-selector {
        min-height: 6.0rem;
        @include align-items(flex-start);
      }
    }
  }
}
.head-text {
  p {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
    text-align: center;
    margin-bottom: $gap-06;
  }
}
.min-width-36 {
  min-width: 3.6rem;
}
.reduction-wrap {
  @include flexbox;
  @include align-items(flex-start);
  p{
    height: 2.8rem;
    @include flexbox();
    @include align-items(center);
    margin: 0;
  }
  margin-bottom: $gap-08;
  .custom-input {
    max-width: 8.4rem;
    padding-left: 1.5rem;
    margin-right: $gap-08;
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .custom-select {
    max-width: 9.4rem;
    padding-left: $gap-10;
    margin-right: $gap-08;
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .reduction {
    text-align: right;
  }
}
.dialation-area {
  @include flexbox;
  @include justify-content(space-between);
  .text {
    font-size: $font-size-xsm;
    padding-top: $gap-05;
    color: $color-blue-light;
  }
}
.sm-checkbox-wrap {
  margin-left: 3.4rem;
  @include flexbox;
  .custom-checkbox {
    width: 4.6rem;
  }
  .ant-checkbox-wrapper .ant-checkbox {
    top: $gap-03;
    right: auto;
    left: -4.5rem;
  }
  &.vertical-checkbox {
    margin-left: 0;
    display: block;
    margin-top: -8px;
    .custom-checkbox {
      width: 100%;
      padding-left: $gap-06;
      height: 2.6rem;
      .ant-row {
        @include flex-direction(row-reverse);
      }
    }
    .ant-checkbox-wrapper .ant-checkbox {
      top: $gap-04;
      right: auto;
      left: -$gap-06;
    }
  }
}
.padding-left-16 {
  padding-left: 1.6rem;
}
.no-white-space-wrap {
  white-space: nowrap;
}
.centter-align {
  @include flexbox;
  @include justify-content(center);
}
#area1 .ant-select-dropdown {
  left: 0px !important;
  overflow: auto;
  height: 25.0rem;
}
.checkbox-pos-mng {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      top: $gap-03;
    }
  }
}

.check-mng-row {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      top: $gap-03;
    }
  }
  &.mr-lt-6 {
    margin-left: $gap-06;
  }
}
.move-top-8 {
  margin-top: -$gap-08;
}

@media (min-width: $lg-desktop) {
  .select-box-section {
    .content-body {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
@media (min-width: $medium-desktop) {
  .select-box-section {
    .content-body {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
  .button-list {
    .medication-btn {
      @include flex(1);
    }
  }
}
@media (max-width: $medium-desktop) {
  .dr-prescription-wrapper {
    .other-wrap {
      .btm-follow-up {
        button {
          font-size: $font-size-xsm;
        }
      }
    }
  }
}
@media (max-width: $max-desktop) {
  .dr-prescription-wrapper {
    .other-wrap {
      .btm-follow-up {
        button {
          font-size: $font-size-xxs + 1;
        }
      }
    }
  }
}

@media (max-width: $min-desktop) {
  .patients-list {
    .patient-details {
      .exam {
        margin-right: 18px;
      }
    }
    .patient-name {
      margin-right: 18px;
      max-width: 116px;
      word-break: break-word;
    }
  }
}

@media (max-width: $min-tablet) {
  .filter-header .filter-left .input-wrap,
  .filter-header .filter-left .collapse-icon,
  .filter-header .filter-left .ant-search {
    margin-right: $gap-08;
  }
  .filter-header {
    display: block;
    button {
      margin-left: 0;
      margin-right: $gap-08;
      margin-top: 0px;
    }
  }
}

.item-label {
  font-size: $font-size-xsm;
  color: $color-blue-light;
}

.image-bottom-control-wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  .single-image-control {
    margin: 0 $gap-02;
    font-size: 2.0rem;
    color: $color-white;
  }
}

.background-bottom-panel {
  border-radius: $border-radius-base;
  .custom-input {
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.control-icon-wrapper {
  width: 10.4rem;
  background-color: $color-black;
  border-radius: $border-radius-base;
  height: 2.8rem;
  margin: 0 $gap-10;
  @include flexbox();
  @include justify-content(space-around);
  .anticon-zoom-in {
    background: url("../../img/Sprite.png");
    background-size: 751px;
    background-position: -636px -106px;
    width: 26px;
    height: 28px;
  }
  .anticon-search {
    background: url("../../img/Sprite.png");
    background-size: 751px;
    background-position: -636px -62px;
    width: 26px;
    height: 28px;
  }
  .anticon-zoom-out {
    background: url("../../img/Sprite.png");
    background-size: 751px;
    background-position: -592px -414px;
    width: 26px;
    height: 28px;
  }
}

.rightChevron,
.leftChevron {
  background-color: $color-white;
  font-size: 2.5rem;
  border-radius: 12px;
  visibility: hidden;
}

.pan-area {
  position: relative;
  max-height: fit-content;

  img {
    object-fit: contain;
    height: -moz-available;
    height: -webkit-fill-available;
    height: auto;
  }
}

.pan-area:hover {
  .image-bottom-control-wrapper {
    visibility: visible;
  }
}

.pannel-body:hover {
  .rightChevron {
    visibility: visible;
  }
  .leftChevron {
    visibility: visible;
  }
}

.image-options-container {
  width: 100%;
  max-width: 100%;
  height: 0;
  overflow: visible;
  display: none;
  z-index: 1;

  .image-options {
    @include flexbox();
    background-color: rgba(0, 0, 0, 0.3);
    padding: $gap-08 $gap-08 0 $gap-08;

    .custom-select {
      @include flex(1);
      margin-left: $gap-08;
      overflow: hidden;
    }
    .custom-select:first-child {
      margin-left: 0;
    }
  }
}

.image-panzoom-container {
  @include flexbox();
  @include flex(1);
  @include align-self(stretch);
  @include align-items(center);
  @include justify-content(center);

  .image-chevron-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    pointer-events: none;

    .image-left-chevron,
    .image-right-chevron {
      background-color: $color-white;
      font-size: 2.5rem;
      border-radius: 50%;
      margin: $gap-05;
      visibility: hidden;
      pointer-events: all;
    }
  }
  .notes-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.fullscreen-icon-wrapper {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: $gap-04;
  border: $gap-01 solid $border-gray;
  background-color: $color-white;
  cursor: pointer;
  margin-right: $gap-08;
  .fullscreen-icon {
    font-size: $font-size-lxl;
    padding: $gap-02;
  }
}

.follow-up {
  .add-icon-follow-up {
    font-size: $font-size-xl;
    color: $color-green;
    display: inline-block;
    top: -$gap-03;
    position: relative;
    right: -$gap-10;
  }
  .panel-heading {
    padding: $gap-05 $gap-08;
  }
  .panel-body {
    padding: $gap-08 0 $gap-04 $gap-08;
  }
}

.display-none {
  display: none;
}

.width-350 {
  width: 35.0rem;
}

.warning {
  background-color: $color-warning;
}
.warning-bg {
  position: relative;
}
.warning-bg span {
  z-index: 9;
}
.warning-bg:before {
  content: "";
  background: $color-warning;
  position: absolute;
  top: -7px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 14px);
}
.ant-alert-warning {
  &.warning-style {
    background-color: $color-orange;
    .ant-alert-message {
      color: $color-alert;
    }
    .anticon-close {
      color: $color-alert;
    }
  }
}

// .medication-view {
//   .medication-list {
//     // ul {
//     //   margin: 0;
//     // }
//     // .medication-list-header, .medication-list-content {
//     //   @include flexbox();
//     //   @include justify-content(space-between);
//     //   @include align-items(center);
//     //   background: #f7f9fb;
//     //   padding: 0 8px;
//     //   min-height: 28px;
//     //   border-bottom: 1px solid #d2ddef;
//     //   p {
//     //     margin-bottom: 0;
//     //     color: #748aac;
//     //     font-size: 12px;
//     //   }
//     // }
//     .medication-list-content {
//       background: $color-white;
//       border-bottom: none;
//     }
//     li {
//       @include flexbox();
//       @include justify-content(space-between);
//       @include align-items(center);
//       color: $color-blue-light;
//       font-size: $font-size-xsm;
//       line-height: 12px;
//       margin-bottom: 4px;
//       font-weight: 400;
//       p {
//         margin: 0;
//       }
//       .close-wrap {
//         @include flexbox();
//         @include align-items(center);
//         .active-circle {
//           background: $color-green;
//           width: 10px;
//           height: 10px;
//           border-radius: 50%;
//           display: inline-block;
//           margin-right: 7px;
//           margin-top: -1px;
//         }
//       }
//     }
//   }
// }

@media (max-width: $sm-desktop) {
  .filter-header {
    @include flex-wrap(wrap);
    .filter-left {
      @include flex-wrap(wrap);
      .fullscreen-icon-wrapper,
      .middle-btns,
      .input-wrap,
      .patient-suggestive-search,
      .doctor-action-btn,
      .schedular-action-btn {
        margin-bottom: 12px;
      }
    }
    .filter-right {
      button {
        margin-left: 0px;
        margin-right: 7px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: $lg-tablet) {
  .doctor-screen {
    .doctor-action-btn {
      &.mr-left-20 {
        margin-left: $gap-12;
      }
    }
    .filter-header {
      @include flex-wrap(wrap);
      .filter-left {
        @include flex-wrap(wrap);
        .fullscreen-icon-wrapper,
        .input-wrap,
        .patient-suggestive-search,
        .doctor-action-btn,
        .schedular-action-btn {
          margin-bottom: $gap-12;
        }
      }
      .filter-right {
        button {
          margin-left: 0px;
          margin-right: $gap-12;
        }
      }
    }
    .doctor-wrapper {
      @include flex-wrap(wrap);
      .doctor-tech-patient-list {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.6rem;
      }
      .patient-details {
        width: 100%;
      }
    }
  }
  .dr-prescription-wrapper {
    @include flex-wrap(wrap);
    .medication-wrap {
      width: calc(50% - 8px);
      order: 2;
      margin-right: $gap-08;
      margin-top: 1.6rem;
    }
    .other-wrap {
      width: calc(50% - 8px);
      order: 3;
      margin-top: 1.6rem;
      margin-left: $gap-08;
      border: $gap-01 solid #d2ddef;
      border-radius: $gap-04;
    }
    .complaint-wrap {
      width: 100%;
      order: 1;
      height: auto;
      overflow: auto;
    }
    &.flex {
      .complaint-wrap {
        height: auto;
        margin-top: 1.6rem;
      }
    }
  }
}

@media (max-width: $tablet-medium) {
  .assesment-area {
    .pannel {
      .pannel-body {
        .small-btn{
          top: 3.3rem;
        }
      }
    }
  }
  .close-circle{
    width: 16px;
    height: 16px;
  }
  .select-box-section {
    .header-pannel {
      .check-icon{
        width: 16px;
        height: 16px;
      }
    }
  }
  .dr-filter-header{
    .filter-left{
      .input-wrap{
        width: 100%;
        .custom-select-box{
          .ant-select{
            width: 100% !important;
          }
        }
        &.mr-lt-8{
          margin-left: 0;
        }
      }
      .patient-suggestive-search{
        width: 100%;
        margin-right: $gap-08;
        .ant-select{
          width: 100% !important;
        }
      }
    }
  }
  .dr-prescription-wrapper {
    .other-wrap,
    .medication-wrap {
      width: 100%;
    }
    .encounter-left-panel-wrapper{
      height: auto;
    }
  }
  .other-wrap {
    button.btn.sm-btn {
      max-height: 100%;
      margin-bottom: $gap-08;
    }
  }
}
