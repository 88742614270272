@import '../../../css/vars.scss';

.patient-list-modal {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    .filter-wrapper {
      display: flex;
      .ant-input-group-wrapper{
        .ant-input-wrapper{
          .ant-input-affix-wrapper{
            height: 28px;
          .ant-input{
            height: 22px;
          }
        }
        }
      }
    }
    .exclude-filter {
      margin-left: $gap-10;
      margin-top: $gap-05;
      align-self: center;
    }
  }
  .patent-list-search {
    margin-bottom: $gap-10;
  }
  .app-table {
    max-height: 50.0rem;
    overflow: auto;
  }
}
