@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.physicain-wrap {
    background: $color-light-grayies;
    min-height: 2.4rem;
    padding: 0.4rem;
    border-radius: 3px;
    margin-top: 8px;
    .ant-input-group-wrapper {
        width: 100% !important;
    }
    p {
        text-align: right;
        margin-bottom: 8px;
        span {
            color: $color-green;
            margin-left: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-weight: $font-regular;
        }
    }
    .destination-fax-no {
        margin-top: 12px;
        font-size: 1.2rem;
        color: $color-blue-light;
        .label-name {
            font-weight: $font-medium;
            text-transform: capitalize;
        }
        .label-value {
            margin-left: 8px;
            font-weight: $font-regular;
        }
    }
    .select-destination-fax-no {
        font-size: 1.2rem;
        color: $color-blue-light;
        margin-bottom: 2px;
        .label-name {
            font-weight: $font-medium;
            text-transform: capitalize;
        }
        .label-value {
            margin-left: 8px;
            font-weight: $font-regular;
        }
    }
    .fax-no-list-wrap {
        .fax-no-list {
            @include flexbox();
            @include align-items(center);
            p {
                span {
                    color: $color-blue-light;
                    font-size: 1.2rem;
                }
            }
        }
    }
}
.ant-modal {
    &.fax-modal-cls {
        padding-bottom: 0;
        .fax-content-area {
            .preview-section {
                &.parent-preview {
                    height: 89vh;
                    overflow: auto;
                    overflow-x: hidden;
                }
                .preview {
                    background: $color-white;
                }
                .preview-image-tools {
                    right: 15px;
                    bottom: 6px;
                    span {
                        height: 2.8rem;
                        width: 2.8rem;
                        margin-top: 0.6rem;
                        margin-bottom: 0.6rem;
                    }
                }
                .preview-section {
                    overflow: auto;
                    width: 100%;
                }
            }
        }
    }
    &.fax-nested-modal-cls {
        .preview-section {
            height: 78vh;
            overflow: auto;
            width: calc(100% + 46px);
            margin-left: -23px;
        }
        padding-bottom: 0;
    }
}
.fax-content-area {
    @include flexbox();
    .left-pannel {
        width: 280px;
        min-width: 280px;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        background: $color-light-grayies;
        margin-right: 1.6rem;
        border-radius: 4px;
        height: 89vh;
        overflow: auto;
        overflow-x: hidden;
    }
    .preview-section {
        width: calc(100% - 280px);
        background: $color-light-grayies;
        border-radius: 4px;
        padding: 1.2rem;
        .upload-document-preview {
            width: 100%;
        }
        p.text-right {
            text-align: center;
        }
        .provider-location-seperator {
            height: 1px;
            border: none;
            border-bottom: 1px solid #d2ddef;
            width: calc(100% + 24px);
            margin-left: -12px;
        }
        .preview-section-inn {
            .header-title {
                color: $color-blue-light;
                font-size: 16px;
                border-bottom: 1px solid #d2ddef;
                width: calc(100% + 22px);
                margin-left: -11px;
                padding-left: 12px;
                padding-bottom: 8px;
            }
            .preview-document-list {
                height: calc(68vh - 2px);
                .list-icon {
                    display: block;
                    color: $color-blue-light;
                    font-size: 14px;
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 8px;
                    &::before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        background-color: $color-green;
                        position: absolute;
                        left: 0;
                        background-image: url("../../../img/file-text-white.png");
                        background-position: center;
                        background-size: 48%;
                        background-repeat: no-repeat;
                        border-radius: 50%;
                    }
                }
            }
        }
        .custom-autocomplete .ant-select-clear {
            display: block;
        }
    }
}
.fax-modal-header-wrap {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    width: 100%;
    padding-right: 40px;
    button {
        position: relative;
        top: -6px;
    }
    .header-icon-wrap {
        @include flexbox();
    }
}
.left-pannel {
    .left-pannel-button {
        text-align: right;
        button {
            margin-left: 0.8rem;
        }
    }
    .documet-list {
        margin-top: 1.2rem;
        .error {
            color: #2a4a79;
        }
        &.selected-document-list {
            li {
                cursor: default;
                &.documet-list-wrap p {
                    padding-left: 10px;
                }
                .delete {
                    cursor: pointer;
                }
            }
            &.active {
                color: $color-blue-light;
                color: $color-white;
                box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
                position: relative;
                background: #2e79f8;
                &::before {
                    content: "";
                    background: #1c4b9b;
                    width: 2px;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }
        li {
            border: 1px solid #d2ddef;
            border-radius: 4px;
            margin-bottom: 0.8rem;
            min-height: 3.6rem;
            padding: 3px 6px 3px 0;
            font-size: 1.4rem;
            cursor: pointer;
            &.error {
                border: 1px solid $color-border-red;
                .documet-list-wrap {
                    padding-left: 44px;
                    .error-icon {
                        width: 1px;
                        height: calc(100% + 6px);
                        position: absolute;
                        left: 0;
                        border-radius: 0;
                        background-repeat: no-repeat;
                        background-color: $color-border-red;
                        background-position: center;
                        background-size: 9px;
                        margin-left: 20px;
                        &::before {
                            content: "";
                            background: url("../../../img/error-icon.png");
                            width: 17px;
                            height: 15px;
                            position: absolute;
                            left: 6px;
                            top: 9px;
                        }
                    }
                }
            }
            &.active {
                background: $color-blue;
                color: $color-white;
            }
            .documet-list-wrap {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);
                padding-left: 18px;
                position: relative;

                .sort-icon {
                    width: 20px;
                    height: calc(100% + 6px);
                    position: absolute;
                    left: 0;
                    border-radius: 2px 0 0 2px;
                    background: url("../../../img/drag-icon-w.png");
                    background-repeat: no-repeat;
                    background-color: rgba(0, 0, 0, 0.2);
                    background-position: center;
                    background-size: 9px;
                }
                .documet-list-wrap-checkbox {
                    @include flexbox();
                    @include align-items(center);
                    height: 28px;
                    .sm-checkbox {
                        .ant-row.ant-form-item {
                            margin: 0;
                        }
                    }
                    p {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 184px;
                    }
                }
                p {
                    margin: 0;
                    padding-left: 8px;
                    .anticon-delete {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.fax-modal-icon {
    background: url("../../../img/fax-icon.png");
    background-size: cover;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.m-fax-ui {
    position: relative;
    .ant-radio-group-outline {
        .ant-radio-wrapper {
            margin-right: 8px;
        }
    }
    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        background: url("../../../img/cross-circle.png");
        width: 16px;
        height: 16px;
        background-size: cover;
    }
}

.fax-container {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.footer-btn-section {
    border-top: 1px solid #d2ddef;
    width: calc(100% + 22px);
    margin-left: -11px;
    padding: 8px 8px 0 8px;
    @include flexbox;
    display: flex;
    justify-content: flex-end;
}

.selected-document-list {
    background: #bacce9;
    margin: 6px -4px;
    border-radius: 2px;
    padding: 4px;
    li {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.active-bundle-flag {
    display: block;
    background: url("../../../img/bundle-icon-w.png");
    width: 28px;
    height: 24px;
    background-size: cover;
    margin: 2px auto 4px;
}

.fax-checkbox-wrap {
    margin-left: 2px;
    text-transform: capitalize;
    .sm-checkbox {
        height: 29px;
        margin-top: -3px;
        .ant-checkbox.ant-checkbox-checked + span {
            color: $color-white;
            font-weight: $font-medium;
        }
        .ant-checkbox + span {
            font-weight: $font-medium;
        }
    }
}

.cover-page-wrap {
    @include flexbox;
    overflow-x: auto;
    .cover-card {
        min-width: calc(20% - 10px);
        margin-right: 12px;
        margin-bottom: 8px;
        border-radius: 3px;
        position: relative;
        &:last-child {
            margin-right: 0;
        }
        .active-check {
            display: none;
        }
        .sm-checkbox {
            position: absolute;
            right: -10px;
            top: -3px;
        }
        &.active {
            position: relative;
            .cover-card-content {
                border: 1px solid #32c788;
                background-size: cover;
                &::before {
                    content: "";
                    background: rgba(50, 199, 136, 0.1);
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: calc(100% - 24px);
                    width: 100%;
                }
            }
        }
        .cover-card-content {
            background: #c2d1e138;
            height: 112px;
            border-radius: 3px;
            overflow: hidden;
        }
        .cover-card-footer {
            padding: 2px;
            color: #2a4a79;
        }
        .ant-upload.ant-upload-select-picture-card {
            display: block !important;
            width: 100%;
            height: 140px;
        }
    }
}
