@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.app-open-sidebar {
  width: 19.6rem;
  background: #eaf2ff;
  border-right: $gap-01 solid $button-border;
  background-color: transparent;
  .ant-drawer-content-wrapper {
    padding-left: 60px;
    .ant-drawer-content {
      overflow: visible;
      .ant-drawer-body {
        padding-top: $gap-12;
      }
    }
  }
  ul {
    margin-left: -2.0rem;
    li {
      padding: $gap-06 $gap-04 $gap-06 5.4rem;
      &.multi-dropdown-list {
        ul {
          margin-left: -3.2rem;
          margin-top: $gap-08;
          display: none;
        }
        &:hover {
          background: $color-blue;
          transition: all 0.2s ease-in;
          ul {
            display: block;
          }
        }
      }
      ul {
        li {
          padding-left: 4.8rem;
          &:hover {
            background: rgba(0, 0, 0, 0.2);
          }
          &:before {
            left: 16px;
          }
        }
      }
      cursor: pointer;
      border-radius: 0 $gap-06 $gap-06 0;
      color: $color-blue-light;
      a {
        padding: 0;
        position: static;
        color: $color-blue-light;
        display: block;
      }

      &:before {
        left: 20px;
        background-position: -20px -20px;
        width: 24px;
        height: 24px;
        position: absolute;
      }
      &.business {
        &:before {
          background-position: -502px -20px;
        }
      }
      &.user {
        &:before {
          background-position: -503px -197px;
        }
      }
      &.location {
        &:before {
          background-position: -504px -153px;
        }
      }
      &.provider {
        &:before {
          background-position: -502px -65px;
        }
      }
      &.payer {
        &:before {
          background-position: -504px -417px;
        }
      }
      &.cpt {
        &:before {
          background-position: -591px -21px;
        }
      }
      &.user-rights {
        &:before {
          background-position: -503px -240px;
        }
      }
      &.composer {
        &:before {
          background-position: -502px -373px;
        }
      }
      &.preferences {
        &:before {
          background-position: -502px -329px;
        }
      }
      &:hover {
        background: $color-blue;
        color: $color-white;
        a {
          color: $color-white;
        }
        &.business {
          &:before {
            background-position: -546px -20px;
          }
        }
        &.user {
          &:before {
            background-position: -548px -197px;
          }
        }
        &.location {
          &:before {
            background-position: -548px -153px;
          }
        }
        &.provider {
          &:before {
            background-position: -546px -65px;
          }
        }
        &.payer {
          &:before {
            background-position: -549px -417px;
          }
        }
        &.cpt {
          &:before {
            background-position: -635px -21px;
          }
        }
        &.user-rights {
          &:before {
            background-position: -546px -240px;
          }
        }
        &.composer {
          &:before {
            background-position: -546px -373px;
          }
        }
        &.preferences {
          &:before {
            background-position: -546px -329px;
          }
        }
      }
    }
  }

  .close-back {
    position: absolute;
    top: 2.0rem;
    right: -$gap-10;
    width: 2.0rem;
    height: 2.0rem;
    border-radius: 2.0rem;
    background: #fff;
    color: $color-blue-light;
    border: 1px solid rgba(0, 0, 0, 0.12);
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;
    background-image: url("../../img//arrow-icons.png");
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      color: $color-white;
      background: $color-blue;
      border: 0px solid $color-blue;
      background-image: url("../../img/white-arr.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
    }
  }
  .ant-drawer-body:hover {
    .close-back {
      @include flexbox;
    }
  }
  &.ant-drawer-open{
    z-index: 999 !important;
  }
}
