@import "../../../../../css/vars.scss";
@import "../../../../../css/mixin.scss";

.create-order-set-wrap {
    .header-options {
        @include flexbox();
        .custom-input {
            margin-right: 2.4rem;
        }
    }
    .order-set-inn-wrap {
        height: 84vh;
        .v-tab {
            border-left: 0.1rem solid #d2ddef;
            height: 100%;
            .procedures {
                .right-tab {
                    .ant-tabs-nav .ant-tabs-tab-active,
                    .ant-tabs-nav-list .ant-tabs-tab-active {
                        background: transparent;
                        padding-left: 0;
                        margin: 0 1.6rem 0 0;
                        .ant-tabs-tab-btn {
                            color: #32c788;
                        }
                    }
                }
            }
            .ant-tabs-left {
                .procedures {
                    .ant-tabs-nav .ant-tabs-tab {
                        padding-left: 0;
                        margin: 0 1.6rem 0 0;
                        box-shadow: none;
                    }
                }
            }
            #tab-left {
                height: 100%;
                .ant-tabs-nav-wrap {
                    border-bottom: 0.1rem solid #d2ddef;
                    border-top: 0.1rem solid #d2ddef;
                }
                .procedures {
                    .ant-tabs-nav-wrap {
                        border-bottom: none;
                        border-top: none;
                    }
                }
            }
            .ant-tabs-left .ant-tabs-nav .ant-tabs-tab {
                padding-left: 2.4rem;
            }
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $color-white;
        }
        .follow-up-label {
            .ant-col.ant-col-5 {
                max-width: 100px;
            }
            .ant-col {
                .item-label {
                    min-width: 155px;
                    display: inline-block;
                }
            }
        }
        .followup-row {
            .row-1 {
                .column-1 {
                    width: 340px;
                    .ant-col-15 {
                        max-width: 160px;
                        flex: 0 0 160px;
                        -webkit-flex: 0 0 160px;
                    }
                    .ant-col-9 {
                        max-width: 160px;
                        flex: 0 0 160px;
                        -webkit-flex: 0 0 160px;
                    }
                }
            }
            .row-2 {
                .custom-select {
                    .ant-col-10 {
                        max-width: 160px;
                        flex: 0 0 160px;
                        -webkit-flex: 0 0 160px;
                    }
                    .ant-col-14 {
                        max-width: 160px;
                        flex: 0 0 160px;
                        -webkit-flex: 0 0 160px;
                    }
                }
            }
            .row-3 {
                @include justify-content(flex-end);
                button {
                    margin-left: 1.2rem;
                }
                div{
                    @include flexbox();
                }
            }
        }
        .select-box-section {
            border-left: 0.1rem solid #d2ddef;
            border-right: 0.1rem solid #d2ddef;
            border-bottom: 0.1rem solid #d2ddef;
        }
    }
}

.on-top-for-now {
    position: absolute;
    top: 9px;
    right: 24px;
}
