@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.h-tabs {
  max-width: calc(100% + 4.8rem);
  margin-left: -2.4rem;
  margin-top: -2.4rem;
  .ant-tabs-ink-bar {
    height: $gap-03;
    bottom: $gap-02;
  }
  .ant-tabs-nav-list {
    padding-left: 1.6rem;
    .tab-label-icon {
      padding-left: 36px;
    }
    .ant-tabs-tab {
      margin: 0 1.6rem 0 0;
      padding: $gap-08 0;
      color: $content-color;
      &.ant-tabs-tab-active {
        color: $color-green;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-bar {
      margin-bottom: $gap-08;
      .ant-tabs-nav-container {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-tab {
              min-height: 4.0rem;
              min-width: auto;
              color: $content-color;
              font-size: $font-size-m;
              font-weight: $font-regular;
              margin-right: $gap-08;
              padding: $gap-04 0;
              &.ant-tabs-tab-active {
                color: $color-green;
              }
              .tab-label {
                padding: $gap-06 $gap-08 $gap-06 $gap-08;
              }
              .tab-label-icon {
                padding: $gap-06 $gap-08 $gap-06 3.6rem;
              }
              &:first-child {
                padding-left: $gap-12;
              }
            }
            min-height: auto;
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    &.ant-tabs-nav-animated {
      transform: none !important;
    }
    .ant-tabs-tab-active {
      .sprite-img-before {
        &:after {
          display: none;
        }
      }
      .diagnosis-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -372px 439px;
          left: -12px;
          z-index: 9;
        }
      }
      .history-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -374px 395px;
          left: -12px;
          z-index: 9;
        }
      }
      .cases-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 24px;
          background-position: -372px 351px;
          left: -12px;
          z-index: 9;
        }
      }
      .doctor-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -372px 307px;
          left: -12px;
          z-index: 9;
        }
      }
      .balance-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 24px;
          background-position: -372px 263px;
          left: -12px;
          z-index: 9;
        }
      }
      .demographic-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -285px 395px;
          left: 8px;
          z-index: 9;
        }
      }
    }
    .diagnosis-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 395px;
        left: -12px;
        z-index: 9;
      }
    }
    .history-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 352px;
        left: -12px;
      }
    }
    .cases-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 24px;
        background-position: -329px 308px;
        left: -12px;
      }
    }
    .doctor-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 263px;
        left: -12px;
      }
    }
    .balance-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 24px;
        background-position: -329px 220px;
        left: -12px;
      }
    }
    .demographic-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -241px 395px;
        left: 8px;
      }
    }
  }
}
