@import '../../../../css/vars.scss';
@import '../../../../css/mixin.scss';
.rcm-collapsable {
  margin: 0px 0 5px;
  display: flex;
  flex-direction: column;
  .dropdown p{
    padding: 0px;
    margin: 10px 0px 0px;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    h3 {
      padding: 0px;
      margin: 0px;
      border-left: 2px solid #2a4a79;
      padding: 0px 10px 0px 10px;
      font-size: medium;
    }
    p {
      margin: 0px;
    }
  }
  .rule-message {
    padding-left: 5px;
    font-weight: 500;
    li {
      margin-left: 5px;
      list-style-type: disc;
      text-wrap: balance;
    }
    span {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.607);
    }
    margin: 0px;
  }
  .ant-collapse-header {
    margin-top: 8px
  }
}
.rcm-no-data-text {
  @include flexbox();
  @include justify-content(space-around);
  @include align-items(center);
  height: 100px;
}
