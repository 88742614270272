@import '../../css/mixin.scss';
@import '../../css/vars.scss';

.custom-widget-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: $background-widget-spinner;
  .ant-spin-spinning {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    height: 100%;
  }
}

