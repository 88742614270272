@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.contract-wrapper {
  .list-header {
    @include flexbox();
    @include justify-content(space-between);
    margin-bottom: 1.6rem;
    @include align-items(center);
    .ant-row {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .heading-area {
    margin-bottom: 0px;
  }
  h2 {
    margin-bottom: 0.8px;
  }
}

.new-contract-wrap {
  .top-section {
    @include justify-content(flex-end);
  }
  h2 {
    margin-bottom: 0;
  }
  .new-contract-form {
    padding: 2.4rem;
  }
}
.added-payer-list {
  margin-top: $gap-06;
  width: 88%;
}
.add-payer-head {
  @include flexbox();
  @include justify-content(flex-end);
}
.add-payer {
  @include flexbox();
  color: $color-blue-light;
  cursor: pointer;
  font-size: $font-size-m;
  font-weight: $font-bold;
  padding-left: 2.4rem;
  position: relative;
  .add-list {
    width: 20px;
    height: 20px;
    background-position: -242px 42px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -3px;
  }
}

.wraping-provider-list {
  margin-bottom: 1.6rem;
  @include flexbox();
  width: 88%;
  .custom-list-wrap {
    @include flex(1);
    margin-top: $gap-04;
  }
}

.checkbox-custom-list-wrap {
  margin-top: 1.6rem;
  .select-all {
    .custom-checkbox {
      .ant-form-item-label {
        &.ant-form-item-label-left {
          width: 16.2rem;
          @include flexbox();
          @include justify-content(flex-end);
        }
      }
    }
  }
  .custom-list-wrap {
    margin-top: 0;
    ul {
      li {
        @include flexbox();
        @include justify-content(space-between);
        padding-right: 2.4rem;
      }
    }
  }
  .custom-checkbox {
    @include flexbox();
    @include justify-content(flex-end);
    padding-right: 0px;
    .ant-col {
      &.ant-col-7 {
        max-width: 7.2rem;
        label {
          font-weight: $font-bold;
          color: $color-blue-light;
          width: 7.2rem;
        }
      }
    }
  }
}

.caption {
  background: $color-green;
  padding: $gap-04 $gap-12 $gap-04 $gap-12;
  color: #fff;
  border-radius: $gap-02 $gap-12 $gap-02 $gap-02;
  font-size: $font-size-s;
  margin-left: -$gap-12;
}

.caption-border {
  padding: 0px;
  margin-top: 1.6rem;
  margin-bottom: $gap-08;
}

.main-form-container.contract-container form {
  max-width: 100%;
}

.contract-container {
  .field-row {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);
    .col {
      width: 49%;
    }
    .to {
      margin: 0 $gap-05;
    }
  }
}

.contract-term-exception {
  .exception-description {
    width: 28.0rem;
    display: flex;
    display: -webkit-flex;
    p,
    input {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      line-height: 1.6rem;
    }
    .ant-row {
      .ant-form-item-label {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        width: 14.6rem;
        padding-right: $gap-12;
      }
    }
  }
  .exception-description-full {
    width: 28.0rem;
    display: flex;
    display: -webkit-flex;
    p,
    input {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      line-height: 1.6rem;
    }
    .ant-row {
      .ant-form-item-label {
        text-overflow: ellipsis;
        width: 28.0rem;
        overflow: hidden;
        display: inline-block;
        padding-right: $gap-12;
      }
    }
  }
  .exception-action {
    width: 13.0rem;
    display: inline-block;
  }
}

.non-editable.width-100-px {
  width: 14.0rem;
  margin-bottom: 0;
}

.error-border {
  .ant-col.ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input {
          border: 1px solid $color-red;
        }
      }
      .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow,
      .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
        .ant-select-selector {
          border: 1px solid $color-red;
        }
      }
    }
  }
}

.search-modifier-contract {
  .custom-autocomplete.replace-search-arrow {
    .ant-select-clear {
      display: inline-block;
    }
  }
}

@media (min-width: $medium-desktop) {
  .contract-term-exception .exception-description .ant-row .ant-form-item-label {
    width: 100%;
  }
}

@media (max-width: $tablet-medium) {
  .contract-wrapper{
    .heading-area{
      margin-bottom: $gap-08;
    }
    .table-filters{
      .filter-components{
        .filter-ant-search{
          width: 100%;
          margin-right: 0;
          .ant-input-search{
            width: 100% !important;
          }
        }
        .filter-select-box{
          width: 100%;
          margin-right: 0;
          .custom-select-box{
            .ant-select{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
