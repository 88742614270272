@import '../../css/vars.scss';
@import '../../css/mixin.scss';

.claims-encounter {
  margin-top: 2.0rem;
}

.collections-filters {
  .custom-autocomplete {
    min-width: 25.0rem
  }
  .filter-select-box{
    .custom-select-box{
      .ant-form-item-no-colon{
        margin-right: 8px;
      }
    }
  }
  &.table-filters{
    @include align-items(flex-start);
    .flex{
      @include flex-wrap(wrap);
      & + .feild-row{
        min-width: 258px;
      }
    }
  }
  .filter-components{
    .clr-btn{
      margin-right: 0.8rem;
      &:hover {
          filter: brightness(0.8);
        }
    }
  }
}

.custom-table-container {
  position: relative;
}


.collection-claims-table-filters {
  .collection-claims-filter-search {
    width: 40.0rem !important;
    height: 2.8rem;
    margin-bottom: $gap-10;
    .ant-input-group{
      .ant-input{
        height: 2.8rem;
      }
      &.ant-input-wrapper {
        .ant-input{
          height: auto;
        }
      }
    }
  }
  display: flex;
  gap: $gap-10;
}
