@import "../../../../../../css/vars.scss";
@import "../../../../../../css/mixin.scss";

.dashboard-card-container {
  background-color: $color-white;
  border-left: 4px solid $color-white;
  padding: 5px 8px;
  margin-bottom: 8px;
  box-shadow: $box-shadow-base-4;
  border-radius: $border-radius-base;
  cursor: grab;

  .row-details {
    @include flexbox();
    @include align-items(flex-start);
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 6px;
    margin-bottom: 6px;
    .avatar {
      width: 30px;
      min-width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 8px;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .basic-info {
      width: 100%;
      position: relative;

      .row-name {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(flex-start);
        @include justify-content(space-evenly);
        .name,
        .visit {
          display: inline-block;
          padding-right: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .name,
        .time {
          font-size: $font-size-xsm;
          color: $color-blue-light;
          font-weight: $font-bold;

          & + div {
            font-size: $font-size-xsm;
            color: $color-blue-light;
            font-weight: $font-medium;
          }
        }

        .time {
          @include flexbox();
          @include flex-direction(column);
          @include align-items(flex-end);
          margin-left: 5px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .row-time {
        color: $content-color;
        font-size: $font-size-xxm;

        .dot-separator {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: $content-color;
          border-radius: 50%;
          margin: 2px 3px;
        }
        .time-stamp {
          white-space: nowrap;
        }
      }
    }
  }

  .row-payment {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    padding-top: 6px;

    & > div {
      line-height: 14px;
    }

    .key {
      font-size: $font-size-xxm;
      color: $content-color;
    }

    .value {
      font-size: $font-size-xxm;
      color: $color-blue-light;
      font-weight: $font-bold;
    }
  }

  .row-doctor,
  .row-technician {
    @extend .row-payment;
    @include justify-content(flex-start);
    padding: 0;
  }
  &.posi-relative {
    .sprite-img-before.appointment-info {
      position: absolute;
      right: 25px;
      bottom: 15px;
    }
  }

  .custom-widget-loader {
    background-color: $background-widget-spinner-darker;
  }
}

.dashboard-card-container .row-doctor .value {
  padding-left: 18px;
}

.dashboard-card-container .row-technician .value {
  padding-left: 4px;
}

// .dashboard-card-container .row-technician .key{
//   max-width: 33px;
// }

.hide-card-container {
  opacity: 0.4;
}

.legend-10min {
  border-left: 4px solid $color-green;
}
.legend-20min {
  border-left: 4px solid $yellow-panel-border;
}
.legend-30min {
  border-left: 4px solid $color-orange;
}
.legend-New {
  border-left: 4px solid $color-blue;
}
.legend-Cancel {
  border-left: 4px solid $red-panel-border;
}

@media (min-width: $max-desktop) {
  .dashboard-card-container {
    .row-details {
      .basic-info {
        .row-time {
          font-size: $font-size-xsm;
        }
      }
    }
    .row-payment,
    .row-doctor,
    .row-technician {
      .key {
        font-size: $font-size-xsm;
        padding-right: 2px;
      }
      .value {
        font-size: $font-size-xsm;
      }
      & > div {
        line-height: 14px;
      }
    }
  }
}

.activity-log-table {
  .app-table {
    max-height: 200px;
    overflow: auto;
  }
}


.card_bottomcard_outer-wrapper {
  display: flex;
  justify-content: space-between;
  .card_event-wrapper {
    display: flex;
   
    align-items: center;
  }
}
