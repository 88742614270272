@import '../../css//vars.scss';

.appointment-table {
  .table-heading {
    font-size: $font-size-s;
    margin: 0 $gap-06 $gap-06;
    font-weight: $font-medium;
    color: $color-blue-light;
  }
}
