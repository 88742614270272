@import "../../../../../css/vars.scss";
// @import "./root";
@import "../../../../../css/mixin";

.appointment-modal {
  overflow: auto;
  .ant-modal-content {
    .ant-modal-body {
      height: 82vh;
      .table-filters {
        @include align-items(center);
      }
      .search-wrapper {
        margin-bottom: 10px;
      }
      .table-container {
        height: 90%;
        .app-table {
          height: 100%;
          overflow: auto;
        }
      }
      .feilds-wrap {
        .feilds {
          .custom-select {
            width: 100%;
          }
          .custom-select-box {
            width: 100%;
          }
          .custom-autocomplete {
            width: 100%;
          }
        }
      }
    }
  }
  .modal-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .date-time {
      font-size: 14px;
      margin-right: 12px;
      @include align-items(center);
    }
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .ant-modal-footer {
    .group-btns {
      margin-right: 10px;
    }
  }
  .textarea-wrappers {
    .feild-wrap {
      .ant-input {
        width: 100%;
      }
    }
  }
  .appointment-lt-wrapper {
    .appointment-scheduler-wrap {
      .schedular-feild-area {
        box-shadow: none;
        border: 0;
        .feild-wrap {
          .ant-form-item-label {
            &.ant-form-item-label-left {
              width: 100%;
              label {
                color: $content-color;
              }
            }
          }
        }
        .app-table {
          box-shadow: none;
          border: 0;
          max-height: 140px;
        }
      }
    }
  }
  .appointment-user-wrap{
    .user-detail-list{
      min-width: 11rem;
    }
  }
}
.status-dropdown-menu-form {
  padding: 8px;
}
.appointment-scheduler-patient-list {
  height: 100%;
}
.opt-out-wrapper {
  .custom-input {
    .ant-row {
      .ant-col-14 {
        max-width: calc(58.33333333% - 80px);
        flex: 0 0 calc(58.33333333% - 80px);
      }
    }
  }
  .custom-checkbox {
    width: 72px !important;
  }
  &.disable-without-bg {
    .custom-input {
      .ant-row {
        .ant-col-14 {
          max-width: calc(58.33333333% - 0px);
          flex: 0 0 calc(58.33333333% - 0px);
        }
      }
    }
  }
}
.opt-out-filter-wrapper {
  .custom-autocomplete {
    .ant-row {
      .ant-col-13 {
        max-width: calc(58.33333333% - 90px);
        flex: 0 0 calc(58.33333333% - 90px);
      }
    }
  }
  .ant-col-offset-23 {
    margin-left: calc(99.33333333% - 87px);
  }
  .custom-checkbox {
    width: 72px !important;
  }
  &.disable-without-bg {
    .custom-input {
      .ant-row {
        .ant-col-14 {
          max-width: calc(58.33333333% - 0px);
          flex: 0 0 calc(58.33333333% - 0px);
        }
      }
    }
  }
}

@media (max-width: $lg-tablet) {
  .appointment-modal {
    height: auto;
  }
}
@media (max-width: $min-tablet) {
  .appointment-modal {
    .modal-title {
      display: block;
      .date-time {
        margin-top: 6px;
      }
    }
    .ant-modal-content {
      .ant-modal-body {
        height: 100%;
      }
    }
  }
}

@media (max-width: $tablet-medium) {
  .appointment-modal {
    .modal-title {
      .mr-lt-5 {
        margin-left: 0;
      }
      .date-time {
        margin-right: 0;
        display: block;
      }
    }
    .ant-modal-content {
      .ant-modal-body {
        .table-filters {
          @include align-items(flex-start);
          .search-input {
            width: 100%;
          }
        }
      }
    }
  }
}
