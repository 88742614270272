@import "../../css/vars";
@import "../../css/root";
@import "../../css/mixin";


.checkbox-menu {
  &.test{
    .custom-menu-items{
      .ant-row.ant-form-item{
        margin-bottom: 0;
        .ant-form-item-control-input{
          min-height: 2.2rem;
        }
      }
    }
  }
  width: auto;
  padding: 8px;
  @include flexbox();
  flex-direction: column;
  .custom-menu-items {
    @include flexbox();
    @include flex-direction(column);
    @include flex(1);
    .item-left {
      .ant-checkbox-wrapper {
        @include flexbox();
        width: auto;
        font-weight: $font-regular;
        font-size: $font-size-s;
        line-height: 2.2rem;
        cursor: pointer;
        span {
          white-space: nowrap;
        }
        .ant-checkbox {
          position: relative;
          right: auto;
        }
      }
    }
    .item {
      @include flexbox();
      padding-left: 2.3rem;
      .ant-checkbox-wrapper {
        @include flexbox();
        width: auto;
        font-weight: $font-regular;
        font-size: $font-size-s;
        line-height: 2.2rem;
        margin-left: 0;
        cursor: pointer;
        span {
          white-space: nowrap;
          padding-right: 0;
        }
        .ant-checkbox {
          position: relative;
          right: auto;
        }
      }
    }

    .sm-checkbox {
      .ant-checkbox-wrapper {
        @include align-items(center);
        padding-right: 5px;
      }
    }
  }
}

