@import "../../../../../../css/vars.scss";
@import "../../../../../../css/mixin.scss";

.custom-antd-radion-wrap {
    @include flexbox;
    .radio-label {
        font-size: 1.2rem;
        color: #2a4a79;
        margin-right: 30px;
        max-width: 100px;
        min-width: 100px;
    }
    & + .custom-antd-radion-wrap {
        margin-top: 16px;
    }
}

.mapping-filter-section {
    @include flexbox;
    @include align-items(flex-start);
    .radio-section {
        max-width: 33.3333%;
        min-width: 33.3333%;
    }
    .date-section {
        max-width: 33.3333%;
        min-width: 33.3333%;
        margin-left: 30px;
        margin-top: -4px;
        .date-section-button{
            @include flexbox;
            @include justify-content(flex-end);
        }
    }
}
.addLocation {
    .patent-list-search {
        width: 160px !important;
    }
}
.provider-location-wrap {
    .add-location-feild-wrap{
        .addLocation{
            .ant-row.ant-form-item{
                margin-bottom: 0;
            }
        }
    }
    &.app-table {
        table {
            th {
                background: #d6e6f7 !important;
            }
            table{
                border: 1px solid #d2ddef;
            }
        }
        .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
            margin: 0;
        }
        .ant-table-content .ant-table-content tr th:nth-child(2){
            padding-left: 8px;
        }
        .ant-table-wrapper{
            .ant-pagination{
                display: none;
            }
        }
    }
    .delete-icon{
        cursor: pointer;
        margin-left: 8px;
    }
}

.add-location-feild-wrap {
    @include flexbox();
    @include align-items(center);
    .delete-icon {        
        display: inline-block;
        cursor: pointer;
    }
}
.search-provider-wrap {
    @include flexbox;
    @include justify-content(space-between);
    margin-bottom: 12px;
    &.right{
        @include justify-content(flex-end);
        .ant-input-group-wrapper{
            width: 210px !important;
        }
    }
}
.provider-location-seperator {
    border: none;
    border-bottom: 1px solid #d6dde9;
    margin: 8px 0;
}

.provider-search-wrap{
    margin-bottom: 0.8rem;
    label{
        font-size: 1.2rem;
        color: #2a4a79;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        min-width: 33.33333333%;
        display: inline-block;
        
    }
    .ant-input-group-wrapper{
        width: 66.66666667% !important;
    }
    .ant-input-wrapper.ant-input-group .ant-input-affix-wrapper{
        padding: 2px 11px 2px 6px;
    }
    .ant-input-search{
        .ant-input{
            color: #2a4a79;
        }
    }
}
.mapping-header-top{
    .date-section-button{
        margin-bottom: 8px;
        @include flexbox;
        @include justify-content(flex-end);
    }
}

.mapping-provider-ui{
    .provider-search-wrap{
        margin-bottom: 0;
        label{
            flex: 0 0 100%;
        }
    }
}


.provider-location-column-wrap{
    @include flexbox;
    @include align-items(center);
    & > div {
        max-width: 180px;
        .ant-row.ant-form-item{
            margin-bottom: 0;
        }
    }
}