.ant-upload-list-picture-container{
    .ant-tooltip-placement-top {
      display: none;  
    }
  }


.ant-upload-list-item-name{
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}