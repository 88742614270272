@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.entity-details-container {
  overflow: hidden;
  .v-tab {
    #tab-left {
      .ant-tabs-nav {
        width: 25.4rem;
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            background: $color-blue;
            color: $color-white;
            font-weight: $font-regular;
          }
          .sprite-img-before {
            &:after {
              display: none;
            }
            &:before {
              left: -4rem;
            }
          }
          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }
  }
}

.tasks-btn {
  @include flexbox();
}

.task-screen {
  h2 {
    margin-bottom: 0px;
  }
}

.task-table-wrapper .app-table table tbody tr td:last-child {
  text-overflow: initial;
}

.comment-card-container-list {
  overflow-y: auto;
  max-height: 25rem;
}

.tasks-modal-header {
  padding-right: 4rem;
  p {
    margin-bottom: $gap-10;
  }
  button {
    margin-top: -0.6rem;
  }
}
.tasks-modal-add-new {
  .tasks-modal-form-sections {
    height: auto;
    &:first-child {
      margin-bottom: $gap-16;
    }
    .tasks-modal-file-upload {
      .ant-upload-select-picture::after {
        content: "Only JPEG, GIF, PNG or PDF";
        font-size: $font-size-xxm;
        margin: $gap-10;
      }
    }
  }
}

.task-list-filters {
  @include flexbox();
  align-items: baseline;
}

.custom-task-table-scroll {
  .app-table {
    max-height: 30rem;
    overflow-y: auto;
  }
}

.tasks-filter-panel-container {
  .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
    margin-bottom: $gap-08;
  }
}

.category-select-option-container {
  @include flexbox();
  @include justify-content(space-between);
  width: 100%;
  min-width: 20rem;
  .option-name {
    max-width: 17rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.option.headerWrapper {
  &.schedular-option-select {
    box-shadow: inset 0 -1px 0 0 #d2ddef;
    padding: $gap-05 $gap-12 !important;
    width: 100%;
    margin-left: 0;
    span {
      @include flex(1);
      color: $color-blue-light;
      font-weight: 500;
      font-size: $font-size-xsm;
    }
  }
}
.option.payer-option-renderer {
  span {
    @include flex(1);
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
}

.ant-select-selector > .ant-select-selection-item > .payer-option-renderer {
  span {
    display: none;
    &:first-child {
      display: block;
    }
  }
}

@media (max-width: $tablet-medium) {
  .task-screen {
    h2{
      margin-bottom: $gap-08;
    }
    .input-wrap {
      &.filter-select-box{
        width: 100%;
        margin-right: 0;
        .custom-select-box{
          .ant-select{
            margin-left: 0 !important;
            width: 100%;
          }
        }
      }
    }
    .mr-rt-16{
      margin-right: 0;
      .mr-rt-12{
        margin-right: 0;
      }
      .mr-bt-12{
        margin-bottom: 4px;
      }
    }
    .table-filters {
      display: block;
      .task-list-filters {
        display: block;
      }
      .btns-action{
        padding-top: 4px;
        display: inline-block;
        &.feild-row{
          width: 100%;
        }
      }
    }
  }
}
