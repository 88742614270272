@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.dashboard-widgets-container {
  @include flexbox();
  @include justify-content(flex-start);
  @include align-items(center);
  @include flex-wrap(wrap);

  .component {
    @include flex(1);
    min-width: 20rem;
    @include flexbox;
    @include align-items(center);
    background-color: $color-white;
    box-shadow: $box-shadow-base-4;
    border-radius: $border-radius-base;
    // min-height: 100px;
    padding: $gap-08 $gap-08 $gap-08 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: $gap-08;
    max-height: 4.4rem;
    &:last-child {
      margin-right: 0;
    }
    .widget-icon {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 16px;
      &.week-icon {
        background: url("../../../../img/week-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.appointment-icon {
        background: url("../../../../img/appointment-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.due-icon {
        background: url("../../../../img/due-today-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.complete-icon {
        background: url("../../../../img/complete-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.cancel-icon {
        background: url("../../../../img/cancel-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .info-area {
      @include flex-direction(column);
      @include flexbox();
      .heading {
        color: $content-color;
        font-size: $font-size-xsm;
        font-weight: $font-regular;
        line-height: 1;
        margin-bottom: 3px;
      }
      .data {
        font-size: $font-size-m;
        color: $color-blue-light;
        line-height: 1;
        font-weight: $font-bold;
      }
      .ant-picker {
        margin-bottom: 0;
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: -1.1rem;
        margin-right: -$gap-08;
        .ant-picker-range-separator {
          @extend .data;
          font-size: $font-size-m;
          & + .ant-picker-input {
            input {
              text-align: right;
            }
          }
        }

        .ant-picker-active-bar {
          display: none;
        }
        input {
          @extend .data;
          cursor: inherit;
          font-size: $font-size-m;
        }
      }
    }
  }
}

.dashboard-datepicker-dropdown > .ant-picker-range-wrapper > .ant-picker-panel-container {
  width: 57rem;
  min-width: 57rem;
  .ant-picker-panels {
    @include flexbox();
    @include justify-content(space-between);
  }
  .ant-picker-footer > ul > li:last-child > span {
    margin-right: 0;
  }
}

@media (min-width: $medium-desktop) {
  .dashboard-widgets-container {
    .component {
      padding: 2.4rem 1.6rem 2.4rem 2.4rem;
      .info-area {
        .data {
          font-size: $font-size-ml;
        }
      }
    }
  }
}

@media (max-width: $lg-tablet) {
  .dashboard-widgets-container {
    .component {
      @include flex(initial);
    }
  }
}

@media (max-width: $tablet) {
  .dashboard-widgets-container {
    .component {
      min-width: 19.3rem;
    }
  }
}
