@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.app-sidebar {
  text-align: center;
  background-color: $color-blue;
  color: $color-white;
  width: $sidebar-width;
  min-width: $sidebar-width;
  position: fixed;
  height: 100%;
  z-index: 102;
  .side-logo {
    margin: $gap-12 $gap-08;
    &+ div{
      & > div{
        margin-bottom: -$gap-09 !important;
      }
    }
  }
  .user-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 4px;
    .user-img {
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;
      background: #bebfc3;
      border-radius: 50%;
      border: $gap-01 solid #bebfc3;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.date-time-style {
  color: #ecf2fe;
  text-align: start;
  margin-left: -3.2rem;
}
.navigation-list {
  padding-top: $gap-08;
  ul {
    li {
      margin-bottom: $gap-06;
      position: relative;
      span {
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 20px;
        &.ba {
          background-position: -680px -109px;
        }
        &.dashboard {
          background-position: -20px -20px;
        }
        &.calender {
          background-position: -20px -65px;
        }
        &.contract {
          background-position: -20px -20px;
        }
        &.patient {
          background-position: -20px -108px;
        }
        &.description {
          background-position: -20px -152px;
        }
        &.infromation {
          background-position: -20px -196px;
        }
        &.doctor {
          background-position: -20px -240px;
        }
        &.users {
          background-position: -20px -284px;
        }
        &.user-lily-lee {
          background-position: -60px -160px;
          width: 26px;
        }
        &.user-des {
          background-position: -20px -327px;
        }
        &.document-icon {
          background-position: -60px -110px;
          height: 24px;
        }
        &.surgery-icon {
          background-position: -61px -10px;
          height: 24px;
        }
        &.billing-icon{
          background-position: -61px -60px;
        }
        &.report-icon {
          background-position: 27px -196px;
          height: 24px;
        }
        &.settings {
          background-position: -680px -240px;
        }
        &.user {
          background-position: -20px -284px;
        }
        &.task-icon {
          background-position: 29px -372px;
        }
        &.pverify-icon {
          background-position: -56px -239px;
        }
      }
      a {
        @include align-items(center);
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        p {
          margin: 0;
          color: $color-white;
          font-size: $font-size-m;
          padding-left: $font-size-m;
        }
        span {
          &.active {
            &:before {
              content: "";
              display: inline-block;
              width: 48px;              
              height: 36px;
              border-radius: 8px;
              background: rgba(0, 0, 0, 0.2);
              position: absolute;
              top: -6px;
              left: -14px;
            }
            &.ba {
              background-position: -680px -153px;
            }
            &.dashboard {
              background-position: -64px -20px;
            }
            &.calender {
              background-position: -64px -65px;
            }
            &.contract {
              background-position: -64px -20px;
            }
            &.patient {
              background-position: -64px -108px;
            }
            &.description {
              background-position: -64px -152px;
            }
            &.infromation {
              background-position: -64px -196px;
            }
            &.doctor {
              background-position: -64px -240px;
            }
            &.users {
              background-position: -64px -284px;
            }
            &.user-lily-lee {
              background-position: -10px -160px;
            }
            &.user-des {
              background-position: -64px -327px;
            }
            &.document-icon {
              background-position: -10px -110px;
              height: 24px;
            }
            &.report-icon {
              background-position: 27px -108px;
              height: 24px;
            }
            &.surgery-icon {
              background-position: -11px -10px;
              height: 24px;
            }
            &.billing-icon{
              background-position: -11px -60px;
              height: 26px;
            }
            &.settings {
              background-position: -11px -10px;
            }
            &.user {
              background-position: -64px -284px;
            }
            &.task-icon {
              background-position: 29px -372px;
            }
            &.pverify-icon {
              background-position: -56px -239px;
            }
          }
        }
      }
    }
  }
}

.app-header {
  .user-icon {
    .user-img {
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;
      background: #bebfc3;
      border-radius: 50%;
      border: $gap-01 solid #bebfc3;
    }
  }
  .nav-icon {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
  }
  .notification-img {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background-position: -108px -107px;
  }
  .setting-img {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background-position: -108px -147px;
  }
}

.fix-version {
  font-size: 10px;
  display: block;
  text-align: center;
  width: 100%;
  line-height: 1.2;
}
.version-container{
  margin-bottom: 15px;
}

.profile-menu-container {
  padding: $gap-02 $gap-05 !important;
}

.user-profile-menu {
  position: relative;
  list-style-type: none;
  background-color: $color-white;
  font-size: $font-size-l;
  background-clip: padding-box;
  border-radius: $gap-04;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: $color-blue-light;
  line-height: 2.0rem;
}

.download-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../img/download-box.png");
  background-repeat: no-repeat;
  background-position: center;
}