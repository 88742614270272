@import "./vars";
@import "./root";
@import "./mixin";

/*Table of Content

    1. Typography css
    2. Default css

*/

/* ------------- Typography css ---------------*/
:root {
  height: 100%;
  min-height: 100%;
  font-size: 10px; // 1rem
}
html,
body {
  height: 100%;
  .un-selectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  background: #f7f9fb !important;
  color: $content-color !important;
  font-family: "Roboto", sans-serif !important;
  font-size: $font-size-m !important;
  font-weight: $font-medium;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: none;
  font-feature-settings: "none";
  overflow-x: hidden;
  overflow-y: auto;
}
@media print {
  body {
    background: transparent !important;
    color: #000000 !important;
  }
}
body:fullscreen {
  overflow: auto;
}
body:-ms-fullscreen {
  overflow: auto;
}
body:-webkit-full-screen {
  overflow: auto;
}
body:-moz-full-screen {
  overflow: auto;
}

*:focus {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-blue-light !important;
  font-weight: $font-medium;
}
h1 {
  font-size: $font-size-xxl;
  margin-bottom: 2rem;
  font-weight: $font-bold !important;
  margin-top: 0;
}

h2 {
  font-size: $font-size-lxl;
  margin-bottom: 1.6rem;
  &.h2-title {
    font-size: $font-size-ml;
  }
}
h3 {
  font-size: $font-size-ml;
  margin-bottom: 1.6rem;
  line-height: $font-size-xxl;
  &.bold {
    font-weight: $font-bold;
  }
}

p {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: $gap-08;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

a:focus {
  outline: none;
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* ------------- End of Typography css ---------------*/

/* ------------- Default css ---------------*/

#app-root,
#app-root > .app {
  height: 100%;
  width: 100%;
}

.sprite-img {
  background: url(../img/Sprite.png);
  background-size: 751px;
}
.sprite-img-before {
  position: relative;
  &:before {
    content: "";
    background: url(../img/Sprite.png);
    background-size: 751px;
  }
}
.sprite-img-next-before {
  position: relative;
  &:before {
    content: "";
    background: url(../img/Sprite-next-img.png);
    background-size: 800px;
  }
}
.sprite-img-2 {
  background: url(../img/Sprite-next.png);
  background-size: 800px;
}
.sprite-img-2-before {
  position: relative;
  &:before {
    content: "";
    background: url(../img/Sprite-next.png);
    background-size: 800px;
  }
}
.main-form-container {
  border-radius: $border-radius-base;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: $color-white;
  padding: 2.4rem 2.4rem 1.6rem 2.4rem;
}

.flex {
  @include flexbox();
}
.flex-auto {
  @include flex(auto);
}
.justify-content-sp-bt {
  @include justify-content(space-between);
}
.justify-content-sp-ev {
  @include justify-content(space-evenly);
}
.justify-content-flex-end {
  @include justify-content(flex-end);
}
.justify-content-center {
  @include justify-content(center);
}
.justify-content-flex-start {
  @include justify-content(flex-start);
}
.align-center {
  @include align-items(center);
}
.align-baseline {
  @include align-items(baseline);
}
.align-flex-start {
  @include align-items(flex-start);
}
.align-flex-end {
  @include align-items(flex-end);
}
.flex-dir-column {
  @include flex-direction(column);
}
.flex-dir-row {
  @include flex-direction(row);
}
.flex-grow-1 {
  @include flex-grow(1);
}

.modifiers-box{
  flex-wrap: nowrap;
}
.flex.justify-content-flex-start.billing-overflow.flex-wrap-box-only, .input-groups.flex.wrap-box-only{
  @include flexbox();
  @include flex-wrap(nowrap);
}
.clip-btn {
  border-radius: 1.6rem;
  border: solid 1px $border-gray;
  background-color: transparent;
  min-height: 2.4rem;
  // @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  padding: 0 1.3rem;
  display: inline-block;
  line-height: 2.4rem;
  cursor: pointer;
  margin-right: 1.6rem;
  margin-bottom: $gap-08;
  .error {
    color: $color-red;
    font-size: $font-size-l;
  }
  &.is-active {
    background-color: rgba(53, 216, 161, 0.1);
    color: $color-green;
    font-weight: $font-medium;
  }
  &.active {
    color: $color-blue-light-shade;
    &.is-active {
      color: $color-green;
    }
  }
}
.required-feilds {
  width: 100%;
  h2 {
    position: relative;
    margin-bottom: 1.8rem;
    margin-top: $gap-10;
    &.h2-title {
      &:before {
        content: "";
        width: 3.2rem;
        height: $gap-03;
        background: $color-green;
        position: absolute;
        left: 0;
        top: 2.9rem;
      }
      &.width-100 {
        &:before {
          content: "";
          width: 100%;
          height: $gap-03;
          background: $color-green;
          position: absolute;
          left: 0;
          top: 2.9rem;
          animation-name: example;
          animation-duration: 4s;
          animation-timing-function: linear;
          -webkit-animation-name: example;
          -webkit-animation-duration: 3s;
          -webkit-animation-timing-function: linear;
        }
      }
    }
  }
  .no-top-mr {
    h2 {
      margin-top: 0;
    }
  }
}
.shadow-wrap {
  border-radius: $border-radius-base;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
}
.font-medium {
  font-weight: $font-medium;
}
.min-width-92 {
  display: inline-block;
  min-width: 9.2rem;
}
.flex-1 {
  @include flex(1);
}
.red-star {
  color: $color-red;
}
.green-text {
  color: $color-green;
}
.mr-rt-24 {
  margin-right: 2.4rem !important;
}
.mr-rt-28 {
  margin-right: 2.8rem !important;
}
.mng-radio-center {
  padding: 0px 0 $gap-12 0;
  box-sizing: border-box;
}
.mr-left-5 {
  margin-left: $gap-05;
}
.mr-left-20 {
  margin-left: 2rem;
}
.mr-left-30 {
  margin-left: 3rem;
}
.mr-left-60 {
  margin-left: 6rem;
}
.mr-top-20 {
  margin-top: 2rem;
}
.mr-lt-12 {
  margin-left: $gap-12;
}
.mr-lt-40 {
  margin-left: 4rem;
}
.mr-lt-16 {
  margin-left: 1.6rem;
}
.mr-top-16 {
  margin-top: 1.6rem;
}
.mr-top-12 {
  margin-top: $gap-12;
}
.mr-top-8 {
  margin-top: $gap-08;
}
.mr-top-10 {
  margin-top: $gap-10;
}
.mr-top-18 {
  margin-top: 1.8rem;
}
.mr-bt-16 {
  margin-bottom: 1.6rem;
}
.mr-bt-10 {
  margin-bottom: $gap-10;
}
.mr-btm-6 {
  margin-bottom: $gap-06;
}
.mr-bt-24 {
  margin-bottom: 2.4rem;
}
.mr-bt-48 {
  margin-bottom: 4.8rem;
}
.mr-bt-12 {
  margin-bottom: $gap-12;
}
.mr-bt-8 {
  margin-bottom: $gap-08;
}
.mr-bt-4 {
  margin-bottom: $gap-04;
}
.mr-bt-3 {
  margin-bottom: $gap-03;
}
.mr-bt-2 {
  margin-bottom: $gap-02;
}
.mr-bt-0 {
  margin-bottom: 0;
}
.mr-rt-16 {
  margin-right: 1.6rem;
}
.mr-rt-12 {
  margin-right: $gap-12;
}
.mr-rt-8 {
  margin-right: $gap-08;
}
.mr-rt-5 {
  margin-right: $gap-05;
}
.mr-tb-4 {
  margin-top: $gap-04;
  margin-bottom: $gap-04;
}
.mr-5 {
  margin: $gap-05;
}
.mr-tp-5 {
  margin-top: $gap-05;
}
.mr-tp-10 {
  margin-top: $gap-10;
}
.mr-tp-20 {
  margin-top: $gap-10;
}
.mr-bt-5 {
  margin-bottom: $gap-05;
}
.mr-lt-5 {
  margin-left: $gap-05;
}
.mr-lt-8 {
  margin-left: $gap-08;
}
.mr-lt-30 {
  margin-left: 3rem;
}
.mr-lt-10 {
  margin-left: $gap-10;
}
.pd-bt-6 {
  padding-bottom: $gap-06;
}
.pd-lt-8 {
  padding-left: $gap-08;
}
.pd-lt-12 {
  padding-left: $gap-12;
}
.pd-bt-2 {
  padding-bottom: $gap-02 !important;
}
.mr-top-ne-9 {
  margin-top: -$gap-09;
}
.mr-top-4 {
  margin-top: $gap-04;
}
.mr-top-2 {
  margin-top: $gap-02;
}
.mr-top-46 {
  margin-top: 4.6rem;
}
.mr-top-6 {
  margin-top: $gap-06;
}
.min-height-50 {
  min-height: 124px;
}
.padding-8 {
  padding: $gap-08;
}
.height-9 {
  height: $gap-09;
}
.padding-5 {
  padding: $gap-05;
}
.posi-relative {
  position: relative;
}
.name-view {
  position: absolute;
  top: $gap-04;
  left: 16rem;
  color: $color-blue-light;
  font-weight: $font-medium;
}
.seperator {
  background: $border-color;
  height: $gap-01;
  border: none;
}
.feild-row {
  &.display-view {
    .input-wrap {
      margin-right: 8.5rem;
    }
  }
}
.encounter-view {
  &.feild-row {
    &.display-view {
      .input-wrap {
        margin-right: 1.5rem;
        width: calc(33.3333% - 15px);
      }
      .name-view {
        left: 12.8rem;
        line-height: 1.8rem;
      }
    }
  }
}
.clip-boxes-wrap {
  @include flexbox();
  flex-wrap: wrap;
}
.custom-table-scroll {
  overflow-y: scroll;
  max-height: 130px;
}

.clipbox {
  cursor: pointer;
  height: 2.8rem;
  border-radius: 1.6rem;
  border: solid 1px $button-border;
  background-color: $color-white;
  min-width: 90px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  color: $color-blue-light;
  padding: 0 $gap-07;
  box-sizing: border-box;
  margin-right: $gap-05;
  font-size: $font-size-xsm;
  margin-top: $gap-05;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 110px;
  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 148px;
    // width: 72px;
  }
  &:first-child {
    @include justify-content(center);
  }
  &:last-child {
    margin-right: 0;
  }
  .close-clip {
    width: 18px;
    height: 18px;
    margin-left: $gap-08;
    background-image: url("../img/close-green-circle.png");
  }
  &.active {
    border: solid 1px $color-green;
  }
}

.MuiTabs-root.child-tabs {
  margin-top: 1.6rem;
}
.top-feild-mng {
  h2 {
    margin-top: 0;
  }
}
.add-patient-cl {
  position: relative;
  overflow: hidden;
  .MuiTabs-root.child-tabs {
    margin-top: 0px;
  }
  .mr-top-20 {
    margin-top: 0;
    h2 {
      margin-top: 0;
    }
  }
}

.custom-user-table {
  .app-table {
    table {
      border-radius: $gap-04;
      tbody {
        tr {
          td {
            &:first-child {
              background: $color-green;
              color: $color-white;
            }
          }
        }
      }
      tfoot {
        tr {
          td {
            &:first-child {
              background: $color-green;
              color: $color-white;
              font-size: $font-size-xsm;
            }
          }
        }
      }
    }
  }
}

/* --------------calender css ---------------*/

.MuiPickersToolbar-toolbar {
  background: $color-green !important;
}
.MuiPickersDay-daySelected {
  background: $color-green !important;
}

/* --------------Filter css ---------------*/

.filter-area {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  .filter-area-left {
    @include flexbox();
    @include flex-wrap(wrap);
    .cus-input-wrap {
      margin-bottom: 1.6rem;
    }
  }
  &.mr-manage {
    .filter-area-left {
      @include align-items(center);
      h4 {
        margin: 0;
      }
      .cus-input-wrap {
        margin-bottom: 0px;
      }
    }
  }
}

/* --------------tabs css -----------------*/

.main-tabs {
  height: 4.8rem;
  & + {
    .tabs-groups {
      width: calc(100% + 4.8rem);
      margin-left: -2.4rem;
      padding: 0 2.4rem;
      box-sizing: border-box;
      height: 68vh;
      overflow: auto;
    }
  }
}
.main-tab-wrap {
  width: calc(100% + 3.4rem);
  margin-left: -2.4rem;
  margin-top: -2.4rem;
  box-shadow: inset 0 -1px 0 0 $button-border;
  padding: 0 $gap-08;
  &.MuiTabs-root {
    min-height: 4rem;
  }
  .MuiTab-labelIcon {
    min-height: 4.8rem;
    min-height: 4rem;
    min-width: auto;
    padding: $gap-06 $gap-08 $gap-06 3.6rem;
    color: $content-color;
    font-size: $font-size-m;
    font-weight: $font-regular;
    margin-right: $gap-08;
    &.Mui-selected {
      color: $color-green;
      font-weight: $font-medium;
    }
  }
  .MuiTabs-indicator {
    background-color: $color-green;
    height: $gap-04;
  }
}
.dashboard-icon {
  &:before {
    content: "";
    position: absolute;
    width: 2.2rem;
    top: -$gap-02;
    background-position: -60px -200px;
    height: 2.4rem;
    left: $gap-07;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -21px;
    }
  }
}
.ant-tabs-tab {
  font-size: 1.4rem;
}
.h-tabs {
  .ant-tabs-tab-active {
    .dashboard-icon {
      &:before {
        background-position: -10px -200px;
      }
    }
    .demographic-icon {
      &:before {
        background-position: -284px -65px;
      }
    }
    .insurance-icon {
      &:before {
        background-position: -284px -109px;
      }
    }
    .financials-icon {
      &:before {
        background-position: -284px -153px;
      }
    }
    .documents-icon {
      &:before {
        background-position: -284px -197px;
      }
    }
    .encounters-icon {
      &:before {
        background-position: -284px -241px;
      }
    }
    .bills-icon {
      &:before {
        background-position: -284px -284px;
      }
    }
    .lists-icon {
      &:before {
        background-position: -284px -329px;
      }
    }
    .forms-icon {
      &:before {
        background-position: -284px -373px;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 8px;
  }
}

.demographic-icon {
  &:before {
    content: "";
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    background-position: -240px -65px;
    left: $gap-08;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -65px;
    }
  }
}
.insurance-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -109px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -109px;
    }
  }
}
.financials-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -153px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -153px;
    }
  }
}
// .financials-icon {
//   &:before {
//     content: "";
//     position: absolute;
//     width: 22px;
//     height: 22px;
//     background-position: -153px -285px;
//     left: 8px;
//   }
// }

.documents-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -197px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -197px;
    }
  }
}
.encounters-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -241px;
    left: 8px;
    top: -1px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -241px;
    }
  }
}
.bills-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 24px;
    background-position: -240px -284px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -284px;
    }
  }
}
.lists-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -329px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -329px;
    }
  }
}
.forms-icon {
  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-position: -240px -373px;
    left: 8px;
  }
  &.Mui-selected {
    &:before {
      background-position: -284px -373px;
    }
  }
}

.ant-tabs-dropdown-menu-item {
  width: 16.4rem;
  .tab-label-icon {
    padding-left: 3.8rem;
  }
}

/* ------------- End of Tab css ---------------*/

/* ------------- Insurance Tab css ---------------*/
.insurance-wrapper-all {
  @include flexbox();
  .insurance-aside-wrap {
    box-shadow: 0 1px 10px 0 rgba(146, 145, 158, 0.24);
    margin-left: 1.6rem;
    margin-bottom: $gap-08;
    border: 1px solid $button-border;
    border-radius: $border-radius-base;
    padding: $gap-12 2rem 1.6rem;
  }
}

.view-mode {
  .ant-row .ant-form-item-label > label {
    white-space: nowrap;
    width: 11.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    padding-top: 0px;
    text-align: left;
    line-height: $font-size-m;
    min-height: 2.8rem;
    height: 2.8rem;
  }
  .ant-row.ant-form-item {
    height: 1.3rem;
  }
  .form-grid-component {
    .posi-relatives {
      @include flexbox;
      @include justify-content(space-between);
      .value-view {
        position: relative;
        left: 0;
        line-height: 1.6rem;
        word-break: break-all;
      }
    }
  }
}
.down-button {
  color: $color-green;
  font-weight: $font-bold;
  margin-top: 5rem;
  display: block;
  text-align: center;
}

.separate-code {
  display: flex !important;
  display: -webkit-flex !important;
  @include align-items(center);
  @include justify-content(space-between);
  padding: 0;
  .left-margin-add-icon.anticon {
    margin-left: 6px;
  }
  &.m-r-0 {
    padding-right: 0 !important;
  }
  &.min-height-24 {
    min-height: 24px;
  }
  &.separate-code-bg {
    background: #eaf1fe;
    &.p-lr-4 {
      padding: 0 4px;
    }
  }
  &.relative-section {
    position: relative;
  }
  .label-text-width {
    padding-right: 80px;
    display: inline-block;
    width: 100%;
    white-space: normal;
  }
  .code-only {
    line-height: 1;
    margin-right: 5px;
    font-weight: 700;
    &.separate-code-bg {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      margin-right: 0;
      z-index: 9;
      display: flex;
      align-items: center;
      padding: 0 4px;
      &:before {
        content: "";
        background: #eaf1fe;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -1;
      }
    }
  }
  .anticon {
    margin: 0;
  }
  .node-title {
    display: block;
  }
}

/* ------------- End of Default css ---------------*/

/* ------------- Modal form feilds css -------------*/

.modal-from-feilds {
  margin-bottom: 2.4rem;
  .feild-row {
    .input-wrap {
      margin-right: 2rem;
      width: calc(50% - 10px);
      &.input-large {
        width: 100%;
        margin-right: 0;
      }
      .ant-form-item-label {
        width: 12.5rem;
        min-width: 12.5rem;
        &.textarea-label {
          padding-top: $gap-08;
        }
      }
      textarea {
        width: calc(100% - 12.5rem);
        resize: none;
        height: 5.4rem;
      }
    }
  }
  &.form-fields-col-3 {
    .feild-row {
      .input-wrap {
        width: calc(33% - 4rem);
        &:nth-child(1n) {
          margin-right: 6.5rem;
        }
        &:nth-child(2n) {
          margin-right: 6.5rem;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .ant-form-item-label {
          width: 10.4rem;
          min-width: 10.4rem;
        }
      }
    }
  }
}

.patient-profiles {
  margin-bottom: 2rem;
  @include flexbox();
  .user-img {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    border-radius: 2rem;
    background: $button-border;
    margin-right: 1.6rem;
    @include flexbox();
    align-items: center;
    margin-top: $gap-04;
    img {
      max-width: 100%;
    }
  }
  .descriptions {
    width: calc(100% - 12rem);
    padding-top: $gap-06;
    @include flexbox;
    @include flex-wrap(wrap);
    .name {
      display: inline-block;
      min-width: 12rem;
      width: 50%;
      &:last-child{
        width: 100%;
      }
      .title {
        color: $color-blue-light;
        font-size: $font-size-xsm;
        margin-bottom: $gap-04;
        font-weight: $font-bold;
      }
      p {
        color: $color-blue-light;
        font-size: $font-size-xsm;
        padding-right: 6px;
        word-break: break-all;
      }
    }
  }
}
.ant-form-item {
  &.mang-zip {
    margin: 0 $gap-08;
  }
}

th {
  .table-checkbox {
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
    .ant-checkbox-wrapper {
      .ant-checkbox {
        position: absolute;
        right: auto;
        left: 1.6rem;
        top: 50%;
        margin-top: -$gap-09;
      }
    }
  }
}
td {
  .table-checkbox {
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
    .ant-checkbox-wrapper {
      .ant-checkbox {
        position: absolute;
        right: auto;
        left: 1.6rem;
        top: 50%;
        margin-top: -1.3rem;
      }
    }
  }
}

.flex-inline {
  @include flexbox();
  @include justify-content(space-between);
}

.top-right-tab {
  ul {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);
    margin-bottom: 0px;

    li {
      height: 3.6rem;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      border: 1px solid $button-border;
      padding: 0 $gap-12;
      min-width: 8.5rem;
      color: $color-blue-light;
      font-weight: $font-medium;
      cursor: pointer;
      &.active {
        background: $color-blue;
        border: 1px solid $color-blue;
        color: $color-white;
      }
      &:first-child {
        border-top-left-radius: $gap-04;
        border-bottom-left-radius: $gap-04;
      }
      &:last-child {
        border-top-right-radius: $gap-04;
        border-bottom-right-radius: $gap-04;
      }
    }
  }
}
.top-section {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: $gap-08;
  margin-top: 0px;
}
.ant-radio-button-wrapper {
  height: 2.8rem;
  line-height: 2.6rem;
}

/* ------------ custom list wrap --------------*/

.custom-list-wrap {
  margin-top: 0px;
  .title {
    @include flexbox();
    @include justify-content(space-between);
    background: $color-light-grayies;
    padding: $gap-04 1.6rem;
    p {
      margin-bottom: 0;
      color: $color-blue-light;
      font-size: $font-size-xsm;
      line-height: 2rem;
    }
    .add-list {
      width: 2rem;
      height: 2rem;
      background-position: -242px 42px;
      cursor: pointer;
    }
  }
  ul {
    margin: 0;
    li {
      box-shadow: inset 0 -1px 0 0 $button-border;
      min-height: 2.8rem;
      padding: $gap-06 1.6rem;
      @include flexbox();
      @include align-items(center);
      .text {
        font-size: $font-size-m;
        color: $color-blue-light;
        padding-left: 1.6rem;
      }
      p {
        margin-bottom: 0;
        color: $color-blue-light;
        font-size: $font-size-xsm;
      }
      &:nth-child(even) {
        background: $app-background;
      }
      &:last-child {
        box-shadow: none;
      }
    }
  }
  &.technician-medication {
    margin-top: $gap-08;
  }
}
.list-clip {
  border: 1px solid rgba(165, 188, 223, 0.7);
  display: inline-block;
  padding: $gap-02 $gap-12;
  border-radius: $gap-03;
  color: $color-blue-light;
  font-size: $font-size-xsm;
}

.ant-picker {
  &.ant-picker-range {
    border: 1px solid rgba(165, 188, 223, 0.7);
    margin-bottom: 8px;
  }
  .ant-picker-input {
    & > input {
      // font-size: $font-size-xsm;
      line-height: 2.8rem;
      color: $color-blue-light;
      font-size: $font-size-m;
      font-weight: $font-regular;
      opacity: 1;
    }
  }
}
.patient-history {
  .pannel {
    &.back-pannel {
      .pannel-heading {
        height: auto;
        min-height: 2.8rem;
      }
      .pannel-body {
        padding: $gap-08 1.6rem 0;
      }
    }
  }
}

.pannel,
.panel {
  .pannel-body,
  .panel-body {
    .inner-header {
      width: calc(100% + 3.2rem);
      background: $color-light-grayies;
      margin-left: -1.6rem;
      padding: $gap-05 1.6rem;
      color: $color-blue-light;
      font-size: $font-size-xsm;
      margin-bottom: $gap-08;
      p {
        margin: 0;
        color: $color-blue-text;
      }
    }
    .footer-btn-section {
      width: calc(100% + 3.2rem);
      margin-left: -1.6rem;
      padding: $gap-05 1.6rem $gap-02;
      border-top: 1px solid $button-border;
      @include justify-content(flex-end);
      @include flexbox;
    }
  }
}
.pannel {
  margin-bottom: $gap-08;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-base;
  &.back-pannel {
    box-shadow: none;
    border: 1px solid $button-border;
    .pannel-heading {
      min-height: 2.8rem;
      border-radius: $border-radius-base $border-radius-base 0 0;
      border-bottom: 1px solid $button-border;
    }
    .pannel-body {
      padding: 1.6rem;
    }
  }
  &.table-pannel {
    .pannel-body {
      padding: 0;
      .app-table {
        margin: 0;
        border: none;
        overflow: hidden;
        border-radius: 0 0 $gap-06 $gap-06;
      }
    }
    .pannel-heading {
      box-shadow: inset 0 -1px 0 0 $button-border;
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
  .pannel-heading {
    background: $color-light-grayies;
    min-height: 2.4rem;
    padding: $gap-04 1.6rem;
    @include flexbox();
    @include align-items(center);
    color: $color-blue-text;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
    p {
      margin: 0;
      color: $color-blue-text;
    }
    .back-arrow {
      position: relative;
      padding-left: 3.2rem;
      cursor: pointer;
      &:before {
        content: "";
        width: 1.7rem;
        height: 1.6rem;
        background: url("../img/backarrow.png");
        position: absolute;
        background-repeat: no-repeat;
        left: 0;
      }
    }
  }
  .pannel-body {
    padding: $gap-08 1.6rem $gap-04 1.6rem;
    background: $color-white;
    border-bottom-left-radius: $gap-04;
    border-bottom-right-radius: $gap-04;
  }
}

.ant-picker-input {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $content-color !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $content-color !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $content-color !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $content-color !important;
  }
}

// ----------------- Left tab section ------------------//

.main-form-container form,
.form-responsive {
  max-width: 100%;
}
.encounter-list-table {
  margin-top: 0px;
}
.filter-btns-group {
  @include flexbox();

  button {
    margin-right: $gap-08;
  }
  margin-bottom: 1.6rem;
}
/* ---------------- select design changes -----------------*/

.green-select-box {
  &.no-mar-bt {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-row {
      .ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 2.8rem;
        }
      }
      .ant-select-single {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            line-height: 2.8rem;
            font-size: $font-size-m;
          }
        }
      }
    }
  }
}
.sm-search-design {
  .ant-input-affix-wrapper {
    padding: 0 5px 0 11px;
    width: 100% !important;
    height: 28px;
    .ant-input {
      border: none !important;
    }
    .ant-input-suffix {
      color: $content-color;
      svg {
        font-size: $font-size-l;
        margin-top: 7px;
      }
    }
  }
}
.modal-content-weapper {
  &.shadow-wrap {
    .sm-search-design .ant-input-affix-wrapper .ant-input-suffix {
      svg {
        margin-top: 1px;
      }
      .ant-input-search-icon {
        padding: 0px;
        margin-left: $gap-12;
        padding-right: $gap-02;
      }
      .anticon-close-circle {
        margin-top: -$gap-01;
      }
    }
  }
}
.search-checkbox-wrap {
  .ant-row .ant-form-item-control .ant-input {
    height: 2.8rem;
  }
  .ant-input-suffix {
    top: $gap-08;
  }
}
.form-btns {
  margin-top: 2.4rem;
  &.exam-room-btns {
    margin-top: $gap-08;
  }
  &.contract-btns {
    margin-top: $gap-12;
    button {
      margin-right: $gap-12;
      & + button {
        margin-right: $gap-12;
      }
    }
  }
  &.no-mr-top {
    margin-top: 0;
  }
  button {
    margin-right: $gap-12;
    & + button {
      margin-right: 0;
    }
  }
}

/* -----------------Patient search box -------------------*/

.patient-search-box {
  .ant-input-search {
    &.ant-input-affix-wrapper {
      width: 30rem !important;
    }
  }
}

/*------------- test--------------*/
.page-container {
  .MuiPaper-elevation5 {
    box-shadow: none;
  }
  .MuiPaper-root {
    background: $app-background !important;
  }
}
.schedular-content {
  box-shadow: $box-shadow-base;
  border-radius: 0 0 $gap-04 $gap-04;
  background: $color-white;
  .grid-time-range-container, .month-container {
    height: calc(100vh - 15.4rem);
    overflow-y: auto;
  }
}
.schedular-header-wrap {
  &.fixed-content-wrap {
    & + .schedular-content {
      box-shadow: none;
      border: 1px solid rgba(165, 188, 223, 0.5);
    }
  }
}

.blue-link {
  color: $color-blue;
}

.rt-side-panel {
  @include flexbox();
  .clip-boxes-wrap {
    margin-left: 2.4rem;
  }
  &.move-head-top {
    .clip-boxes-wrap {
      margin-left: 0;
    }
  }
}

.medication-btn {
  height: 2.4rem;
  border-radius: $border-radius-base;
  border: 1px solid $border-gray;
  background-color: $light-green;
  font-size: $font-size-xsm;
  color: $color-blue-light;
  padding: $gap-04 $gap-10;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  cursor: pointer;
}
.demographic-wrap {
  position: relative;
  overflow: hidden;
}
.contact-info {
  position: absolute;
  left: 34%;
  top: 0;
}
.contact-info-rt {
  position: absolute;
  left: 67.5%;
  top: -$gap-10;
}

.gree-heading {
  color: $color-green;
}

.group-shadow {
  box-shadow: none;
  padding: $gap-10 $gap-12 $gap-04;
  background: rgba(210, 221, 239, 0.2);
  border-radius: $gap-04;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.04);
  height: 100%;
}
.search-input {
  position: relative;
  input {
    padding-right: 3rem;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: $gray-panel-shadow;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
  &.lg-search {
    .search-icon {
      top: $gap-02;
      &:after {
        content: "";
        position: absolute;
        background: rgba(165, 188, 223, 0.7);
        width: 1px;
        height: calc(100% + 4px);
        top: -1px;
        left: -3px;
      }
    }
  }
  .search-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 2.7rem;
    width: 2.4rem;
    border-radius: 0 $gap-10 $gap-10 0;
    input {
      padding-right: 3rem;
    }
    &:before {
      background-size: 524px;
      background-position: -74px -38px;
      width: 24px;
      height: 28px;
      right: 0;
      cursor: pointer;
      position: absolute;
    }
  }
}

/* ---------------Social icons css ----------------*/
.ant-modal-header {
  .social-icons {
    ul {
      margin-bottom: 0;
    }
  }
}
.social-icons {
  &.open-social {
    ul {
      @include flexbox();
    }
  }
  ul {
    display: none;
    opacity: 1;
    transition: all 0.2s ease-in;
    padding: 0px $gap-04 0 $gap-04;
    border-radius: $border-radius-base;
    margin-bottom: 0;
    li {
      margin-bottom: 0px;
      &.icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-left: 8px;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }
      &.share-icons {
        background: url("../img/share-bg.png");
        background-size: cover;
      }
      &.print-icons {
        background: url("../img/print-bg.png");
        background-size: cover;
      }
      &.scan-icons {
        background: url("../img/scan-bg.png");
        background-size: cover;
      }
      &.email-icons {
        background: url("../img/email-bg.png");
        background-size: cover;
      }
      &.edit-icons {
        background: url("../img/edit-bg.png");
        background-size: cover;
      }
      &.diagonos-icons {
        background: url("../img/diagnose-bg.png");
        background-size: cover;
      }
      &.note-icons {
        background: url("../img/note-bg.png");
        background-size: cover;
      }
      &.delete-icons {
        background: url("../img/delete-bg.png");
        background-size: cover;
      }
      &.watch-icons {
        background: url("../img/watch.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.search-icons {
        background: url("../img/search-bg.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.download-icons {
        background: url("../img/download-bg.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.user-icons {
        background: url("../img/user-bg.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.map-icons {
        background: url("../img/map-bg.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.camra-icons {
        background: url("../img/camra-bg.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.payment-icons {
        background: url("../img/payment-icon.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.insurance-icons {
        background: url("../img/umbrella.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.transaction-icons {
        background: url("../img/money.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.document-icons {
        background: url("../img/document-icon.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.claim-icons {
        background: url("../img/claim-icon.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.task-icons {
        background: url("../img/task-icon.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.invite-icon-red {
        background: url("../img/invite.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.invite-icon-gray {
        background: url("../img/invite2.png");
        background-size: cover;
        border-radius: 3px;
      }
      &.fax-icon {
        background: url("../img/fax-icon.png");
        background-size: cover;
        border-radius: 3px;
        .badge {
          position: relative;
          span {
            background: #2e79f8;
            position: absolute;
            color: #fff;
            min-width: 18px;
            display: inline-block;
            border-radius: 50%;
            top: -12px;
            right: -6px;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            line-height: 16px;
            padding: 1px;
          }
        }
      }
    }
  }
}

.display-view {
  .ant-row {
    .ant-col {
      &.ant-col-md-9 {
        max-width: 9.4rem;
        width: 9.4rem;
        label {
          width: 9.4rem;
          max-width: 9.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          line-height: 3rem;
          text-align: left;
        }
      }
    }
  }
}

.value-view {
  font-size: $font-size-xsm;
  color: $color-blue-light;
  line-height: 1.4rem;
  position: absolute;
  top: -$gap-01;
  font-weight: $font-regular;
  left: 10.8rem;
}
.combine-fields {
  @include flexbox;
  .value-view {
    position: static;
    margin-bottom: $gap-06;
    word-break: break-all;
  }
}

.discription-area {
  @include flexbox;
  @include flex-wrap(wrap);
  margin-top: 1.6rem;
  // background: $color-light-gray;
  padding: 0;
  border-radius: $border-radius-base;
  & > div {
    width: 50%;
  }
  .row {
    @include flexbox;
    margin-bottom: $gap-02;
    .key {
      color: $content-color;
      font-size: $font-size-xsm;
      margin-right: $gap-12;
    }
    .value {
      color: $color-blue-light;
      font-size: $font-size-xsm;
    }
  }
}
.document-btns {
  button {
    width: calc(50% - 12px);
  }
}
.merge-table {
  border: 1px solid $button-border;
  border-radius: $gap-04;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $color-white;
  .app-table {
    margin-bottom: 0;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    table tbody tr td {
      padding: 0 $gap-07;
      &:first-child {
        padding-left: 1.6rem;
      }
    }
    table thead tr th {
      padding: 0px $gap-04;
      height: 2.8rem;
      &:first-child {
        padding-left: 1.6rem;
      }
    }
    .ant-checkbox-wrapper .ant-checkbox {
      top: $gap-02;
    }
    & + .app-table {
      margin: 0;
    }
  }
}

.phone-group {
  position: relative;
  .custom-input {
    & + .custom-checkbox {
      position: absolute;
      right: 0;
      top: 0;
      width: 23%;
    }
  }
  .custom-autocomplete,
  .referring-physician-autocomplete-container {
    & + .custom-autocomplete,
    & + .custom-checkbox {
      position: absolute;
      right: 0;
      top: 0;
      width: 23%;
    }
  }
  &.phone-group-validation{
    @include flexbox;
    .custom-input {
      max-width: calc(100% - 80px);
      min-width: calc(100% - 80px);
      width: calc(100% - 80px);
      .ant-col-8{
        min-width: calc( 33.33333333% + 27px );
      }
      .ant-col-10{
        min-width: calc(41.66666667% + 30px);
      }
      & + .custom-input {
        position: relative;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
      }
    }
  }
  &.phone-group-validation-width{
    @include flexbox;
    .custom-input {
      max-width: calc(100% - 80px);
      min-width: calc(100% - 80px);
      width: calc(100% - 80px);
      .ant-col-8{
        min-width: calc( 33.33333333% + 56px );
      }
      .ant-col-10{
        min-width: calc(41.66666667% + 33px);
      }
      & + .custom-input {
        position: relative;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
      }
    }
  }
}

.ant-select-arrow,
.ant-picker-suffix {
  span {
    color: $color-blue-light;
  }
}
.together-fields {
  &:before {
    content: "";
    background: #f0f2f6;
    width: calc(100% + 10px);
    height: calc(100% + 3.7rem);
    position: absolute;
    top: -$gap-04;
    left: -$gap-05;
    border-radius: $gap-04;
  }
  &.wrap-side-three {
    &:before {
      content: "";
      background: #f0f2f6;
      width: calc(100% + 10px);
      height: calc(100% + 2px);
      position: absolute;
      top: -$gap-04;
      left: -$gap-05;
      border-radius: $gap-04;
    }
  }
  &.loc-field-wrap {
    &:before {
      content: "";
      background: #f0f2f6;
      width: calc(100% + 10px);
      height: calc(100% + 3.4rem);
      position: absolute;
      top: -$gap-03;
      left: -$gap-05;
      border-radius: $gap-04;
    }
  }
}
.encounter-table {
  .ant-row {
    &.ant-form-item {
      margin-bottom: 0;
    }
  }
  .filter-modifier-autocomplete {
    margin-right: $gap-06;
    width: 100%;
    min-width: 7.2rem;
    max-width: 9.8rem;
  }
  .app-table {
    thead {
      tr {
        th {
          min-width: 14.6rem;
          & + th {
            min-width: 11.8rem;
            & + th {
              min-width: 20rem;
              & + th {
                min-width: 43rem;
                & + th {
                  min-width: 9rem;
                  & + th {
                    min-width: 9.5rem;
                    & + th {
                      min-width: 9rem;
                      & + th {
                        min-width: 16rem;
                        & + th {
                          min-width: 6rem;
                          & + th {
                            min-width: 6rem;
                            & + th {
                              min-width: 9.4rem;
                              & + th {
                                min-width: 6.6rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .custom-checkbox {
      position: relative;
      top: -2px;
      .ant-checkbox {
        top: auto;
      }
    }
  }
  .cross-icon {
    background: url("../img/input-table-cross.png");
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    position: absolute;
    background-size: cover;
    right: -$gap-02;
    top: $gap-08;
    cursor: pointer;
  }
  .last-head {
    position: relative;
    .custom-input {
      padding-right: 1.6rem;
    }
  }
  .input-groups {
    .custom-input {
      margin-right: 2px;
    }
  }
  table {
    tr {
      th {
        &:nth-child(1) {
          width: 7%;
        }
        &:nth-child(2) {
          width: 5%;
        }
        &:nth-child(3) {
          width: 17%;
        }
        &:nth-child(4) {
          width: 17%;
        }
        &:nth-child(5) {
          width: 3%;
        }
        &:nth-child(6) {
          width: 4%;
        }
        &:nth-child(7) {
          width: 6%;
        }
        &:nth-child(8) {
          width: 6%;
        }
        &:nth-child(9) {
          width: 3%;
        }
        &:nth-child(10) {
          width: 3%;
        }
        &:nth-child(11) {
          width: 4%;
        }
        &:nth-child(12) {
          width: 3%;
        }
        &:nth-child(13) {
          width: 4%;
        }
        &:nth-child(14) {
          width: 12%;
        }
      }
    }
  }
  .posi-relative {
    .cross-icon {
      right: 1.5rem;
      top: 1.5rem;
    }
  }
}

.margin-0 {
  margin: 0px;
}
.field-manage-row {
  .ant-col.ant-col-4 {
    max-width: calc(16.6666667% - 7px);
  }
  .ant-col.ant-col-20 {
    @include flex(calc(83.33333333% + 7px));
    max-width: calc(83.33333333% + 7px);
    .ant-col-20.ant-form-item-control {
      max-width: 100%;
      @include flex(100%);
    }
  }
}
.field-manage-row-2 {
  .ant-col.ant-col-4 {
    max-width: calc(16.6666667% - 7px);
  }
  .ant-col.ant-col-8 {
    @include flex(calc(33.33333333% - 1.4rem));
    max-width: calc(33.33333333% - 1.4rem);
    .ant-col-20.ant-form-item-control {
      max-width: 100%;
      @include flex(100%);
    }
  }
}
.add-input {
  .add-icon {
    display: inline-block;
    width: 2.1rem;
    height: 2.8rem;
    background: $color-green;
    border-radius: $gap-04;
    top: 0;
  }
  &.add-input-gap{
    .custom-select{
      .ant-col-16{
        flex: 0 0 calc(66.66666667% - 18px);
        max-width: calc(66.66666667% - 18px);
      }
    }
  }
  position: relative;
  cursor: pointer;
  .add-icon.sprite-img-before {
    &:before {
      content: "+";
      right: $gap-10;
      cursor: pointer;
      display: inline-block;
      left: $gap-03;
      top: -$gap-06;
      position: absolute;
      color: $color-white;
      font-size: 2.6rem;
      font-weight: $font-regular;
    }
  }
  .ant-input {
    padding-right: 1.8rem;
  }
  .edit-icon {
    background: url("../img/pencil-fill2.png");
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 4rem;
    top: $gap-08;
    background-repeat: no-repeat;
  }
  .custom-select {
    .ant-select-arrow {
      right: 1.3rem;
    }
    .ant-select-clear {
      right: $gap-12;
    }
    .ant-select-selector{
      .ant-select-selection-item{
        & > div{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 24px);
        }
      }
    }
  }
}
.ant-form-item-control {
  .ant-form-item-explain {
    font-weight: $font-regular;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 1.6rem;
  }
}

.patient-description {
  & + .modal-from-feilds {
    margin-bottom: 1.6rem;
  }
}
.adjustment-pop-screen {
  margin-top: -$gap-10;
}
.schedular-wrapper {
  .ant-radio-button-wrapper {
    height: 2.6rem;
    color: $color-blue-light;
    font-weight: $font-regular;
    background: $light-green;
    line-height: 2.4rem;
    font-size: $font-size-s;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      height: 2.2rem;
      background: $light-green;
      font-weight: $font-regular;
      color: $color-blue-light;
      line-height: 2.2rem;
    }
  }
  .ant-select {
    color: $color-blue-light;
    font-weight: $font-regular;
  }
}

.mng-head-gaps {
  margin-bottom: $gap-12;
  .heading-area {
    margin: 0;
  }
}
.mng-exception-tab {
  .table-filters {
    margin-bottom: 1.6rem;
  }
}

.pannel-content-area {
  padding: 1.6rem;
}
.ant-radio-wrapper span.ant-radio + * {
  padding-right: $gap-03;
  padding-left: $gap-03;
}
.move-btn-top {
  position: relative;
  & > button {
    position: absolute;
    top: -4.8rem;
    left: 0;
  }
  .table-filters {
    margin-bottom: 0;
    @include justify-content(flex-end);
  }
}
.ant-row {
  .ant-form-item-control {
    .ant-input-search {
      .ant-input {
        border: none;
      }
    }
    .ant-input {
      border: 1px solid rgba(165, 188, 223, 0.7);
      height: 2.8rem;
      border-radius: $gap-04;
      color: $color-blue-light;
    }
    .ant-form-item-control-input {
      min-height: 2.8rem;
    }
  }
  .ant-select,
  .ant-select-item {
    font-size: $font-size-xsm;
  }
  .ant-form-item-label > label.ant-form-item-required:before {
    display: none;
  }
  &.ant-form-item {
    margin-bottom: $gap-08;
  }

  .ant-form-item-label {
    @include flexbox();
    & > label {
      font-size: $font-size-xsm;
      color: $color-blue-light;
      line-height: $font-size-m;
      line-height: 2.8rem;
    }
  }
}

.red-text {
  color: $red-panel-border;
}
.white-text {
  color: $color-white;
}

.repeat-wrap {
  position: relative;
  .repeat-option {
    position: absolute;
    top: $gap-04;
    left: calc(29.16666667% + 4.2rem);
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
}
.only-weekdays {
  padding-left: calc(29.16666667% + 4rem);
  margin-top: 1.4rem;
  font-size: $font-size-xsm;
  color: $color-blue-light;
  a {
    color: $color-blue-light;
    &:hover {
      color: $color-blue-light;
    }
  }
  &.sprite-img-before {
    &:before {
      background-size: 640px;
      background-position: -280px -14px;
      width: 22px;
      height: 28px;
      position: absolute;
      left: 112px;
      top: -5px;
    }
  }
}
.week-list {
  margin-top: 12px;
  .ant-checkbox-wrapper {
    margin-right: 8px;
    padding-left: 0px;
    padding-top: 2px;
    min-width: 98px;
    .ant-checkbox {
      right: auto;
      top: 0px;
      left: 0;
    }
    .ant-checkbox-checked::after {
      border: none;
      top: 5px;
      left: 2px;
    }
  }
}

.textarea-wrap {
  margin-top: 12px;
  label {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    margin-bottom: $gap-04;
    display: block;
  }
  textarea {
    resize: none;
  }
}

.ant-form {
  .info-row {
    @include flexbox();

    label {
      display: inline-block;
      color: $content-color;
      font-size: $font-size-xsm;
    }
    .lavel-value {
      color: $color-blue-light;
      font-size: $font-size-xsm;
    }
  }
}

.info {
  font-size: $font-size-xsm;
  color: $color-blue-light;
  @include flexbox();
  @include align-items(center);
  margin-top: -$gap-04;
  position: relative;
  padding-left: 2.2rem;
  margin-left: -2.5rem;
  cursor: pointer;
  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background: url("../img/checkbox-circle-fill.png");
    position: absolute;
    top: 10px;
    left: 0;
  }
}
.custom-menu-items {
  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }
}

// ----------- Imaging Modal css -------------//

.view-images {
  .pannel-heading {
    .anticon {
      width: 1.6rem;
      height: 1.6rem;
      display: inline-block;
      &.anticon-merge-cells {
        background: url("../img/Sprite.png");
        background-size: 751px;
        background-position: -595px -197px;
        width: 20px;
        height: 20px;
        position: relative;
        right: 1px;
        top: 2px;
        svg {
          display: none;
        }
      }
      &.anticon-inbox {
        background: url("../img/Sprite.png");
        background-size: 751px;
        background-position: -595px -197px;
        width: 20px;
        height: 20px;
        position: relative;
        right: -2px;
        top: 2px;
        svg {
          display: none;
        }
      }
      &.anticon-close {
        background: url("../img/Sprite.png");
        background-size: 751px;
        background-position: -154px -22px;
        width: 20px;
        height: 20px;
        position: relative;
        right: -3px;
        top: 2px;
        svg {
          display: none;
        }
      }
      &.anticon-border-outer {
        width: 20px;
        height: 20px;
        font-size: 17px;
        color: $content-color;
        margin-right: 4px;
      }
    }
  }
}

.sm-date-picker {
  .ant-picker {
    padding: 0 1.1rem 0;
  }
}
.filter-input-mr {
  .ant-row {
    &.ant-form-item {
      margin-bottom: 0;
    }
  }
}
.tabs-gap-mng {
  .ant-affix {
    background: $button-border;
    padding-left: $gap-08;
    .clip-btn {
      margin-bottom: $gap-06;
      margin-top: $gap-06;
    }
  }
}

.doc-document {
  .sc-AxirZ {
    max-width: 65vw;
  }
}
.pdf-document {
  .react-pdf__Document {
    max-width: 65vw;
  }
}

.req-star {
  padding-top: $gap-05;
  padding-right: $gap-04;
}

.ant-checkbox-wrapper {
  font-size: $font-size-m;
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
      border: $gap-02 solid $content-color;
      background: transparent;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $color-green;
      border: $gap-02 solid $color-green;
    }
  }
}
.ant-form-item-control-input-content {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-green;
  }
}

.fields-wrapper .ant-row .ant-form-item-label label {
  font-size: $font-size-xsm;
}

.row-add {
  position: relative;
  height: 28px;
  display: block;
  &.sprite-img-before {
    &:before {
      content: "";
      width: 20px;
      height: 13px;
      background-position: -210px 34px;
      cursor: pointer;
      display: inline-block;
      left: 0;
      top: calc(50% - 7px);
      background-size: 645px;
      position: absolute;
    }
  }
}
.input-field-title {
  color: $color-blue-light;
  font-size: $font-size-m;
  margin-bottom: $gap-10;
}

.custom-input .ant-row .ant-form-item-label > label,
.custom-select .ant-row .ant-form-item-label > label,
.custom-checkbox .ant-row .ant-form-item-label > label,
.custom-picker .ant-row .ant-form-item-label > label,
.custom-radio .ant-row .ant-form-item-label > label,
.custom-autocomplete .ant-row .ant-form-item-label > label,
.time-picker-wrap .ant-row .ant-form-item-label > label {
  white-space: nowrap;
  max-width: 11.6rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 3rem;
  &:after {
    content: "";
    margin: 0 $gap-01 0 $gap-02;
  }
}
@media (max-width: $min-desktop) {
  #add-encounter {
    .form-grid-component {
      display: block;
    }
  }
  .m-vertical-scroll {
    width: 100%;
    overflow: auto;
    table {
      width: 1200px;
    }
  }
}
@media (min-width: $min-desktop) {
  .custom-input .ant-row .ant-form-item-label > label,
  .custom-select .ant-row .ant-form-item-label > label,
  .custom-checkbox .ant-row .ant-form-item-label > label,
  .custom-picker .ant-row .ant-form-item-label > label,
  .custom-radio .ant-row .ant-form-item-label > label,
  .custom-autocomplete .ant-row .ant-form-item-label > label,
  .time-picker-wrap .ant-row .ant-form-item-label > label {
    max-width: 99%;
  }
}

#users_rights_tab {
  .ant-tabs-content-left {
    .ant-tabs-tabpane {
      padding-left: 3.8rem;
      padding-top: 1.4rem;
    }
  }
}

.claim-filter {
  .ant-picker {
    padding: 0 1.1rem 0;
    height: 2.8rem;
  }
  .filter-components {
    margin-bottom: $gap-08;
  }
  .feild-row {
    // margin-bottom: $gap-08;
  }
  & + .group-btns {
    margin-bottom: $gap-08;
  }
}
.no-componet-mr {
  .filter-components {
    margin-bottom: 0;
  }
}
.claim-screen {
  & + .white-tabs {
    .filter-components {
      .ant-form-item-label > label {
        height: 2.8rem;
      }
    }
  }
  h2 {
    margin-bottom: 0;
  }
  & + div {
    .filter-ant-search,
    .filter-select-box {
      margin-right: 0.8rem;
    }
    .table-filters {
      .custom-select-box {
        .ant-select {
          margin-left: 0 !important;
        }
      }
    }
  }
  .filter-ant-search{
    & + .clr-btn{
      margin-right: 0.8rem;
      &:hover {
          filter: brightness(0.8);
        }
    }
  }
}
.no-h2-margin {
  h2 {
    margin-bottom: 0;
  }
}
.style-italics {
  font-style: italic;
}

.care-team-head {
  margin-bottom: $gap-12;
}

.group-btns {
  button {
    margin-left: 1.6rem;
    &:first-child {
      margin-left: 0;
    }
  }
}

.heading-area {
  .clipbox {
    margin-top: $gap-03;
    margin-bottom: $gap-02;
  }
  .filter-components {
    margin-bottom: 0;
  }
  margin-bottom: $gap-08;
  h1 {
    margin: 0;
  }
}

.edit-insurance {
  color: $color-green;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: $gap-01;
  top: 2rem;
  position: absolute;
  cursor: pointer;
}

.add-physician {
  position: relative;
}
.add-icon {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  background: url("../img/add-popup.png");
  background-size: cover;
  position: absolute;
  top: $gap-02;
  right: 0;
  cursor: pointer;
}

.check-in-filter {
  .input-wrap {
    width: 22rem;
    & + .input-wrap {
      width: 16rem;
      & + .input-wrap {
        width: 11rem;
      }
    }
  }
}
.show-revel {
  font-size: $font-size-xsm;
  color: $color-blue-light;
  margin-right: 1.6rem;
}

.manage-popover-dropdown {
  width: calc(100% + 3.2rem);
  margin-left: -1.6rem;
  .cursor-pointer {
    padding: $gap-05 1.6rem $gap-06;
    border-bottom: 1px solid $button-border;
    &:first-child {
      padding-top: 0;
    }
  }
  .ant-menu-vertical > .ant-menu-item {
    height: 2.8rem;
    line-height: 2.8rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.app-table {
  table {
    .sm-checkbox {
      .ant-row {
        &.ant-form-item {
          margin-bottom: 0;
        }
        .ant-form-item-control {
          .ant-form-item-control-input {
            min-height: 1.4rem;
          }
        }
      }
    }
  }
  .custom-checkbox {
    max-height: 1.8rem;
    margin-top: -$gap-08;
  }
  .ant-form-item-explain {
    display: none;
  }
  thead {
    th {
      .sm-checkbox {
        .ant-checkbox {
          position: static;
          top: auto;
        }
      }
    }
  }
}
.info-img {
  &.sprite-img-before {
    display: block;
    position: relative;
    height: 18px;
    width: 22px;
    &:before {
      content: "";
      width: 22px;
      height: 22px;
      background-position: -285px -417px;
      position: absolute;
      top: calc(50% - 11px);
    }
  }
}
.visit-select {
  .ant-col {
    &.ant-col-7 {
      min-width: 37.5%;
    }
  }
}
.textarea.ant-input {
  min-height: 2.8rem;
}
.allergies-panel {
  padding: $gap-05 $gap-08 $gap-06;
  .list {
    .ant-tag {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
}
.refresh-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: $gap-09;
  left: 11.8rem;
  background: url("../img/refresh-line.png");
  background-size: cover;
  cursor: pointer;
}
.refresh-modal {
  .ant-modal-content .ant-modal-body {
    padding: 0 2.4rem 0 2.4rem;
  }
  .ant-modal-footer {
    padding: $gap-04 1.6rem;
  }
  .ant-modal-header {
    padding: $gap-08 2.4rem;
  }
  .ant-modal-content .ant-modal-footer {
    padding-bottom: 1.6rem;
  }
  .ant-modal-body {
    iframe {
      height: calc(100vh - 16rem);
    }
  }
}

.select-box-section {
  .custom-text-input {
    .ant-input {
      margin-bottom: $gap-04;
      width: calc(100% - 4.16666667% - 3px);
    }
  }
  .select-box {
    &.one,
    &.two {
      &.site-input {
        .custom-text-input .ant-input {
          width: calc(100% - 4.16666667% + -10px);
          margin-left: 4.16666667%;
          margin-bottom: $gap-04;
          margin-top: $gap-04;
        }
      }
    }
  }
  .content-body {
    .select-box {
      &.site-input {
        .right-arrow {
          top: 1.6rem;
        }
        &.two {
          .right-arrow {
            top: 1.6rem;
          }
        }
      }
      .ant-select-selection-placeholder {
        width: $gap-10;
      }
    }
  }
}

.allergies-area {
  .fields-wrapper {
    .btns-action {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.ros-header-switch-wrapper .custom-switch-wrap .ant-radio-group {
  margin-left: $gap-08;
  .ant-radio-button-wrapper {
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: $font-size-xsm;
  }
}

.patient-screen {
  h2 {
    margin-bottom: 0;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.8rem;
}
.billing-header {
  h2 {
    margin-bottom: 0;
  }
}
.filter-components {
  .ant-picker {
    padding: 0 1.1rem 0;
    height: 2.8rem;
  }
}
.input-wrap {
  &.filter-select-box {
    & + .ant-picker {
      margin-right: 1.6rem;
    }
  }
}

.dr-motility {
  .group-shadow {
    background: transparent;
  }
}
.user-screen,
.screen-title {
  h2 {
    margin-bottom: 0;
  }
}
.payer-screen,
.add-payer-screen {
  h2 {
    margin-bottom: 0;
  }
}

.payer-screen {
  & + .table-filters {
    & + .table-container {
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.dr-name {
  .ellipse {
    white-space: nowrap;
    max-width: 7.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
.month-inner-table {
  table {
    margin: 0 auto;
    border: $gap-01 solid $button-border;
    font-size: $font-size-xsm;
    font-weight: $font-regular;
    thead {
      th {
        padding: $gap-02;
        text-align: center;
      }
    }
    tbody {
      td {
        padding: $gap-02;
        text-align: center;
      }
      tr {
        td {
          &:first-child {
            padding: $gap-02;
            text-align: left;
          }
        }
      }
    }
  }
}
.schedular-settings {
  & + .main-form-container {
    .btns-action {
      padding-top: 0;
    }
    .table-filters {
      .table-filter-inner button {
        margin-right: 0;
        margin-left: $gap-12;
      }
      .filter-ant-search {
        margin-right: 0;
      }
    }
  }
}

.all-select-wrap {
  .ant-select.ant-select-multiple.ant-select .ant-select-selection-item {
    height: auto;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    white-space: normal;
  }
  .ant-select-selection-item-content {
    span {
      word-break: break-all;
    }
  }
}
.input-search-box {
  &.ant-search {
    .ant-input-search {
      height: 2.8rem;
    }
    .ant-input-suffix svg {
      font-size: $font-size-m + 1;
      top: 0;
    }
  }
}
.add-edit-input {
  .edit-icon {
    right: 2.5rem;
  }
}
.input-group-wrapper {
  @include flexbox;
  @include align-items(center);
  label {
    min-width: 33.33333333%;
    font-size: $font-size-xsm;
    color: $color-blue-light;
    margin-bottom: $gap-08;
  }
  .input-group-only {
    @include flexbox;
    .custom-input {
      margin-right: $gap-08;
    }
  }
}

.surgery-steps-wrap {
  .follow-up {
    max-width: 56rem;
    .panel-body {
      .ant-row {
        &:first-child {
          .item-label {
            width: 14.9rem;
            @include flexbox;
            padding-top: $gap-05;
            font-size: $font-size-xsm;
            color: $color-blue-light;
          }
          .ant-col-5 {
            max-width: 9.2rem;
          }
        }
      }
      .ant-row-middle {
        .ant-col-offset-1 {
          margin-left: $gap-12;
        }
      }
    }
    .btm-follow-up {
      @include flexbox;
      .left-area {
        @include flex(2);
        margin-right: $gap-12;
      }
      .right-area {
        @include flex(1);
      }
      button {
        margin-bottom: $gap-08;
      }
    }
  }

  .add-icon-follow-up {
    margin-left: $gap-12;
    color: $color-green;
    cursor: pointer;
  }
}
.select-area-box {
  width: 30.6rem;
  @include flexbox;
  .custom-select {
    width: 100%;
    margin-right: 1.6rem;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.min-width-procedure {
  .custom-autocomplete {
    min-width: 10rem;
  }
}
.exception-term-para {
  p {
    margin-bottom: 0;
  }
  .col {
    .ant-row {
      @include justify-content(center);
    }
  }
}
.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    height: 2.8rem;
    line-height: 2.8rem;
  }
}
.exception-description {
  .custom-input {
    &.non-editable {
      .ant-row .ant-form-item-control .ant-input {
        height: 1.6rem;
      }
      height: 1.6rem;
      padding-right: $gap-12;
      & + .non-editable {
        padding-right: 0;
      }
      .ant-input {
        height: 1.6rem;
      }
    }
  }
}

.exception-description-full {
  .custom-input {
    &.non-editable {
      .ant-row .ant-form-item-control .ant-input {
        height: 1.6rem;
      }
      height: 1.6rem;
      padding-right: $gap-12;
      & + .non-editable {
        padding-right: 0;
      }
      .ant-input {
        height: 1.6rem;
      }
    }
  }
}

.ant-select-multiple {
  .ant-select-selector {
    .ant-tag {
      word-break: break-word;
      white-space: normal;
      margin: $gap-03 $gap-02 0 $gap-02;
    }
  }
}
.patient-history.tabs-gap-mng {
  position: relative;
  z-index: 0;
  .history-white-bg {
    position: relative;
    z-index: 0;
    .pannel-heading,
    .pannel-body {
      position: relative;
      z-index: 0;
    }
  }
  .ant-select-multiple {
    .ant-select-selector {
      .ant-tag {
        word-break: break-word;
        white-space: normal;
        margin: $gap-02 $gap-02 0 $gap-02;
      }
    }
  }
}
.doctor-wrapper .patient-description {
  .check-in-step {
    margin-top: -$gap-02;
  }
  .tag-list {
    margin-top: $gap-06;
  }
}
.drop-modal-id {
  .hover-section-content {
    max-width: 70.8rem;
    min-width: 70.8rem;
  }
}

.patient-filter {
  .ant-select-multiple .ant-select-selector .ant-tag {
    margin: -$gap-02 $gap-04 0 0;
  }
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    height: 2.8rem;
  }
}
.back-headings {
  color: $color-blue-light;
  margin-bottom: $gap-08;
  margin-top: -$gap-06;
}
.no-btm-mr {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}

.refract-contract {
  @include flexbox;
  flex-wrap: wrap;
  .custom-select-box,
  & > .refract-input-field {
    min-width: 20rem;
    margin-right: $gap-12;
    max-width: 20rem;
  }
}
.extended-wear-checkbox {
  margin-top: $gap-08;
}

.digit-one-icon {
  p {
    svg {
      color: $color-green;
    }
  }
  &.manage-icons {
    p {
      margin-left: 1.6rem;
    }
  }
}
.digit-one-icons {
  padding: $gap-06;
  margin-top: $gap-06;
  p {
    svg {
      color: $color-green;
    }
  }
  &.manage-icons {
    p {
      margin-left: 1.6rem;
    }
  }
}
.edit-form-area {
  .wrap-inner-section {
    margin-left: -1.1rem;
    margin-top: -$gap-12;
    padding-bottom: 0;
  }
}

.ant-select-tree-list {
  .ant-select-tree-list-holder-inner {
    .ant-select-tree-treenode {
      font-size: $font-size-xsm;
      background: $app-background;
      padding-bottom: $gap-02;
      padding-top: $gap-02;
      color: $color-blue-light;
      border-bottom: 1px solid $button-border;
      &.ant-select-tree-treenode-switcher-close.ant-select-tree-treenode-leaf-last {
        border-bottom: none;
      }
    }
    .ant-select-tree-treenode-switcher-open {
      margin-bottom: 0;
    }
    .ant-select-tree-indent-unit {
      width: 0;
    }
    .ant-select-tree-switcher {
      height: 24px;
    }
  }
}
.ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  color: $color-blue;
}
.white-tabs {
  .h-tabs {
    margin-top: 0;
    .ant-tabs-nav-wrap {
      background: $color-white;
      margin-left: 2.4rem;
      border-radius: $border-radius-base;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
  }
}

.encounter-filter {
  .custom-checkbox-select {
    height: 2.4rem;
  }
}

.ss-content {
  .md-toolbar-tools {
    font-size: 1.4rem;
  }
}
.filter-button {
  margin-right: $gap-10;
}
.ant-form .input-group-wrapper label {
  font-size: $font-size-xsm;
}
.mobile-menu-icon {
  display: none;
}
.width-33-only {
  width: 33.3333%;
}
.heading-area.flex.no-componet-mr {
  @include flex-wrap(wrap);
  margin-bottom: 0;
  @include align-items(flex-start);
  button {
    margin-bottom: 8px;
  }
}
.inner-arrow {
  background: url("../img/arrow-green.png");
  width: 2rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.outer-arrow {
  background: url("../img/arrow-green.png");
  width: 2rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.aldrete-table {
  margin-bottom: $gap-12;
  .custom-select,
  .custom-input {
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }
}

.add-row-button {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: $color-green;
  color: $color-white;
  border-radius: 50%;
  text-align: center;
  line-height: 2rem;
  font-size: $font-size-ml;
  margin: $gap-06;
  cursor: pointer;
}
.copay-collection {
  .total-due {
    margin-bottom: $gap-12;
  }
}
.collect-modal {
  .ant-checkbox-group {
    label {
      @include flexbox();
      margin-bottom: $gap-08;
      height: auto;
    }
  }
  .ant-modal-body {
    padding-top: 3.4rem;
  }
}
.left-align-text {
  width: 14.4rem;
  color: $color-blue-light;
  @include flexbox();
  @include align-items(center);
}

.custom-select,
.auto-complete-search {
  .ant-row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 $gap-06;
    .ant-select-selection-search {
      min-width: 60px;
      .ant-select-selection-search-input {
        padding-right: $gap-04;
        font-size: $font-size-xsm;
      }
    }
    .ant-select-selection-placeholder {
      min-width: 74px;
    }
    .ant-select-selection-item {
      font-size: $font-size-m;
      min-width: 7.6rem;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: $gap-06;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 1.6rem;
  }
  .ant-select-arrow {
    right: 7px;
    width: 10px;
    height: 10px;
    .anticon-search {
      width: 14px;
      font-size: 10px;
      top: 1px;
      position: relative;
    }
  }
  .ant-select-clear {
    right: 6px;
    width: 10px;
    height: 10px;
  }
}

.wrap-expend-collapse {
  @include flexbox();
  @include justify-content(flex-end);
  position: absolute;
  top: 0px;
  button {
    margin-right: $gap-08;
    margin-bottom: $gap-12;
    & + button {
      margin-right: 0;
    }
  }
}
.procedure-right-section {
  .wrap-expend-collapse {
    position: absolute;
    top: -3.5rem;
    @media (max-width: 1440px) {
      position: static;
      margin-top: 0.8rem;
    }
  }
  .json-tree-container {
    .sub-menu-collapse {
      max-height: 54vh;
      overflow-y: auto;
    }
  }
}
.select-box-section .procedures .h-tabs + .side-tab {
  margin-right: 0;
}

.patient-manage {
  .patient_status {
    .custom-checkbox {
      width: 100%;
      & + .custom-checkbox {
        position: absolute;
        right: 0;
        width: auto;
      }
    }
    .patient_active_status {
      margin-left: 0;
    }
  }
}
.checkbox-fixes {
  .custom-checkbox {
    .ant-checkbox-wrapper {
      @include flexbox();
    }
  }
}

span#sidebar-clock-date,
span#sidebar-clock-time {
  font-size: 12px;
  display: block;
  text-align: center;
  width: 100%;
  margin-left: 0px;
  position: relative;
}

.alert-box-orange {
  max-width: 16%;
}
.plus-design-icon {
  svg {
    color: $color-green;
    font-size: $font-size-ml;
  }
}

.add-encounter {
  .anticon-plus {
    position: relative;
    top: 2px;
    svg {
      font-size: $font-size-l;
      position: relative;
      top: $gap-04;
      left: 0;
    }
  }
  .add-encounter-text {
    font-size: $font-size-xsm;
    @include flexbox();
    @include align-items(center);
    margin-right: 0.7rem;
  }
}
.insurance-head-box {
  .form-grid-row {
    grid-template-columns: initial;
    @include flexbox;
    @include flex-wrap(wrap);
  }
}
.font-size-13 {
  font-size: $font-size-s;
}

.wrap-inner-section .goal-wraps .crossponding-area {
  margin-bottom: 0;
}
.goal-wraps {
  .crossponding-area {
    margin-bottom: 0;
    & + .flex.mr-lt-8 {
      margin-left: $gap-04;
    }
  }
  .reduction-wrap {
    width: 21.4rem;
    .custom-select {
      padding-left: $gap-04;
      max-width: 10rem;
    }
    .custom-input {
      max-width: 10rem;
      padding-left: $gap-04;
    }
  }
}
.copy-all-os-od-wrap {
  position: relative;
  .box-one {
    position: static;
    min-width: auto !important;
    .copy-all-os-od {
      position: absolute;
      left: 1.4rem;
      cursor: pointer;
    }
  }
  .copy-all-os-od-right {
    position: absolute;
    right: $gap-10;
    cursor: pointer;
  }
}
.select-box-section .header-pannel {
  .copy-all-os-od-wrap {
    .head.box-two {
      border-right: none;
      border-left: none;
    }
  }
}
.check-icon {
  &.check-icon-right {
    position: absolute;
    right: $gap-08;
  }
}
#phy-exam-tab-slit-and-fundus {
  &.select-box-section {
    .select-box.two.site-input .custom-text-input .ant-input,
    .select-box.one.site-input .custom-text-input .ant-input {
      width: calc(100% - 4.16666667% + -1.6rem);
    }
  }
}
.shadow-overlay-1 {
  position: absolute;
  height: 23rem;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}
.shadow-overlay-2 {
  position: absolute;
  height: calc(100% - 23.6rem);
  width: calc(50vw + 20.3rem);
  left: 0;
  top: 23rem;
  z-index: 9999;
}
.shadow-overlay-3 {
  position: absolute;
  height: calc(100% - 29rem);
  width: 5.5rem;
  right: 0;
  top: 28.2rem;
  z-index: 9999;
}
.shadow-overlay-4 {
  position: absolute;
  // background: #00000061;
  height: calc(100% - 47.8rem);
  width: calc(100vw - (50vw + 26.6rem));
  right: 5.5rem;
  top: 47.8rem;
  z-index: 9999;
}
.inactive-icons {
  background: url("../img/not-allowed-icon.png");
  width: 1.8rem;
  height: 1.7rem;
  display: inline-block;
  position: relative;
  top: $gap-04;
  left: $gap-04;
}

.technician-tab-wrap {
  .drawing-area {
    max-width: 140rem;
  }
}
.wrap-dx-accordion {
  max-height: 50vh;
  overflow-y: auto;
  padding: $gap-03;
  @media (min-width: $lg-desktop) {
    max-height: 59vh;
    overflow-x: hidden;
  }
  .fix-height-tabs {
    margin-top: 1.2rem;
    margin-right: 1.2rem;
  }
}
.height-to-gap-button {
  padding-bottom: 34px;
}
.json-tree-container {
  &.wrapper-to-fix-height {
    max-height: 57vh;
    overflow-y: auto;
    padding-right: 8px;
    @media (min-width: $lg-desktop) {
      height: 63vh;
    }
    .info-alert .hhh .sub-l-child-group.j-code-sub-child {
      padding-top: 4px;
      padding-left: 12px !important;
      padding-right: 4px;
      & > div {
        padding-left: 12px !important;
        border: 1px solid #ccc;
        margin-bottom: 4px;
        border-radius: 2px;
      }
    }
  }
  &.order-set-tree-wrap {
    max-height: 30vh;
    overflow-y: auto;
    @media (min-width: $medium-desktop) {
      .info-alert
        .hhh
        .add-alert-bg
        + .sub-l-child-group
        .positive-relative
        + .sub-l-child-group.j-code-sub-child
        > div
        .positive-relative
        p
        p {
        right: 0;
      }
    }
    @media (min-width: $lg-desktop) {
      max-height: 54vh;
    }
  }
}
.ap-tab {
  .json-tree-container {
    &.wrapper-to-fix-height {
      max-height: 51vh;
      overflow-y: auto;
      @media (min-width: $lg-desktop) {
        max-height: 58vh;
      }
    }
  }
}
.merge-fields-container {
  .wrapper-tree-fix-height {
    max-height: 56vh;
    overflow-y: auto;
    padding: 3px;
    .ae-accordian {
      &.posi-relative {
        &.mr-bt-8 {
          .note-accordion {
            .ant-collapse-content {
              .ant-collapse-content-box {
                .pannel-body {
                  padding: 6px 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  .order-set-acc-wrap {
    max-height: 30vh;
    overflow-y: auto;
    padding: 3px;
  }
}

.patient-details {
  .tag-list {
    .medication-list {
      padding: 0;
      .medication-list-original {
        li {
          border-bottom: none;
          padding-bottom: 0.4rem;
        }
        .ant-collapse-header {
          border: 1px solid rgba(165, 188, 223, 0.7);
        }
        .ant-collapse-content-box {
          border: 1px solid rgba(165, 188, 223, 0.7);
          border-top: none;
        }
        .ae-accordian {
          margin-top: 0;
        }
      }
    }
  }
}
.manage-ui-block {
  .ant-tree-list-holder-inner {
    .ant-tree-treenode {
      width: 100%;
      .ant-tree-switcher-noop {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        width: 100%;
      }
    }
  }
  .ant-collapse-content {
    padding: $gap-10 $gap-06;
  }
}

.history-white-bg {
  background: $color-white;
}

.user-img {
  .ant-avatar {
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
  }
}

// override css
.ant-menu {
  font-size: $font-size-m;
}
.ant-checkbox-inner {
  &::after {
    width: 5px !important;
    height: 7px !important;
  }
}
.sm-checkbox {
  .ant-checkbox-inner {
    &:after {
      width: 5px !important;
      height: 7px !important;
    }
  }
}
.mr-bt-20 {
  margin-bottom: 20px;
}
.ant-form label {
  font-size: $font-size-xsm;
}

.radio-option-gap {
  .ant-radio-group {
    .ant-radio-wrapper {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.diagnosis-group-arrow {
  &.ap-parent {
    & > div {
      & > div.hhh {
        .positive-relative {
          .sm-checkbox {
            margin-left: 1.2rem;
            & + p {
              padding-left: 1.2rem;
            }
          }
        }
      }
    }
  }
}
.zip-search-wrap {
  position: relative;
  .custom-input {
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    &.ant-dropdown-menu-light {
      &.ant-dropdown-menu-root {
        &.ant-dropdown-menu-vertical {
          max-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }
  }
}
.ant-form-item-explain {
  &.ant-form-item-explain-error {
    line-height: 1;
    margin-top: 4px;
    font-size: 1.2rem;
  }
}

.billing-list-table {
  &.billing-filter-gap-mng {
    .table-filters {
      .table-filter-inner {
        .filter-components {
          .filter-ant-search {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.drop-down-type-small-btn {
  color: $color-green;
  border: none;
  background: none;
  border-radius: $border-radius-base;
  padding: 0;
  font-size: $font-size-xsm;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: $font-bold;
}
.medication-wrap {
  &.encounter-left-panel-wrapper {
    .draggable-panel-container {
      .ant-collapse-item {
        .ant-collapse-content-inactive {
          display: none;
        }
      }
    }
  }
}
.insurance-info-wrap {
  .custom-select .ant-row .ant-select-single .ant-select-selector .ant-select-selection-item {
    max-width: 20rem;
    div {
      max-width: 20rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.regular-weight {
  font-weight: $font-regular;
}

.cursor-pointer {
  &.block-ui-display {
    margin-bottom: 8px;
  }
}

.p-rt-2 {
  padding-right: 2px;
}
.p-lt-4 {
  padding-left: 4px;
}

.capitalize {
  text-transform: capitalize;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #f5f5f5 !important;
  font-weight: $font-regular !important;
}

.back-arrow {
  position: relative;
  padding-left: 2.4rem;
  margin-bottom: 5px;
  cursor: pointer;
  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background: url("../img/backarrow.png");
    position: absolute;
    left: 0;
    background-size: cover;
  }
}
.add-test-type {
  .billing-diagnosis {
    margin-right: 0.8rem;
  }
}
.ant-select-multiple .ant-select-selection-item-content {
  font-size: $font-size-xsm;
}

.error-boundary-wrapper {
  padding-bottom: 80px;
  @include flexbox;
  height: 100vh;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  .error-boundary-img {
    margin-bottom: 20px;
    img {
      max-width: 140px;
      @media (min-width: $medium-desktop) {
        max-width: 200px;
      }
    }
  }
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-title {
    color: $color-blue-light;
    font-weight: $font-medium;
  }
  .ant-modal-confirm-content {
    color: $color-blue-light;
    font-weight: normal;
  }
}
.ant-modal-confirm-btns {
  button.ant-btn,
  button.ant-btn:focus,
  button.ant-btn:hover {
    min-height: 2.8rem;
    padding: 0 0.8rem;
    font-size: 1.2rem;
    max-height: 2.8rem;
    border-radius: 0.4rem;
    outline: none;
    background: transparent;
    font-weight: 700;
    text-transform: uppercase;
    color: #748aac;
    cursor: pointer;
    min-width: 62px;
    &.ant-btn-primary,
    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus {
      background: #32c788;
      border: 0.1rem solid #32c788 !important;
      color: #fff !important;
    }
    &.ant-modal-ok-white-btn,
    &.ant-modal-ok-white-btn:hover,
    &.ant-modal-ok-white-btn:focus {
      background: #fff !important;
      border-color: $border-gray !important ;
      color: #748aac !important;
    }
  }
}
.ant-modal-continue-green-btn {
  background: #32c788 !important;
  border: 0.1rem solid #32c788 !important;
  color: #fff !important;
}
.vital-group-input-bg {
  @include flexbox;
  position: relative;
  &:before {
    content: "";
    background: #dde7f3;
    width: calc(100% + 10px);
    height: calc(100% + 0rem);
    position: absolute;
    top: -0.4rem;
    left: -0.5rem;
    border-radius: 0.4rem;
  }
}
.mr-top-neg-8 {
  margin-top: -8px;
}
.mr-top-veg-10 {
  margin-top: -10px;
}

.heading-text {
  color: $color-blue-light;
  font-weight: $font-medium;
  margin-bottom: 12px;
  display: inline-block;
}

.allergy-icon-gap {
  .anticon-plus {
    margin-right: 8px;
  }
}

.disable-with-bg {
  .non-editable
    .ant-row
    .ant-form-item
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input-disabled,
  .non-editable
    .ant-row
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-select-disabled
    .ant-select-selector {
    border: 0.1rem solid rgba(165, 188, 223, 0.7);
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
}

.appointment-user-wrap {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: red !important;
    font-size: $font-size-s;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: red !important;
    font-size: $font-size-s;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: red !important;
    font-size: $font-size-s;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: red !important;
    font-size: $font-size-s;
  }
}

.date-picker-two-form {
  @include flexbox;
  width: 100%;
  .label-cls {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    @include align-items(center);
    max-width: 33.33333333%;
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
    -webkit-flex: 0 0 33.33333333%;
    @include flexbox;
    margin-bottom: 0.8rem;
  }
  .date-picker-two-form-inn {
    @include flexbox;
    width: 66.666666%;
    .custom-report-datepicker-from {
      width: calc(50% - 20px);
      & + span {
        width: 40px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        margin-bottom: 0.8rem;
      }
    }
    .custom-report-datepicker-to {
      width: calc(50% - 20px);
    }
  }
}
.follow-up {
  .followup-row {
    padding-right: 8px;
  }
}

// letter css
.letter-generation-wrap .fr-view table td,
.letter-generation-wrap .fr-view table th {
  border: none;
}
.letter-generation-wrap .letter_pdf_table table td {
  border: 1px solid #ccc;
}
.letter-generation-wrap .circle-icon {
  font-size: 8px !important;
}
.letter-generation-wrap .user-profile-letter_pdf_table {
  border: none !important;
}
.letter-generation-wrap .letter-logo {
  display: none !important;
}
.letter-generation-wrap .preview-logo {
  display: none !important;
}

// consent form css
.save-consent-form-wrap .fr-view table td,
.save-consent-form-wrap .fr-view table th {
  border: none;
}
.save-consent-form-wrap .letter_pdf_table table td {
  border: 1px solid #ccc;
}
.save-consent-form-wrap .circle-icon {
  font-size: 8px !important;
}
.save-consent-form-wrap .user-profile-letter_pdf_table {
  border: none !important;
}
.save-consent-form-wrap .letter-logo {
  display: none !important;
}
.save-consent-form-wrap .preview-logo {
  display: none !important;
}

// overlay css

#overlay {
  &:before {
    content: "";
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
}
.height-28-only {
  &.mr-tp-5 {
    @media (min-width: $lg-desktop) {
      margin-top: 0;
    }
  }
}

.override-grid-block > div {
  display: block;
}

.flask-green,
.flask-red {
  cursor: pointer;
}

.filter-wrapper {
  .ant-input-search {
    .ant-input-group {
      .ant-input {
        height: 28px;
      }
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $content-color !important;
  font-size: $font-size-s !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $content-color !important;
  font-size: $font-size-s !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $content-color !important;
  font-size: $font-size-s !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $content-color !important;
  font-size: $font-size-s !important;
}
.ant-input-number-input {
  padding: 0 6px !important;
}
.task-filter-form {
  @include flexbox;
  @include justify-content(space-between);
  .form-grid-component {
    width: 924px;
    @media (min-width: $lg-desktop) {
      width: 1400px;
    }
  }
}

.input-height-28 {
  .custom-input {
    input {
      height: 2.8rem;
    }
  }
}
.custom-action-icon {
  .anticon-plus {
    margin-left: 2px;
  }
  svg {
    color: $color-green;
    font-size: 1.4rem;
  }
}

.report-table-wrap {
  tfoot {
    tr {
      td {
        padding-left: 1.6rem;
        font-weight: 700;
        &:first-child {
          display: none;
        }
      }
    }
  }
}

.pophover-content {
  &.manual-status {
    .education-form-list {
      margin-bottom: 0;
      li {
        p {
          margin: 0;
        }
      }
    }
  }
  .education-form-list {
    &.address-list {
      li {
        padding: 8px 12px 7px;
        margin: 0;
        width: 100%;
        cursor: pointer;
        p {
          font-size: $font-size-s;
        }
        &:hover {
          background: #cccccc45;
        }
      }
    }
  }
}
.check-in-step {
  .green-pdf-icon {
    background: url("../img/green-pdf.png");
    width: 26px;
    height: 28px;
    background-size: cover;
    margin-left: 5px;
    svg {
      display: none;
    }
  }
  .red-pdf-icon {
    background: url("../img/red-pdf.png");
    width: 26px;
    height: 28px;
    background-size: cover;
    margin-left: 5px;
    svg {
      display: none;
    }
  }
  .ant-steps-item-content {
    .ant-steps-item-title {
      & > div {
        @include flexbox();
        @include align-items(flex-start);
      }
    }
  }
}

.ros-list-row {
  .green-pdf-icon {
    background: url("../img/green-pdf.png");
    width: 30px;
    height: 32px;
    background-size: cover;
    margin-left: 5px;
    svg {
      display: none;
    }
  }
  .red-pdf-icon {
    background: url("../img/red-pdf.png");
    width: 30px;
    height: 32px;
    background-size: cover;
    margin-left: 5px;
    svg {
      display: none;
    }
  }
}
.other-wrap {
  .methometic-area {
    .right-one.methometic-pannel {
      .motility-indicator {
        position: relative;
        .red-pdf-icon {
          background: url("../img/red-pdf.png");
          width: 30px;
          height: 32px;
          background-size: cover;
          margin-left: 5px;
          position: absolute;
          top: 0;
          right: 0;
          svg {
            display: none;
          }
        }
      }
    }
  }
}
.new-dr-tabs {
  .h-tabs {
    .manage-tab-gap {
      padding-right: 24px;
      & + .red-pdf-icon {
        background: url("../img/red-22.png");
        width: 22px;
        height: 23px;
        background-size: cover;
        margin-left: 5px;
        position: absolute;
        top: 8px;
        right: -15px;
        svg {
          display: none;
        }
      }
      & + .green-pdf-icon {
        background: url("../img/green-22.png");
        width: 22px;
        height: 23px;
        background-size: cover;
        margin-left: 5px;
        position: relative;
        top: 8px;
        right: -15px;
        svg {
          display: none;
        }
      }
    }
  }
}
.scheduleIcon {
  svg {
    color: $color-green;
  }
}
.report-rt-container {
  .report-form-wrapper {
    .form-grid-component {
      .form-grid-column,
      .form-grid-row {
        display: block;
      }
    }
  }
}
.required-star-red {
  color: #cc3d00;
}

.verified-icon-right {
  position: relative;
  & + .ant-select-tree-treenode-selected {
    .ant-select-tree-node-content-wrapper {
      .ant-select-tree-title {
        &::after {
          content: "";
          position: absolute !important;
          top: 2px !important;
          right: 4px !important;
          width: 17px;
          height: 19px;
          background: url("../img/red-shield.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

.schedular-table-filters {
  .ant-select.ant-select-multiple.ant-select .ant-select-selection-item {
    margin-top: 0.1rem;
  }
  #schedularMonthlyView,
  .app-table {
    height: 74vh;
  }
}

// need to check css parent
.insurence-info-error {
  &.ant-tree-select-dropdown
    .ant-select-tree-list-holder-inner
    .ant-select-tree-treenode
    .ant-select-tree-node-content-wrapper {
    display: inline-block;
    width: calc(100% - 44px);
    flex: initial;
  }
}

// end

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 3px;
}

.drop-icon {
  position: relative;
  &:before {
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 15px;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  &:after {
    display: inline-block;
    position: absolute;
    right: 23px;
    top: -1px;
    width: 1px;
    height: calc(100% + 2px);
    content: "";
    background: $color-white;
  }
}

.verification-status {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: -8px;
  ul {
    &.address-list {
      &.education-form-list {
        margin-bottom: 0;
        li {
          border-bottom: 0.1rem solid #d2ddef;
          &:last-child {
            border-bottom: none;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.name-view-align-center {
  @include align-items(center);
  .responsibility-value {
    margin-right: 10px;
  }
}
.patient-responsibility-wrap {
  @include flexbox;
  @include flex-direction(column);
  @media (min-width: $medium-desktop) {
    display: block;
    .text-decoration-underline {
      margin-right: 8px;
      & + .text-decoration-underline {
        margin-right: 0;
      }
    }
  }
}

.wrap-insurence-map {
  position: relative;
  .insurence-map-icon {
    background-color: #32c788;
    width: 25px;
    background-size: cover;
    height: 26px;
    display: inline-block;
    position: absolute;
    right: 1px;
    top: 1px;
    cursor: pointer;
    &::before {
      content: "";
      background-image: url("../img/insurence-map-icon.png");
      width: 16px;
      background-size: cover;
      height: 21px;
      display: inline-block;
      position: absolute;
      right: 4px;
      top: 2px;
    }
  }
}

.with-pagination {
  &.mr-top-12 {
    .ant-pagination {
      margin-top: 12px;
      @include flexbox;
      @include justify-content(flex-end);
    }
  }
  .ant-pagination {
    display: block;
  }
}

.sift-right {
  @include flexbox;
  @include justify-content(flex-end);
}

.ant-tree-list-holder-inner {
  font-size: 12px;
  font-weight: 500;
  color: #2a4a79;
}
.manual-intervention-screen {
  .header-section {
    @include flexbox;
    @include justify-content(space-between);
    h2 {
      margin-bottom: 6px;
    }
  }
  .header-filters {
    @include flexbox;
    margin-bottom: 4px;
    .filter-components{
      .filter-ant-search{
        margin-right: 0;
      }
    }
    .patent-list-search {
      width: 392px !important;
    }
  }
}

.filter-parent-wrap {
  .ant-dropdown-trigger {
    background: url("../img/filter-drop-icon2.png");
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: cover;
    margin-left: 0.8rem;
  }
}

.insurence-map-table {
  position: relative;
  .table-container {
    .app-table {
      // max-height: 30rem;
      // overflow: auto;
      tbody {
        tr {
          td {
            &.table-data {
              div {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}

.custom-select .ant-row .ant-select-single .ant-select-selector .ant-select-selection-item {
  width: 20rem;
}

.select-transection-type{
  .ant-row .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-row .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 3.0rem;
  }
}
.flex-wrap-style{
  @include flexbox();
  @include flex-wrap(nowrap);
}

.medication-view{
  &.medical-history-accordian{
    padding: 0;
  .medication-list{
    padding: 0;
    .medication-list-original{
      .patient-reported-medications{
        box-shadow: none;
      }
      .medications{
        li{
          display: block;
          padding: 0;
          .extra-medication-info{
            padding: 0.5rem 0.6rem 0.5rem 1.6rem;
          }
          .ant-collapse {
            background: transparent;
            padding: 0;
            & > .ant-collapse-item{
              border-bottom: none;
              .medication-sub-heading{
                font-size: 1.2rem;
                max-width: 72%;
              }
              .written-date{
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
}

.medication-body {
  &.ocular-problem-area{
    .table-container{
      .app-table{
        .affixed-table{
          z-index: 9;
        }
      }
    }
  }
}

.extra-medication-info .font-medium.min-width-92 + span {
  padding-right: 4px;
}
// Code end before of media query

@media (max-width: $max-desktop) {
  .follow-up {
    .ant-col-order-1 {
      order: 1;
      position: absolute;
      bottom: -18px;
      width: 100%;
      max-width: 100%;
      /* padding-left: 0; */
      margin-left: 0;
      & + .ant-col-11 {
        max-width: 100%;
        flex: 0 0 calc(100% - 12px);
      }
    }
    .follow-up-label {
      margin-bottom: 18px;
    }
    .followup-row {
      padding-right: 8px;
    }
  }
  .technician-tab-wrap {
    .drawing-area {
      max-width: 80rem;
    }
  }
  .manage-draw-aside-form {
    width: calc(100% - 80rem) !important;
  }
  .custom-select .ant-row .ant-select-single .ant-select-selector .ant-select-selection-item {
    width: 20rem;
  }
}

@media (max-width: $mid-sm-desktop) {
  .technician-tab-wrap {
    .drawing-area {
      max-width: 60rem;
    }
  }
  .manage-draw-aside-form {
    width: calc(100% - 60rem) !important;
  }
}
@media (min-width: $max-desktop) {
  .dr-name {
    .ellipse {
      max-width: 8.2rem;
    }
  }
  .dr-filter-header {
    .patient-suggestive-search {
      .filter-patient-autocomplete {
        .ant-select {
          width: 248px !important;
        }
      }
    }
  }
}

@media (min-width: $medium-desktop) {
  .custom-input .ant-row .ant-form-item-label > label,
  .custom-select .ant-row .ant-form-item-label > label,
  .custom-checkbox .ant-row .ant-form-item-label > label,
  .custom-picker .ant-row .ant-form-item-label > label,
  .custom-radio .ant-row .ant-form-item-label > label,
  .custom-autocomplete .ant-row .ant-form-item-label > label,
  .time-picker-wrap .ant-row .ant-form-item-label > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .dr-name {
    .ellipse {
      max-width: 10.8rem;
    }
  }

  .week-list {
    margin-top: 0;
  }
  .main-form-container form,
  .form-responsive {
    max-width: 100%;
    width: 100%;
  }
  .main-form-container {
    .insurance-side-bar {
      & + {
        .required-feilds {
          form {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .schedular-content {
    height: calc(100vh - 11rem);
  }
  .select-box-section {
    .custom-text-input {
      .ant-input {
        margin-bottom: $gap-04;
        width: calc(100% - 4.16666667% - 2px);
      }
    }
  }
  #phy-exam-tab-slit-and-fundus {
    &.select-box-section {
      .select-box.two.site-input .custom-text-input .ant-input,
      .select-box.one.site-input .custom-text-input .ant-input {
        width: calc(100% - 4.16666667% + -1.6rem);
      }
    }
  }
}

@media (min-width: $lg-desktop) {
  .dr-filter-header {
    .ant-form-item {
      .ant-select,
      .ant-cascader-picker {
        width: 100% !important;
      }
    }
    .patient-suggestive-search {
      .filter-patient-autocomplete {
        .ant-select {
          width: 294px !important;
        }
      }
    }
  }

  .select-box-section {
    .custom-text-input {
      .ant-input {
        margin-bottom: 4px;
        width: calc(100% - 4.16666667% - 1px);
      }
    }
  }
  .scheduler-header-main {
    .custom-select .ant-row .ant-form-item-label > label {
      padding-top: 0;
    }
  }
  .custom-input .ant-row .ant-form-item-label > label,
  .custom-select .ant-row .ant-form-item-label > label,
  .custom-checkbox .ant-row .ant-form-item-label > label,
  .custom-picker .ant-row .ant-form-item-label > label,
  .custom-radio .ant-row .ant-form-item-label > label,
  .custom-autocomplete .ant-row .ant-form-item-label > label,
  .time-picker-wrap .ant-row .ant-form-item-label > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .dr-name {
    .ellipse {
      max-width: 14.6rem;
    }
  }
  .main-form-container form,
  .form-responsive {
    max-width: 100%;
    width: 100%;
  }
  .main-form-container {
    .insurance-side-bar {
      & + {
        .required-feilds {
          form {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  #phy-exam-tab-slit-and-fundus {
    &.select-box-section {
      .select-box.two.site-input .custom-text-input .ant-input,
      .select-box.one.site-input .custom-text-input .ant-input {
        width: calc(100% - 4.16666667% + -2.3rem) !important;
      }
    }
  }
}
@media (min-width: $xlg-desktop) {
  :root {
    font-size: 12px;
  }
}
@media (min-width: $xxlg-desktop) {
  :root {
    font-size: 16px;
  }
}

@media (min-width: $max-desktop) {
  .app-table {
    table {
      font-weight: $font-medium;
    }
  }
  .display-view {
    .ant-row {
      .ant-col {
        &.ant-col-md-9 {
          max-width: 11rem;
          width: 11rem;
          label {
            width: 11rem;
            max-width: 11rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            line-height: 3rem;
            text-align: left;
          }
        }
      }
    }
  }
  .app-table table tbody {
    font-size: $font-size-m;
  }
  .app-table table thead tr th {
    font-size: $font-size-m;
  }
  #phy-exam-tab-slit-and-fundus {
    &.select-box-section {
      .select-box.two.site-input .custom-text-input .ant-input,
      .select-box.one.site-input .custom-text-input .ant-input {
        width: calc(100% - 4.16666667% + -2rem);
      }
    }
  }
}

@media (max-width: $lg-tablet) {
  :root {
    font-size: 9px;
  }

  .btns-action {
    &.mr-left-60 {
      margin-left: 0;
    }
  }
  .top-section {
    display: block;
    button {
      margin-bottom: $gap-08;
    }
  }
  .schedular-settings {
    & + .main-form-container {
      .btns-action {
        padding-top: 0;
      }
      .table-filters {
        @include align-items(flex-start);
        .table-filter-inner {
          @include flex-wrap(wrap);
          button {
            margin-right: 0;
            margin-left: 0px;
          }
        }
        .filter-ant-search {
          margin-right: $gap-12;
        }
      }
    }
  }
  .billing-list-table {
    .filter-components {
      .filter-ant-search {
        margin-bottom: 0;
      }
    }
  }
  .patient-profiles {
    .descriptions {
      width: 50%;
    }
  }
  .ant-modal {
    width: 96% !important;
  }
  #patients_financial_reversal {
    min-width: 95px;
    display: inline-block;
    text-align: right;
    padding-top: $gap-04;
  }
  .tab-filter-align {
    .input-wrap {
      margin-bottom: $gap-12;
    }
    .search-input {
      margin-bottom: $gap-12;
    }
    .mr-left-20 {
      margin-left: 0;
      margin-bottom: $gap-12;
      button {
        margin-left: 0;
      }
    }
    &.filter-area {
      @include align-items(flex-start);
    }
    & + .mr-top-20 {
      margin-top: $gap-04;
    }
  }
  .procedure-right-section {
    .wrap-expend-collapse {
      position: static;
      margin-top: 12px;
    }
  }
}
@media (max-width: $tablet) {
  .no-componet-mr {
    & + .flex {
      display: block;
    }
  }
  .tab-filter-align {
    button {
      margin-left: 1.6rem;
    }
    .mr-left-20 {
      button {
        margin-left: 1.6rem;
      }
    }
  }
  .patient-profiles {
    .descriptions {
      width: 62%;
    }
  }
}

@media (max-width: $tablet-medium) {
  :root {
    font-size: 9px;
  }
  #add-encounter {
    .ant-select {
      width: 100% !important;
    }
  }
  .schedular-content {
    .grid-days-container {
      overflow: auto;
      .grid-container {
        min-width: 100px;
      }
    }
  }
  .filter-header {
    .filter-left {
      .fullscreen-icon-wrapper {
        & + .middle-btns {
          margin-bottom: 0;
        }
      }
    }
  }
  .pannel {
    .pannel-heading {
      padding: 0.4rem 1rem;
    }
  }
  .assesment-area {
    .assessment-pannel {
      .assessment-tab {
        .test-tab {
          margin-left: 4px;
        }
      }
    }
  }
  .filter-components {
    width: 100%;
    .filter-ant-search {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .mobile-gap-manage {
    display: block;
    h2 {
      margin-bottom: $gap-08;
    }
    .custom-select-box {
      .ant-select {
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
  }
  #tab-left {
    &.ant-tabs {
      display: block;
      .ant-tabs-content-holder {
        .ant-tabs-tabpane {
          padding-left: 4px !important;
          .back-pannel {
            &.mr-rt-16 {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .mobile-layout-filter {
    @include align-items(flex-start);
    .flex {
      display: block;
      width: 100%;
      .filter-components {
        .filter-ant-search {
          margin-right: 0;
          .ant-input-search {
            min-width: 100% !important;
          }
        }
      }
    }
  }
  .table-filters {
    .filter-components {
      width: 100%;
    }
  }

  .tab-filter-align {
    &.filter-area {
      display: block;
    }
    .input-wrap {
      width: 100%;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .insurance-wrapper-all {
    display: block;
    .insurance-aside-wrap {
      margin-left: 0;
      margin-top: 12px;
      .group-shadow {
        margin-bottom: $gap-08;
      }
      .form-grid-component {
        display: block;
      }
    }
  }
  .required-feilds {
    h2 {
      &.h2-title:before {
        top: 2.4rem;
      }
    }
  }
  #patient-form {
    .custom-input,
    .custom-picker,
    .custom-select {
      .ant-col-9 {
        max-width: 100%;
        flex: 0 0 66.66666667%;
        -webkit-flex: 0 0 66.66666667%;
      }
      .ant-col-10 {
        max-width: 100%;
        flex: 0 0 66.66666667%;
        -webkit-flex: 0 0 66.66666667%;
      }
    }
  }
  .width-33-only {
    width: 100%;
  }
  .rt-side-panel.move-head-top {
    margin-bottom: $gap-06;
  }
  #users_rights_tab {
    display: block;
  }
  .right-side-header {
    .schedular-action-btn {
      margin-bottom: $gap-08;
    }
  }
  .app .right-content.open-menu {
    padding-left: 0;
  }
  .verified-email {
    word-break: break-all;
  }
  .form-btns.contract-btns button {
    margin-right: $gap-08;
    margin-bottom: $gap-08;
  }
  .move-btn-top > button {
    top: -$gap-08;
  }
  .ant-radio-button-wrapper {
    padding: 0 $gap-08;
  }
  .pannel-content-area {
    padding: $gap-08;
  }
  .new-encounter .top-title {
    display: block;
    .btn-group button {
      margin-bottom: $gap-10;
    }
  }
  .m-w-100 {
    display: block;
    width: 100%;
  }
  .filters-billing-encounter {
    .flex {
      @include flex-wrap(wrap);
      .mr-lt-10 {
        margin-left: 0;
        width: 100%;
      }
      span {
        display: inline-block;
        .custom-select-box {
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }
  }
  .no-componet-mr {
    .filter-components .filter-select-box {
      margin-bottom: 0;
    }
    .filter-components {
      margin-bottom: $gap-08;
    }
    & + .flex.justify-content-sp-bt {
      .claim-filter {
        & + .group-btns {
          margin-bottom: $gap-08;
        }
        .filter-ant-search {
          margin-bottom: 0;
        }
      }
      .filter-ant-search {
        .ant-input-affix-wrapper {
          min-width: 100% !important;
        }
      }
    }
  }

  .billing-header {
    & + .heading-area {
      .filter-components {
        .ant-input-affix-wrapper {
          width: 100% !important;
        }
      }
      .mr-lt-10 {
        margin-right: $gap-08;
        margin-left: 0;
      }
      button {
        margin-bottom: $gap-08;
      }
    }
    h2 {
      margin-bottom: $gap-08;
    }
    .group-btns button {
      margin-right: 0px;
    }
    .filter-button {
      margin-right: 0;
    }
  }
  .heading-area {
    &.flex {
      @include flex-wrap(wrap);
      button {
        margin-bottom: $gap-08;
      }
    }
  }
  .insurance-side-bar {
    margin-left: 0;
  }
  .contact-info {
    display: none;
  }
  .feild-row.contact-info {
    display: none;
  }
  .main-form-container {
    padding: 2.4rem 1.4rem 1.6rem 1.4rem;
    overflow: hidden;
  }
  .app-content {
    margin: $gap-08 $gap-12 2.4rem;
  }
  .social-icons.open-social ul {
    display: none;
  }
  .social-icons.open-social {
    span {
      display: inline-block;
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
    }
    &.mobile-social {
      .mobile-menu-icon {
        span {
          transform: rotate(45deg);
          display: inline-block;
          -webkit-transition: all 0.2s ease-in;
          -webkit-transform: rotate(45deg);
          transition: all 0.2s ease-in;
        }
      }
      ul {
        display: block;
        position: absolute;
        top: 4.2rem;
        right: $gap-10;
        background: #ddd;
        z-index: 9;
        padding: 6px 6px 4px 0;
        li {
          margin-bottom: $gap-03;
          &.icon:first-child {
            margin-left: $gap-08;
          }
        }
      }
    }
  }

  .mobile-menu-icon {
    display: block;
    background: $color-blue;
    width: 2.8rem;
    height: 2.8rem;
    color: #fff;
    border-radius: $gap-04;
    font-size: 2.4rem;
    text-align: center;
    line-height: 2.5rem;
    font-weight: 400;
    margin-left: 1rem;
  }
  h2 {
    font-size: $font-size-ml;
    line-height: 2rem;
  }
}

.width-100-pr {
  width: 100%;
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.mr-bottom-8 {
  margin-bottom: $gap-08;
}

.mr-bottom-4 {
  margin-bottom: $gap-04;
}

.inline-right {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: $gap-05;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.bg-white.circle {
  background: $color-white;
}

.bg-red.circle {
  background: $color-red;
}

.bg-green.circle {
  background: $color-green;
}

.ant-select-item-empty {
  text-align: center;
}

.filter-patient-autocomplete {
  width: 100%;
  min-width: 15rem;
  .custom-autocomplete .ant-select-clear {
    display: block;
  }
}
.filter-modifier-autocomplete {
  width: 100%;
  min-width: 8rem;
  max-width: 8rem;
  position: relative;
  .custom-autocomplete .ant-select-clear {
    display: block;
  }

  .crosswalk-checkbox-row {
    position: absolute;
    top: 2.5rem;
    overflow: hidden;
    width: 100%;
    .crosswalk-checkbox {
      width: 100%;
      z-index: 1000;
      line-height: 1;
      padding-left: $gap-03;
      border-bottom-left-radius: $gap-03;
      border-bottom-right-radius: $gap-03;
      background-color: $content-dark-subcolor;
      transform: translateY(-8px) scaleY(0);
      transition: all 0.1s ease;
      padding-top: $gap-03;
      padding-bottom: $gap-01;
      span {
        font-size: $font-size-xxm;
      }
      .ant-checkbox {
        transform: scale(0.8);
      }
    }
    .show {
      transform: translateY(-2px) scaleY(1);
    }
  }
}

.encounter-diagnosis-row {
  .filter-modifier-autocomplete {
    width: 100%;
    min-width: 17rem;
    max-width: 17rem;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Track */
.navigation-list::-webkit-scrollbar-track {
  background: #2e79f8;
}

.navigation-list {
  overflow-x: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2ddef;
  box-shadow: inset 0 0 5px #8e9eaf;
  border-radius: 200px;
}

.notes {
  @include flexbox();
  color: $color-blue-light;
  font-size: $font-size-xsm;
  div {
    text-overflow: ellipsis;
    width: 5rem;
    overflow: hidden;
  }
}

.ant-tree-select-dropdown {
  &.insurance-tree-dropdown {
    width: 20rem !important;
    background: $app-background;
    padding-top: 0;
  }
}

.display-none {
  display: none;
}

.content-label {
  font-size: $font-size-xsm;
  color: $color-blue-light;
}
.danger {
  color: $color-red;
}

.ant-notification {
  z-index: 9999 !important;
}

.flex-space-between {
  @include justify-content(space-between);
  flex: 1;
}

.heading-align-center {
  text-align: center;
}

.checkin-modal-form-body {
  .app-table {
    .ant-form-item-explain {
      display: inline-block;
    }
  }
}

.error {
  color: $color-red;
  font-size: $font-size-l;
}

.red-color {
  color: $color-red;
}

.text-bold {
  color: $color-blue-light;
}

.version-section {
  position: absolute;
  bottom: 0;
  right: $gap-10;
  font-size: $font-size-l;
}

.white-space-pre {
  white-space: pre-wrap;
}

.toggle-dr-changes {
  position: absolute;
  right: 7.4rem;
  span {
    display: inline-block;
    margin-right: $gap-12;
  }
}
.max-width-100 {
  form {
    width: 100%;
    max-width: 100%;
  }
}

.table-max-height-350 {
  .app-table {
    max-height: 40rem;
    overflow-y: auto;
  }
}

.table-max-height-200 {
  .app-table {
    max-height: 20rem;
    overflow-y: auto;
  }
}
.table-max-height-500 {
  .app-table {
    max-height: 50rem;
    overflow-y: auto;
  }
}

.table-max-height-600 {
  .app-table {
    max-height: 60rem;
    overflow-y: auto;
  }
}

.table-max-height-800 {
  .app-table {
    max-height: 80rem;
    overflow-y: auto;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}
.text-decoration-underline {
  text-decoration: underline;
}

.max-height-200 {
  max-height: 200px;
  overflow-y: auto;
}

.max-height-400 {
  max-height: 40rem;
  overflow-y: auto;
}
.disabled-btn {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

@media (max-width: $min-tablet) {
  .alert-box-orange {
    max-width: 100%;
    margin-top: $gap-08;
  }
  .insurance-wrapper-all {
    .insurance-aside-wrap {
      .view-mode {
        .form-grid-component {
          .form-grid-column {
            margin-bottom: 8px;
          }
          .form-grid-row {
            display: block;
            .mr-lt-12 {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .schedular-content {
    .month-container {
      width: 1200px;
    }
  }
}

@media (max-width: $mobile) {
  .add-test-type {
    .form-grid-component {
      .justify-content-flex-start {
        flex-wrap: wrap;
        .billing-diagnosis {
          margin-right: 8px;
        }
      }
    }
  }
  #patient-case-form {
    .custom-input .ant-row .ant-form-item-label > label,
    .custom-select .ant-row .ant-form-item-label > label,
    .custom-checkbox .ant-row .ant-form-item-label > label,
    .custom-picker .ant-row .ant-form-item-label > label,
    .custom-radio .ant-row .ant-form-item-label > label,
    .custom-autocomplete .ant-row .ant-form-item-label > label,
    .time-picker-wrap .ant-row .ant-form-item-label > label {
      min-width: 132px;
    }
  }
  .claim-screen {
    & + div {
      .white-tabs {
        .filter-ant-search {
          .ant-input-search {
            min-width: 100% !important;
          }
        }
      }
    }
  }
  .table-filters {
    .filter-components {
      .filter-ant-search {
        .ant-input-search {
          width: 100% !important;
          margin-bottom: 6px;
        }
      }
    }
  }
  .procedures {
    .right-tab {
      .merge-fields-container {
        margin-top: 30px;
        .wrap-expend-collapse {
          margin-top: 30px;
        }
      }
    }
  }
  .name-view {
    font-size: $font-size-xxm;
    left: 11rem;
    top: 8px;
  }
  .m-btn-manage {
    display: block;
    button {
      width: 100%;
      margin-left: 0;
      margin-top: 8px;
    }
  }
  .group-shadow {
    padding: 1rem 0.8rem 0.4rem;
  }
  .tab-filter-align {
    &.filter-area {
      .ant-select {
        width: 100% !important;
      }
      .search-input {
        .ant-col-1 {
          display: none;
        }
      }
      .mr-left-20 {
        margin-bottom: 5px;
        button {
          margin-left: 10px;
        }
      }
    }
  }


  .phone-group {
    position: relative;
    .custom-input {
      & + .custom-input,
      & + .custom-checkbox {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
    .custom-autocomplete,
    .referring-physician-autocomplete-container {
      & + .custom-autocomplete,
      & + .custom-checkbox {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
  }
  #patient-form {
    .custom-input,
    .custom-picker,
    .custom-select {
      .ant-col-9 {
        max-width: 100%;
        flex: 0 0 33.33333333%;
        -webkit-flex: 0 0 33.33333333%;
      }
    }
    .custom-checkbox {
      .ant-col-16 {
        max-width: 100%;
        flex: 0 0 33.33333333%;
        -webkit-flex: 0 0 33.33333333%;
      }
    }
  }
}



@media (max-width: $mobile) {
  .manage-draw-aside-form {
    width: 100% !important;
  }
  .patient-description {
    .technician-tab-wrap {
      .posi-relative {
        .justify-content-flex-end {
          display: block;
          button {
            width: 100%;
            margin-left: 0;
            & + button {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .select-area-box {
    display: block;
    width: 100%;
    button {
      width: 100%;
    }
    & + div {
      display: block !important;
    }
  }
}
.password-strength-meter {
  position: relative;
  height: 10px;
  margin-top: 5px;
}

.password-strength-text {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 12px;
}

.ant-upload-disabled{
  opacity: 0.7;
}

.default-mips-option {
  font-weight: bold;
}


