@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.authorization-list-wrap {
  @include flexbox();
  .custom-list-wrap {
    @include flex(1);
  }
}

.modal-content-area {
  .ant-form {
    .form-grid-component {
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }
  }
}
