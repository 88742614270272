@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.dashboard-legends-container {
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);

  .legends-item {
    @include flexbox();
    @include align-items(center);
    padding: 8px 8px 0 8px;

    .color-item {
      height: 14px;
      width: 14px;
      margin-right: 8px;
    }
    .color-label {
      font-size: $font-size-xsm;
      color: $color-blue-light;
      font-weight: $font-regular;
      line-height: $font-size-xsm;
    }

    .color-item-10min {
      background-color: $color-green;
    }
    .color-item-20min {
      background-color: $yellow-panel-border;
    }
    .color-item-30min {
      background-color:$color-orange;
    }
    .color-item-New {
      background-color: $color-blue;
    }
    .color-item-Cancel {
      background-color: $red-panel-border;
    }
  }
}


@media (max-width: $mobile) {
  .dashboard-widgets-container .component {
    width: 100%;
    margin-right: 0;
  }
}