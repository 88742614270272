@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.new-encounter {
  margin-top: -1.4rem;
  .top-title {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin-bottom: $gap-10;
    h1 {
      margin: 0;
    }
    .btn-group {
      button {
        margin-right: $gap-16;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    h3 {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .ant-form {
    &.patient-encounter-form {
      width: 100%;
      max-width: 100%;
    }
    .info-row {
      label {
        min-width: 13.3rem;
      }
    }
  }
  .top-title{
    h3{
      margin: 0;
    }
  }
  .encounter-actions{
    margin-top: 10px;
  }
  .encounter-rcm-tab {
    border-bottom: 2px solid $button-border;
    padding-bottom: 12px;
  }
}
.manage-list-layout{
  padding: 0 6px 0 2px;
}

.option.headerWrapper {
  &.schedular-option-select {
    width: 100%;
    margin-left: 0;
    padding: 0;
    span {
      padding: 0 $gap-04 $gap-03 $gap-02;
    }
  }
}
.select-doses-modal {
  .ant-modal-content {
    .ant-modal-header {
      padding: 0.8rem 2.4rem 0.8rem 1.6rem;
    }
    .ant-modal-body {
      padding: 1.2rem 2.4rem 1.2rem 2rem;
      .custom-radio {
        .ant-radio-group {
          margin-top: 4px;
        }
      }
    }
    .ant-modal-close-x {
      svg {
        font-size: 1.4rem;
        position: relative;
        top: -2px;
      }
    }
  }
}

.total-charges-row {
  background: $app-background;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: $gap-08 0px $gap-04 $gap-12;
  @include flexbox;
  @include justify-content(space-between);
  .total-amnt {
    @include flexbox;
    .amount {
      margin-right: $gap-16;
      @include flexbox;
      .key {
        font-size: $font-size-xsm;
        color: $color-blue-light;
        margin-right: $gap-08;
      }
      .value {
        font-size: $font-size-xsm;
        color: $color-blue-light;
      }
    }
  }
  .charges {
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
}

.encounter-table {
  margin-top: $gap-08;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  .app-table {
    margin: 0;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .encounter-diagnoses-code {
      min-width: 8rem;
    }
  }
}
.encounter-actions {
  @include flexbox;
  @include justify-content(space-between);
  margin-top: $gap-16;
  .checkbox {
    width: 12.4rem;
  }
  .encounter-btns {
    @include flexbox;
    @include align-items(center);
    button {
      margin-left: $gap-16;
    }
    .dropdown-btn {
      margin-left: $gap-16;
      width: max-content;
      text-transform: uppercase;
      background-color: #32c788;
      border-radius: 0.4rem;
      @include flexbox;
      @include align-items(center);
      .btn {
        margin: 0px;
      }
    }
  }
  .custom-checkbox .ant-row .ant-form-item-label > label {
    line-height: 2.4rem;
  }
}

.encounter-add.add-case {
  cursor: pointer;
  font-size: $font-size-xsm;
  color: $color-blue-light;
  position: relative;
  padding-left: 2.2rem;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background-position: -242px 42px;
    cursor: pointer;
    display: inline-block;
    left: 0;
    top: -2px;
    position: absolute;
  }
}
.dob-row {
  .key {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    margin-right: $gap-08;
  }
  .value {
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
}

.dr-prescription-wrapper {
  .medication-wrap {
    .ocular-area {
      .pannel {
        box-shadow: $box-shadow-base;
        .pannel-body {
          min-height: 25rem;
        }
      }
    }
  }
}

.methometic-pannel {
  .btm-area {
    .ant-dropdown-trigger {
      padding: $gap-02 $gap-04 $gap-03 $gap-05;
    }
  }
}
.wrap-inner-section {
  .btn-small,
  .btn-small:hover,
  .btn-small:active {
    border-radius: $border-radius-base;
    font-size: $font-size-xsm;
  }
  .btn-cancel-small,
  .btn-cancel-small:hover,
  .btn-cancel-small:active {
    color: $content-color;
  }
}
.medication-wrap {
  .imaging-pannel {
    .active {
      background: $background-active;
      width: calc(100% + 16px);
      margin-left: -8px;
      padding: 0 $gap-08;
      .medication-list-content {
        padding: 0 $gap-08;
      }
    }
  }
}

.ant-form {
  .demographic-form {
    .info-row label {
      min-width: 2.5rem;
      @include flexbox();
      @include align-items(center);
    }
    .ant-row {
      &.ant-form-item {
        margin-bottom: $gap-04;
      }
    }
  }
}

.action-btns {
  position: absolute;
  right: $gap-08;
  top: $gap-08;
  span {
    font-size: $font-size-xsm;
    color: $content-color;
    display: inline-block;
    margin-left: $gap-08;
    font-weight: $font-medium;
    cursor: pointer;
    border: 1px solid $color-green;
    border-radius: $border-radius-base;
    padding: $gap-02 $gap-02 0;
  }
}
.demograohic-form-wrap {
  margin-top: 2.4rem;
  .ant-form:focus {
    outline: none !important;
  }
  .form-grid-component:focus {
    outline: none !important;
  }
}

.accordian-btn {
  width: 30px;
  height: 24px;
  position: absolute;
  bottom: 4px;
  right: 8px;
  background: url("../../img/acordian-arrow.png");
  background-position: center;
  cursor: pointer;
  z-index: 99;
}

.add-authorization-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
      padding-left: 3rem;
      .ant-modal-title {
        color: $color-green;
      }
    }
    .ant-modal-body {
      padding-top: 0px;
    }
  }
  .btns-action {
    text-align: right;
  }
}

.add-case-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
      padding-left: 3rem;
      .ant-modal-title {
        color: $color-green;
      }
    }
    .ant-modal-body {
      padding-top: 0px;
    }
  }
  .btns-action {
    text-align: right;
  }
}

.left-tab-section {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background: #fff;
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    background-color: #d6e6f7;
    border-bottom: 1px solid #e8e8e8;
    
    .accordion-title {
      margin: 0;
      // font-weight: 500;
      color: #2a4a79;
      font-size: 12px;
    }

    .accordion-btn {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .arrow-icon {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #666;
        transition: transform 0.3s ease;
      }

      &.expanded .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease-out;
    
    &.expanded {
      padding: 8px;
      max-height: 2000px; // Adjust based on your content
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Optional: Add animation for the content
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.ant-tabs-nav-scroll {
  min-height: 264px;
}

.j-code-ndc-wrap-main {
  box-shadow: $box-shadow-base;
  padding: 1.2rem;
  border-radius: 0.4rem;
  .j-code-ndc-wrap {
    @include flexbox;
    margin-bottom: 0.8rem;
    .key {
      color: $color-blue-light;
      font-size: 1.2rem;
      text-transform: uppercase;
      width: 24%;
    }
    .value {
      font-size: 1.2rem;
    }
  }
  & + .btn-right {
    margin-top: 1.2rem;
    text-align: right;
  }
  .custom-select-box {
    .ant-col {
      &.ant-col-7 {
        max-width: 24%;
        flex: 0 0 24%;
      }
      &.ant-col-20 {
        max-width: 76%;
        flex: 0 0 76%;
      }
    }
  }
}

@media (max-width: $lg-tablet) {
  .tab-info {
    .info-row {
      @include flex-direction(column);
      .info {
        margin: $gap-03 2.5rem $gap-06 3.6rem;
        &:before {
          top: 2px;
        }
      }
    }
  }
  .demograohic-form-wrap {
    margin-top: 3.2rem;
  }
}
@media (max-width: $tablet-medium) {
  .encounter-actions {
    display: block;
    .encounter-btns button {
      margin-left: 0;
      margin-right: $gap-08;
      margin-bottom: $gap-08;
    }
  }
  .new-encounter .top-title {
    margin-bottom: 0;
  }
}

.mr-lt-5 {
  margin-left: $gap-05;
}

.encounter-case.option.headerWrapper span:nth-child(2) {
  flex: 2;
  padding-left: $gap-06;
}
.encounter-case-options.option span:nth-child(2) {
  flex: 2;
  padding-left: 0px;
}

.encounter-case-options.option span + span {
  padding-left: 0px;
}

.check-box-min-width-160 {
  min-width: 16rem;
}
.flask-green {
  background: url("../../img/flaskgreen.png");
  position: absolute;
  width: 12px;
  height: 16px;
  right: -6px;
  top: 13px;
  background-size: cover;
  cursor: pointer;
}
.flask-red {
  background: url("../../img/flaskred.png");
  position: absolute;
  width: 12px;
  height: 16px;
  right: -6px;
  top: 13px;
  background-size: cover;
  cursor: pointer;
}
.flask-lens {
  background: url("../../img/contact-lens.png");
  position: absolute;
  width: 12px;
  height: 16px;
  right: -4px;
  top: 13px;
  background-size: cover;
  cursor: pointer;
}

@media (max-width: $mobile) {
  .accordian-btn {
    bottom: 12px;
  }
  .new-encounter {
    .patient-encounter-form {
      .ant-tabs-mobile {
        .ant-tabs-nav-list {
          display: block;
        }
      }
      .left-tab-section .ant-tabs-nav-wrap .ant-tabs-nav-list {
        min-height: 88px;
      }
    }
  }
  .action-btns {
    top: 54px;
  }
  .encounter-actions {
    .checkbox {
      width: 100%;
    }
  }
  .encounter-btns {
    button {
      width: 100%;
    }
  }
}

.auth-sel-box {
  align-items: center;
  margin-bottom: 0px;
  .ant-row {
    align-items: center;
  }
  .ant-form-item.ant-row {
    margin-bottom: 0px;
  }
  .edit-icon {
    margin-top: 3px;
    margin-right: 2px;
  }
  .add-icon {
    margin-top: 2px;
  }
}
