@import "./vars";
@import "./mixin";

/* ------------- Button css ---------------*/

button {
  &.btn {
    min-height: 3.6rem;
    border:  $gap-01 solid $button-border;
    padding: $gap-06 1.3rem;
    color: $content-color;
    font-size: $font-size-m;
    border-radius: $gap-04;
    outline: none;
    background: transparent;
    font-weight: $font-bold;
    text-transform: uppercase;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
    &:hover{
      filter: brightness(0.8);
    }
    &.min-wt-86 {
      min-width: 8.6rem;
    }
    &.opc-05 {
      opacity: 0.5;
    }
    &.active{
      background: $color-green;
      border: $gap-01 solid $color-green;
      color: $color-white;
      &:hover {
          filter: brightness(1.1);
        }
    }
    &.sm-btn{
      min-height: 2.8rem;
      padding: 0 $gap-08;
      font-size: $font-size-xsm;
      max-height: 2.8rem;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &.btn-success {
    background: $color-green;
    border: $gap-01 solid $color-green !important;
    color: $color-white !important;
    &:hover {
      color: $color-white !important;
      background: $color-green;
      border: $gap-01 solid $color-green !important;
      filter: brightness(1.1);
    }
    &:focus,
    &:active {
      color: $color-white !important;
    }
  }
  &.btn-ghost {
    background: transparent;
    border-color: rgba(60, 108, 179, 0.2);
    color: $color-blue-light;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: rgba(60, 108, 179, 0.2);
      color: $color-blue-light;
    }
        &:hover {
          filter: brightness(0.1);
        }
  }
  &.btn-block {
    display: block;
    width: 100%;
  }

  &.btn-download-template {
    margin-top: 1.5%;
    margin-left: 41.5% !important;
    margin-bottom: 2.7%;
    align-items: center;
    appearance: none;
    background-color: #fff;
    border: 1px solid #0c48ee38;
    border-radius: .375em;
    box-shadow: none;
    box-sizing: border-box;
    color: #202c6d;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: center;
    line-height: 1.5;
    padding: calc(.5em - 1px) 1em;
    position: relative;
    text-align: center;
    user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;


    &:active {
      border-color: #4fe015b7;
      outline: 0;
    }

    &:focus {
      border-color: #6880ecfa;
      outline: 0;
    }

    &:hover {
      border-color: #4fe015b7;

    }

    &:focus:not(:active) {
      box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
    }
  }
}

a {
  &.btn-success {
    &.btn {
      min-height: 3.6rem;
      padding: $gap-06 1.3rem;
      font-size: $font-size-m;
      border-radius: $gap-04;
      outline: none;
      font-weight: $font-bold;
      text-transform: uppercase;
      cursor: pointer;
      background: $color-green;
      border: $gap-01 solid $color-green;
      color: $color-white;
      display: inline-block;
    }
  }
}
.filter-btns-group{
  button{
    &.btn{
      font-weight: $font-medium;
      border-radius: $border-radius-base;
      height: 3.6rem;
    }
  }
  .ant-search{
    margin-top: $gap-04;
    .ant-input-search{
      background: transparent;
      .ant-input{
        background: transparent;
      }
    }
  }
}
/* ------------- End of button css ---------------*/
