@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.patient-list-wrapper {
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  background-color: $color-white;
  height: 100%;
  overflow: hidden;
  .patient-autocomplete {
    width: 100%;
    padding: $gap-08 $gap-08 0;
  }
  .title {
    width: 100%;
    padding: $gap-05 $gap-12;
    margin: 0;
    background: $color-light-grayies;
    height: 2.8rem;
    @include flexbox;
    @include align-items(center);
    border-radius: $gap-04 $gap-04 0 0;
    color: $color-blue-text;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
  }
  .app-table {
    border: none;
    margin: 0;
    box-shadow: none;
    .list-avatar {
      margin-right: $gap-05;
    }
  }
  .search-input-wrap {
    padding: $gap-08 $gap-08 0;
  }
  .table-filters {
    flex-direction: column;
    .filters-container {
      @include flexbox();
      width: 100%;
      .patient-demographics-button {
        padding: o.3rem;
        margin: $gap-08 {
          left: 0;
        }
        &.disabled {
          opacity: 0.7;
        }
        .anticon-profile {
          font-size: $font-size-xl;
        }
      }
    }
  }
  .filter-components {
    width: 100%;
    margin-bottom: 0px;
    padding: $gap-08;
    .filter-ant-search {
      width: 100%;
      margin-right: 0px;
      .ant-search {
        .ant-input-search {
          width: 100% !important;
        }
      }
    }
  }
}

#ba-patient-filters-select-container .ant-select-dropdown.ant-select-dropdown-placement-bottomRight,
#ba-patient-filters-select-container .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  left: auto !important;
  right: 3.0rem !important;
}

.redirect-patient-icon {
  background-position: -64px -107px !important;
  display: inline-block;
  width: 24px;
  height: 24px;
}
