@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.range-date-picker-wrap {
  label {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    width: 9.7rem;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    top: $gap-05;
    position: relative;
  }
  .ant-picker {
    width: 18.1rem;
    height: 2.8rem;
    padding: 0px 1.1rem 0px;
  }
}

.mb-8{
  margin-bottom: 5px;
}

.newEncounter-btn{
  display: block !important;
  text-align: right;
  margin-top: 5px;
}