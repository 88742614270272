@import "../../../../../css/mixin.scss";
@import "../../../../../css/vars.scss";

.insurance-header {
  @include flexbox;
  height: 3rem;
  padding-left: 16px;
  border-bottom: 1px solid $button-border;
  .back-arrow {
    &:before {
      top: 2px;
    }
  }
  @include align-items(center);
  .head-back {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
    margin: 0;
  }
  .head-text {
    @include flexbox;
    .col {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      font-weight: $font-bold;
      margin-left: 40px;
      @include flexbox;

      .key {
        margin-right: 3px;
      }
    }
  }
}

.insurance-pay-detail {
  @include flexbox;
  padding: 10px 16px;
  .insurance-field {
    margin-right: 40px;
    width: 50%;
    .ant-form {
      .form-grid-component {
        grid-row-gap: 0px;
      }
    }
  }
  .insurance-table {
    width: 50%;
  }
}
.action-text {
  @include flexbox;
  margin-top: 16px;
  p {
    color: $color-green;
    font-size: $font-size-m;
    font-weight: $font-medium;
    margin-right: 24px;
    cursor: pointer;
  }
}
.insurance-payment-search {
  @include flexbox;
  @include justify-content(space-between);
  .ok {
    color: $color-green;
    font-weight: $font-medium;
    font-size: $font-size-m;
    margin-left: 16px;
    cursor: pointer;
  }
}

.search-table-wrap {
  padding: 0 16px 24px;
  tfoot {
    tr {
      td {
        &:nth-child(1) {
          span {
            min-width: 72px;
            display: block;
          }
        }
      }
    }
  }
}

.action-drop-value {
  .flex.flex-wrap {
    display: block;
    button {
      width: 100%;
      display: block;
    }
  }
}
.action-filter-value{
  .action-dot {
    font-weight: $font-bold;
    padding-left: 8px;
  }
}
