@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.photo-modal-header {
  padding-right: 4.0rem;
  p {
    margin-bottom: $gap-10;
  }
  button {
    margin-top: -0.6rem;
  }
}

.photo-content-area {
  margin: $gap-16 0 $gap-04;
  .content-area-message {
    .horizontal-content-message {
      text-align: center;
      border-bottom: $gap-01 solid $button-border;
      line-height: 0.1em;
      margin: 1.8rem 0 2.0rem;
      span {
        background: $color-white;
        padding: 0 $gap-10;
      }
    }
    .content-type-message {
      color: #b2b2b2;
      padding: 0px;
      font-size: $font-size-xsm;
      font-weight: normal;
    }
  }
  .ant-form-item-control-input-content {
    .ant-upload-select-picture {
      width: 100%;
      button {
        width: 100%;
        color: $color-blue-light;
        font-weight: normal;
        svg {
          color: $color-green;
          font-size: $font-size-l;
        }
        &:hover {
          color: $color-white;
          border: $gap-01 solid $color-green;
          background-color: $color-green;
          svg {
            color: $color-white;
          }
        }
      }
    }
  }

  .webcam-content-area {
    border: solid $gap-01 #d2ddef;
    border-radius: $gap-08;
    font-size: $font-size-m;
    padding: $gap-04 $gap-15;
    cursor: pointer;
    color: $color-blue-light;
    text-align: center;
    font-weight: normal;
    svg {
      color: $color-green;
      font-size: $font-size-l;
      position: relative;
      top: $gap-01;
    }
    &:hover {
      color: $color-white;
      border: $gap-01 solid $color-green;
      background-color: $color-green;
      svg {
        color: $color-white;
      }
    }
    .webcam-content-message {
      margin-left: $gap-10;
    }
  }
}

.webcam-container {
  margin: $gap-16 0 $gap-04;
  .take-image-container {
    display: flex;
    flex-direction: column;
    .click-image-btn {
      margin-top: $gap-10;
      color: $color-white;
      border: $gap-01 solid $color-green;
      background-color: $color-green;
      svg {
        font-size: $font-size-l;
        position: relative;
        top: $gap-02;
      }
      &:hover {
        color: $color-white;
        border: $gap-01 solid $color-green;
        background-color: $color-green;
        svg {
          color: $color-white;
        }
      }
    }
  }
  .upload-image-container {
    display: flex;
    flex-direction: column;
    .upload-image-btn {
      margin-top: $gap-10;
    }
  }
  #canvas {
    display: none;
  }
}
