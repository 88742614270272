@import "../../../../../../css/vars.scss";
@import "../../../../../../css/mixin.scss";

.dashboard-list {
  min-height: 50px;
  height: 100%;
  overflow-x: auto;
  padding: 5px;
  margin-left: 7px;
  margin-right: 7px;

  .drop-indicator-card {
    width: 100%;
    background-color: $button-border;
    margin-bottom: 8px;
    box-shadow: $box-shadow-base-4;
    border-radius: $border-radius-base;
  }
}

.hovering {
  outline: 5px solid rgba($gray-panel-border, 0.5) !important;
}