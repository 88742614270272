@import "../../css/mixin.scss";
@import "../../css/vars.scss";


.message-area {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    .message {
        .ant-alert {
            font-size: 18px;
        }
    }
}