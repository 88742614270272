@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.dashboard-header-section {
  padding: 0px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  .header-btn {
    margin-left: 10px;
  }
  h2 {
    margin-bottom: 0;
  }
  .mr-lt-40 {
    margin-left: 40px;
  }
  .ant-select-selector{
    height: 2.8rem;
  }
}

@media (max-width: $tablet) {
  .dashboard-header-section {
    display: block;
    h2 {
      margin-bottom: 8px;
    }
  }
}
@media (max-width: $mobile) {
  .dashboard-header-section {
    .mr-lt-40 {
      margin-left: 0;
    }
    .flex {
      &.align-center {
        display: block;
      }
    }
    .ant-form-item-label > label {
      height: 10px;
    }
    .custom-select-box {
      .ant-select {
        width: 100% !important;
      }
    }
  }
}
