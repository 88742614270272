.contract-term-exception {
  .filter-modifier-autocomplete {
    .modifier-autocomplete {
      &.ant-row{
        margin-bottom: 0px;
    
      }
    } 
  }
  .app-table {
   tr {
     td {
      .ant-row {
        .ant-row.ant-form-item {
          margin-bottom: 0px;
        }
        .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
          .ant-col.ant-form-item-control {
            .ant-form-item-explain {
              white-space: pre;
            }
          }
        }
      }
     }
   } 
  }
  &.manage-para-test{
    .app-table{
      table{
        tbody{
          td{
            p{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}