@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.financial-footer {
  color: $color-blue-light;
  font-size: $font-size-xsm;
}

.financial-patient-payment-modal {
  .ant-modal-body {
    .service-table-container {
      .app-table {
        max-height: 40vh;
        overflow: auto;
      }
    }
  }
}

.right-align-financial-charge {
  margin-right: 0px !important;
}

.patient-refund-table-wrapper {
  .app-table {
    max-height: 35rem;
    overflow-y: auto;
  }
}
.footer-count-value{
  margin-top: $gap-08;
  color: $color-blue-light;
  display: inline-block;
}

.insurance-payment-table-wrapper {
  .app-table {
    max-height: 45rem;
    overflow-y: auto;
  }
}

.grid-autoComplete-clear {
  .custom-autocomplete {
    .ant-row {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-select-clear {
              display: inline !important;
            }
          }
        }
      }
    }
  }
}

.charge-table-icons {
  @include flexbox();
  @include justify-content(space-around);
}

.show-reversal {
  color: $color-green;
}

.table-scroll-wrapper {
  .app-table {
    max-height: 40rem;
    overflow-y: auto;
  }
}

.tree-view-wrap {
  max-height: 60rem;
  overflow-y: auto;
}
.financial-detail-table {
  max-width: none;
}

.patient-refund-table {
  max-height: 35rem;
  overflow-y: auto;
}

.payment-table-row {
  background-color: $border-color !important;
}
.min-width-100 {
  .app-table {
    width: 100%;
    min-width: 100%;
  }
}

.financial-label-wrapper {
  font-weight: bold;
  font-size: large;
}

.financial-select-dropdown-wrapper {
  .ant-select-item-group {
    font-size: $font-size-m;
    font-weight: bold;
  }
}

.table-layout-field{
  .display-view{
    padding-bottom: 1rem;
    .right-align-financial-charge{
      width: calc(33.3333% - 0px);
      @include flexbox;
      @include align-items(center);
      height: auto;
      border: 1px solid rgba(0, 0, 0, 0.04);
      margin-bottom: 0;
      border-radius: 0;
      border-top: none;
      border-right: none;
      .label-wrap{
        min-width: 15.2rem;
        width: 15.2rem;
        @include flexbox;
        @include align-items(center);
      }
      .ant-form-item-label{
        min-width: 100%;
        width: auto;
      }
      .ant-form-item{
        padding-left: 8px;
        margin-bottom: 0;   
      }
      &:nth-child(3n + 0){
        border-right: 1px solid rgba(0, 0, 0, 0.04);
      }
      &:nth-child(1){
        border-top: 1px solid rgba(0, 0, 0, 0.04);
      }
      &:nth-child(2){
        border-top: 1px solid rgba(0, 0, 0, 0.04);
      }
      &:nth-child(3){
        border-top: 1px solid rgba(0, 0, 0, 0.04);
      }
      .name-view{
        position: static;
        font-weight: $font-regular;
        font-size: $font-size-s;
        padding-right: 4px;
      }
      &.border-right-only{
        position: relative;
        &::before{
          content: '';
          background: rgba(0, 0, 0, 0.04);
          height: 100%;
          width: 1px;
          position: absolute;
          top: 0;
          right: -1px;
        }
      }
    }
  }
}