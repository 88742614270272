.pverify-pdf-table {
    font-family: "Roboto", sans-serif;
    color: #000000;
    font-size: 12px;
    font-weight: normal;
    table.border-table,
    .border-table th,
    .border-table td,
    .border-table tr,
    .border-table tbody {
        border: 1px solid black;
        border-collapse: collapse;
    }

    table.border-table1,
    .border-table1 th,
    .border-table1 td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    table.prime-table td,
    table.prime-table th {
        border: 1px solid #000000;
        padding: 0.5rem 0.75rem;
        vertical-align: middle;
    }

    table.prime-table {
        /* border: 1px solid #ccc; */
        border-collapse: collapse;
        width: 100%;
    }

    .signature {
        width: 185px;
        text-align: center;
        margin: 0 52% 0 auto;
        padding-top: 12px;
    }

    .signature input,
    .signature input:focus {
        border: none;
        border-bottom: 1px solid #000000;
    }

    .width-25-print {
        width: 25%;
    }
}

/* @page {
    size: 7in 9.25in;
    margin: 8mm 6mm 8mm 6mm;
  } */
@page {
    size: 22cm 29.7cm;
    margin: 8mm 6mm 8mm 6mm;
    /* change the margins as you want them to be. */
}

@media print {
    .signature {
        width: 185px;
        text-align: center;
        margin: 0 16% 0 auto;
    }

    body {
        font-size: 10px;
    }

    .width-25-print {
        width: 40%;
    }

    .footer-on-btm {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    table,
    tbody,
    .prime-table {
        page-break-after: avoid;
        page-break-before: avoid;
        page-break-inside: avoid;
    }
}
