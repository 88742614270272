@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.map-container {
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-base;
    background-color: $color-white;
    padding: 2.4rem 2.0rem;
    height: 100%;
    .map {
      height: 100%;
    }
}