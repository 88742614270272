@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.search-master{
    margin-bottom: $gap-03;
    color: $color-blue-light;
    font-weight: $font-medium;
    font-size: $font-size-m;
    cursor: pointer;
    margin-top: -$gap-04;
}

.right-side-tabs{
    @include flexbox();
    @include justify-content(flex-end);
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
    & + .mr-bt-12{
        margin-top: -5.0rem;
    }
}