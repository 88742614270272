@import "../../../../../css/mixin.scss";
@import "../../../../../css/vars.scss";

.insurance-header {
  @include flexbox;
  height: 3.0rem;
  padding-left: 16px;
  border-bottom: 1px solid $button-border;
  .back-arrow {
    &:before {
      top: 2px;
    }
  }
  @include align-items(center);
  .head-back {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
    margin: 0;
  }
  .head-text {
    @include flexbox;
    .col {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      font-weight: $font-bold;
      margin-left: 40px;
      @include flexbox;

      .key {
        margin-right: 3px;
      }
    }
  }
}
.collection-service-wrap{
  @include align-items(flex-start);
  .group-btns{
    margin-left: 3px;
    button{
      & + button{
        margin-left: 0.8rem;
      }
    }
  }
  .custom-select-box{
    min-width: 19.4rem;
    .ant-row{
      .ant-form-item-label{
        min-width: 6rem;
      }
      .ant-select-selection-item{
        display: inline-block;
        margin-right: 4px;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        max-width: 100px;
      }
    }
  }
  & + .service-table-container{
    margin-top: 0
  }
}

.insurance-pay-detail {
  @include flexbox;
  padding: 10px 16px;
  .insurance-field {
    margin-right: 40px;
    width: 50%;
    .ant-form {
      .form-grid-component {
        grid-row-gap: 0px;
      }
    }
  }
  .insurance-table {
    width: 50%;
  }
}
.action-text {
  @include flexbox;
  margin-top: 16px;
  p {
    color: $color-green;
    font-size: $font-size-m;
    font-weight: $font-medium;
    margin-right: 24px;
    cursor: pointer;
  }
}
.insurance-payment-search {
  @include flexbox;
  @include justify-content(space-between);
  .ok {
    color: $color-green;
    font-weight: $font-medium;
    font-size: $font-size-m;
    margin-left: 16px;
    cursor: pointer;
  }
}

.search-table-wrap {
  padding: 0 16px 24px;
  .patient-payment-list-item-buttons {
    @include flexbox();
    @include justify-content(flex-end);
    margin: 5px 0;
    .btn {
      margin-left: 5px;
    }
  }
}

.patient-name-title {
  font-size: 1.4rem;
  color: #2a4a79;
  margin-bottom: 0px;
  span {
    padding-left: 3px;
    font-weight: bold;
  }
}
