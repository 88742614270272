@import "../../css/vars.scss";

.demographic-wrap {
  .remove-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: cover;
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer;
  }
}

.provider-error-fix{
  .ant-form-item-explain-error{
    padding-left: 17.5%;
  }
}

.new-profile-instant{
  .vertical-radio {
    .ant-radio-group label{
      margin-bottom: 0.6rem;
    }
  }
}