@import "../../../../../../../css/vars.scss";
@import "../../../../../../../css/mixin.scss";

.top-section {
  .search {
    .ant-col-20 {
      max-width: 100%;
    }
  }
}

.add-exception {
  @include flexbox();
  color: $color-blue-light;
  cursor: pointer;
  font-size: $font-size-m;
  font-weight: $font-bold;
  padding-left: 2.4rem;
  position: relative;
  .add-list {
    width: 20px;
    height: 20px;
    background-position: -242px 42px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -3px;
  }
}
.exceptions {
  position: relative;
  .field-row {
    @include flexbox();
    @include justify-content(space-between);
    .col {
      .filter-modifier-autocomplete {
        min-width: auto;
        max-width: none;
      }
      .non-editable {
        &.ant-row {
          .ant-col.ant-form-item-control {
            margin: 0;
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .ant-select-disabled {
                  .ant-select-selector {
                    border: none;
                    cursor: pointer;
                    background: none;
                    padding: 0;
                    font-size: $font-size-xsm;
                    color: $color-blue-light;
                    text-align: center;
                  }
                  .ant-select-arrow {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .lists-wrap {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin-bottom: 0px;
    button {
      width: 12.0rem;
    }
    .effective-filter {
      .ant-form.ant-form-horizontal.ant-form-hide-required-mark.ant-form-middle {
        width: 100%;
      }
    }
  }
  .table-filters {
    @include justify-content(flex-end);
    margin-top: -0.4rem;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .app-table {
    margin: 0;
  }
}

.effective-filter {
  @include flexbox;
  .feild-row.display-view {
    form {
      .fields-wrapper {
        @include flexbox();
        @include justify-content(flex-end);
        max-width: none;
      }
    }
  }
  .search.sm-search-design.search-box-schedule {
    .custom-input {
      max-width: 12.0rem;
      span.ant-input-affix-wrapper {
        input {
          height: initial;
          box-shadow: none;
        }
        span.ant-input-suffix {
          span {
            svg {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
  padding-top: $gap-04;
  .date-from {
    max-width: 25.8rem;
  }
  .date-to {
    max-width: 19.4rem;
    margin-left: $gap-16;
    margin-right: $gap-16;
  }
  .custom-picker .ant-row .ant-form-item-label > label {
    min-width: 8.8rem;
  }
}


.feePreferences-container {
  .field-row {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    .to {
      margin: 0 $gap-05;
    }
  }
  .ant-form.ant-form-horizontal {
    width: 100%;
  }
  table {
    tbody {
      tr {
        td {
          .custom-input {
            .ant-form-item-explain {
              div {
                white-space: break-spaces;
              }
            }
          }
        }
      }
    }
  }
}

.edit-schedule {
  margin: 0 $gap-10;
}

.search-box-schedule {
  margin-left: $gap-10;
}

.main-form-container {
  form.ant-form.ant-form-horizontal {
    max-width: none;
  }
}

.mng-exception-tab {
  &.feePreferences-container {
    .table-filters {
      .table-count {
        margin-bottom: 0;
        @include align-self(center);
      }
    }
  }
}
.feePreferences-container.manage-fre-table {
  .app-table table td {
    .custom-input.non-editable {
      display: inline-block;
      padding-right: $gap-12;
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .exception-description-full {
      .custom-input.non-editable .ant-row .ant-form-item-control .ant-input {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 24.0rem;
        margin-right: $gap-08;
      }
    }
    .exception-description {
      .custom-input.non-editable .ant-row .ant-form-item-control .ant-input {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 12.0rem;
        margin-right: $gap-08;
      }
    }
  }
}
@media (min-width: $medium-desktop) {
  .feePreferences-container.manage-fre-table
    .app-table
    table
    td
    .custom-input.non-editable
    .ant-row
    .ant-form-item-control
    .ant-input {
    width: 100%;
  }
}
@media (min-width: 1840px) {
  .effective-filter {
    .custom-picker .ant-row .ant-form-item-label > label {
      padding-top: 0;
    }
  }
}
@media (max-width: $tablet-medium) {
  .effective-filter .search.sm-search-design.search-box-schedule .custom-input{
    max-width: 100%;
  }
  .exceptions {
    .lists-wrap {
      display: block;
      .effective-filter{
        display: block;
        .search-box-schedule{
          margin-left: 0;
        }
      }
    }
  }
}
