@import '../../../../../../../../css/vars.scss';
@import '../../../../../../../../css/mixin.scss';

.collapse-wrapper {
  margin-top: 16px;
  margin-bottom: 8px;
  .app-table {
    margin: 0;
    border: 0;
  }
  .ant-collapse-header {
    padding: 13px 16px !important;
    background-color: $color-light-grayies !important;
  }

  .no-documents-wrapper {
    @include flexbox();
    padding: 10px;
    min-width: 0;
    max-height: 200px;
    @include justify-content(center);
  }

  .documents-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px,1fr));
    grid-gap: 10px;
    padding: 10px;
    min-width: 0;
    max-height: 200px;
    overflow: auto;
    .document {
      background-color: $color-white;
      border-radius: 6px;
      overflow: hidden;
      padding: 10px 10px 10px 0px;
      @include flexbox();
      height: 40px;
      @include align-items(center);
      color: $color-blue-light;
      border: 1px solid $border-color;
      cursor: pointer;
      text-transform: capitalize;
      .icon {
        padding: 0px 20px;
        font-size: $font-size-xl;
      }
      .document-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  
}

.custom-upload-wrapper{
  .feild-row{
    justify-content: space-between;
  .ant-row{
    margin-right: 24px;
    .ant-col{
      .ant-row{
        margin: -7px;
        padding: 0;
      }
    }
  }
}
}