@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";

.follow-up-container {
  .ant-col-12 {
    & + .ant-col-12 {
      max-width: 8.2rem;
    }
  }
}
.code-value {
  @include flexbox;
  .key {
    color: $content-color;
    font-size: $font-size-xsm;
  }
  .value {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    font-weight: $font-medium;
    margin-left: $gap-08;
    &.fix-code-value {
      width: 88px;
    }
  }
}
.content-filter-area {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: 1.6rem;
  .em-codes {
    width: 100%;
    .ant-radio-group {
      width: 100%;
      @include justify-content(space-between);
      @include flexbox();
      @include flex-wrap(wrap);
    }
  }
  .create-favourite-bundles {
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);
    .favourite-bundles{
      display: flex;
      flex-direction: column;
      min-height: 3.6rem;
      border:  $gap-01 solid $button-border;
      border-radius: $gap-04;
      outline: none;
      .bundle-icons{
        @include flexbox();
        @include justify-content(space-between);  
        cursor: pointer;     
      }
      .bundle-descriptor{
        color: $content-color;
        font-size: $font-size-m;
        background: transparent;
        padding: 0.2rem 1.3rem 0.6rem 1.3rem;
        font-weight: $font-bold;
        text-transform: uppercase;
        cursor: pointer;     
    }
  }
    .ant-radio-group {
      width: 100%;
      @include justify-content(space-between);
      @include flexbox();
      @include flex-wrap(wrap);
    }
  }
}

.modifier-wrap {
  @include flexbox;
  @include align-items(center);
  .modifier-input-wrap {
    @include flexbox;
    form {
      max-width: 30rem;
      overflow-y: hidden;
      overflow-x: auto;
      @include flex-wrap(wrap);
      .input-groups {
        .ant-row.ant-form-item {
          margin-bottom: 0;
        }
      }
    }
    .custom-input {
      flex: 1;
      margin-right: $gap-02;
      .ant-row {
        &.ant-form-item {
          margin-bottom: 0;
        }
      }
    }
    .billing-radio {
      @include flexbox;
      @include align-items(center);

      .label {
        @extend .label;
      }
      .custom-radio-btn > .ant-radio-group > label {
        margin-left: $gap-03;
      }
    }
    button {
      margin-left: 1.4rem;
    }
  }
  .label {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    font-weight: $font-regular;
    margin-left: 8rem;
    margin-right: $gap-08;
  }
}
.billing-table {
  .custom-input {
    margin-right: $gap-02;
    width: 4.8rem;
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .custom-select {
    margin-right: $gap-04;
    width: 9.2rem;
    .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.billing-footer {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-end);
  margin-top: 2.4rem;
  button {
    margin-left: 2rem;
    min-width: 8.6rem;
  }
}

.billing-modal {
  .app-table {
    .filter-modifier-autocomplete {
      .auto-complete-search {
        .ant-select {
          min-width: 7.5rem;
        }
      }
    }
    .loader {
      z-index: 999;
    }
  }
  .modifier-wrap .modifier-input-wrap form {
    max-width: 100%;
    overflow: inherit;
  }
  .modal-content-area {
    .content-filter-area {
      margin-bottom: 0;
      &.filter-wrap {
        margin-bottom: $gap-08;
        & + .content-filter-area {
          margin-bottom: $gap-08;
        }
      }
    }
  }
  .content-filter-area {
    display: block;
    .manage-billing-filter-header {
      @include flexbox();
      .billing-radio {
        margin-left: 2rem;
        @include flexbox();
        .label {
          font-size: $font-size-s;
          color: $color-blue-light;
        }
      }
    }
    .modifier-wrap {
      margin-top: $gap-12;
      .label {
        margin-left: 0;
      }
      .modifier-input-wrap {
        .label {
          margin-left: $gap-12;
        }
      }
    }
  }

  .modifier-wrap .label,
  .modifier-wrap .modifier-input-wrap .billing-radio .label {
    margin-left: 2rem;
  }
  .code-value {
    @include align-items(center);
  }
  .modifier-wrap .modifier-input-wrap {
    @include align-items(center);
  }
  .panel {
    &.follow-up {
      &.posi-relative{
        margin-top: 8px;
        .followup-row .row-2 .ant-select-multiple .ant-select-selection-item-content{
          max-width: 100%;
        }
      }
    }
  }
}
.follow-up-section-wrap {
  max-width: 60rem;
}
.follow-up {
  .follow-up-label {
    align-items: baseline;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #748aac;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #748aac;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #748aac;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #748aac;
    }
    .ant-col-5{
      width: 18%;
      flex: auto;
      max-width: initial;
    }
    .ant-col:first-child{
      width: 6.4rem;
    }
    .custom-input .ant-row .ant-form-item-control .ant-input{
      padding: 0.4rem 0.5rem; 
    }
  }
  .followup-row {
    .custom-select .custom-input {
      .ant-col-10 {
        max-width: 16rem;
        min-width: 16rem;
      }
      .ant-col-14 {
        max-width: 100%;
        flex: 0 0 calc(100% - 16rem);
      }
    }
    .followup-column {
      margin: $gap-08;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-evenly);
      &:first-child {
        @include flex(1);
        max-width: 17rem;
      }

      button {
        white-space: nowrap;
      }
    }
    .row-1 {
      display: block;      
      .column-2 {
        .sm-checkbox {
          margin-left: 1.4rem;
        }
      }
    }
    .row-2 {
      width: 100%;
      .ant-select-multiple {
        .ant-select-selection-item-content {
          max-width: 100px;
        }
      }
    }
    .row-3 {
      @include flexbox;
      @include justify-content(flex-end);
      padding-bottom: $gap-12;
      width: 100%;
      .row-btn-wrap {
        @include flexbox();
        button {
          margin-left: $gap-12;
        }
        & + button{
          margin-left: $gap-12;
        }
      }
      button.btn.sm-btn {
        width: auto;
      }
    }
  }
  .follow-up-check-box {
    @include flexbox;
    padding-left: 16rem;
    .sm-checkbox {
      margin-right: 1.6rem;
    }
  }
  .ant-select-selector {
    height: 2.8rem;
    padding: 0 1.8rem 0 0.6rem !important;
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        @include flexbox();
        @include align-items(center);
        .ant-select-selection-item {
          margin-top: 0.1rem;
        }
      }
    }
    .ant-select-selection-item{
      height: 2.2rem;
      margin-top: 0.2rem;
    }
  }
}

@media (min-width: 1840px) {
  .section-checkbox-wrapper {
    .custom-checkbox .ant-row .ant-form-item-label > label {
      padding-top: 0;
      line-height: 2.4rem;
    }
  }
  .follow-up {
    .follow-up-label {
      .custom-input .ant-row .ant-form-item-label > label {
        padding-top: 0;
        margin-top: -1px;
      }
    }
  }
}

@media (max-width: $tablet-medium) {
  .follow-up {
    .followup-row {
      display: block;
      .row-1 {
        display: block;
        .column-1 {
          margin-right: 12px;
        }
        .column-2 {
          .sm-checkbox {
            margin-right: $gap-08;
            margin-left: 0;
          }
        }
      }
      .row-3 {
        display: block;
        button {
          width: 100%;
          &.btn {
            &.sm-btn {
              width: 100%;
              margin-bottom: 8px;
              & + .sm-btn {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: $max-desktop) {
  .follow-up-container {
    .ant-col-12 {
      & + .ant-col-12 {
        max-width: 50%;
      }
    }
  }
}
