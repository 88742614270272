@import "../../../../../../../css/vars.scss";
@import "../../../../../../../css/mixin.scss";

.edit-worklist-modal {
  .claim-search-box {
    margin-bottom: 15px;
  }
  .line-item-table-wrapper {
    margin: 15px;
  }
}
