@import "../../../css/mixin.scss";
// @import "../../../../../../css/mixin";

.pdfscreens {
    background: #ffffff;
    // padding-top: 20px;
    margin-top: 12px;
}
body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

ul li {
    list-style: none;
}

p {
    margin: 0 0 12px 0;
}

table {
    border-collapse: collapse;
    width: 100%;
}

.main-wrapper {
    max-width: 1140px;
    margin: 0 auto;
}
.main-wrapper ol li {
    list-style: inherit;
}
.date-font-10{
    input{
        font-size: 10px !important;
    }
}

.header-top {
    display: flex;
    display: -webkit-flex;
    @include flexbox();
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-right: 20px;
}

.main-section-wrapper {
    display: flex;
    display: -webkit-flex;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 6px;
    margin-top: -3px;
}

.form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 4px;
    width: 2px;
    height: 5px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.table-wrapper table,
.table-wrapper th,
.table-wrapper td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}

.inner-table th,
.inner-table td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.occupation-wrap {
    border: 1px solid #000000;
    width: calc(100% - 20px);
    padding: 8px;
}

//End braile css

// procedure css

.patient-info h4 {
    margin-top: 0;
}

.patient-info .row p {
    display: flex;
}

.patient-info .row p span {
    display: inline-block;
}

.line {
    border-bottom: 1px solid #000000;
    display: inline-block;
}

.verified-colunm {
    @include flexbox();
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.inner-table .form-group input:checked + label:after {
    top: 4px;
}

.inner-table tr:last-child td {
    border-bottom: none;
}

.inner-table tr td:first-child {
    border-left: none;
}
.inner-table tr td:last-child {
    border-right: 0;
}
.inner-table tr:first-child td {
    border-top: 0;
}

.table-double-border-fixes {
    border: none !important;
}

.main-container {
    max-width: 992px;
    margin: 0 auto;
    text-align: justify;
}

.main-heading {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
}

// ICAF

.para-listing li {
    list-style: unset;
    margin-bottom: 2px;
}

.combine-section {
    .row {
        display: flex;
        display: -webkit-flex;
        font-size: 20px;
        margin-bottom: 16px;
    }
    .left {
        width: 30%;
    }
    .right {
        width: 70%;
    }
}
.doted-style {
    margin-left: 40px;
    li {
        list-style-type: circle;
    }
}

.flex-only {
    display: flex;
    display: -webkit-flex;
}
.flex-wrap-only {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.flex-space-between-type {
    justify-content: space-between;
    -webkit-justify-content: space-between;
}
.flex-center-type {
    justify-content: center;
    -webkit-justify-content: center;
}
.flex-justify-end {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}
.flex-align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}
.flex-align-top {
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    -webkit-align-items: flex-start;
}
.flex-align-bottom {
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}
.flex-direction-col {
    flex-direction: column;
    -webkit-flex-direction: column;
}
.table-wrapper.no-first-border th:first-child,
.table-wrapper.no-first-border td:first-child {
    border-top: none;
    border-left: none;
}
.table-wrapper.no-first-border th:last-child,
.table-wrapper.no-first-border td:last-child {
    border-right: none;
}
.full-width-heading {
    color: #ffffff !important;
}
.full-width-gry-heading {
    color: rgb(21, 81, 150) !important;
}
.separators {
    position: relative;
    &::before {
        content: "";
        background: rgb(21, 81, 150);
        height: 100%;
        width: 1px;
        position: absolute;
        left: calc(50% - 1px);
    }
}
.transform-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    margin-left: -14px;
}
.sign-bill-arrow {
    background: rgb(21, 81, 150);
    color: #ffffff;
    min-width: 164px;
    font-size: 20px;
    font-weight: bold;
    margin-left: -20px;
    padding: 4px 12px;
    position: relative;
    height: 70px;
    border-radius: 0 3px 3px 0;
    &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        border-left: 28px solid rgb(21, 81, 150);
        position: absolute;
        right: -27px;
        top: 0;
    }
}
table.no-borders,
.no-borders th,
.no-borders td {
    border: none;
    border-collapse: collapse;
}
.no-borders th,
.no-borders td {
    padding: 5px;
    text-align: left;
}

.sign-indication {
    position: relative;
    &:before {
        content: "";
        background: url("../../../img/ylw-arrow.png");
        width: 40px;
        height: 28px;
        position: absolute;
        left: -40px;
        background-size: cover;
        top: -2px;
    }
}
// .table-wrapper .yellow-borders table, .table-wrapper .yellow-borders th, .table-wrapper .yellow-borders td{
//     border: 1px solid yellow;
// }

.no-in-border-table {
    border: none;
    td {
        // border: none;
    }
}

.custom-list-dot {
    li {
        position: relative;
        padding-left: 12px;
        &:before {
            content: "";
            width: 8px;
            height: 8px;
            background: rgb(224, 230, 160);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 6px;
        }
    }
}

.two-section-list {
    li {
        width: 50%;
    }
}
canvas.sigCanvas {
    position: relative;
    top: 25px;
}
.consent-form-modal {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000 !important;
    }
    &.ant-modal {
        color: #000000;
    }
}
.pdf-pannel {
    &.page {
        color: #000000;
    }
}
.letter-generation-wrap .main-wrapper.black-heading {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000 !important;
    }
}
.consent-form-modal .full-width-heading {
    color: #ffffff !important;
    font-size: 18px;
}

.consent-form-modal .full-width-gry-blue-text {
    color: rgb(21, 81, 150) !important;
    font-size: 18px;
    text-align: center;
}

// .full-with-heading-bg{
//     width: calc(100% + 80px);
//     position: relative;
//     left: -40px;
// }
.black-heading .form-group.manage-check input:checked + label:after {
    top: 4px;
}
.black-heading .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 3px;
    width: 4px;
    height: 5px;
    border: solid #000;
    border-width: 3px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.black-heading .form-group input + label:before{
    padding: 5px;
}
.black-heading .form-group input:checked + label:before {
    background: transparent;
    border: 1px solid #000;
    padding: 5px;
}

@media print {
    .table-double-border-fixes {
        border-left: 1px solid #000000 !important;
        border-right: 1px solid #000000 !important;
    }
    .full-width-heading {
        width: calc(100% + 80px);
    }
    body {
        background: #ffffff !important;
        color: #000 !important;
    }
    canvas.sigCanvas {
        position: relative;
        top: 22px;
    }
    .consent-form-modal {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #000000 !important;
        }
        &.ant-modal {
            color: #000000;
        }
    }

    .p-f-name {
        width: 94px !important;
    }
    .p-ph-no {
        width: 125px !important;
    }
    .p-address {
        width: 80px !important;
    }
    .print-font-12 {
        font-size: 14px !important;
    }
    .p-zip {
        width: 80px !important;
    }
    .p-weight {
        width: 120px !important;
    }
    .p-height {
        width: 110px !important;
    }
    .p-dob {
        width: 110px !important;
    }
    .p-content {
        width: 330px !important;
    }
    .p-fax {
        width: 180px !important;
    }
    .p-patient-name {
        width: 140px !important;
    }
    .p-date-o-b {
        width: 200px !important;
    }
    .p-addresses {
        width: 90px !important;
    }
    .p-cities {
        width: 50px !important;
    }
    .p-states {
        width: 100px !important;
    }
    .p-zip-code {
        width: 120px !important;
    }
    .p-phone-no {
        width: 220px !important;
    }
    .p-request-date {
        width: 280px !important;
    }
    .black-heading h1,
    .black-heading h2,
    .black-heading h3,
    .black-heading h4,
    .black-heading h5,
    .black-heading h6 {
        color: #000000 !important;
    }
    .black-heading h2 {
        &.full-width-heading {
            color: #ffffff !important;
        }
    }
    .print-gap-top{
        margin-top: 160px !important;
    }
    .height-fix-complete{
        height: 282px !important;
    }
    .print-top-12{
        padding-top: 12px;
    }
}

* {
    -webkit-print-color-adjust: exact !important;
} // print with background color
