@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.time-picker-wrap {
  .ant-row .ant-form-item-label > label {
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
  .ant-picker {
    padding: 0 1.1rem 0;
    .ant-picker-input {
      input {
        line-height: 2.6rem;
      }
    }
  }
}

.time-picker-component {
  .ant-picker-footer {
    display: none;
  }
}
