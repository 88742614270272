@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.v-tab {
  text-align: left;
  .ant-tabs-left {
    .ant-tabs-nav {
      .ant-tabs-tab {
        box-shadow: inset 0 -1px 0 0 #d2ddef;
        margin: 0;
        height: 4.0rem;
        color: $color-blue;
        font-size: $font-size-m;
        text-align: left;
        padding-left: 5.6rem;
        line-height: 2.6rem;
      }
    }
  }
  .ant-tabs-nav,
  .ant-tabs-nav-list {
    &.ant-tabs-nav-animated {
      transform: none !important;
    }

    .ant-tabs-tab-active {
      background: $color-blue;
      color: $color-white;
      font-weight: $font-regular;
      .sprite-img-before {
        &:after {
          content: "";
          width: 32px;
          height: 32px;
          background: $color-green;
          position: absolute;
          z-index: 0;
          left: -16px;
          top: -5px;
          border-radius: $border-radius-base;
        }
      }
      .diagnosis-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -372px 439px;
          left: -12px;
          z-index: 9;
        }
      }
      .history-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -374px 395px;
          left: -12px;
          z-index: 9;
        }
      }
      .cases-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 24px;
          background-position: -372px 351px;
          left: -12px;
          z-index: 9;
        }
      }
      .doctor-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -372px 307px;
          left: -12px;
          z-index: 9;
        }
      }
      .balance-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 24px;
          background-position: -372px 263px;
          left: -12px;
          z-index: 9;
        }
      }
      .demographic-icon {
        &:before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-position: -373px 219px;
          left: -11px;
          z-index: 9;
        }
      }
      .general-icon {
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 24px;
          background-position: 691px 210px;
          left: -40px;
          z-index: 9;
        }
      }
      .credential-icon {
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 691px 590px;
          left: -40px;
          z-index: 9;
        }
      }
      .statement-icon {
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 605px 478px;
          left: -40px;
          z-index: 9;
          background-size: 700px;
        }
      }
      .task-config-icon {
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 607px 431px;
          left: -40px;
          z-index: 9;
          background-size: 700px;
        }
      }
      .charts-icon {
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 690px 443px;
          left: -40px;
          z-index: 9;
        }
      }
      .patients-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -64px;
          left: -40px;
          z-index: 9;
        }
      }
      .billing-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -196px;
          left: -40px;
          z-index: 9;
        }
      }
      .busi-claim-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -240px;
          left: -40px;
          z-index: 9;
        }
      }
      .feature-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -284px -327px;
          left: -40px;
          z-index: 9;
          filter: invert(100%) brightness(1000%);
        }
      }
      .remittance-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -328px;
          left: -40px;
          z-index: 9;
        }
      }
      .collection-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -373px;
          left: -40px;
          z-index: 9;
        }
      }
      .documents-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 690px 176px;
          left: -40px;
          z-index: 9;
        }
      }
      .schedule-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -460px -20px;
          left: -40px;
          z-index: 9;
        }
      }
      .contract-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: -371px -327px;
          left: -40px;
          z-index: 9;
        }
      }
      .pverify-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 694px 362px;
          left: -40px;
          z-index: 9;
        }
      }
      .appoitment-icon{
        &:before {
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 606px 124px;
          left: -40px;
          z-index: 9;
          background-size: 700px;
        }
      }
      .fee-schedule-icon{
        &:before{
          content: "";
          position: absolute;
          width: 27px;
          height: 26px;
          background-position: 606px 219px;
          left: -40px;
          z-index: 9;
        }
      }
    }
    .appoitment-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 557px 124px;
        left: -40px;
        z-index: 9;
        background-size: 700px;
      }
    }
    .fee-schedule-icon{
      &:before{
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 557px 219px;
        left: -40px;
        z-index: 9;
        background-size: 700px;
      }
    }
    .pverify-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 641px 362px;
        left: -40px;
        z-index: 9;
      }
    }
    .contract-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -371px -283px;
        left: -40px;
        z-index: 9;
      }
    }
    .schedule-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -20px;
        left: -40px;
        z-index: 9;
      }
    }
    .diagnosis-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 395px;
        left: -12px;
        z-index: 9;
      }
    }
    .general-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 24px;
        background-position: 637px 210px;
        left: -40px;
        z-index: 9;
      }
    }
    .credential-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 642px 590px;
        left: -40px;
        z-index: 9;
      }
    }
    .statement-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 563px 478px;
        left: -40px;
        z-index: 9;
        background-size: 700px;
      }
    }
    .task-config-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 564px 431px;
        left: -40px;
        z-index: 9;
        background-size: 700px;
      }
    }
    .charts-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 642px 443px;
        left: -40px;
        z-index: 9;
      }
    }
    .patients-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -64px;
        left: -40px;
        z-index: 9;
      }
    }
    .billing-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -196px;
        left: -40px;
        z-index: 9;
      }
    }
    .busi-claim-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -240px;
        left: -40px;
        z-index: 9;
      }
    }
    .feature-icon {
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -284px -329px;
        left: -40px;
        z-index: 9;
      }
    }
    .remittance-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -328px;
        left: -40px;
        z-index: 9;
      }
    }
    .collection-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: -416px -373px;
        left: -40px;
        z-index: 9;
      }
    }
    .documents-icon{
      &:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 26px;
        background-position: 636px 176px;
        left: -40px;
        z-index: 9;
      }
    }
    .history-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 352px;
        left: -12px;
      }
    }
    .cases-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 24px;
        background-position: -329px 308px;
        left: -12px;
      }
    }
    .doctor-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -329px 263px;
        left: -12px;
      }
    }
    .balance-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 24px;
        background-position: -329px 220px;
        left: -12px;
      }
    }
    .demographic-icon {
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-position: -328px 175px;
        left: -12px;
      }
    }
  }
}

.left-tab-section {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: $border-radius-base;
  margin-top: $gap-08;
  background: $color-white;
  &.active {
    position: relative;
    &:before {
      content: "";
      background: $color-light-grayies;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    .ant-tabs {
      height: 32px;
      transition: all 0.2s ease-in;
    }
  }
  .ant-tabs {
    .ant-tabs-left-content {
      padding: $gap-08;
    }
    .ant-tabs-left-bar {
      background: rgba(50, 199, 136, 0.1);
      .ant-tabs-tab {
        padding-top: 1.8rem;
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
    display: none;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
    padding: 0;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
    display: none;
  }
}
.ant-tabs-nav-scroll {
  min-height: 26.4rem;
}
