@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.default-profile-checkbox {
  @include flexbox;
  @include justify-content(flex-end);
  margin-bottom: 0px;
  margin-top: $gap-08;
  padding-right: 0px;
  .sm-checkbox {
    position: absolute;
  }
}

/* ------------- Insurance left pannel css ---------------*/

.insurance-side-bar {
  margin-top: $gap-08;
  width: 25.5rem;
  min-width: 25.5rem;
  border: $gap-01 solid $button-border;
  padding: 1.6rem;
  box-sizing: border-box;
  border-top-right-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
  margin-left: -2.4rem;
  .ant-menu-submenu {
    .ant-menu-sub {
      li {
        &.ant-menu-item {
          &.active {
            padding-left: 4rem !important;
            position: relative;
            color: $color-green;
            &:before {
              content: "";
              width: 3px;
              height: 30px;
              background: $color-green;
              position: absolute;
              top: 6px;
              left: 24px;
            }
          }
        }
      }
    }
    & > .ant-menu {
      background-color: transparent;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: $color-gray-blue;
      &.active {
        background: #eaf1fe;
        span {
          font-size: $font-size-m;
          color: #2e79f8;
        }
      }
    }
  }
  button {
    margin-bottom: $gap-08;
  }
  h2 {
    background: $app-background;
    color: $content-color !important;
    height: 3.6rem;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    font-size: $font-size-xsm;
    width: calc(100% + 3.2rem);
    margin-left: -1.6rem;
    margin-top: 1.6rem;
    font-weight: $font-bold !important;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  ul {
    &.ant-menu {
      box-shadow: none;
      .default {
        box-shadow: none;
      }
    }
    & > li {
      &.ant-menu-submenu {
        position: relative;
        box-shadow: inset 0 1px 0 0 #d2ddef;
        &:first-child {
          box-shadow: none;
        }

        .ant-menu-submenu-title {
          height: 4rem;
          @include flexbox();
          @include align-items(center);
          margin: 0;
          padding-left: 1.5rem !important;
          span {
            color: $color-blue-light;
            font-weight: $font-bold;
          }
          i {
            display: none;
          }
        }
        li {
          height: auto;
        }
        &:before {
          content: "";
          width: 14px;
          height: 8px;
          position: absolute;
          top: 16px;
          right: 15px;
          transition: all 0.2s ease-in;
        }
        &.ant-menu-submenu-open {
          &:before {
            width: 14px;
            height: 8px;
            position: absolute;
            top: 16px;
            right: 15px;
            transform: rotate(180deg);
          }
        }
      }
    }
    ul {
      &.ant-menu-sub {
        width: 100%;
        li {
          height: 4rem;
          padding-left: 2.4rem !important;
          margin: 0 !important;
          width: calc(100% - 34px);
        }
        a {
          display: block;
          padding: 1.8rem $gap-10 3.2rem 2.3rem;
          color: $color-green;
          font-weight: $font-medium;
        }
      }
    }
  }
  li {
    &.ant-menu-submenu {
      &.active-default {
        background: $border-gray;
      }
    }
  }
  .green-shield-image {
    background: url("../../../../img/green-shield.png");
    width: 18px;
    height: 22px;
    position: absolute;
    right: 12px;
  }
  .orange-shield-image {
    background: url("../../../../img/orange-shield2.png");
    width: 20px;
    height: 24px;
    position: absolute;
    right: 12px;
  }
  .red-shield-image {
    background: url("../../../../img/red-shield.png");
    width: 20px;
    height: 24px;
    position: absolute;
    right: 12px;
  }
  .hour-glass-shield-image {
    background: url("../../../../img/timer-sand .png");
    width: 28px;
    height: 28px;
    position: absolute;
    right: 12px;
  }
}
.ant-select-tree-list-holder-inner {
  .add-shield-icon {
    & > span{
      padding-right: 8px;
      & + span{
        position: absolute;
        padding-right: 0;
        top: calc(50% - 10px);
        right: -18px;
        width: 16px;
        height: 18px;
        background-size: cover;
      }
    }
  }
}

.wrap {
  @include flexbox();
  @include flex-direction(row);
}

.policy-invalid-wrapper {
  background-color: $disabled-section-gainsboro;
  color: $color-lightgray-2;
  border-bottom: $gap-01 solid $color-white;
}
.submission-method-input {
  margin-bottom: $gap-04;
}

.list-arrow-wrap {
  position: relative;
  @include flexbox();
  @include align-items(center);
  .ups {
    width: 9px;
    height: 15px;
    margin: 0;
    z-index: 999;
    opacity: 0.5;
    cursor: pointer;
    background: url("../../../../img/up-line-arrow.png");
    &:hover {
      width: 9px;
      height: 15px;
      z-index: 999;
      opacity: 0.5;
      cursor: pointer;
      background: url("../../../../img/uparrow-line-g.png");
    }
  }
  .downs {
    width: 9px;
    height: 15px;
    margin: 0;
    opacity: 0.5;
    z-index: 999;
    cursor: pointer;
    background: url("../../../../img/up-line-arrow.png");
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    &:hover {
      width: 9px;
      height: 15px;
      opacity: 0.5;
      z-index: 999;
      cursor: pointer;
      background: url("../../../../img/uparrow-line-g.png");
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
}

.wrap-up-down {
  @include flexbox();
  width: 16px;
  position: relative;
  left: 10px;
}

.add-input {
  &.add-input-with-icon {
    .custom-select {
      .ant-col-16 {
        max-width: calc(66.66666667% - 14px);
      }
    }
  }
  &.add-edit-icon {
    .edit-icon {
      width: 1.9rem;
      height: 2.8rem;
      right: 20px;
      background: url("../../../../img/pencil-fill2-w.png");
      background-color: #32c788;
      top: 0;
      border-right: 1px solid rgba(225, 225, 225, 0.5);
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
    }
    .add-icon {
      border-radius: 0 0.4rem 0.4rem 0;
      width: 2rem;
      height: 2.8rem;
    }
    .custom-select {
      .ant-col-16 {
        max-width: calc(66.66666667% - 30px);
      }
    }
  }
}
.heading-with-icon {
  @include flexbox;
  h2 {
    + span {
      margin-bottom: 18px;
      margin-left: 10px;
    }
  }
}

@media (max-width: $tablet-medium) {
  .insurance-side-bar {
    min-width: 100%;
    width: 100%;
  }
}
@media (max-width: $tablet-medium) {
  .default-profile-checkbox {
    .sm-checkbox {
      position: static;
    }
  }
}
.green-shield-image {
  background: url("../../../../img/green-shield.png");
  width: 18px;
  height: 22px;
}
.orange-shield-image {
  background: url("../../../../img/orange-shield2.png");
  width: 20px;
  height: 24px;
}
.red-shield-image {
  background: url("../../../../img/red-shield.png");
  width: 20px;
  height: 24px;
}
.hour-glass-shield-image {
  background: url("../../../../img/timer-sand .png");
  width: 28px;
  height: 28px;
}

.disable-insurance-type {
  pointer-events: none;
}
