@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.down-arrow-select {
  background: url("../../img/cross-arrow-left.png");
  width: 8px;
  height: 12px;
  background-position: center;
  transform: rotate(-90deg);
  -webkit-transform: rotate(0deg);
  transition: all 0.2s ease-in;
}
.filter-components {
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
  @include align-items(center);
  .clr-btn {
    cursor: pointer;
    // text-decoration: underline;
    border: $gap-01 solid $button-border;
    height: 2.8rem;
    padding: $gap-04 $gap-12;
    line-height: 2.0rem;
    border-radius: $gap-04;
    &:hover {
        filter: brightness(0.8);
      }
  }
}
.margin-0 {
  margin-bottom: 0;
}

.cus-input-wrap-select {
  height: 3.6rem;
  border-radius: $gap-04;
  border:  $gap-01 solid $border-gray;
  background-color: $color-white;
  display: inline-block;
  position: relative;
  width: 15.9rem;
  margin-right: 1.6rem;
  select {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    padding-left: $gap-12;
    outline: none;
    color: $color-blue-light;
  }
  .down-arrow-select {
    background-position: -113px -200px;
    position: absolute;
    top: 14px;
    right: 12px;
    width: 14px;
    height: 10px;
    pointer-events: none;
  }
}

@media (max-width: $lg-tablet) {
  .filter-components {
    @include flex-wrap(wrap);
    margin-bottom: $gap-08;
    button {
      margin-bottom: $gap-08;
    }
    .filter-ant-search {
      @extend button;
    }
    .filter-select-box {
      @extend button;
    }
  }
  .table-filters {
    .table-count {
      min-width: 9.0rem;
      position: relative;
      top: $gap-03;
    }
  }
}

@media (max-width: $min-tablet) {
  .table-filters {
    .table-count {
      min-width: 9.0rem;
      top: 0px;
    }
  }
}
