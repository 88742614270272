@import "../../../../../../css/mixin.scss";
@import "../../../../../../css/vars.scss";

.side-tabs {
  & + .top-tabs {
    background: $color-white;
    .h-tabs {
      .ant-tabs-nav-wrap {
        border: 1px solid $button-border;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.overview-panel {
  background: $color-light-grayies;
  height: auto;
  @include flexbox;
  @include align-items(center);
  padding: $gap-08 1.6rem;
  h3 {
    font-size: $font-size-xsm;
    margin-bottom: $gap-03;
    line-height: 1.4rem;
    font-weight: $font-bold;
  }
  p {
    font-size: $font-size-xxs + 1;
    color: $color-blue-light;
    margin-bottom: 0;
  }
}
.tab-content-wrapper {
  padding: 0 0.16rem;
}
.panel-border {
  border: o.1rem solid $button-border;
  overflow: hidden;
  box-shadow: none;
  &.service-panel {
    .pannel-body {
      padding-bottom: 0;
    }
  }
}
.overview-panel-details {
  padding-bottom: 4px;
  .row {
    @include flexbox;
    .col {
      @include flexbox;
      width: 30.0rem;
      .key {
        color: $content-color;
        font-size: $font-size-xsm;
        min-width: 14.8rem;
        margin-bottom: $gap-04;
      }
      .value {
        color: $color-blue-light;
        font-size: $font-size-xsm;
      }
    }
  }
}
.panel-wrapper {
  @include flexbox();
  .pannel {
    @include flex(1);
  }
}

.provider-text {
  color: $content-color;
  font-size: $font-size-xxs + 1;
  margin-top: $gap-06;
  margin-bottom: $gap-03;
}
.service-table {
  width: calc(100% + 3.2rem);
  margin-left: -1.6rem;
  .app-table {
    border-radius: 0;
    box-shadow: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    table th.table-data {
        border-bottom: $gap-01 solid $button-border;
        background: $color-light-grayies;
    }
  }
}

.header-with-icon{
    width: 100%;
    @include  flexbox;
    @include justify-content(space-between);
    span{
        @include flexbox;
    }
}


.edit-icon{
    background: url('../../../../../../img/mail-fill.png');
    width: 16px;
    height: 16px;
    background-size: cover;
    cursor: pointer;
}

@media (max-width: $tablet-medium) {
  .overview-panel{
    padding: $gap-08 1.6rem;
    height: auto;
  }
}