@import '../../css/vars.scss';

.with-edit-option {
    .add-input{
        .payer-autocomplete-insurance{
            .ant-select-auto-complete {
                .ant-select-selector {
                    .ant-select-selection-search {
                        .ant-select-selection-search-input {
                            padding-right: $gap-16;
                        }
                    }
                }
            }
        }
    }
}