@import "../../../../../css/vars.scss";
@import "../../../../../css/mixin.scss";

.insurance-mapping-key-table-wrapper {
        .app-table {
          max-height: 36.5rem;
          overflow: auto;
        }
  }

  .map-another-payer-modal{
    .table-container{
      .app-table{
        overflow: auto;
        .infinite-scroll-component {
          overflow: initial !important;
        }
      }
    }
  }