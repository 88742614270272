@import "../../../../../../../css/mixin.scss";
@import "../../../../../../../css/vars.scss";

.follow-up-bind {
    padding: 6px;
    .btm-follow-up {
        display: none;
    }
}
.wrap-inner-panel {
    padding: 6px;
    .followup-row {
        .row-3 {
            @include flex-wrap(wrap);
            @include justify-content(initial);
            button {
                margin: 0 8px 8px 0;
            }
        }
    }
    .ant-collapse-item {
        border-bottom: 1px solid #cccccc !important;
    }
}

.procedures {
    @include flexbox();
    .ae-accordian {
        &.favorite-panel-head {
            margin-top: 4px;
            .ant-collapse-item {
                box-shadow: $box-shadow-base;
                &.ant-collapse-item-active > .ant-collapse-header {
                    background: $color-light-grayies;
                    .ant-collapse-arrow {
                        transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                    }
                    & + .ant-collapse-content {
                        .hhh{
                            padding-left: 0.8rem;
                        }
                        .remove-tree-switchers{
                            .sub-menu-collapse{
                                border-bottom: none;
                                &:last-child{
                                    border-bottom: 1px solid rgba(165, 188, 223, 0.7);
                                }
                            }
                        }
                        .ant-collapse-header {
                          .ant-collapse-arrow {
                            position: absolute;
                            left: 4px;
                          }
                        }
                      }
                }
                .ant-collapse-header {
                    background: $color-white;
                    color: $color-blue-light;
                    font-size: $font-size-m;
                    font-weight: $font-bold;
                    padding: $gap-06 1.8rem $gap-06 1.2rem;
                    .ant-collapse-arrow {
                        background: url("../../../../../../../img/arrow-icons.png");
                        width: 2rem;
                        height: 2rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        right: $gap-10;
                        top: 50%;
                        left: auto;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        margin-top: -$gap-10;
                        transition: transform 0.24s;
                        -webkit-transition: transform 0.24s;
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .side-tab {
        padding: 1.6rem;
        @include flexbox();
        @include flex-direction(column);
        background: $color-white;
        border: $gap-01 solid $button-border;
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
        box-shadow: $box-shadow-base;
        min-width: 40%;
        min-height: 88vh;
        margin-right: 1.6rem;
    }
    .right-tab {
        padding: 1.6rem;
        @include flexbox();
        @include flex-direction(column);
        background: $color-white;
        border: $gap-01 solid $button-border;
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
        box-shadow: $box-shadow-base;
        min-width: 58%;
        min-height: 88vh;
        margin-right: 1.6rem;
    }
    &.procedure-right-section {
        .json-tree-container {
            &.procedure-set-tree-wrap {
                height: 40vh;
                overflow: auto;
                @media (min-width : $lg-desktop){
                    height: 58vh;
                }
                .sub-menu-collapse{
                    max-height: 100%;
                    overflow-y: visible;
                }
            }
            .ap-accordion-tree {
                .right-tree {
                    .ant-collapse-content-box {
                        .ant-collapse {
                            border-bottom: 1px solid rgba(165, 188, 223, 0.7);
                            &:last-child {
                                border-bottom: none;
                            }
                            .right-tree-view {
                                &.procedure-right {
                                    &.ap-tree {
                                        .ant-collapse-item {
                                            padding-left: 2.6rem;
                                            .ant-collapse-content-box {
                                                .positive-relative {
                                                    p {
                                                        padding-left: 1.2rem;
                                                        min-height: 2.2rem;
                                                    }
                                                }
                                                .sub-l-child-group {
                                                    & > div:after {
                                                        left: -8px;
                                                    }
                                                }
                                            }
                                        }
                                        &.inner-sub-child-icon {
                                            .ant-collapse {
                                                .ant-collapse-item {
                                                    .ant-collapse-content {
                                                        .ant-collapse-content-box {
                                                            padding-left: 1rem;
                                                            padding-right: 1.2rem;
                                                            padding-top: 0.5rem;
                                                            .sub-l-child-group {
                                                                & > div {
                                                                    &:after {
                                                                        content: "";
                                                                        width: 15px;
                                                                        height: 2px;
                                                                        background: #cccccc;
                                                                        position: absolute;
                                                                        bottom: 10px;
                                                                        left: -16px;
                                                                    }
                                                                }
                                                            }
                                                            & > div {
                                                                & > div {
                                                                    & > div {
                                                                        border: 1px solid #ccc;
                                                                        padding-left: 3.8rem;
                                                                        margin-bottom: 0.3rem;
                                                                        border-radius: 0.2rem;
                                                                        position: relative;
                                                                        p {
                                                                            padding-left: 0;
                                                                        }
                                                                        &.sub-l-child-group {
                                                                            padding-left: $gap-08;
                                                                            &::before {
                                                                                display: none;
                                                                            }
                                                                            &:after {
                                                                                content: "";
                                                                                width: 2px;
                                                                                height: calc(100% - 8px);
                                                                                background: #ccc;
                                                                                position: absolute;
                                                                                top: -3px;
                                                                                left: -9px;
                                                                            }
                                                                            border: none;
                                                                            & > div {
                                                                                border: $gap-01 solid #cccccc;
                                                                                padding-left: 3.8rem;
                                                                                margin-bottom: $gap-03;
                                                                                border-radius: $gap-02;
                                                                                position: relative;
                                                                                &::before {
                                                                                    content: "";
                                                                                    width: 26px;
                                                                                    height: 100%;
                                                                                    background: #ccc;
                                                                                    position: absolute;
                                                                                    top: 0;
                                                                                    left: 0px;
                                                                                    background-image: url("../../../../../../../img/grid-fill4.png");
                                                                                    background-repeat: no-repeat;
                                                                                    background-position: 2px 0px;
                                                                                    background-size: 84%;
                                                                                }
                                                                            }
                                                                        }
                                                                        &::before {
                                                                            content: "";
                                                                            width: 26px;
                                                                            height: 100%;
                                                                            background: #ccc;
                                                                            position: absolute;
                                                                            top: 0;
                                                                            left: 0;
                                                                            background-image: url("../../../../../../../img/grid-fill4.png");
                                                                            background-repeat: no-repeat;
                                                                            background-position: 2px 0;
                                                                            background-size: 84%;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1440px) {
        .wrap-expend-collapse {
            position: relative !important;
            top: 5px !important;
        }
    }
}
.sub-tree-left-gap {
    .right-tree {
        .ant-collapse-content {
            .ant-collapse-content-box {
                margin-left: 1.6rem;
                .ant-collapse-content-box {
                    margin-left: 0;
                }
            }
        }
    }
}
.json-tree-container {
    .sub-menu-collapse {
        border: 1px solid rgba(165, 188, 223, 0.7);
        & > .right-tree {
            margin-bottom: 0;
            border-bottom: $gap-01 solid #cccccc;
            .ant-collapse > .ant-collapse-item > .ant-collapse-content .ant-collapse-header {
                padding-left: 50px;
                background: url("../../../../../../../img/grid-fill3.png");
                background-repeat: no-repeat;
                background-position: 4px 1px;
                color: #0000008c;   
                padding-bottom: 3px;
                .ant-collapse-arrow {
                    left: 32px;
                }
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-content {
                border-top: none;
                background: $color-white;
            }
            .ant-collapse {
                border-radius: 0;
                .ant-collapse {
                    background: $color-white;
                    background: #f2f7ffb5;
                }
            }
            .right-tree-view {
                &:before {
                    display: none;
                }
                &.gap-on-dx-list {
                    // border: 20px solid red;
                    .pannel-body {
                        &.posi-relative {
                            // padding: 0.6rem 0rem 0.4rem 3.8rem;
                            padding: 0rem 0rem 0rem 3.8rem;
                            .min-tree-node-height{
                                min-height: 2.4rem;
                                .align-text-mid{
                                    @include align-items(center);
                                    @include flexbox();
                                }
                            }
                        }
                    }
                }
                .ant-collapse-content-box {
                    .info-alert{
                        .hhh{
                            padding: 4px 4px 0px 3px;
                        }
                    }
                    .hhh {
                        position: relative;
                        padding: 4px 4px 0px 3px;
                        .positive-relative {
                            border-bottom: none;
                        }
                        .square-line {
                            background: #ffffff;
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 2px;
                            left: -43px;
                            border: solid 1px #000;
                            cursor: pointer;
                            z-index: 9;
                            &:before {
                                content: "-";
                                top: -15px;
                                left: 3px;
                                font-size: 28px;
                                font-style: normal;
                                font-weight: 300;
                                position: absolute;
                            }
                        }
                        & > div {
                            position: relative;
                            border: 1px solid #cccccc;
                            padding-left: 38px;
                            margin-bottom: 4px;
                            border-radius: 2px;
                            .square-line {
                                display: none;
                            }
                            &:first-child {
                                .square-line {
                                    display: block;
                                    display: none;
                                }
                            }
                            &.sub-l-child-group {
                                border: none;
                                padding-left: 6px;
                            }
                            &::before {
                                content: "";
                                width: 8px;
                                height: 0px;
                                background: #000;
                                position: absolute;
                                bottom: 6px;
                                left: -35px;
                            }
                            &::after {
                                content: "";
                                width: 26px;
                                height: 100%;
                                // background: #eaf1fe;
                                background: #ccc;
                                position: absolute;
                                top: 0;
                                left: 0px;
                                background-image: url("../../../../../../../img/grid-fill4.png");
                                background-repeat: no-repeat;
                                background-position: 2px 0px;
                                background-size: 84%;
                            }
                        }
                        p {
                            padding: 4px 0 4px 0;
                            margin: 0;
                            min-height: 2.4rem;
                            // padding-right: 24px;
                        }
                        &:before {
                            content: "";
                            background: #000000;
                            height: calc(100% - 14px);
                            width: 0px;
                            position: absolute;
                            left: 50px;
                            top: 8px;
                        }

                        .sub-l-child-group {
                            & > div {
                                border: 1px solid #ccc;
                                padding-left: 38px;
                                margin-bottom: 4px;
                                border-radius: 2px;
                                .sub-l-child-group {
                                    &.j-code-sub-child{
                                        & > div{
                                            padding-left: 12px;
                                        }
                                    }
                                }
                                &:after {
                                    content: "";
                                    width: 26px;
                                    height: 100%;
                                    // background: #eaf1fe;
                                    background: #ccc;
                                    position: absolute;
                                    top: 0;
                                    left: 0px;
                                    background-image: url("../../../../../../../img/grid-fill4.png");
                                    background-repeat: no-repeat;
                                    background-position: 2px 0px;
                                    background-size: 84%;
                                }
                            }
                            &:before {
                                content: "";
                                width: 2px;
                                height: calc(100% - 6px);
                                background: #cccccc;
                                position: absolute;
                                bottom: auto;
                                left: -8px;
                                top: -5px;
                            }
                            &:after {
                                content: "";
                                width: 0px;
                                height: calc(100% - 14px);
                                background: #000;
                                position: absolute;
                                top: 7px;
                                left: -24px;
                            }
                            & > div::before {
                                content: "";
                                width: 14px;
                                height: 2px;
                                background: #cccccc;
                                position: absolute;
                                bottom: 10px;
                                left: -15px;
                            }
                            span.hide-line {
                                display: inline-block;
                                position: absolute;
                                height: calc(50% - 5px);
                                width: 1px;
                                background: #fff;
                                bottom: 5px;
                                left: -58px;
                            }
                        }
                    }
                }
                .positive-relative {
                    display: block;
                    border-bottom: 1px solid #cccccc;
                    &:last-child {
                        border-bottom: none;
                    }
                }
                &.procedure-right {
                    padding-left: 0px;
                }
            }
            .right-tree-view > div > div > div::before {
                display: none;
            }
        }
    }
    .square-view-box {
        .ant-collapse-header {
            padding-left: 3rem !important;
            background: #eaf1fe !important;
            & + .ant-collapse-content {                
                .ant-collapse-content-box{
                    padding-left: 8px;
                    padding-right: 8px;
                    padding-bottom: 1px;
                }
            }
            .anticon {
                margin-right: 0;
                &.anticon-close-circle{
                    background: url('../../../../../../../img/close-circle.png');
                    width: 15px;
                    height: 15px;
                    border: none;
                    display: inline-block;
                    text-align: center;
                    cursor: pointer;
                    text-indent: -999999px;
                    svg{
                        display: none;
                    }
                }
                svg {
                    font-size: 15px;
                }
            }
        }
        .pannel-body {
            position: relative;
            border: 0.1rem solid #ccc;
            padding-left: 3rem;
            margin-bottom: 0.3rem;
            border-radius: 0.2rem;
            padding-right: 2.4rem;
            color: #2a4a79;
            font-size: 1.2rem;
            font-weight: 700;
            padding-bottom: 0;
            min-height: 2.4rem;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            width: 100%;
            padding-top: 2px;
            &:after {
                content: "";
                width: 26px;
                height: 100%;
                background: #ccc;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url("../../../../../../../img/grid-fill4.png");
                background-repeat: no-repeat;
                background-position: 2px 0;
                background-size: 84%;
            }
        }
    }
    &.order-set-tree-wrap {
        .ap-accordion-tree {
            .right-tree {
                .line-tree-view {
                    .ant-collapse-item {
                        .ant-collapse-content {
                            .ant-collapse-content-box {
                                .right-tree {
                                    .ant-collapse {
                                        .ant-collapse-item {
                                            border-bottom: 1px solid rgba(165, 188, 223, 0.7);
                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .ant-collapse > .ant-collapse-item > .ant-collapse-header{
                    padding-right: 104px;
                    min-height: 24px;
                    .ant-collapse-extra{
                        position: absolute;
                        top: calc(50% - 8px);
                        right: 12px;
                        .mr-top-4{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    &.library-tree-ui {
        border: 0.1rem solid rgba(165, 188, 223, 0.7);
        .line-tree-view {
            .ant-collapse {
                border-bottom: 0.1rem solid rgba(165, 188, 223, 0.7);
                &:last-child {
                    border-bottom: none;
                }
                .ant-collapse {
                    border-bottom: none;
                }
            }
        }
        .ap-accordion-tree {
            background-color: #eaf1fe;
            border: none;
            border-radius: 0;
            .ant-collapse {
                .ant-collapse-item {
                    .ant-collapse-header {
                        background-color: #eaf1fe;
                        padding-left: 3rem;
                        svg {
                            font-size: 1.6rem;
                        }
                    }
                    .ant-collapse-content-box {
                        .hhh {
                            padding-top: 0.8rem;
                            margin-right: 1rem;
                            .positive-relative {
                                display: inline-block;
                                width: calc(100% - 16px);
                                p {
                                    margin: 0.3rem 0 0.4rem;
                                    overflow: hidden;
                                    width: 100%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    display: block;
                                    padding-right: 0;
                                    padding-right: 8px;
                                    min-height: 2.2rem;
                                }
                            }
                            .anticon-copy {
                                position: relative;
                                top: -5px;
                            }
                            & > div {
                                border: 1px solid #ccc;
                                padding-left: 3.8rem;
                                padding-right: 1.6rem;
                                margin-bottom: 0.3rem;
                                border-radius: 0.2rem;
                                position: relative;
                                &.library-tree-list-code{
                                    padding-right: 0;
                                    .positive-relative{
                                        @include flexbox();
                                        width: auto;
                                        min-height: 2.4rem;
                                        @include align-items(center);
                                        .anticon-copy{
                                            top: 0;
                                            padding: 0 3px;
                                        }
                                    }
                                }

                                &:before {
                                    content: "";
                                    width: 26px;
                                    height: 100%;
                                    background: #ccc;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAxQTFRFAAAA////////////OMA7qAAAAAR0Uk5TAMD/v+rGo7UAAAAiSURBVHicY2DABhhXcS1YdYCBCUQ1YKdQAXVUgq11wOoiAIUMIv8MA1h0AAAAAElFTkSuQmCC);
                                    background-repeat: no-repeat;
                                    background-position: 2px 0;
                                    background-size: 84%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.follow-up-bind-green-label {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include align-self(center);
    color: $color-white;
    width: 14px;
    height: 14px;
    background: $color-green;
    border-radius: 50%;
    border: 2px solid $color-green;
    margin-right: 10px;
    font-size: 10px;
    padding-top: 2px;
}

.follow-up-bind-grey-label {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include align-self(center);
    color: $color-white;
    width: 14px;
    height: 14px;
    background: #a8b7ce;
    border-radius: 50%;
    border: 2px solid #a8b7ce;
    margin-right: 10px;
    font-size: 9px;
    padding-top: 1px;
}

.dlt-procedure-icon {
    &.anticon-close-circle {
        background: url("../../../../../../../img/close-circle.png");
        width: 15px;
        height: 15px;
        border: none;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        text-indent: -999999px;
    }
    &.anticon-delivered-procedure {
        svg {
            color: $color-green;
        }
    }
    svg {
        font-size: 16px;
        display: none;
    }
}

.save-order-set {
    .follow-up {
        .panel-body {
            min-height: auto;
        }
    }
}
