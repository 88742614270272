@import "../../css//vars.scss";
@import "../../css//mixin.scss";

.add-synonym-heading {
  color: $color-blue-light;
  margin-bottom: 0px;
  display: inline-block;
  font-size: $font-size-m;
}

.add-synonym-without-code-container {
  text-align: left;
  .no-results {
    color: $color-grey;
  }
  .no-results-message {
    color: $color-blue-light;
  }
  .no-results-warning {
    color: $color-lightgray-2;
    font-size: $font-size-xsm;
  }
  .no-results-actions {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);
    margin-top: $gap-08;
  }
}
