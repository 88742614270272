@import "../../../css/vars.scss";

.activity-logs{
    .title{
      color: #aab3b6;
    font-weight: $font-medium;
    font-size: $font-size-xsm;
    padding-left: 3.2rem;
    padding-bottom: $gap-04;
    padding-top: $gap-04;
      .inner-title {
        margin-bottom: $gap-05;
      }
    }
   .inner-accordian .ant-collapse-content{
      padding-top: $gap-10;
      .inner-accordian .ant-collapse-content{
        padding-top: 0;
      }
        .inner-accordian.ant-collapse-item{
          box-shadow: none;
          border: $gap-01 solid rgba(165, 188, 223, 0.7);
          .ant-collapse-header{
            font-size: $font-size-xsm;
          }
      }
    }
    &.info-accordian .ant-collapse > .ant-collapse-item > .ant-collapse-header{
      padding: 0.6rem 1.6rem;
    }
    .ae-accordian .inner-accordian.ant-collapse-item .ant-collapse-header .ant-collapse-arrow{
      svg{
        display: none;
      }
    }
}