@import "../../../../../css/mixin.scss";
@import "../../../../../css/vars.scss";

.view-mode-field {
    .posi-relative {
        @include flexbox();
        .name-view {
            position: static;
            font-weight: $font-regular;
            font-size: $font-size-s;
            padding-right: 4px;
        }
        .ant-row.ant-form-item {
            min-width: 12.0rem;
            margin-top: -0.5rem;
        }
    }
}
.marked-unbillable{
    white-space: nowrap !important;
}