@import "../../css/vars.scss";
@import "../../css/mixin.scss";

$border-blue: 1px solid $border-gray;
$background-blue: lighten($color-dark-blue, 17);
$font-color-blue: $color-hard-blue;

@mixin component-close-button-style {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border-radius: $border-radius-base-2;
  color: red;
  font-size: $font-size-ml;
  line-height: 1;
  padding: 0 $gap-05;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: all 0.2s;

  .anticon-edit {
    transform: translateY(-2px);
    color: $font-color-blue;
    font-size: $font-size-xs;
  }
}

.sub-special-wrap{
  .ant-select-selection-overflow{
    .ant-select-selection-overflow-item{
      @include flexbox();
      @include align-items(center);
    }
  }
  .custom-select{
    .ant-select {
      &.ant-select-multiple{
        .ant-select-selector{
          .ant-select-selection-item-content{
            max-width: 80px;
          }
        }
      }
    }
  }
}

@mixin single-line-component-style {
  position: relative;
  width: 100%;
  height: 100%;
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(flex-start);
  background-color: $green-panel-background;
  border: 1px solid $color-green;
  border-radius: $border-radius-base-2;

  div {
    padding: $gap-05 $gap-05 $gap-02;
    line-height: 1.2;
    font-size: $font-size-xxm;
    color: $color-blue-light;
    pointer-events: none;

    &.patient-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.patient-info {
      padding-right: $gap-03;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.one-liner {
    @include flex-direction(row);
    @include align-items(center);
    div {
      padding: 0 $gap-05;
    }
  }

  button {
    @include component-close-button-style;
  }
  &.check-in {
    background-color: $green-panel-background;
    border: 1px solid $color-green;
    button {
      background-color: $green-panel-background;
    }
  }
  &.pending {
    background-color: $blue-panel-background;
    border: 1px solid $color-blue;
    button {
      background-color: $blue-panel-background;
    }
  }
  &.canceled {
    background-color: $red-panel-background;
    border: 1px solid $red-panel-border;
    button {
      background-color: $red-panel-background;
    }
  }
  &.pending-checkout {
    background-color: $yellow-panel-background;
    border: 1px solid $yellow-panel-border;
    button {
      background-color: $yellow-panel-background;
    }
  }
  &.check-out {
    background-color: $dark-blue-panel-background;
    border: 1px solid $color-blue;
    button {
      background-color: $dark-blue-panel-background;
      .anticon-edit {
        color: $color-white;
      }
    }
    div {
      color: $color-white;
    }
  }
  &.billed {
    background-color: $dark-green-panel-background;
    border: 1px solid $dark-green-panel-background;
    button {
      background-color: $dark-green-panel-background;
    }
    div {
      color: $color-white;
    }
  }
  &.billedandpendingcheckout  {
    background-color: $light-brown-background;
    border: 1px solid $dark-green-panel-background;
    div {
      color: $color-white;
    }
  }
}

.grid-time-range-container {
  position: relative;
  display: grid;
  overflow: hidden;
  grid-template-columns: 65px auto;

  .grid-time-range-view {
    display: grid;
    grid-auto-rows: $scheduler-row-height;
    border-right: 1px solid $border-gray;

    div:first-child {
      height: 100%;
      width: 100%;
      min-height: 41px;
    }
    
    .timestamp {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      color: $color-blue-light;
      font-size: 11px;
      transform: translateY($scheduler-row-height / -2);
    }
  }
}

.day-view-time-needle {
  width: 100%;
  height: 1px;
  @include flexbox();
  @include align-items(center);
  @include flex(1);
  position: absolute;
  pointer-events: none;
  background-color: red;

  &-head {
    width: 8px;
    height: 8px;
    margin: -4px;
    border-radius: 50%;
    background-color: red;
  }
}

.option.headerWrapper {
  &.schedular-option-select {
    box-shadow: inset 0 -1px 0 0 #d2ddef;
    padding: $gap-05 $gap-12 !important;
    width: 100%;
    margin-left: 0;
    span {
      @include flex(1);
      color: $color-blue-light;
      font-weight: 500;
      font-size: $font-size-xsm;
    }
  }
}
.option.payer-option-renderer {
  span {
    @include flex(1);
    font-size: $font-size-m;
    color: $color-blue-light;
  }
}

.grid-days-container {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  &::-webkit-scrollbar {
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  .grid-container {
    display: grid;
    grid-template-rows: 40px auto;
    &:last-child {
      border-right: 0;
    }

    .affixed-header {
      z-index: 100;
      div:first-child {
        width: 100% !important;
      }
      .ant-affix > .header-container {
        border-bottom: $border-blue;
      }
    }
    .header-container {
      height: 4.0rem;
      @include flexbox();
      @include align-items(center);
      font-size: $font-size-l;
      color: $font-color-blue;
      background-color: $light-green;
      padding: 0 $gap-10;
      z-index: 999;
      &::before{
        content: '';
        width: 1px;
        height: 4rem;
        background: rgba(165, 188, 223, 0.5);
        position: absolute;
        left: -1px;
      }
      .header-name-component {
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        font-size: $font-size-m;
        font-weight: $font-regular;
        color: $color-blue-light;
      }
      .header-date-component {
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        font-size: $font-size-m;
        font-weight: $font-regular;
        color: $color-blue-light;
      }
    }

    .grid-day-view-container {
      display: grid;
      overflow: hidden;
      grid-template-columns: 17px auto;
      border-right: none;

      .grid-location-container {
        display: grid;
        grid-auto-rows: $scheduler-row-height / 2;
        background-color: $light-green;
        overflow: hidden;
        border-top: 1px solid $border-gray;

        .grid-location-view {
          @include flexbox();
          font-size: $font-size-xs;
          width: $scheduler-row-height;
          align-items: center;
          background-color: $light-green;
          .location-hour-wrapper {
            width: 1.7rem;
            .location-hour:first-child {
              border-top: 1px solid $color-hard-blue;
            }
            .location-hour {
              @include flexbox();
              @include align-items(center);
              @include justify-content(center);
              @include flex-direction(column);
              color: $color-black;
              padding: $gap-05 0;
              .location-count {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: $gap-10;
                font-size: $font-size-xsm;
                color: $color-black;
                transform: rotate(180deg);
                writing-mode: vertical-rl;
              }
              .location-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: $gap-10;
                font-size: $font-size-m;
                line-height: 1.15;
                color: $color-black;
                transform: rotate(180deg);
                writing-mode: vertical-rl;
              }
            }
            .location-hour:last-child {
              border-bottom: 1px solid $color-hard-blue;
            }
          }
        }
      }

      .grid-timeslot-container {
        display: grid;
        grid-auto-rows: $scheduler-row-height / 2;
        position: relative;

        .timeslot-view-table {
          width: 100%; // added to fix issue in safari browser
          table-layout: fixed;
          border: 0.5px solid $border-gray;

          tr > td {
            background-color: $color-lightgray;
            height: $scheduler-row-height / 2;
          }

          .free-slot-cell {
            td {
              border-top: 0.5px solid $border-gray;
              background-color: $color-white;
            }
            & ~ .free-slot-cell {
              // sibling rows after the first class occurrence
              &.even-row {
                td {
                  border: 0 {
                    bottom: 0.5px solid $border-gray;
                  }
                }
              }
            }
          }

          .no-location-cell {
            td {
              background-color: $disabled-section-gainsboro;
            }
          }
        }

        .grid-events-container {
          display: grid;
          position: absolute;
          grid-auto-rows: $scheduler-row-height / 2;
          grid-template-columns: 1fr;
          overflow: hidden;
          height: 100%;
          width: 100%;

          .grid-event-collections {
            display: grid;
            pointer-events: none;
          }

          .grid-event-view {
            @include flexbox();
            overflow: hidden;
            align-items: center;
            pointer-events: all;
            .appointment-orange-border{
              border: 1px solid $orange-panel-border !important;
            }
            .grid-event-single-line-component {
              @include single-line-component-style;
            }

            .grid-event-multi-line-component {
              display: grid;
              width: 100%;
              grid-auto-rows: $scheduler-row-height / 2;
              white-space: nowrap;

              .grid-event-multi-line-first-child {
                position: relative;
                pointer-events: all;
                overflow: hidden;
                @include flexbox();
                @include justify-content(space-evenly);
                @include flex-direction(column);
                border-top-left-radius: $gap-05;
                border-top-right-radius: $gap-05;
                border: 1px solid $yellow-panel-border {
                  bottom: 0;
                }
                background-color: $yellow-panel-opc;
                .patient-name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .patient-info {
                  @include flexbox();
                  @include flex-wrap(wrap);
                  @include justify-content(flex-start);
                  @include align-items(space-between);
                  max-height: 25px;

                  span {
                    padding-right: $gap-03;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.2;
                  }
                }

                div {
                  padding: 0 $gap-05;
                  line-height: 1.2;
                  font-size: $font-size-xxm;
                  color: $color-blue-light;
                }

                button {
                  @include component-close-button-style;
                }
              }

              .grid-event-multi-line-etc-child {
                pointer-events: all;
                border: 0 {
                  left: 1px solid $yellow-panel-border;
                  right: 1px solid $yellow-panel-border;
                }
                border-bottom-right-radius: $gap-05;
                background-color: $yellow-panel-opc;
              }

              .grid-event-multi-line-last-child {
                pointer-events: all;
                border-bottom-left-radius: $gap-05;
                border-bottom-right-radius: $gap-05;
                border: 1px solid $yellow-panel-border {
                  top: 0;
                }
                background-color: $yellow-panel-opc;
              }

              .first-child-component-filler {
                border-bottom: 1px solid $yellow-panel-border;
                border-bottom-right-radius: $gap-05;
                transform: translateX(2px) translateY(2px);
              }

              .last-child-component-filler {
                border-top: 1px solid $yellow-panel-border;
                border-top-left-radius: $gap-05;
                transform: translateX(-2px) translateY(-1px);
              }
            }
          }
          .event-tooltip-container {
            position: absolute;
            z-index: 99;
            top: -100px;
            left: -100px;
            padding: $gap-02 $gap-05;
            background-color: $light-green;
            border: 1px solid $color-hard-blue;
            border-radius: $gap-05;

            .outOfSlot {
              color: $color-red;
            }
          }
        }
      }
    }
  }

  .grid-days-empty {
    height: 100%;
    width: 100%;
    background-color: $light-green;
  }
}

.appointment-tooltip-container {
  pointer-events: none;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      p {
        margin-bottom: $gap-08;
      }
      .ant-alert {
        padding: $gap-04 $gap-08;
        .ant-alert-message {
          font-size: $font-size-m;
          margin: 0;
        }
        .ant-alert-description {
          font-size: $font-size-xsm;
        }
      }
    }
  }
  .appointment-orange-border{
    color:$orange-panel-border;
  }
}

.month-container {
  .calendar-week-container {
    @include flexbox();
    background-color: $light-green;

    div {
      @include flex(1);
      padding: $gap-05;
      border: 0 {
        bottom: $border-blue;
        right: $border-blue;
      }
      text-align: center;
      color: $color-blue-light;
      font-size: $font-size-xsm;
      font-weight: $font-regular;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .calendar-day-container {
    @include flexbox();
    @include flex(1);

    .calendar-day-cell {
      @include flex(1);
      padding: $gap-05;
      color: $font-color-blue;
      overflow: hidden;
      border: 0 {
        bottom: $border-blue;
        right: $border-blue;
      }

      &:last-child {
        border-right: 0;
      }

      &:hover {
        background-color: $background-blue;
      }

      .date-header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        line-height: 1;
        padding: $gap-06 0;
        font-size: $font-size-s;
        font-weight: $font-regular;
        color: $color-blue-light;

        .date-bubble {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          background-color: $font-color-blue;
          color: white;
          min-height: 2.0rem;
          min-width: 2.0rem;
          border-radius: 13px;
          padding: $gap-04;
          margin: -4px 0;
        }
      }

      .date-body {
        padding-top: $gap-03;

        .section {
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);
        }

        .label {
          font-size: $font-size-xs;
          font-weight: 300;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &-bold {
            font-size: $font-size-xs;
            font-weight: 500;
          }
        }

        .location {
          text-align: center;
        }

        table {
          background-color: $color-white;
          cursor: default;
          width: 100%;
          border-color: $content-color;

          .dr-name {
            padding-left: $gap-06;
            -webkit-box-shadow: inset 6px 0px 1px -2px;
            box-shadow: inset 6px 0px 1px -2px;
            span {
              color: $font-color-blue;
            }
          }

          .row-on-leave {
            background-color: $disabled-section-gainsboro;
            .dr-name {
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
        }
      }
    }

    .inactive {
      pointer-events: none;
      color: lighten($font-color-blue, 45);
      .date-header > div {
        color: $content-color;
      }

      .date-header > .date-bubble {
        color: lighten($font-color-blue, 45);
        background-color: transparent;
        border: 1px solid lighten($font-color-blue, 45);
      }
    }
  }
}

.grid-event-draggable-view {
  .appointment-orange-border{
    border: 1px solid $orange-panel-border !important;
  }
  .grid-event-single-line-component {
    @include single-line-component-style;
  }
}

.scheduler-title-main {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-direction(row);
  width: 100%;
  height: 4.2rem;
  box-shadow: inset 0 -1px 0 0 $border-gray;
  padding: 0 16px;
  .title {
    flex: 1;
    overflow: hidden;
    padding-left: 2.0rem;
    padding-right: 2.0rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .heading {
    font-weight: $font-medium;
    font-size: $font-size-ml;
    color: $color-blue-light;
    text-align: center;
    text-transform: capitalize;
  }
  .left-side-title {
    @include flexbox();
    .lt-arrow {
      background: url("../../img/arrow-right.png");
      width: 14px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
    }
    .rt-arrow {
      background: url("../../img/arrow-right.png");
      width: 14px;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 8px;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
    button {
      padding: 0 8px;
      border: none;
      min-height: auto;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
    .ant-picker {
      padding: 0 1.1rem 0;
      height: 2.6rem;
      width: 17.0rem;
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .right-side-title {
    @include flexbox();
    button {
      border-radius: $border-radius-base;
      border: solid 1px $border-gray;
      background-color: $light-green;
      color: $color-blue-light;
      font-size: $font-size-s;
      font-weight: $font-regular;
      margin-right: $gap-12;
      min-height: 2.6rem;
      line-height: $gap-14;
      height: 2.6rem;
    }
    .MuiFormControl-root {
      margin-top: 0;
    }
  }
}

.scheduler-header-main {
  @include flexbox();
  @include justify-content(space-between);
  @include flex-direction(row);
  width: 100%;
  .btn {
    margin-top: $gap-10;
  }
  .align-print-icon {
    @include flexbox();
    @include align-items(flex-start);
    ul {
      padding: 0 0 0 $gap-08;
      li {
        &.print-icons {
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }
  }

  .heading {
    font-weight: $font-bold !important;
    margin-bottom: 0;
  }

  .left-side-header {
    @include flexbox();
    padding: 0px;
    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
  }

  .right-side-header {
    @include flexbox();
    .ant-form-horizontal {
      @include flexbox();
      @include flex-wrap(wrap);
    }
    .input-wrap {
      margin-right: $gap-16;
    }
    .doctor {
      .ant-select-selection-item {
        max-width: 9.0rem;
      }
    }

    .MuiFormControl-root {
      margin-top: 0;
    }
    & > div {
      margin-right: $gap-12;
      & + div {
        margin-right: $gap-12;
        & + div {
          margin-right: $gap-12;
          & + div {
            margin-right: 0px;
          }
        }
      }
    }
    .ant-form-item-label {
      label:after {
        content: "";
      }
      .ant-form-item-required {
        &::after {
          content: "*";
        }
        &::before {
          display: none;
        }
      }
    }
    .custom-select-box {
      .ant-select {
        .ant-select-selector {
          height: 2.8rem;
          padding: 0 1.8rem 0 0.6rem;
          .ant-select-selection-search{
            left: 6px;
          }
        }
      }
      .ant-row {
        display: block;
        label {
          height: 20px;
          line-height: 14px;
        }
      }
    }
  }
  h2 {
    margin-bottom: 0;
  }
  .conmbine-btn {
    border: 1px solid $border-gray;
    margin-left: 254px;
    border-radius: $border-radius-base;
    button {
      margin: 0;
      border: none;
      font-size: $font-size-m;
      color: $color-blue-light;
      border-right: 1px solid $border-gray;
      border-radius: 0;
      font-weight: $font-regular;
      padding: 0px $gap-12;
      min-height: 2.8rem;
      & + button {
        border-right: none;
      }
    }
  }

  .schedular-action-btn {
    .btn {
      margin-top: 0;
      &.mr-rt-8 {
        margin-right: $gap-12;
      }
    }
  }
  .option.headerWrapper span:nth-child(2) {
    flex: 2;
    padding-left: $gap-06;
  }
  .option span:nth-child(2) {
    flex: 2;
    padding-left: 0px;
  }

  .option span + span {
    padding-left: 0px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 2.8rem;
  }
  .custom-select-box .ant-form-item-control-input .ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-item{
    margin-top: 0.2rem;
  }
}

.add-appointment-dialog-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  justify-items: end;
}

.radio-switch-tab-group.ant-radio-group {
  margin: 0 $gap-10;
}

.warp-visit-type {
  &.ant-row {
    .ant-form-item-label {
      display: none;
    }
  }
}

@media (min-width: $medium-desktop) {
  .scheduler-header-main {
    @include flex-direction(row);
    margin-bottom: 0;
  }
}

@media (max-width: $lg-tablet) {
  .scheduler-title-main {
    .heading {
      font-size: $font-size-m;
      font-weight: $font-bold;
      max-width: 130px;
      display: inline-block;
    }
  }
  .scheduler-header-main {
    display: block;
    .right-side-header {
      @include flex-wrap(wrap);
    }
  }
}
@media (max-width: $tablet-medium) {
  .scheduler-header-main {
    .right-side-header {
      .input-wrap {
        .ant-select{
          max-width: 100% !important
        }
      }
    }
    .ant-select.ant-select-multiple.ant-select .ant-select-selection-item{
      margin-top: -1.0rem;
    }
  }
  
}

@media (max-width: $min-tablet) {
  .scheduler-title-main {
    @include flex-wrap(wrap);
    padding-bottom: $gap-08;
    @include justify-content(center);
    padding-top: $gap-08;
    height: auto;
    span {
      &.heading {
        margin: 0 $gap-12;
      }
    }
    .right-side-title {
      margin-top: 0;
    }
  }
  .scheduler-header-main {
    .right-side-header {
      .input-wrap {
        label {
          min-width: 7.2rem;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .scheduler-header-main .right-side-header .input-wrap {
    margin-right: 0;
    width: 100%;
  }
  .scheduler-header-main .right-side-header .input-wrap .ant-select {
    width: 100% !important;
  }
  .scheduler-header-main .ant-form-item-label > label {
    height: 1.6rem;
  }
  .scheduler-header-main .right-side-header .schedular-action-btn {
    margin-bottom: 8px;
  }
  .scheduler-title-main {
    .feild-row {
      @include justify-content(center);
      .left-side-title {
        & + {
          button {
            margin-top: $gap-08;
            &.mr-left-20 {
              margin-left: 0px;
              width: 110px;
            }
          }
        }
      }
      &+.title {
        &.heading{
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }
  .scheduler-header-main{
    .align-print-icon{
      position: relative;
      .social-icons{
        &.open-social{
          &.mobile-social {
            ul{
              top: -2px;
            }
          }
        }
      }
    }
  }
}

.schedular-wrapper {
  .ant-affix {
    background-color: $app-background;
  }
}

.schedular-title-wrapper {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: $border-radius-base;
  background: $color-white;
  .ant-radio-button-wrapper {
    padding: 0 $gap-08;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    .payer-option-renderer {
      flex-wrap: nowrap;
    }
  }
}

.appointment-extra-info-menu-container {
  .loader-container {
    padding: 2.0rem 3.5rem 1.6rem;
  }
}

.appointment-table-wrapper {
  .app-table {
    overflow-y: auto;
    max-height: 20.0rem;
  }
}

.schedular-visit-type {
  .custom-select-box {
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 2.8rem;
        border-radius: $gap-04;
        border: 1px solid $color-green;
      }
    }
  }
  .ant-form-item-has-error {
    .ant-select:not(.ant-select-borderless) {
      .ant-select-selector {
        box-shadow: 0 0px 2px 0 #f5222d;
        position: relative;
        &:before{
          content: '*';
          color: #f5222d;
          position: absolute;
          top: 4px;
          right: 28px;
          font-size: 15px;
        }
      }
    }
  }
  .ant-form-item-has-warning {
    .ant-select:not(.ant-select-borderless) {
      .ant-select-selector {
        box-shadow: 0 0px 2px 0 #faad14;
        position: relative;
        &:before{
          content: '*';
          color: #f5222d;
          position: absolute;
          top: 4px;
          right: 28px;
          font-size: 15px;
        }
      }
    }
  }
}
