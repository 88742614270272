@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.provider-screen{
  h2{
    margin-bottom: 0;
  }
}

.snackbar-success > .MuiSnackbarContent-root {
  background-color: $color-green;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bolder;
}

.form-title {
  color: $color-blue-light;
  font-weight: $font-ex-bold;
  font-size: $font-size-xl;
  margin-bottom: $gap-10;
}

.snackbar-error > .MuiSnackbarContent-root {
  background-color: $color-red;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bolder;
}

.provider-file-upload {
  .ant-upload-list-item-name {
    width: 23.0rem;
  }
  .ant-upload-list-item-info {
    img {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
    & > span {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      button {
        margin-right: $gap-08;
        svg {
          color: $color-red-icon;
        }
      }
    }
  }
}
.wrap-payer-option{
  position: relative;
  .custom-autocomplete{
    .ant-col-16{
      max-width: calc(66.66666667% - 50px);
    }
  }
  .custom-checkbox{
    position: absolute;
    width: 68px;
    top: 0;
    right: -18px;
    label{
      position: relative;
      left: 8px;
    }
  }
}
