@import "./vars";
@import "./mixin";

.feild-row {
  @include flexbox();
  @include flex-wrap(wrap);
  .input-wrap {
    width: calc(49.3333% - 3.8rem);
    margin-bottom: $gap-07;
    margin-right: 8.8rem;
    position: relative;
    height: 3rem;
    border-radius: $gap-08;
    .ant-form-item-label {
      min-width: 15.2rem;
      text-align: left;
      width: 15.2rem;
      white-space: normal;
      line-height: 1.8rem;
      & > label {
        &.ant-form-item-required {
          &:before {
            position: absolute;
            top: $gap-08;
            right: -$gap-12;
            color: $color-red;
            font-family: "Roboto", sans-serif;
          }
        }
      }
      label {
        &:after {
          display: none;
        }
      }
    }
    .ant-select-arrow {
      svg {
        color: $content-color;
      }
    }
    .ant-picker-input {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $content-color;
        color: blue;
        font-size: $font-size-s;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $content-color;
        color: blue;
        font-size: $font-size-s;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: $content-color;
        color: blue;
        font-size: $font-size-s;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $content-color;
        color: blue;
        font-size: $font-size-s;
      }
      .ant-picker-suffix {
        svg {
          color: $content-color;
        }
      }
      input {
        color: $color-blue-light;
      }
    }
    .ant-picker {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(165, 188, 223, 0.7);
      height: 2.8rem;
    }
    .ant-form-item-control {
      .ant-input {
        border: 1px solid rgba(165, 188, 223, 0.7);
        height: 2.8rem;
        border-radius: $gap-04;
        color: $color-blue-light;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 2.8rem;
        border-radius: $gap-04;
        border: 1px solid rgba(165, 188, 223, 0.7);
      }
    }
    .ant-select-single {
      .ant-select-selector {
        .ant-select-selection-item {
          line-height: 2.7rem;
          color: $color-blue-light;
        }
        .ant-select-selection-placeholder {
          line-height: 2.8rem;
          color: $content-color;
          font-size: $font-size-s;
          font-weight: $font-regular;
          opacity: 1;
        }
      }
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
    .MuiFormControl-root {
      width: 100%;
      margin: 0;
    }
    .sc-AxjAm {
      height: 4.8rem;
      .sc-AxirZ {
        height: 2.7rem;
        padding: 0 0 0 1.6rem;
        border: none;
      }
    }
    label {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      @include flexbox();
    }
    .sc-AxhCb {
      color: $color-blue-light;
      font-size: $font-size-m;
    }
    .input-active {
      input {
        position: relative;
        top: $gap-06;
        width: 90%;
      }
    }
    &.active {
      border: 1px solid $color-blue;
      label {
        color: $color-blue;
      }
    }
    &.error {
      border: 1px solid $color-red;
      background: $color-red-op;
      label {
        color: $color-red;
      }
      input {
        background: $color-red-op-0;
      }
    }
    .ant-form-item-has-error {
      .ant-form-item-control-input-content {
        .ant-input {
          border-color: $color-border-red;
        }
        .ant-picker {
          border-color: $color-border-red;
        }
      }
    }
  }
  .radio-wraper {
    margin-bottom: $gap-08;
    margin-right: 0px;
    position: relative;
    height: 2.8rem;
    border-radius: $gap-08;
    span {
      color: $color-blue-light;
      font-size: $font-size-m;
      display: inline-block;
      line-height: 1.6rem;
    }
    .ant-radio-inner {
      border-color: rgba(165, 188, 223, 0.9);
      top: -1px;
    }
    .red-star {
      color: $color-red;
      position: absolute;
      left: 4.8rem;
      top: $gap-04;
    }
    .radio-wrap-in {
      @include flexbox();
      @include align-items(center);
      .radio-label {
        min-width: 15.2rem;
      }
    }
  }
  .ant-radio-group-outline {
    @include flexbox();
  }
}
.ant-checkbox-wrapper {
  position: relative;
}
.radio-wraper {
  .ant-checkbox {
    & + {
      padding-left: 0;
    }
  }
  .ant-checkbox-checked {
    &:after {
      display: none;
    }
    .ant-checkbox-inner {
      background-color: $color-green;
      border-color: $color-green;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-green;
  }
  .container {
    position: relative;
  }
  .radio-wraper-in {
    @include flexbox();
    .container {
      width: 8.8rem;
      padding-left: 3rem;
      padding-top: $gap-05;
      font-size: $font-size-xsm;
      &:first-child {
        padding-left: 0;
      }
    }
    .ant-radio-inner {
      &:after {
        background: $color-green;
      }
    }
    .ant-radio-checked {
      &:after {
        border: 1px solid $color-green;
      }
      .ant-radio-inner {
        border-color: $color-green;
      }
    }
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-green;
}
/* ******************************************** */
/* This style is use to set the css of radio group */
.ant-radio-group-outline {
  .ant-radio-wrapper {
    @include align-items(center);
    display: inline-flex;
    display: -webkit-inline-flex;
    margin: 2px 3px 2px 0;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $color-green;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: $color-green;
  }
  .ant-radio-wrapper-checked {
    .ant-radio-input {
      background-color: $color-green;
    }
    .ant-radio-inner {
      border-color: $color-green;
      &:after {
        border: 1px solid $color-green;
        background: $color-green;
      }
    }
    .ant-radio-checked {
      &:after {
        display: none;
      }
    }
  }
}
/* ******************************************** */

.btns-action {
  padding-top: 1.4rem;
  button {
    margin-right: 1.6rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &.mr-bt-16 {
    padding-top: 0;
    margin-bottom: 8px;
  }
}

.container {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 6rem;
    height: 2.4rem;
    left: 0;
    z-index: 999;
  }
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark,
.container input ~ .checkmark {
  background-color: transparent;
  border: $gap-01 solid $content-color;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: transparent;
  border: $gap-01 solid $color-green;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container input:checked + span {
  font-weight: $font-medium;
  color: $color-blue-light;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: $gap-02;
  left: $gap-02;
  width: $gap-12;
  height: $gap-12;
  border-radius: 50%;
  background: $color-green;
}

/* ------------- custom checkbox css ------------------*/
.manage-left {
  padding-left: 8.8rem;
}
.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 1.5rem;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  color: $color-blue-light;
  font-size: $font-size-m;
}
.form-group.manage-check label:before {
  padding: 5px;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: $gap-01 solid $content-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: $gap-07;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: $gap-05;
  top: -$gap-01;
}
.form-group input:checked + label:before {
  background: $color-green;
  border: $gap-02 solid $color-green;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: $gap-03;
  left: $gap-07;
  width: $gap-05;
  height: $gap-09;
  border: solid $color-white;
  border-width: 0 $gap-02 $gap-02 0;
  transform: rotate(45deg);
}

/*------------- select box -----------*/

.MuiFilledInput-underline:after {
  display: none;
}
.input-wrap {
  select {
    height: 4rem;
    width: 100%;
    background: transparent;
    border: none;
    color: $content-color;
    font-size: $font-size-l;
    padding-left: 1.3rem;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    &.MuiSelect-root {
      height: 100%;
    }
  }
  .makeStyles-formControl-1 {
    width: 100%;
    margin: 0;
  }
  .MuiFilledInput-root {
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .MuiSelect-select {
    &:focus {
      background-color: transparent !important;
    }
  }
  .MuiSelect-icon {
    color: #748aac;
    top: $gap-07;
    right: 1.4rem;
  }
  .MuiFilledInput-input {
    padding: 1.9rem 1.6rem $gap-10;
    font-size: $font-size-m;
    color: $color-blue-light;
  }
  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(16px, 11px) scale(1);
    pointer-events: none;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(16px, 5px) scale(0.75);
  }
  .drop-icon {
    background: url(../img/dropdown.png);
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 1.4rem;
    top: $gap-07;
    pointer-events: none;
  }
  .date-icon {
    background: url(../img/calender-icon.png);
    width: 1.8rem;
    height: 2rem;
    position: absolute;
    right: 1.4rem;
    top: $gap-07;
    pointer-events: none;
    background-size: cover;
    z-index: 9;
    cursor: pointer;
  }
}
.MuiFilledInput-underline:before {
  display: none;
}
.MuiSelect-filled.MuiSelect-filled {
  color: $color-blue-light;
}

/* --------------input custome one general-----------*/
.cus-input-wrap {
  height: 3.6rem;
  border-radius: $gap-04;
  border: solid 1px $border-gray;
  background-color: $color-white;
  display: inline-block;
  position: relative;
  width: 15.9rem;
  margin-right: 1.6rem;
  &:last-child {
    margin-right: 0;
  }
  input {
    height: 3.4rem;
    border: none;
    border-radius: $gap-04;
    outline: none;
    padding: 0 4rem 0 $gap-12;
    box-sizing: border-box;
    width: 100%;
    color: $color-blue-light;
  }
  select {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    padding-left: $gap-12;
    outline: none;
    color: $color-blue-light;
  }
  .clock {
    background-position: -110px -371px;
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 1.4rem;
    top: $gap-08;
  }
  .calender {
    position: absolute;
    width: 1.8rem;
    height: 2rem;
    right: 1.4rem;
    top: $gap-08;
    background: url(../img/calender-icon.png);
  }
  .down-arrow {
    background-position: -113px -200px;
    position: absolute;
    top: 1.4rem;
    right: $gap-12;
    width: 1.4rem;
    height: $gap-10;
    pointer-events: none;
  }
  .search {
    background-position: -110px -62px;
    position: absolute;
    top: $gap-04;
    right: $gap-12;
    width: 2.2rem;
    height: 2.2rem;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-blue-light;
    color: orange;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-blue-light;
    color: orange;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-blue-light;
    color: orange;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-blue-light;
    color: orange;
  }
  &.search-box {
    width: 164px;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $content-color;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $content-color;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $content-color;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $content-color;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $color-blue;
}

/* -----------  File Uploader css --------------*/
.custom-uploader-file {
  .ant-row .ant-form-item-label > label {
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
  .ant-row .ant-form-item-control .ant-form-item-control-input {
    height: auto;
  }
  .ant-upload-list-picture .ant-upload-list-item-list-type-picture,
  .ant-upload-list-picture-card .ant-upload-list-item-list-type-picture {
    border-color: $color-green;
  }
  .ant-upload-list-item-list-type-picture,
  .ant-upload-list-item-list-type-picture .ant-upload-text-icon > .anticon,
  .ant-upload-list-item-list-type-picture .ant-upload-list-item-name,
  .ant-upload-list-item-list-type-picture .ant-upload-list-item-card-actions .anticon {
    color: $color-green;
  }
  button {
    &.ant-btn {
      border: 1px solid rgba(165, 188, 223, 0.7);
      border-radius: $border-radius-base;
      font-size: $font-size-xsm;
      font-weight: $font-bold;
      color: $color-blue-light;
      &:hover {
        color: $color-green;
        border-radius: $border-radius-base;
        border: 1px solid $color-green;
        font-size: $font-size-xsm;
        font-weight: $font-bold;
      }
    }
    &.ant-btn-icon-only {
      border: none;
    }
  }
}

.uploads-img {
  width: 100%;
  position: relative;
  margin-bottom: $gap-08;
  .ant-upload {
    &.ant-upload-select {
      position: absolute;
      left: 21.6rem;
      top: 7rem;
      z-index: 999999;
    }
  }
  button {
    border-color: #d2ddef;
    height: 3.6rem;
    border-radius: $gap-08;
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.24);
    color: #748aac;
    font-weight: 600;
    .anticon-upload {
      display: none;
      & + span {
        margin-left: 0;
      }
    }
  }
  .ant-upload-list-item-card-actions {
    display: none;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 20rem;
    height: 14.8rem;
    left: 0;
    top: 0;
    background: #f7f9fb;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    width: 20rem;
    height: 15rem;
  }
  .ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
  .ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 1.8rem;
    height: 15rem;
    width: 100%;
    padding-left: 34.6rem;
    padding-top: 5.6rem;
    color: #748aac;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 15rem;
    padding: 0;
    border: none;
  }
}
.input-wrap.min-height-148 {
  height: 15rem;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}
.ant-upload-list-item-card-actions.picture {
  right: $gap-08;
}
.ant-upload-list-item-thumbnail + .ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1 {
  padding-right: 3.6rem;
}
@media (max-width: $min-tablet) {
  .feild-row {
    .input-wrap {
      width: 100%;
      &:nth-child(3n) {
        margin-right: 2.4rem;
      }
      &:nth-child(2n) {
        margin-right: 0px;
      }
    }
    .radio-wraper {
      &:nth-child(3n) {
        margin-right: 2.4rem;
      }
    }
  }
}

.custom-input .ant-input-number {
  width: 100%;
}

.custom-input .ant-input-number-input {
  height: 2.6rem;
}

.space {
  margin-left: $gap-05;
}
.ant-select-dropdown {
  .search-footer {
    font-weight: $font-bold;
    font-size: $font-size-xsm;
    color: $color-blue-light;
  }
}
