@import "../../css/vars";
@import "../../css/root";
@import "../../css/mixin";

.sm-checkbox {
  .ant-checkbox {
    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        left: 5px;
      }
    }
    & + span {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      font-weight: $font-regular;
    }
    &.ant-checkbox-checked {
      & + span {
        color: $color-blue-light;
      }
    }
  }
  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 2px solid $content-color;
    &:after {
      width: 5px;
      height: 7px;
      left: 2px;
    }
  }
  .ant-checkbox-wrapper {
    width: auto;
    .ant-checkbox-checked {
      .ant-checkbox-inner:after {
        top: calc(50% - 0px);
        left: 1px;
      }
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        border: 2px solid $content-color;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border: 2px solid $color-green;
        }
      }
    }
    &.labelLeft {
      @include flexbox();
      @include align-items(center);
      @include flex-direction(row-reverse);
      @include justify-content(flex-end);
      .ant-checkbox {
        & + span {
          padding-left: 0;
        }
      }
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      &::after {
        top: 50%;
        left: 50% !important;
        width: 4px !important;
        height: 4px !important;
        border: none !important;
        background-color: $color-green;
        border-radius: 0 !important;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: " ";
      }
      border: 2px solid $color-green;
    }
  }
}
