@import "../../../../../../css/vars.scss";
@import "../../../../../../css/mixin";
.denial-code-align-left {
    text-align: left;
}
.table-scroll-wrap{
  &.line-item-table{
    .app-table{
      min-width: auto;
      tbody{
        td{
          .ant-input{
            padding: 2px 4px;
            font-size: $font-size-xs;
            max-width: 62px;
            color: $color-blue-light;
          }
          padding: 0.6rem 0.2rem;
        }
        .lineItem-dos {
          min-width: 78px;
          padding-left: 6px;
        }
      }
    }
  }
}

.denialForm{
  .custom-autocomplete{
    .ant-select-clear {
      display: block !important ;
    }
    .ant-row{
      height: 24px;
      .ant-col-23{
        height: 100%;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 24px;
      }
      .ant-row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 24px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
        height: 24px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height: 24px;
      }
      .custom-autocomplete .ant-row .ant-select-single .ant-select-selector .ant-select-selection-item{
        line-height: 22px;
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: 0 4px;
  }
  .replace-search-arrow .ant-select-clear .anticon-close-circle{
    width: 18px;
    height: 16px;
  }
  
}

.patient-name-title {
  font-size: 1.4rem;
  color: #2a4a79;
  margin-bottom: 0px;
  span {
    padding-left: 3px;
    font-weight: bold;
  }
}

.demographicInfo {
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  // background: #dbd9d93d ;
  border-radius: 5px;
}

.insuranceInput {
  margin-right: 40px
}