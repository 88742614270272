@import '../../../css/vars.scss';
.cpt-icd-custom-table {
  table {
    td {
      padding : 0px !important;
      .backColor {
        background-color: #9d9d9d4f;
        padding:  $gap-07 $gap-07 $gap-07 $gap-12;
      }
      .noBackColor {
         padding:  $gap-07 $gap-07 $gap-07 $gap-12;
      }
    }
  }
}
