@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.ant-pagination {
  display: none;
}
.app-table {
  .ant-table-tbody > tr .ant-table {
    margin: -7px -6px -7px 33px;
  }
  .ant-table-content {
    .ant-table-content {
      tr {
        th:nth-child(2) {
          padding-left: $gap-02;
        }
        td:nth-child(2) {
          padding-left: $gap-02;
        }
      }
    }
  }
  &.collapse-cus-table {
    table th :last-child,
    .app-table table td:last-child {
      border-right: $gap-01 solid #40a9ff;
    }
  }
  .ant-table-thead > tr > th {
    background: $color-white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }
}

.add-icon {
  &.cpt-add {
    width: 20px;
    height: 20px;
    background-position: -242px 42px;
    cursor: pointer;
    display: inline-block;
    left: 0;
    top: -3px;
  }
}

.app-table table tbody tr td {
  height: 2.8rem;
  .custom-checkbox {
    height: 2.8rem;
  }
}
.cpt-table-wrap {
  &.app-table {
    table {
      thead {
        tr {
          th {
            height: 2.8rem;
            padding: 0px $gap-04;
            &:first-child {
              padding-left: $gap-16;
              min-width: 10.0rem;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0px $gap-07;
            &:first-child {
              padding-left: $gap-16;
              min-width: 10.0rem;
            }
          }
        }
      }
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .custom-input .ant-row .ant-form-item-control .ant-input {
      height: 2.2rem;
    }
    .search-input {
      .custom-input {
        input {
          padding-right: 2.4rem;
          padding-left: $gap-08;
        }
      }
    }
    .custom-input {
      input {
        padding-left: $gap-08;
        padding-right: $gap-08;
      }
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        top: $gap-02;
      }
    }
  }
}

