@import "../../css/vars";
@import "../../css/root";
@import "../../css/mixin";

.custom-radio-btn {
  @include flexbox();
  & ~ .custom-radio-btn{
    margin-top: 6px;
  }
  .ant-radio-wrapper {
    display: inline-flex;
    display: -webkit-inline-flex;
    span {
      color: $color-blue-light;
      font-size: $font-size-xsm;
      display: inline-block;
    }
  }

  .custom-radio {
    .ant-row .ant-form-item-label > label {
      font-size: $font-size-xsm;
      color: $color-blue-light;
    }
  }
  label{
    &.ant-form-item-no-colon{
        font-size: $font-size-xsm;   
        margin-right: 6px;     
    }
  }
}
