@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.ae-accordian {
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: $border-radius-base;
  }
  .ant-collapse-content {
    & > .ant-collapse-content-box {
      padding: 0;
    }
  }
  &.ant-collapse {
    border: none;
  }
  .inner-accordian {
    &.ant-collapse-item {
      border-bottom: none;
      box-shadow: $box-shadow-base;
      &.ant-collapse-item-active {
        & > .ant-collapse-header {
          background: $color-light-grayies;
          .ant-collapse-arrow {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
          }
        }
      }
      .ant-collapse-header {
        background: $color-white;
        color: $color-blue-light;
        font-size: $font-size-m;
        font-weight: $font-bold;
        padding: $gap-06 1.6rem;
        .ant-collapse-arrow {
          background: url("../../img/arrow-icons.png");
          width: 2.0rem;
          height: 2.0rem;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          right: $gap-10;
          top: 50%;
          left: auto;
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          margin-top: -$gap-10;
          transition: transform 0.24s;
          -webkit-transition: transform 0.24s;
          svg {
            display: none;
          }
        }
      }
    }
  }
}

.group-accordians {
  margin-top: $gap-06;
  .accordian-row {
    @include flexbox();
    margin-bottom: $gap-08;
    .ae-accordian {
      @include flex(1);
      margin-right: $gap-08;
    }
  }
}
.p-verify-wrap {
  .note-accordion {
    .ant-pagination {
      width: 40.0rem;
      li {
        width: 2.4rem;
        &.ant-pagination-item {
          min-width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
        }
        &.ant-pagination-prev,
        &.ant-pagination-next,
        &.ant-pagination-jump-prev,
        &.ant-pagination-jump-next {
          line-height: 2.2rem;
          min-width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
  .ant-collapse {
    .note-accordion {
      border: $gap-01 solid #d2ddef;
      border-radius: $gap-04;
      margin-top: $gap-08;
      .ant-collapse-content {
        padding: $gap-08;
      }
    }
  }
  .p-verify-content {
    font-size: $font-size-xsm;
    color: #2a4a79;
    padding-top: $gap-08;
    max-height: 10.0rem;
    overflow: auto;
    padding-right: 3.0rem;
    padding-bottom: $gap-08;
  }
}

.p-verify-pagination {
  @include flexbox();
  @include justify-content(flex-end);
  .ant-pagination {
    @include flexbox();
    @include justify-content(flex-end);
  }
}
.note-edit-icon {
  background: url("../../img/pencil-fill.png");
  width: $gap-12;
  height: $gap-12;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
  background-size: cover;
}

@media (max-width: $mobile) {
  .p-verify-pagination {
    .ant-pagination{
      @include justify-content(flex-start);
      .ant-pagination-item {
        a{
          padding: 0 2px;
        }
      }
    }
  }
}