@import "../../../../../css/vars.scss";
@import "../../../../../css/mixin.scss";

.procedure-section-form {
  .diagnostic-icd-component {
    .app-table {
      border: none;
      box-shadow: none;
      max-height: 20rem;
      overflow: auto;
    }
  }
  color: $color-blue-light;

  .custom-autocomplete {
    .ant-row {
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }

  .procedure-heading {
    margin-bottom: $gap-08;
    margin-top: $gap-08;
    p {
      font-size: $font-size-xsm;
      color: $color-blue-light;
      font-weight: $font-medium;
      &.gree-heading {
        color: $color-green;
        cursor: pointer;
      }
      .delete-icon {
        margin-left: $gap-10;
        cursor: pointer;
        svg{
          color: $color-green;
          font-size: 1.3rem;
        }
      }
    }
  }
  .ant-form-item-control {
    .ant-form-item-control-input {
      height: auto;
      .ant-form-item-control-input-content {
        height: auto;
      }
    }
  }

  .single-line {
    @include flexbox();
    @include flex-direction(row);
    margin-bottom: $gap-08;
    color: $color-blue-light;
    font-size: $font-size-xsm;
    &.input-fields-section {
      .custom-radio {
        width: 100%;
        .ant-col-3 {
          max-width: 15%;
          flex: 0 0 15%;
        }
      }
    }
    &.custom-width-24 {
      .flex {
        span:not(.mr-lt-12) {
          .custom-select {
            .ant-col-20 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    .custom-label {
      @include flexbox();
      white-space: nowrap;
      width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 3rem;
      @include align-items(center);
      @include justify-content(center);
    }
    .w-146 {
      width: 14.6rem;
      min-width: 6.5rem;
    }
    .mr-28 {
      margin-right: 2.8rem;
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .align-left {
      @include justify-content(left);
    }
  }
  .btn-procedure-group {
    @include flexbox();
    @include flex-wrap(wrap);
    .procedure-btn {
      margin: $gap-08 $gap-08 $gap-08 0;
      & + .mr-top-8{
        button{
          margin-right: $gap-08;
        }
      }
    }
  }

  .procedure-btn {
    height: 2.8rem;
    border-radius: $border-radius-base;
    border: $gap-01 solid $border-gray;
    background-color: $light-green;
    font-size: $font-size-xsm;
    color: $color-blue-light;
    padding: $gap-06 $gap-08;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    cursor: pointer;
  }
  .procedure-save-btn {
    height: 2.8rem;
    border-radius: $border-radius-base;
    border: $gap-01 solid $border-gray;
    background-color: #32c788;
    font-size: $font-size-xsm;
    color: #fff !important;
    padding: $gap-06 $gap-08;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    cursor: pointer;
  }
}

.procedure-tab-table-wrap {
  max-height: 300px;
  overflow: auto;
  .table-container {
    tbody {
      td {
        span {
          &.flex-dir-row {
            white-space: normal;
          }
        }
      }
    }
  }
}

.warning-date {
  color: $content-color;
}

.procedure-line {
  .custom-input {
    margin-right: $gap-08;
  }
  .custom-input:last-child {
    margin-right: 0;
  }
}
.procedure-modal-area {
  .modal-area {
    min-height: auto;
    .single-line-wrap {
      @include flexbox;
      @include flex-direction(column-reverse);
      .single-line {
        &.input-fields-section {
          border: 0.1rem solid rgba(165, 188, 223, 0.7);
          padding: 2rem 2rem 1.2rem 1.2rem;
          position: relative;
          border-radius: 0.4rem;
          background: #f2f7ff7a;
          .info-row {
            position: static;
            &:nth-child(odd) {
              padding-right: 1.2rem;
            }
            &:last-child {
              padding-right: 0;
            }
            .delete-icon.manage-dlt-icon {
              position: absolute;
              right: 4px;
              top: 4px;
              font-size: 1.4rem;
              color: $color-green;
            }
          }
        }
      }
    }
  }
}

.input-fields-section {
  @include flex-wrap(wrap);
  .info-row {
    width: 50%;
    .custom-input,
    .custom-select {
      width: 100%;
    }
  }
}
