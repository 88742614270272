@import "../../../../css/vars.scss";
@import "../../../../css/mixin.scss";

.list-wrapper {
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  background-color: $color-white;
  padding: 0 2.4rem 1.6rem 2.4rem;
  .text {
    color: $color-blue-light;
    font-size: $font-size-xsm;
    line-height: $font-size-ml;
  }
  .ant-list-items {
    .ant-list-item {
      padding: 2.4rem 0;
      &:first-child {
        padding: 1.6rem 0 2.4rem 0;
      }
      &:last-child {
        padding: 2.4rem 0 0 0;
      }
    }
  }
  .list-item {
    @include flexbox();
    flex-direction: column;
    flex: 1;
    .header {
      margin-bottom: $gap-12;
      @include flexbox();
      @include justify-content(space-between);
      .doctor-basic-info {
        @include flexbox();
        .text-info {
          @include flexbox();
          flex-direction: column;
          margin-left: $gap-10;
        }
      }
    }
    .content {
      .distance-info {
        font-size: $font-size-xsm;
        min-width: 9.2rem;
        margin-left: $gap-08;
        text-align: right;
        color: $content-color;
      }
      .text {
        margin-bottom: 2.0rem;
      }
    }
    .footer {
      @include flexbox();
      @include justify-content(space-between);
      position: relative;
      a {
        color: $color-green;
        font-size: $font-size-m;
        text-transform: uppercase;
        font-weight: $font-medium;
        @include flex(1);
        padding-left: 2.2rem;
        &.profile {
          span {
            position: relative;
          }
          &:before {
            content: "";
            background: url("../../../../img/account-circle.png");
            width: 16px;
            height: 16px;
            position: absolute;
            top: 3px;
            left: 0;
            background-size: cover;
          }
        }
        &.direction {
          span {
            position: relative;
            &:before {
              content: "";
              background: url("../../../../img/map-pin.png");
              width: 16px;
              height: 20px;
              position: absolute;
              top: -2px;
              left: -24px;
              background-size: cover;
            }
          }
        }
      }
      .vr-seperator {
        position: relative;
        text-align: left;
        &:after {
          content: "";
          position: absolute;
          height: 20px;
          width: 1px;
          background: $button-border;
          right: 0;
          top: 1px;
        }
      }
    }
    .align-right {
      margin-left: 1.5rem;
    }
  }
}
.doctor-list-section {
  .doctor-basic-info {
    .ant-avatar-circle {
      border: 1px solid $color-blue;
      min-width: 5.0rem;
    }
  }
}

@media (max-width: $lg-tablet) {
  .list-wrapper {
    padding: 0 $gap-12 1.6rem $gap-12;
    .text-info {
      h3 {
        font-size: $font-size-l;
        margin-bottom: 0;
      }
    }
    .list-item {
      .header {
        .doctor-basic-info {
          @include align-items(center);
        }
        button {
          &.btn-success {
            min-height: 2.8rem;
            padding: 0px $gap-08;
            font-size: $font-size-xsm;
          }
        }
      }
      .footer {
        a {
          font-size: $font-size-s;
        }
      }
      .content {
        .distance-info {
          font-size: $font-size-xs;
        }
      }
    }
    .text {
      font-size: $font-size-xs;
    }
  }
}
