@import '../../../css/mixin.scss';
@import '../../../css/vars.scss';

.manage-favorite-wrap{
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
    & > div {
        width: calc(100% - 8rem);
    }
}

.save-cancel-wrap{
    @include flexbox();
    @include justify-content(flex-end);
    margin-top: 1.2rem;
    button{
        margin-left: 1.2rem;
    }
}