@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.panel {
  margin-bottom: $gap-08;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-base;
  .panel-heading {
    background: $color-light-grayies;
    min-height: 2.4rem;
    padding: $gap-04 1.6rem;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    color: $color-blue-text;
    font-size: $font-size-xsm;
    font-weight: $font-bold;
  }
  .panel-body {
    padding: $gap-08 1.6rem $gap-04 1.6rem;
    background: $color-white;
    border-bottom-left-radius: $gap-04;
    border-bottom-right-radius: $gap-04;
  }
}
