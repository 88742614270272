@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.print-modal-icon {
  background: url("../../../img/print-bg.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -2px;
}
.watch-modal-icon {
  background: url("../../../img/watch.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -2px;
}
.print-modal-cls {
  .modal-content-area {
    margin-top: 28px;
  }
}
.ant-modal-body {
  .print-modal-header {
    justify-content: unset;
  }
}
.print-content-area {
  display: flex;
  justify-content: space-evenly;
  // margin-bottom: 28px;
  button {
    margin-bottom: 28px;
  }
}
.large-button {
  width: 314px;
  height: 44px;
}
.print-modal-footer {
  border-top: 1px solid #d2ddef;
  margin-left: -24px;
  margin-right: -24px;
}
.appointment-modal-footer {
  border-top: 1px solid #d2ddef;
  margin-left: -24px;
  margin-right: -24px;
  padding-top: 12px;
  padding-right: 20px;
}
.footer-button {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  margin-top: 10px;
}
.dropdown-with-icon {
  position: relative;
  margin-right: 0px;
  width: 33px;
  margin-left: 8px;
  background: #32c788;
  height: 20px;
  border-radius: 3px;
  .transaction-icons {
    background: url("../../../img/money.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .document-icons {
    background: url("../../../img/document-icon.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .share-icons {
    background: url("../../../img/share-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  &.fax-icon {
    background: url("../../../img/fax-icon.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block
  }
  .print-icons {
    background: url("../../../img/print-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .scan-icons {
    background: url("../../../img/scan-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .email-icons {
    background: url("../../../img/email-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .edit-icons {
    background: url("../../../img/edit-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .diagonos-icons {
    background: url("../../../img/diagnose-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .note-icons {
    background: url("../../../img/note-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .delete-icons {
    background: url("../../../img/delete-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .watch-icons {
    background: url("../../../img/watch.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .search-icons {
    background: url("../../../img/search-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .download-icons {
    background: url("../../../img/download-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .user-icons {
    background: url("../../../img/user-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .map-icons {
    background: url("../../../img/map-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .camra-icons {
    background: url("../../../img/camra-bg.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .payment-icons {
    background: url("../../../img/payment-icon.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .insurance-icons {
    background: url("../../../img/umbrella.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .claim-icons {
    background: url("../../../img/claim-icon.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .task-icons {
    background: url("../../../img/task-icon.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .invite-icon-red {
    background: url("../../../img/invite.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  .invite-icon-gray {
    background: url("../../../img/invite2.png");
    background-size: cover;
    border-radius: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 0px;
    cursor: pointer;
    display: inline-block;
  }
  span + li {
    width: 12px;
    height: 20px;
    position: absolute;
    top: 0px;
    left: 20px;
    &:before {
      content: "";
      position: absolute;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2.5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      top: 5px;
      left: 3px;
    }
  }
}

@media (max-width: $tablet-medium) {
  .print-content-area {
    display: block;
  }
  .print-modal-cls {
    .addition-header {
      padding-right: 30px;
    }
  }
}
