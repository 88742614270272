@import "../../css/vars";
@import "../../css/root";
@import "../../css/mixin";

.custom-select-box {
  label {
    font-size: $font-size-xsm;
    display: inline-block;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 2.8rem;
      border-radius: 4px;
      border: 1px solid rgba(165, 188, 223, 0.7);
    }
  }
  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-item {
        line-height: 2.7rem;
        color: $color-blue-light;
        font-weight: $font-regular;
        font-size: $font-size-m;
        .add-shield-icon,
        .flex.justify-content-sp-bt {
          & > div,
          & > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .ant-select-selection-placeholder {
        line-height: 2.8rem;
        color: $content-color;
        font-size: $font-size-s;
        font-weight: $font-regular;
        opacity: 1;
      }
    }
  }
  .ant-form-item-label > label {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    @include align-content(center);
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $gray-panel-shadow;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
}

.tree-select-dropdown-component {
  .ant-select-tree-treenode {
    padding-left: 6px !important;
    &.ant-select-tree-treenode-switcher-open,
    &.ant-select-tree-treenode-switcher-close {
      padding-left: 0px !important;
    }
  }
  .ant-select-tree-treenode-selected {
    &.ant-select-tree-treenode-switcher-open,
    &.ant-select-tree-treenode-switcher-close {
      padding-left: 0px !important;
      .ant-select-tree-switcher:before {
        display: none;
      }
    }
    .ant-select-tree-switcher:before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../img/right-tick.png") no-repeat center / contain;
      display: inline-block;
      transform: scale(0.7);
    }
  }

  .ant-select-tree-node-selected {
    background-color: $color-green !important;
    color: $color-white !important;
    border-radius: $border-radius-base;
    .ant-select-tree-node-content-wrapper:hover {
      background-color: $color-green;
    }
    svg {
      color: $color-white !important;
    }
  }
}
