@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.ba-wrapper {
  height: 95vh;
  .header-section {
    padding: 0px 0px $gap-08;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    h2 {
      margin-bottom: 0;
    }
    .header-btn {
      margin-left: 10px;
    }
  }
  .content {
    @include flexbox();
    @include justify-content(space-between);
    flex: 10;
    .doctor-list-section {
      flex: 3;
      margin-right: 2.4rem;
    }
    .map-section {
      flex: 4;
      margin-right: 2.4rem;
    }
    .patient-section {
      flex: 3;
    }
  }
  .filter-header {
    .filter-left {
      @include flexbox;
      @include flex-wrap(wrap);
      &.custom-ui-fix-header {
        .ant-col-8 {
          display: inline-block;
          flex: 0 0 auto;
          max-width: initial;
        }
      }
      .input-wrap {
        margin-bottom: 0;
        margin-right: 1.6rem;
        &.sub-special-wrap {
          margin-right: 1.6rem;
          margin-bottom: 0;
          .ant-select-multiple .ant-select-selector {
            padding: 0px 4px;
            height: 2.8rem;
            .ant-select-selection-item{
              margin-top: 0.1rem;
            }
          }
          & + .input-wrap {
            max-width: 11.6rem;
            @media (min-width: $lg-desktop) {
              min-width: 11.6rem;
            }
            .ant-col-16 {
              padding-left: 4px;
              flex: 100%;
              -webkit-flex: 100%;
              max-width: calc(100% - 3.2rem);
              @media (min-width: $lg-desktop) {
                max-width: calc(100% - 3.6rem);
              }
            }
            & + .input-wrap {
              .ant-select {
                width: 10rem !important;                
              }
              .ant-col-16 {
                padding-left: 4px;
              }
            }
          }
        }
        & + .filter-buttons {
          button {
            margin-bottom: 8px;
          }
        }
      }
      .filter-buttons {
        & button:nth-child(2) {
          margin-left: 0.5rem;
        }
      }
    }
    .insurance-tree-wrap {
      .ant-form-item-no-colon {
        color: $content-color;
        margin-right: 6px;
        font-size: $font-size-xsm;
      }
    }
    .ant-form-item-explain {
      &.ant-form-item-explain-error {
        font-size: $font-size-xsm;
      }
    }
  }
  .custom-input,
  .custom-autocomplete,
  .custom-select {
    .ant-row {
      .ant-form-item-label {
        & > label {
          color: $color-blue-light;
          font-size: $font-size-xsm;
          height: 2.6rem;
        }
      }
    }
  }
  .list-wrapper {
    height: calc(100vh - 106px);
    overflow-y: scroll;
  }
  .custom-ui-fix-header{
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
      height: 2.8rem;
    }
  }
}
.custom-ui-fix-header {
  .ant-form-item-required::after {
    content: "*";
    color: $color-red;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #748aac !important;
    font-size: 1.3rem;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #748aac!important;
    font-size: 1.3rem;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #748aac!important;
    font-size: 1.3rem;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #748aac!important;
    font-size: 1.3rem;
  }
}

@media (min-width: $lg-desktop) {
  .ba-wrapper {
    .custom-select .ant-row .ant-form-item-label > label,
    .custom-input .ant-row .ant-form-item-label > label {
      padding-top: 0;
      line-height: 2.6rem;
    }
    .filter-header {  
      .filter-left {
        .input-wrap {
          min-width: 25.5rem;
          &:nth-child(5){
            min-width: auto;
          }
        }
      }
    }
  }
  .filter-header {
    .filter-left {
      .input-wrap {
        min-width: 16.5rem;
        .custom-select {
          .ant-select {
            width: 100% !important;
          }
        }
        .insurance-tree-wrap .custom-select .ant-select{
          width: calc(100% - 9.4rem) !important;
        }
      }
    }    
  }
}

@media (max-width: $lg-tablet) {
  .ba-wrapper {
    .content {
      @include flex-wrap(wrap);
      flex: auto;
      .doctor-list-section {
        flex: auto;
        margin-right: $gap-12;
        width: 48%;
        order: 2;
      }
      .map-section {
        flex: auto;
        margin-right: 0;
        order: 1;
        width: 100%;
        height: 36.4rem;
        margin-bottom: $gap-12;
      }
      .patient-section {
        flex: auto;
        width: 48%;
        order: 3;
      }
    }
  }
}

@media (max-width: $tablet-medium) {
  .ba-wrapper .filter-header .filter-left .input-wrap {
    width: 100%;
    margin-right: 0;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-row,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-input .ant-row {
    display: block;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-select {
    width: 100% !important;
    min-width: 100% !important;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-autocomplete .ant-select {
    width: 100% !important;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-8,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-10,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-6 {
    min-width: 12rem;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-16,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-14,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-select .ant-col.ant-col-18,
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-input .ant-col.ant-col-19 {
    // max-width: calc(100% - 12.0rem);
    max-width: 100%;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-autocomplete .ant-col.ant-col-5 {
    min-width: 120px;
  }
  .ba-wrapper .filter-header .filter-left .input-wrap .custom-autocomplete .ant-col.ant-col-19 {
    max-width: calc(100% - 12rem);
  }
  .ba-wrapper .filter-header .filter-left .filter-buttons {
    margin-top: 0;
    margin-bottom: $gap-12;
  }
  .ba-wrapper .content .doctor-list-section {
    flex: auto;
    margin-right: 0;
    width: 100%;
    order: 2;
    margin-bottom: $gap-12;
  }
}

@media (min-width: $xlg-desktop) {
  .filter-header {
    .filter-left {
      .input-wrap {
        min-width: 22.2rem;
      }
    }
  }
}
