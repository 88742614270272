@import "../../css/vars";

.referring-physician-autocomplete-container {
  position: relative;
  padding-bottom: $gap-12;
  margin-bottom: -$gap-12;
  width: 100%;
  .all-states-checkbox-row {
    position: absolute;
    top: 2.5rem;
    overflow: hidden;
    width: 100%;
    .all-states-checkbox {
      width: 100%;
      z-index: 1000;
      line-height: 1;
      padding-left: $gap-02;
      border-bottom-left-radius: $gap-03;
      border-bottom-right-radius: $gap-03;
      background-color: $content-dark-subcolor;
      transform: translateY(-8px) scaleY(0);
      transition: all 0.1s ease;
      span {
        font-size: $font-size-xxm;
      }
      .ant-checkbox {
        transform: scale(0.8);
      }
    }
    .show {
      transform: translateY(-2px) scaleY(1);
    }
  }
}
