@import '../../../../../../../../../../css/mixin.scss';
@import '../../../../../../../../../../css/vars.scss';

.in-between-space {
    height: 5px;
}
.in-between-group-drag {
    background: #fdaf1e !important;
}

.in-between-space-hover {
    height: 38px;
    background: #b9cce7;
}
.ap-parent {
    width: 100%;
    // background: #f2f7ff;
    background: #f2f7ffb5;
    margin-bottom: 4px;
    border-radius: 4px;
    font-size: 13px;
    color: #2a4a79;
    & > div {
        & > div {
            & > div {
                position: relative;
                border: 1px solid #cccccc;
                padding-left: 35px;
                margin-bottom: 2px;
                border-radius: 2px;

                .positive-relative {
                    display: block;
                    padding-right: 42px;
                }
                &:after {
                    content: "";
                    width: 26px;
                    height: 100%;
                    background: #ccc;
                    position: absolute;
                    top: 0;
                    left: 0px;
                    background-image: url("../../../../../../../../../../img/grid-fill4.png");
                    background-repeat: no-repeat;
                    background-position: 2px 0px;
                    background-size: 84%;
                }
                &:first-child {
                    &::before {
                        display: none;
                        // bottom: 10px;
                        // top: auto;
                    }
                }
                &:last-child {
                    &::before {
                        // bottom: auto;
                        // top: 9px;
                    }
                }
                &:before {
                    content: "";
                    width: 8px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    bottom: 15px;
                    left: -34px;
                    display: none;
                }
                &.sub-l-child-group {
                    position: relative;
                    padding-left: 0;
                    border: none;
                    .in-between-space {
                        display: none;
                    }
                    & > div {
                        // position: relative;
                        position: relative;
                        border: 1px solid #cccccc;
                        padding-left: 38px;
                        margin-bottom: 2px;
                        border-radius: 2px;
                        min-height: 24px;
                        p {
                            margin-top: 4px;
                        }
                        &::before {
                            content: "";
                            width: 14px;
                            height: 2px;
                            background: #cccccc;
                            position: absolute;
                            bottom: 10px;
                            left: -15px;
                        }
                        &:after {
                            content: "";
                            width: 26px;
                            height: 100%;
                            background: #ccc;
                            position: absolute;
                            top: 0;
                            left: 0px;
                            background-image: url("../../../../../../../../../../img/grid-fill4.png");
                            background-repeat: no-repeat;
                            background-position: 2px 0px;
                            background-size: 84%;
                        }
                    }
                    &::before {
                        display: block;
                        content: "";
                        width: 2px;
                        height: calc(100% - 6px);
                        background: #cccccc;
                        position: absolute;
                        bottom: auto;
                        left: -15px;
                        top: -5px;
                    }
                    &:after {
                        content: "";
                        width: 0px;
                        height: calc(100% - 29px);
                        background: #000;
                        position: absolute;
                        top: 14px;
                        left: -34px;
                    }
                }
            }
        }
    }
}

// .ap-parent > div > div > div.sub-l-child-group::before {
//     display: block;
//     content: "";
//     width: 2px;
//     height: calc(100% - 6px);
//     background: #cccccc;
//     position: absolute;
//     bottom: auto;
//     left: -15px;
//     top: -5px;
// }

.order-set-copy-ui {
    p {
        color: #2a4a79;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: normal;
        margin-bottom: 1.6rem;
    }
    button{
        &:hover{
            background: $color-green;
            color: $color-white;
            border: 1px solid $color-green;
        }
    }
}

.ap-parent:before {
    content: "";
    width: 1px;
    height: calc(100% - 30px);
    background: #000;
    position: absolute;
    top: 15px;
    left: 14px;
    display: none;
}
.arrow-close {
    background: #ffffff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1.8px solid #0000007d;
    font-weight: bold;
    font-style: normal;
    line-height: 8px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 11px;
    color: #00000070;
    padding-left: 1px;
    font-family: sans-serif;
    padding-top: 1px;
}
.diagnosis-group-arrow {
    position: relative;
    padding: 8px 12px 6px;
    .arrow-close {
        background: url("../../../../../../../../../../img/close-circle.png");
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0px;
        right: 4px;
        border: none;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        text-indent: -999999px;
    }
    .arrow {
        background: #ffffff;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 7px;
        left: 6px;
        // border-radius: 50%;
        // border: solid 1px #d2ddef;
        border: solid 1px #000;
        cursor: pointer;
        z-index: 9;
        // background: url('../../../../../../../../../../../../img/grid-fill3.png');
        display: none;

        &:before {
            content: "+";
            // border: solid black;
            // border-width: 0 1px 1px 0;
            // display: inline-block;
            // padding: 3px;
            // position: absolute;
            // left: 4px;
            width: 6px;
            height: 6px;
        }
    }
    .right {
        &:before {
            content: "+";
            // transform: rotate(-45deg);
            // -webkit-transform: rotate(-45deg);
            top: -6px;
            left: 2px;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            position: absolute;
        }
    }
    .down {
        &:before {
            content: "-";
            // transform: rotate(45deg);
            // -webkit-transform: rotate(45deg);
            top: -15px;
            left: 3px;
            font-size: 28px;
            font-style: normal;
            font-weight: 300;
            position: absolute;
        }
    }
}
