@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";

.input-range-wrap {
  height: 28px;
  .ant-input-number-input {
    height: 28px;
  }
  .input-upto-range {
    font-size: $font-size-xsm;
    color: $color-blue-light;
    margin-left: 12px;
    display: inline-block;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.pannel {
  &.remittance-panel {
    .anticon-warning{
      background: url('../../../../img/warning-icon.png');
      width: 20px;
      height: 18px;
      svg{
        display: none;
      }
    }
    .insurance-header{
      & + .flex {
        &.justify-content-flex-end {
          margin-right: 1.2rem;
        }
      }
    }
    .pannel-heading {
      height: 42px;
      border-bottom: 1px solid $button-border;
    }
  }
}

.remittance-head {
  @include flexbox;
  width: 100%;
  position: relative;
  @include align-items(center);
  @include justify-content(center);
  .refresh-btn {
    margin-bottom: 0;
    position: absolute;
    right: 40px;
    svg{
      font-size: $font-size-l;
      margin-top: 3px;
    }
  }
  .upload-file-btn {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    svg{
      font-size: $font-size-l;
      margin-top: 3px;
    }
  }
  .lt-arrow {
    background: url("../../../../img/arrow-right.png");
    width: 14px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
    border-radius: 2px;
  }
  .rt-arrow {
    background: url("../../../../img/arrow-right.png");
    width: 14px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 8px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    border-radius: 2px;
  }
  button {
    height: 28px;
    padding: 0 8px;
    border: none;
    min-height: auto;
  }
}
.print-icon {
  @include flex(1);
  .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../../../img/printer.png");
    background-size: cover;
    cursor: pointer;
  }
}

.remittance-panel {
  .pannel-heading {
    .row {
      @include flexbox;
      color: $color-blue-text;
      font-size: $font-size-m;
      font-weight: $font-medium;
      margin-right: 12px;
      @include flex-wrap(wrap);
      &:last-child {
        margin-right: 0;
      }
      .key {
        margin-right: 3px;
      }
    }
  }
}

.remittance-collection {
  @include flexbox;
  .right-area {
    max-width: 60%;
    @include flex(1);
    transition: transform 2.5s;
    -webkit-transition: transform 2.5s;
    .remittance-panel{
      height: calc(85vh - 18px);
      overflow: auto;
      scroll-behavior: smooth;
      backface-visibility: hidden;
      background: $color-white;
    } 
      .panel-body {
      padding: 16px;
      background: $color-white;
      .head-title {
        font-size: $font-size-l;
        color: $color-blue-light;
        font-weight: $font-medium;
        margin-bottom: 16px;
      }
    }
  }
  .upload-document-preview .pdf-wrapper{
    height: 76vh;
    overflow-y: scroll;
  }
  .left-area {
    @include flex(1);
    max-width: calc(40% - 6px);
    margin-right: 12px;
    transition: transform 2.5s;
    -webkit-transition: transform 2.5s;
    .remittance-panel {
      @include flexbox;
      @include flex-direction(column);
      // height: 100vh;
      height: calc(100vh - 121px);
      &.remittance-image-wrap{
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(row);
        height: 100%;
        img{
          max-width: 280px;
        }
      }
    }
    .panel-body {
      @include flexbox;
      @include flex-direction(column);
      flex: 1;
      max-height: calc(100vh - 42px);
      background: $color-white;
      .head-title {
        font-size: $font-size-l;
        color: $color-blue-light;
        font-weight: $font-medium;
        margin-bottom: 16px;
      }
      .preview-container {
        flex: 1;
        .upload-document-preview {
          width: 100%;
          .preview-image-tools {
            z-index: 1;
          }
        }
      }
    }
  }
  .remittence-expend{
    position: absolute;
    top: calc(50% - 20px);
    background: #d0dae1;    
    width: 12px;
    height: 44px;
    left: -9px; 
    border: none;
    padding: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    cursor: pointer;
    .icon{
      background-image: url('../../../../img/arrow-split.png');
      display: inline-block;
      width: 7px;
      height: 9px;
      background-size: cover;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s
    }
  }
  .flex-end {
    @include justify-content(flex-end);
  }
  &.open{
    .left-area{
      margin-left: calc(-40% - 22px);
      margin-right: 2px;
      transition: transform 2.5s;
    -webkit-transition: transform 2.5s;
    }
    .right-area{
      max-width: 100%;
      transition: transform 2.5s;
    -webkit-transition: transform 2.5s;
    margin-left: 25px;
    }
    .remittence-expend{
      .icon{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: transform 0.5s;
        -webkit-transition: transform 0.5s
      }
    }
  }
}

.head-list {
  @include flexbox;
  @include flex-wrap(wrap);
  .list-one {
    @include flexbox;
    width: calc(50% - 10px);
    padding-right: 12px;
    &:last-child {
      width: 100%;
    }
    .key {
      color: $content-color;
      font-size: $font-size-xsm;
      margin-right: 24px;
      min-width: 86px;
    }
    .value {
      color: $color-blue-light;
      font-size: $font-size-xsm;
    }
  }
}
.insurance-table-btn {
  @include flexbox;
  @include justify-content(space-between);
  @include flex-direction(column);
  margin-top: 12px;
  .table-in {
    min-width: 354px;
    margin-bottom: 0;
  }
  .payment-btns {
    @include flexbox;
    margin-bottom: 12px;
    button {
      min-width: 172px;
    }
    &.mr-top-16 {
      text-align: right;
    }
    .table-discriptions {
      min-height: 3.6rem;
      padding: 0.6rem 1.3rem;
      font-size: 1.4rem;
      border-radius: 0.4rem;
      outline: none;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      margin-left: 1.2rem;
      background: #32c788;
      border: 0.1rem solid #32c788;
      color: #fff;
      p {
        margin-bottom: 0;
        line-height: 2.2rem;
      }
    }
    .disable-table-descriptions {
      background: transparent;
      border: 0.1rem solid #d2ddef;
      color: #748aac;
    }
  }
}

.collection-table {
  max-height: 166px;
  overflow-y: auto;
  @media (min-width : $lg-desktop){
    max-height: 40vh;
  }
  .app-table {
    border-radius: 0;
    box-shadow: none;
    table {
      tbody {
        tr {
          &:last-child {
            box-shadow: none;
          }
          &:nth-child(odd) {
            background: transparent;
          }
        }
      }
      th {
        background: #f1f5f8;
        color: $color-blue-light;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $button-border;
      }
    }
  }
  table {
    tbody {
      tr {
        &:last-child {
          box-shadow: none;
        }
      }
    }
  }
}

.line-item-table {
  width: 100%;
  overflow-x: auto;
  .app-table {
    width: 100%;
    min-width: 1000px;
    box-shadow: none;
    table {
      tbody {
        tr {
          &:last-child {
            box-shadow: none;
          }
          &:nth-child(odd) {
            background: transparent;
          }
        }
      }
      th {
        white-space: nowrap;
        padding: 8px 5px;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $button-border;
      }
    }
    th {
      @include justify-content(center);
    }
    td {
      @include justify-content(center);
    }
  }
  table {
    tbody {
      tr {
        &:last-child {
          box-shadow: none;
        }
      }
    }
  }
}

.patient-line-item-table {
  width: 100%;
  overflow-x: auto;
  .app-table {
    width: 100%;
    min-width: 700px;
    box-shadow: none;
    table {
      tbody {
        tr {
          text-align: center;
          &:last-child {
            box-shadow: none;
          }
          &:nth-child(odd) {
            background: transparent;
          }
        }
      }
      th {
        white-space: nowrap;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $button-border;
      }
    }
    th {
      @include justify-content(center);
    }
    td {
      @include justify-content(center);
    }
  }
  table {
    tbody {
      tr {
        &:last-child {
          box-shadow: none;
        }
      }
    }
  }
}

.collection-table-wrap {
  @include flexbox;
  @include flex-direction(column-reverse);
  .table-discriptions {
    margin-bottom: 8px;
  }
}
.table-in {
  max-width: 340px;
  margin-bottom: 16px;
  &.app-table {
    box-shadow: none;
    overflow: hidden;
    border-radius: 0;
    table {
      tbody {
        tr {
          &:last-child {
            box-shadow: none;
          }
        }
      }
      td {
        &:first-child {
          border-right: 1px solid $button-border;
        }
      }
    }
  }
  &.app-table table tbody tr:nth-child(odd) {
    background: transparent;
  }
}

.white-tabs {
  .h-tabs {
    margin-top: 0;
    .ant-tabs-nav-wrap {
      background: $color-white;
      margin-left: 24px;
      border-radius: $border-radius-base;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
  }
}
.remittance-back-arrow {
  display: inline-block;
  &:before {
    top: 8px !important;
  }
}

@media (max-width: $min-desktop) {
  .remittance-collection {
    display: block;
    .left-area {
      max-width: 100%;
      margin-right: 0;
      .remittance-panel {
        height: 54vh;
      }
    }
    .right-area {
      max-width: 100%;
      margin-top: 32px;
    }
  }
}

@media (max-width: $tablet-medium) {
  .collection-table-wrap {
    display: block;
    .table-discriptions {
      margin-left: 0;
    }
  }
  .pannel {
    &.remittance-panel {
      .pannel-heading {
        min-height: 43px;
      }
    }
  }
  .payment-btns {
    button {
      margin-bottom: 12px;
      & + button {
        margin-bottom: 0;
      }
    }
  }
  .remittance-panel {
    .social-icons {
      &.open-social {
        &.mobile-social {
          ul {
            top: 88px;
          }
        }
      }
    }
  }
  .remittance-back-arrow {
    &::before {
      top: 0 !important;
    }
  }
}
.edit-batch {
  background: url("../../../../img/edit-bg.png");
  background-size: cover;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}

.remittance-error-list {
  max-width: 700px;
  max-height: 300px;
  .remittance-error-list-item {
    padding: 4px 12px 0px;
    margin: 5px;
  }
}
