@import "../../../../../../css/vars.scss";
@import "../../../../../../css/mixin.scss";

.financial-insurance-payment {
  .line-item-table {
    width: 100%;
    overflow-x: auto;
    .app-table {
      width: 100%;
      min-width: fit-content;
      border-radius: 0;
      box-shadow: none;
      table {
        tbody {
          tr {
            text-align: center;
            &:last-child {
              box-shadow: none;
            }
            &:nth-child(odd) {
              background: transparent;
            }
          }
        }
        th {
          white-space: nowrap;
          padding: 8px 6px;
          text-align: center;
          &:first-child{
            text-align: left;
          }
        }
      }
      thead {
        tr {
          border-bottom: 1px solid $button-border;
        }
      }
      th {
        @include justify-content(center);
      }
      td {
        @include justify-content(center);
      }
    }
    table {
      tbody {
        tr {
          &:last-child {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.financial-insurance-payment-form {
  .fields-wrapper {
    .modal-from-feilds {
      .feild-row {
        margin-bottom: 10px;
        .ant-row {
          width: 100%;
          .ant-col {
            flex: 1;
          }
        }
      }
    }
  }
}

.payer-wrapper {
  position: relative;
  .plus-icon {
    display: none;
    position: absolute;
    top: 4px;
    right: 0px;
    color: $color-green;
    font-size: 20PX;
    cursor: pointer;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 26px;
  }
  .edit-icon{
    position: absolute;
    top: 0px;
    right: 41px;
    width: 20px;
    height: 28px;
    background: url('../../../../../../img/pencil-fill2.png');
    background-position: center;
    background-repeat: no-repeat;
  }
  &.with-edit-option{
    .ant-select-selection-search{
      input{
        padding-right: 33px;
      }
    }
  }
}

.claim-head-detail{
  @include flexbox;
  margin-bottom: $gap-08;
  color: $color-blue-light;
}